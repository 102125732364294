import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alertBarHeight, drawerWidth, navbarHeight } from '../../../constants';

export const style = makeStyles(() =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            marginTop: navbarHeight,
            height: `calc(100vh - ${navbarHeight}px)`,
        },
        alertbar: {
            marginTop: `${navbarHeight + alertBarHeight}px !important`,
            height: `calc(100vh - ${navbarHeight + alertBarHeight}px)`,
        },
        drawerContainer: {
            overflow: 'auto',
            paddingBottom: 10,
            background: 'white',
        },
        drawerToggler: {
            left: 27,
        },
    })
);
