import { useEffect, useState } from 'react';
import { Login } from './login';
import { SignUp } from './signup';
import { SignupSuccess } from './signupSuccess';
import { ForgotPassword } from './forgotPassword';
import { ResetPassword } from './resetPassword';
import { SubscriptionSuccess } from '../stripe/subscriptionSuccess';
import Pricing from '../stripe/Pricing';
import { Route, Switch, useHistory } from 'react-router-dom';
import { authRoutes, routes } from '../pages/routes';
import { Auth as amplify } from 'aws-amplify';
import { Alert } from '../common';
import { navbarHeight } from '../../constants';
import { InformationModal } from '../common/modals/informationModal'

export const Auth = () => {
    const history = useHistory();
   

    const getCurrentUser = async () => {
        try {
            await amplify.currentAuthenticatedUser();
        } catch {
            if (authRoutes.includes(history?.location?.pathname))
                return history.push(`${history?.location?.pathname}${history?.location?.search}`);

            if (!authRoutes.includes(history?.location?.pathname))
                return history.push({
                    pathname: routes.login,
                    search:
                        history?.location?.pathname !== routes.root
                            ? '?' + new URLSearchParams({ redirect: window.location.href })
                            : '',
                });
        }
    };
    useEffect(() => {
        getCurrentUser();
    }, []);

   

    return (
        <div>
            <Switch>
                <Route path={routes.login} exact component={Login} />
                <Route path={routes.signup} exact component={SignUp} />
                <Route path={routes.confirm} exact component={SignupSuccess} />
                <Route path={routes.resetPassword} exact component={ResetPassword} />
                <Route path={routes.forgotPassword} exact component={ForgotPassword} />
                <Route path={routes.selectPlan} exact component={Pricing} />
                <Route path={routes.subscription} exact component={SubscriptionSuccess} />

                <Route path="*" exact component={Login} />
            </Switch>
            <Alert top={0} />
        </div>
    );
};
