/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6aa52faa-fcd9-4d7e-8bac-54f174228e6b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VDC5HOS7q",
    "aws_user_pools_web_client_id": "5gljna6kk61h4efbis6p04fmff",
    "oauth": {
        "domain": "nimdone-ci.auth.us-east-1.amazoncognito.com"
    },
    "aws_user_files_s3_bucket": "nimdone-user-files-ci",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;