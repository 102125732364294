import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { style } from './style';
import { RootState } from '../../../redux/combineReducers';
import { hideAlert } from '../../../redux/reducers/alertHandler';
import { SuccessIcon } from '../../../assets/icons/success';
import { ErrorIcon } from '../../../assets/icons/error';
import { alertTypes } from '../../../constants';

interface AlertProps {
    top: number;
}
export const Alert = ({ top }: AlertProps) => {
    const classes = style();
    const { type, isVisible, message } = useSelector((state: RootState) => state.AlertHandler);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideAlert());
    };

    return (
        <Snackbar
            style={{ top: top }}
            className={classes.container}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isVisible}
            message={
                <span className={classes.alertContent}>
                    {type === alertTypes.error && <ErrorIcon className={classes.alertIcon} />}
                    {type === alertTypes.success && <SuccessIcon className={classes.alertIcon} />} {message}
                    <span className={classes.alertClose} role="presentation" onClick={handleClose}>
                        Close
                    </span>
                </span>
            }
            key={'alert-bar'}
            ContentProps={{
                classes: {
                    root: `${classes.root} ${type === alertTypes.error ? classes.error : classes.success}`,
                },
            }}
        />
    );
};
