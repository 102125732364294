import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        uploadModalContent: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '40px 80px',
            outline: 'none',
        },
        dropzone: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            background: colors.grayBackground,
            border: `2px dashed ${colors.darkTeal}`,
            boxSizing: 'border-box',
            borderRadius: 12,
            outline: 'none',
            padding: 24,
        },
        uploadModalHeading: {
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
            fontSize: 32,
            letterSpacing: 0.15,
            color: 'rgba(55, 53, 47, 0.6)',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: 0,
        },
        uploadModalsubHeading: {
            fontFamily: 'Roboto',
            fontSize: 16,
            letterSpacing: 0.15,
            color: 'rgba(55, 53, 47, 0.6)',
            textAlign: 'center',
        },
        uploadModalDnDText: {
            fontFamily: 'Roboto',
            fontSize: 16,
            letterSpacing: 0.15,
            color: colors.darkTeal,
            margin: 0,
        },
        uploadModalBtn: {
            width: '70%',
            borderRadius: '30px',
            marginTop: 10,
            fontWeight: 'normal',
        },
        uploadedFilesHeadingContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            marginBottom: 5,
        },
        uploadedFilesHeading: {
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: 15,
            color: colors.smoke,
            letterSpacing: 0.2,
        },
        UploadCloudIcon: {
            width: 104,
            height: 104,
            marginBottom: 10,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
        },
    })
);
