import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        modalList: {
            paddingLeft: '10px',
            margin: 0,
            maxWidth: "180px"
        },
    })
);

export const TitleSlideHelpContent = () => {
    const classes = useStyles();
    return <p className={classes.modalList}>Use this page to introduce your presentation</p>;
};
