import { Grid } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import { alertTypes } from '../../../../constants';
import { MuiCMButton } from '../../';
import { style } from './style';
import { HelpInfoTooltip } from '../../helpInfoToolTip';
import { InformationModal as InformationModalProps } from '../../../../types';

export const InformationModal = ({
    open,
    handleClose,
    dataTestId,
    type,
    title,
    helpInfo,
    subTitle,
    cancelBtnLabel,
    cancelBtnLoading,
    handleConfirm,
    successBtnLabel,
    closeBtnLabel,
    footer,
    temp,
    titleInCenter,
    subTitleInCenter = true,
    hideBackdrop = true,
}: InformationModalProps) => {
    const classes = style();

    return (
        <Modal
            open={open}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
            data-testid={dataTestId}
            className={classes.modal}
            closeAfterTransition
            hideBackdrop={hideBackdrop}
        >
            <Fade in={open}>
                <div className={classes.content}>
                    <Grid container spacing={1} justifyContent={'space-between'}>
                        <Grid style={{ width: `${titleInCenter ? '100%' : ''}` }} item>
                            <p className={classes.title} style={{ width: `${titleInCenter ? '100%' : ''}` }}>
                                {type && (
                                    <span className={classes.iconContainer}>
                                        {type == alertTypes.success ? (
                                            <CheckCircleRounded className={classes.successIcon} />
                                        ) : (
                                            <WarningRounded className={classes.warningIcon} />
                                        )}
                                    </span>
                                )}
                                {title}
                            </p>
                        </Grid>
                        {helpInfo && <HelpInfoTooltip title={helpInfo} />}
                    </Grid>

                    {subTitle && (
                        <div className={classes.subTitle} style={{ textAlign: subTitleInCenter ? 'center' : 'left' }}>
                            {subTitle}
                        </div>
                    )}

                    <div className={classes.bottomContainer}>
                        <div className={classes.buttonContainer}>
                            {!!cancelBtnLabel && (
                                <MuiCMButton
                                    variant="outlined"
                                    loading={cancelBtnLoading}
                                    onClick={handleClose}
                                    role="cancel-btn"
                                >
                                    {cancelBtnLabel}
                                </MuiCMButton>
                            )}
                            {!!successBtnLabel && (
                                <MuiCMButton onClick={handleConfirm} className={classes.enterDoiBtn} role="success-btn">
                                    {successBtnLabel}
                                </MuiCMButton>
                            )}
                            {!!closeBtnLabel && (
                                <MuiCMButton onClick={handleClose} className={classes.closeBtn} role="close-btn">
                                    {closeBtnLabel}
                                </MuiCMButton>
                            )}
                        </div>
                        {!!footer && <div className={classes.footer}>{footer}</div>}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
