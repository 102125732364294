import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles<Theme>((theme) =>
    createStyles({
        toggleContainer: {
            width: 35,
            backgroundColor: '#70C3D6',
            cursor: 'pointer',
            userSelect: 'none',
            borderRadius: '73px',
            border: '2px solid #25282B',
            height: '14px',
            padding: '2px',
            position: 'relative',
            marginLeft: '5px',
            display: 'flex',
            alignItems: 'center',
            left: '3.57%',
            right: '3.57%',
            top: '2.83%',
            bottom: '20.83%',
        },
        toggleButton: {
            backgroundColor: 'white',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '6px',
            height: '6px',
            borderRadius: '80px',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '63.71%',
            top: ' 20.5%',
            right: '17.86%',
            bottom: '37.5%',
            transition: 'all 0.3s ease',
            border: '2px solid #25282B',
        },
        text: {
            fontSize: '8px',
            fontFamily: 'Roboto',
            position: 'absolute',
            paddingLeft: '1px',
            fontWeight: 400,
        },
        disabledText: {
            fontSize: '8px',
            fontFamily: 'Roboto',
            position: 'absolute',
            color: colors.black,
            left: '34.43%',
            fontWeight: 400,
        },
        disabled: {
            backgroundColor: colors.gray,
            left: '2px',
        },
        disabledButton: {
            backgroundColor: 'white',
            left: '9%',
            top: '20.5%',
        },
        toggleOn: {
            backgroundColor: 'white',
        },
    })
);
