import { executeGraphqlCall } from './api';
import { Layout } from '../types';

export const getLayouts = async (
    textItems: number,
    mediaItems: number,
    layoutType: string,
    themeId: string
): Promise<Layout[]> => {
    const variables = {
        data: {
            textItems,
            mediaItems,
            layoutType,
            themeId,
        },
    };
    const body = `query Layouts($data: GetLayoutsInput!) {
        layouts(data: $data) {
          id
          name
        }
      }`;

    return executeGraphqlCall<Layout[]>('layouts', body, undefined, variables);
};
