import { SvgIcon } from '@material-ui/core';

export const SlidePreviewWhite = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.25 0H6.6795V1.66425H5.25V0Z" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9948 1.96571C11.9948 1.71521 11.7555 1.51196 11.4608 1.51196H0.585758C0.290258 1.51196 0.0517578 1.71521 0.0517578 1.96571V2.95271H0.773258V8.98946H4.09876L2.37751 11.043C2.25676 11.1645 2.30176 11.4067 2.47951 11.5845L2.80201 11.9062L5.29726 9.07871V11.976H6.68551V9.00146L9.24976 11.9055L9.57151 11.5837C9.74926 11.4067 9.79501 11.1637 9.67351 11.0422L7.89976 8.98871H11.2043V2.95196H11.9963V1.96571H11.9948ZM10.3078 8.15379H1.69238V5.69225V2.95271H10.3078V5.69225V8.15379Z"
                    fill="white"
                />
            </svg>
        </SvgIcon>
    );
};
