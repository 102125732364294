import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

import { useOutlineSlidePreviewStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/combineReducers';
import { getPresentation, getSlide } from '../../backend/slide';
import { showAlert, setPresentation, updateSlide } from '../../redux/reducers';
import { alertTypes } from '../../constants';
import { Slide } from './components/slide';
import { Slide as SlideInterface } from '../../types';
import { useCustomEventListener } from 'react-custom-events';
import { useQuery } from '../../utils';

export const OutlineSlidePreview = () => {
    const classes = useOutlineSlidePreviewStyles();

    const query = useQuery();
    const projectId = query.get('projectId');
    const Projects = useSelector((state: RootState) => state.Projects);
    const [selectedProject] = Projects.filter((project) => project.id === projectId);

    const [loading, setLoading] = useState<boolean>(true);
    const { presentation } = useSelector((state: RootState) => state.ProjectPresentation);
    const { outline } = useSelector((state: RootState) => state.ProjectOutline);
    const outlinePagesCount = outline && outline.pages ? outline.pages.length : 0;

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProject && outlinePagesCount > 0) {
            setLoading(true);
            (async () => {
                try {
                    const outlinePresentation = await getPresentation(selectedProject.id);
                    dispatch(
                        setPresentation({
                            presentation: outlinePresentation,
                        })
                    );
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    if (error instanceof Error)
                        dispatch(
                            showAlert({
                                isVisible: true,
                                type: alertTypes.error,
                                message: error.message,
                            })
                        );
                }
            })();
        } else {
            setLoading(false);
            dispatch(
                setPresentation({
                    presentation: null,
                })
            );
        }
    }, [selectedProject, setLoading, dispatch, outlinePagesCount]);

    const updateOutlinePageSlide = async ({ outlinePageId }: any) => {
        try {
            const slide = await getSlide(outlinePageId);
            dispatch(updateSlide({ slide }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        message: error.message,
                        type: alertTypes.error,
                        isVisible: true,
                    })
                );
        }
    };

    // listen changes to outline page
    // fetch the updated respective slide
    useCustomEventListener(`outline-page-updated`, updateOutlinePageSlide);

    return (
        <Box className={classes.readerViewRoot}>
            {!loading && (!presentation || presentation.slides.length === 0) && (
                <div className={classes.emptySlides}>No Slides to preview</div>
            )}
            {loading && !presentation ? (
                <p>Loading slides</p>
            ) : (
                presentation?.slides.map((slide: SlideInterface) => <Slide key={slide.id} slide={slide} />)
            )}
        </Box>
    );
};
