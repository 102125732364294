import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SearchIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.49998 1.875C8.54846 1.87496 9.57609 2.16796 10.4669 2.72094C11.3577 3.27392 12.0762 4.06487 12.5414 5.00453C13.0065 5.94418 13.1998 6.99514 13.0993 8.03881C12.9989 9.08247 12.6088 10.0773 11.973 10.911L15.5302 14.4697C15.6647 14.6047 15.7428 14.7858 15.7486 14.9763C15.7545 15.1667 15.6876 15.3523 15.5615 15.4952C15.4355 15.6381 15.2598 15.7277 15.0701 15.7458C14.8804 15.7639 14.691 15.7091 14.5402 15.5925L14.4697 15.5303L10.911 11.973C10.2007 12.5146 9.37172 12.8792 8.49256 13.0368C7.6134 13.1943 6.70935 13.1403 5.85523 12.8791C5.00111 12.6179 4.22147 12.157 3.58082 11.5347C2.94017 10.9123 2.45694 10.1463 2.1711 9.30014C1.88526 8.45394 1.80504 7.55184 1.93707 6.66848C2.06911 5.78513 2.4096 4.94591 2.93037 4.22027C3.45115 3.49463 4.13723 2.90344 4.93186 2.49561C5.72649 2.08779 6.60681 1.87505 7.49998 1.875ZM7.49998 3.375C6.40596 3.375 5.35675 3.8096 4.58316 4.58318C3.80958 5.35677 3.37498 6.40598 3.37498 7.5C3.37498 8.59402 3.80958 9.64323 4.58316 10.4168C5.35675 11.1904 6.40596 11.625 7.49998 11.625C8.594 11.625 9.64321 11.1904 10.4168 10.4168C11.1904 9.64323 11.625 8.59402 11.625 7.5C11.625 6.40598 11.1904 5.35677 10.4168 4.58318C9.64321 3.8096 8.594 3.375 7.49998 3.375Z"
                    fill="#BABABA"
                />
            </svg>
        </SvgIcon>
    );
};
