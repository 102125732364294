import { Avatar, Button, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../redux/combineReducers';
import { AccSettingsForm } from './components/form';
import { style } from './style';
import { DeleteAccountModal } from './components/deleteAccount/deleteAccoutModal';

export const AccountSettings: React.FC = () => {
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const Speaker = useSelector((state: RootState) => state.Speaker);
    const dispatch = useDispatch();
    const classes = style();

    const matches = Speaker.name.match(/\b(\w)/g);
    const acronym = matches?.join('');

    return (
        <div className={classes.settingContainer}>
            <Typography variant="h2" className={classes.heading}>
                Account Settings
            </Typography>
            {Speaker.email && (
                <div className={classes.settingContent}>
                    <Avatar className={classes.avatarDefault}>{acronym}</Avatar>
                    <AccSettingsForm speaker={Speaker} dispatch={dispatch} />
                </div>
            )}
            <Paper className={classes.delAccPaper} elevation={0}>
                <Button
                    variant="text"
                    size="large"
                    className={classes.delAccButton}
                    onClick={() => setDeleteModalOpen(true)}
                    role="del-account-option"
                >
                    Delete Account
                </Button>
            </Paper>
            <DeleteAccountModal open={deleteModalOpen} setOpen={setDeleteModalOpen} speaker={Speaker} />
        </div>
    );
};
