import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

const smallSpace = 8;

export const textOptionStyle = makeStyles(() => ({
    textOptionContainer: {
        display: 'flex',
        marginLeft: `${smallSpace}px`,
        flex: 1,
        alignItems: 'center',
    },
    textOption: {
        color: colors.darkTeal,
        cursor: 'pointer',
        margin: 0,
        fontSize: 12,
        fontWeight: 600,
    },
    orText: {
        color: colors.grayText,
    },
    transparentButton: {
        backgroundColor: 'transparent',
        border: 'none',
        padding:0
    },
}));
