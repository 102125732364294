import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModifiedPaperFile } from '../../../types';

const initialState: ModifiedPaperFile = {
    isCreateProjectWithPaper: false,
    citation: undefined,
    id: '',
    name: '',
    paper: {},
};

const paperSlice = createSlice({
    name: 'PaperFile',
    initialState,
    reducers: {
        setCreateProjectWithPaper: (state, action: PayloadAction<{ isCreateProjectWithPaper: boolean }>) => {
            state.isCreateProjectWithPaper = action.payload.isCreateProjectWithPaper;
            return state;
        },
        setPaperFile: (state, action: PayloadAction<ModifiedPaperFile>) => {
            return { ...state, ...action.payload };
        },
        setCitation: (state, action: PayloadAction<{ citation: string }>) => {
            return { ...state, ...action.payload };
        },
        resetPaperFile: () => {
            return { ...initialState };
        },
    },
});

export const { setCreateProjectWithPaper, setPaperFile, setCitation, resetPaperFile } = paperSlice.actions;
export default paperSlice.reducer;
