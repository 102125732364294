import { Container, Divider } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectPaperFiles } from '../../../../backend/project';
import { RootState } from '../../../../redux/combineReducers';
import { showAddProject, showAlert, updatePaperFileArray } from '../../../../redux/reducers';
import { PaperFile, ProjectInterface } from '../../../../types';
import { style } from './style';
import { useHistory } from 'react-router';
import { useQuery } from '../../../../utils';
import { colors } from '../../../../assets/colors';
import { alertTypes } from '../../../../constants';
import { AddCircularBtn, DrawerWrapper } from '../../../common';

export const ProjectSelector = () => {
    const classes = style();
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const key = query?.get('key');
    const doi = query.get('doi');
    const Projects = useSelector((state: RootState) => state.Projects);

    const handleAddProject = () => dispatch(showAddProject({ addProjectModal: true }));

    const handleSelectProject = async (item: ProjectInterface) => {
        try {
            let paperIdentity = null;
            if (key) paperIdentity = `&key=${key}`;
            else if (doi) paperIdentity = `&doi=${doi}`;

            const path = `/reader?projectId=${item.id}${paperIdentity && paperIdentity}`;

            history.push(path);

            const project = await getProjectPaperFiles(item.id);
            dispatch(updatePaperFileArray({ projectId: item.id, paperFile: project?.paperFiles as PaperFile[] }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        message: error.message,
                        type: alertTypes.error,
                        isVisible: true,
                    })
                );
        }
    };

    return (
        <div className={classes.root}>
            <DrawerWrapper anchor="right" open={true}>
                <Container className={classes.drawerHeader}>
                    <p className={classes.title}>Nimdone Projects</p>
                </Container>
                <Divider />

                <Container style={{ flex: 1, backgroundColor: colors.grayBackground }}>
                    <div className={classes.subHeading}>
                        <p>Open a project or create a new one</p>
                    </div>

                    {Projects.length === 0 && (
                        <div className={classes.emptyProjectText}>Click on + to create a project</div>
                    )}
                    {Projects.map((item) => (
                        <div key={item.id} className={classes.projectContainer}>
                            <div
                                className={classes.projectTitle}
                                role="presentation"
                                onClick={() => handleSelectProject(item)}
                            >
                                <p>{item.title}</p>
                            </div>
                            <div className={classes.slideTitle}>
                                <p>{item?.outline?.pages[0]?.title}</p>
                            </div>
                            <div className={classes.moreContainer}>
                                {item?.outline?.pages?.length && <p>• {item?.outline?.pages?.length - 1} more...</p>}
                            </div>
                            <Divider />
                            <div className={classes.dateContainer}>
                                <p>{moment(item.createdAt).format('MM/DD/YYYY')}</p>
                            </div>
                        </div>
                    ))}
                    <AddCircularBtn onClick={handleAddProject} applyNavbarHeight={false} />
                </Container>
            </DrawerWrapper>
        </div>
    );
};
