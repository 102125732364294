/* eslint-disable no-unused-vars */
import { Descendant } from 'slate';

export interface ProjectInterface {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    noOfPaperFiles:number,
    paperFiles?: PaperFile[];
    outline?: Outline; 
}

export interface ExtendedProjectInterfce {
    project: ProjectInterface;
}

export interface Paper {
    id?: string;
    doi?: string;
    isOpenAccess?: boolean;
    title?: string;
    primaryAuthor?: Author;
    secondaryAuthors?: Author[];
    journalName?: string;
    publishedDate?: string;
    errors?: any;
    status?: string;
    isCreateProjectWithPaper?: boolean;
    update?: boolean;
    citation?: string | null;
    createdAt?: string | undefined;
    updatedAt?: string | undefined;
}

export interface PaperFile {
    id: string;
    paper?: Paper;
    speaker?: Speaker;
    key?: string;
    name?: string;
    status?: string;
    createdAt?: string;
    updatedAt?: string;
    loading?: boolean;
}

export interface PaperFileWithPDF {
    pdf: File;
    paperFile: PaperFile;
}

export interface ModifiedPaperFile extends PaperFile {
    isCreateProjectWithPaper?: boolean;
    citation?: string;
}

export interface PaperFileProject {
    id: string;
    projectId?:string;
    paperFile?: PaperFile;
    project?: Project;
    createdAt?: Date;
}

export interface Institution {
    id: string;
    name: string |JSX.Element;
    country:string
}

export interface Speaker {
    id?: string;
    name: string;
    email: string;
    identityId?: string;
    professionalCategory: string;
    specialty: string;
    country:string;
    institution: Institution;
    errors?: any;
    data?: any;
    projects?: ProjectInterface[];
    emailVerified?: boolean;
    stripeCustomerId?:string;
    allowUser?:boolean;
    isSubscriptionActive?:boolean | undefined;
    trialEnds?:string | undefined | null;
    createdAt?: string;
    updatedAt?: string;
    subscriptionStatus?: SpeakerSubscriptionStatuses;
    freeTrialRemainingDays?:number;
}

export interface SpeakerWithPreferences extends Speaker{
    speakerPreferences:SpeakerPreferences
}


export interface Author {
    id?:string;
    firstInitial: string;
    lastName: string;
    createdAt?:string;
    updatedAt?:string
}

interface Project {
    id: string;
}

export interface PaperResponse {
    url: string;
    status: string;
}

export interface Outline {
    id: string;
    pages: OutlinePage[];
}

export interface Layout {
    id: string;
    textItems?: number;
    layoutType: string;
    mediaItems?: number;
    isDefault?: string;
    order?: number;
    name: string;
}

export interface Slide {
    id: string;
    number: number;
    layout: Layout;
    markup: string;
    outlinePage: OutlinePage;
}

export interface Presentation {
    id: string;
    themeId: string;
    slides: Slide[];
}

export interface OutlinePage {
    id: string;
    title: string;
    number: number;
    items: OutlineItem[];
}

export enum OutlineItemType {
    TEXT = 'text',
    IMAGE = 'image',
    LIST = 'list',
}
export interface OutlineTextItemTextProperty {
    type: string;
    children: Child[];
}

export interface Child {
    text?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    numberedList?: boolean;
    bulletedList?: boolean;
    type?: string;
    children?: Child[];
}

export interface OutlineItemProperties {
    text?: Descendant[] | null;
    citation?: string | null;
    imageKey?: string | null;
    originalImageKey?: string | null;
}

export interface OutlineItem {
    id: string;
    order: number;
    itemType: OutlineItemType;
    properties: OutlineItemProperties;
}

export interface Alert {
    message: string;
    type: string;
    isVisible: boolean;
}

export interface InformationModal {
    type?: string;
    open: boolean;
    title?: string;
    cancelBtnLabel?: string;
    successBtnLabel?: string;
    closeBtnLabel?: string;
    subTitle?:any
    footer?:any
    helpInfo?: string | JSX.Element;
    cancelBtnLoading?: boolean;
    handleConfirm?: () => void;
    handleClose?: () => void;
    dataTestId?: string;
    temp?:any
    titleInCenter?: boolean
    hideBackdrop?:boolean;
    subTitleInCenter?:boolean
}

export interface ProjectPFCitation {
    doi: string;
    citation: string;
}

export interface imageDetailsType {
    url: string;
    key: string;
    originalImageKey: string;
}

export enum OutlineModes {
    PREVIEW = 'PREVIEW',
    READER = 'READER',
}

export interface PaperJobResType {
    paperFile?: PaperFile;
    doi: string;
    url?: string;
    status: string;
    jobId?: string;
    keyToPaperInStorage?: string;
    paperFileProjectId?:string;
}

export interface ProjectOutline {
    savingOutlineItem: boolean;
    mode: OutlineModes;
    outline: Outline | null;
    error: boolean;
    projectId: string | null;
    updatingPages:string[];
    updatingItems:string[];
    enableHighlight:boolean
}

export enum PaperStatusList {
    doiExtractionFailed = 'DOI Extraction Failed',
    fetchMetadataFailed = 'Fetch Metadata Failed',
    paperDoesntExist = "Paper doesn't exist",
    empty = '',
    duplicated = "Duplicated Paper"
};
export type PaperStatus = `${PaperStatusList}`

export const PaperWithNoErrStatusList = {
    paperDoesntExist: "Paper doesn't exist",
    empty: '',
    duplicated: "Duplicated Paper"
};

export interface Invoice {
    id:string
    nextBillingDate: string
    amountDue:number
    customerEmail:string
    planId:string
    emailVerified: Boolean
    trialEnds?: String | undefined | null
    allowUser?: Boolean | undefined | null
    isSubscriptionActive?: Boolean | undefined | null
}

export interface SpeakerPreferences {
    id: string
    citationStyle:string
    showOnboarding:boolean
    proxyUrl:string
    showFullTextModal:boolean
}
export interface UpdatePreferencesRes {
    id: string
    citationStyle:string
    proxyUrl:string
    showFullTextModal:boolean
}
export interface UpdateProxyUrlRes {
    id: string
    proxyUrl:string
}
export interface UpdateShowFullTextModalRes {
    id: string
    showFullTextModal:boolean
}


export interface UpdateSpeakerShowOnboardingRes {
    id: string
    showOnboarding:boolean
}


export interface SampleCitation {
    citation:string
}

export interface getMatchedInstitutionResponse{
    label:string |JSX.Element,
    value:number | string
}

export interface RemoveFromLibrary {
    isPaperDeleted: boolean
    updatedPaperFile: PaperFile | null
    deletedPaperFileId:string
}
export type PaperSearchResponse = {
    doi:string;
    response: boolean;
    title?: string;
    isOpenAccess?: boolean;
    paperUrl?: string;
    primaryAuthor?: Partial<Author>;
    secondaryAuthors?: Partial<Author>[];
    published_date?: string;
    journal_name?: string;
    referenceExist?:boolean
    saving?:boolean,
    source?: PaperSource
};

export enum PaperSource {
    PMC = 'pmc',
    UNPAYWALL = 'unpaywall',
}


export type PaperReferences={
    paperSearchResponse:PaperSearchResponse[],
    status:string
}
export interface ReferencePaperFile{
    [key: string]: PaperReferences

}

export interface getPaperWithProjectIDInput{
    paperSearchResponse: PaperSearchResponse;
    speakerId: string;
    projectId: string;
}

export interface paperFileProjectsDoi{
    [key: string]: PaperFileProject[]
}

export enum SpeakerSubscriptionStatuses {
    NEW_USER = 'NEW_USER', // User who signed up but never completed subscription flow
    ACTIVE_USER = 'ACTIVE_USER', // User who completed free trials and charged by stripe. Having subscription status active
    TRIALING_USER = 'TRIALING_USER', // User who is in free trial mode and not charged yet
    CANCELLED_TRAILING='CANCELLED_TRAILING', // User who cancelled his/her subscription during free trial mode
    CANCELLED_SUBSCRIPTION='CANCELLED_SUBSCRIPTION', // User who cancelled his/her subscription during active subscription status
    ALLOWED_USER='ALLOWED_USER', // User who is allowed and may not have any other values (trialEnds & isSubscriptionActive) as expected
    NOT_ALLOWED_USER='NOT_ALLOWED_USER' // User who is not allowed to use nimdone and may have un expected values for (trialEnds & isSubscriptionActive)
    
}

export interface OnboardingSection {
    id:        string;
    title:     string;
    onboards:  Onboard[];
}

export interface Onboard {
    id:        string;
    title:     string;
    hint:      string;
    videoLink: string;
}

export interface SpeakerOnboarding {
    id:           string;
    onboardingId: string;
}

export enum TEXT_OPTIONS {
    TYPE_TEXT = 'TYPE_TEXT',
    HIGHLIGHT = 'HIGHLIGHT',
}
    
export interface Theme {
    id: string;
    name: string;
    order: number;
}

export interface PaperDataType {
    doi?: string;
    title?: string;
    primaryAuthor?: Author | undefined | null;
    secondaryAuthors?: Author[] | undefined| null ;
    journalName?: string;
    publishedDate?: string;
}