import { Grid } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { withFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { createSpeaker } from '../../../backend/speaker';
import config from '../../../config';
import { alertTypes, gtm } from '../../../constants';
import { showAlert, signupSpeaker } from '../../../redux/reducers';
import { GTMService } from '../../../services/gtmService';
import { Speaker } from '../../../types';
import { signupValidation } from '../../../validation';
import { MuiCMButton, TextInput } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

interface signUpFormValues {
    name: string;
    email: string;
    password: string;
    repeatPassword: string;
}

interface signUpFormProps {
    dispatch: any;
    history: any;
    setSubmitting: any;
    isSubmitting: any;
}

const InnerForm = (props: any) => {
    const { handleSubmit, touched, errors, values, handleChange, isSubmitting, setFieldValue } = props;
    const classes = style();

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                placeholder="Name"
                name="name"
                key="name"
                value={values.name}
                label="Name"
                id="name"
                onChange={handleChange}
                containerClassName={classes.input}
                error={errors.name && touched.name ? errors.name : ''}
            />
            <TextInput
                placeholder="E-mail address"
                name="email"
                key="email"
                value={values.email}
                label="E-mail address"
                id="email"
                onChange={handleChange}
                containerClassName={classes.input}
                type="email"
                error={errors.email && touched.email ? errors.email : ''}
            />
            <TextInput
                placeholder="Password"
                name="password"
                key="password"
                value={values.password}
                label="Password"
                id="password"
                onChange={handleChange}
                containerClassName={classes.input}
                type="password"
                error={errors.password && touched.password ? errors.password : ''}
            />
            <TextInput
                placeholder="Repeat password"
                name="repeatPassword"
                key="Repeat password"
                value={values.repeatPassword}
                label="Repeat password"
                id="Repeat password"
                onChange={handleChange}
                containerClassName={classes.input}
                type="password"
                error={errors.repeatPassword && touched.repeatPassword ? errors.repeatPassword : ''}
            />
            <MuiCMButton loading={isSubmitting} className={classes.button} fullWidth type="submit">
                Sign up
            </MuiCMButton>
        </form>
    );
};

export const SignUp = () => {
    const [isSubmitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = style();

    return (
        <Grid container className={classes.container}>
            <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} />
            <div className={classes.formContainer}>
                <h1 className={classes.heading}> Sign up for an account</h1>
                <MyForm
                    dispatch={dispatch}
                    history={history}
                    setSubmitting={setSubmitting}
                    isSubmitting={isSubmitting}
                />
                <p className={classes.returnLoginText}>
                    Already have an account &nbsp;
                    <span className={classes.loginText} onClick={() => history.push(routes.login)}>
                        Login
                    </span>
                </p>
            </div>
        </Grid>
    );
};

const MyForm = withFormik<signUpFormProps, signUpFormValues>({
    mapPropsToValues: () => ({
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
    }),
    validationSchema: signupValidation,

    handleSubmit: async (values, { props }) => {
        const { email, name, password } = values;
        const { dispatch, history, setSubmitting } = props;
        setSubmitting(true);

        await Auth.signUp({
            username: email.trim(),
            password,
        })
            .then(async (res) => {
                const id = res.userSub;
                await createSpeaker(name, email.trim(), false, id)
                    .then(async (speaker: Speaker) => {
                        const gtmService = new GTMService();
                        await gtmService.handleEvent({
                            ProfessionalCategory: speaker.professionalCategory,
                            Speciality: speaker.specialty,
                            Institution: speaker.institution.name,
                            event: gtm.events.NEW_SIGNUP,
                        });
                        localStorage.setItem('unauth_speaker', JSON.stringify(speaker));
                        dispatch(signupSpeaker(speaker));
                    })
                    .catch((err: NodeJS.ErrnoException) => {
                        alert(`Something went wrong while posting data to database ${err.message}`);
                    });
                dispatch(
                    showAlert({
                        message: 'Please check your email to verify your account',
                        isVisible: true,
                        type: alertTypes.success,
                    })
                );
                setSubmitting(false);
                if (config.featureFlags.pricing) history.push(routes.selectPlan);
                else history.push(routes.login);
            })
            .catch((error: NodeJS.ErrnoException) => {
                setSubmitting(false);
                dispatch(
                    showAlert({
                        message: error.message,
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );
            });
    },
})(InnerForm);
