import { API_TYPE } from '../constants';
import { executeGraphqlCall } from './api';
import { Speaker, Invoice, SpeakerWithPreferences } from '../types';

export const createSpeaker = async (
    name: string,
    email: string,
    emailVerified: boolean,
    id: string,
    professionalCategory?: string,
    specialty?: string,
    institutionId?: string,
    country?: string
): Promise<Speaker> => {
    const variables = {
        data: { name, email, professionalCategory, specialty, institutionId, country, emailVerified, id },
    };

    const body = `mutation createSpeaker($data: SpeakerInput!){
      createSpeaker(data: $data) {
        id
        name
        email
        professionalCategory 
        specialty
        emailVerified
        stripeCustomerId
        country
        institution {
          id
          name
        }
      }
    }
    `;
    return executeGraphqlCall<Speaker>('createSpeaker', body, API_TYPE.OPEN, variables);
};

export const getSpeaker = async (id: string): Promise<SpeakerWithPreferences> => {
    const body = `{
      getSpeaker(id:"${id}") {
        id
        professionalCategory
        name
        email
        specialty
        emailVerified
        updatedAt
        createdAt
        identityId
        stripeCustomerId
        allowUser
        trialEnds
        isSubscriptionActive
        planId
        country
        institution{
            id
            name
        }
        speakerPreferences {
          id
          citationStyle
          showOnboarding
          showFullTextModal
          proxyUrl
        }
      }
    }`;

    return executeGraphqlCall<SpeakerWithPreferences>('getSpeaker', body);
};

export const updateSpeakerSetting = async (
    email: string,
    name?: string,
    professionalCategory?: string,
    specialty?: string,
    institutionId?: string,
    country?: string
): Promise<Speaker> => {
    const variables = {
        data: { name, email, professionalCategory, specialty, institutionId, country },
    };

    const body = `mutation updateSpeaker($data:UpdateSpeakerInput!) {
      updateSpeaker(data:$data) {
        id
        professionalCategory
        name
        email
        specialty
        emailVerified
        stripeCustomerId
        updatedAt
        createdAt
        identityId
        country
        institution{
            id
            name
        }
        speakerPreferences {
          id
          citationStyle
          showOnboarding
        }
      }
    }`;
    return executeGraphqlCall<Speaker>('updateSpeaker', body, undefined, variables);
};

export const addIdentityId = async (email: string, identityId: string): Promise<Speaker> => {
    const body = `mutation {
    addIdentityId(
    data:{
      email:"${email}", identityId:"${identityId}"   
    }){
    id
    }}`;
    return executeGraphqlCall<Speaker>('addIdentityId', body);
};

export const createStripeCheckout = async (planId: string, name: string, email: string): Promise<any> => {
    const body = `mutation {
    createStripeCheckout(
  data:{
    planId:"${planId}",email:"${email}", name:"${name}"   
  })}`;
    return executeGraphqlCall<Speaker>('createStripeCheckout', body, API_TYPE.OPEN);
};

export const getStripeCustomerPortalUrl = async (stripeCustomerId?: string): Promise<any> => {
    const body = `{
    getStripeCustomerPortalUrl(
  data:{
    stripeCustomerId:"${stripeCustomerId}"  
  })}`;
    return executeGraphqlCall<Speaker>('getStripeCustomerPortalUrl', body);
};

export const updateSpeakerSubscription = async (stripeCustomerId: string, email: string): Promise<any> => {
    const body = `mutation {
    updateSpeakerSubscription(
  data:{
    stripeCustomerId:"${stripeCustomerId}",email:"${email}"   
  })}`;
    return executeGraphqlCall<Speaker>('updateSpeakerSubscription', body, API_TYPE.OPEN);
};

export const getSubscriptionDetails = async (stripeCustomerId: string): Promise<Invoice> => {
    const body = `mutation {
    getSubscriptionDetails(
    stripeCustomerId:"${stripeCustomerId}"   
  ){
    id
    amountDue
    nextBillingDate
    customerEmail
    planId
    emailVerified
    trialEnds
    allowUser
    isSubscriptionActive
  }}`;
    return executeGraphqlCall<Invoice>('getSubscriptionDetails', body, API_TYPE.OPEN);
};

export const speakerEmailVerification = async (email: string, emailVerified?: boolean): Promise<Speaker> => {
    const variables = { data: { email, emailVerified } };
    const body = `mutation($data: UpdateSpeakerInput!){
      speakerEmailVerification(data: $data) {
        id
      }
    }`;
    return executeGraphqlCall<Speaker>('speakerEmailVerification', body, API_TYPE.OPEN, variables);
};

export const deleteSpeaker = async (speakerId: string) => {
    const variables = { data: { speakerId } };
    const body = `mutation DeleteSpeaker($data: DeleteSpeakerInput!) {
    deleteSpeaker(data: $data)
  }`;
    return executeGraphqlCall<boolean>('deleteSpeaker', body, undefined, variables);
};
