import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Fade, Modal, Checkbox } from '@material-ui/core';

import { alertTypes } from '../../../../../constants';
import { HelpInfoTooltip } from '../../../helpInfoToolTip';
import { MuiCMButton } from '../../../';
import { style } from '../../../modals/informationModal/style';
import { colors } from '../../../../../assets/colors';
import { ProxyModalHelpContent } from './proxyModalInfo';
import { updateShowFullTextModal } from '../../../../../backend/speakerPreferences';
import { showAlert, updateSpeakerPreference } from '../../../../../redux/reducers';
import { RootState } from '../../../../../redux/combineReducers';

export const FullTextModal = ({ open, handleClose, toggleProxyUrlModal, handleConfirm }: any) => {
    const classes = style();
    const dispatch = useDispatch();
    const speaker = useSelector((state: RootState) => state.Speaker);

    const handleShowFullTextModal = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        try {
            if (checked) {
                await updateShowFullTextModal(speaker.id!, false);
                dispatch(updateSpeakerPreference({ showFullTextModal: false }));
            } else {
                await updateShowFullTextModal(speaker.id!, true);
                dispatch(updateSpeakerPreference({ showFullTextModal: true }));
            }
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
            data-testid={''}
            className={classes.modal}
            closeAfterTransition
            hideBackdrop={true}
        >
            <Fade in={open}>
                <div className={classes.content}>
                    <Grid container spacing={1} justifyContent={'space-between'}>
                        <Grid item>
                            <p className={classes.title}>You’re about to leave nimdone</p>
                        </Grid>
                        {<HelpInfoTooltip title={<ProxyModalHelpContent />} />}
                    </Grid>

                    <div className={classes.subTitle}>
                        <p style={{ textAlign: 'left' }}>
                            You will be taken the journal website where you may enter your institutional credentials and
                            download the PDF
                            <br />
                            <br />
                            If you’d like to provide nimdone with your proxy URL to aviod having to re enter your
                            credentials on each journal site{' '}
                            <a onClick={toggleProxyUrlModal} className={classes.link}>
                                click here
                            </a>
                        </p>
                    </div>

                    <div className={classes.bottomContainer}>
                        <div className={classes.buttonContainer}>
                            <MuiCMButton variant="outlined" loading={false} onClick={handleClose} role="cancel-btn">
                                Cancel
                            </MuiCMButton>

                            <MuiCMButton onClick={handleConfirm} className={classes.enterDoiBtn} role="success-btn">
                                Continue to Journal Site
                            </MuiCMButton>
                        </div>

                        <div className={classes.footer} style={{ display: 'flex' }}>
                            <p>Do not show this again</p>
                            <Checkbox
                                onChange={handleShowFullTextModal}
                                size="small"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                disabled={false}
                                style={{ color: colors.darkTeal }}
                            />
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
