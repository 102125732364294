import { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';

import { useOutlineSidebarStyles } from './styles';
import { Outline } from './components/outline';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/combineReducers';
import { createOutlinePage, getOutline } from '../../backend/outline';
import { OutlineModes } from '../../types';
import { updateMode, showAlert, setOutline, updateOutlineProject } from '../../redux/reducers';
import { alertTypes } from '../../constants';
import { useQuery } from '../../utils';
import { AddCircularBtn } from '../common';

interface PathToModes {
    [key: string]: OutlineModes;
}

const pathnameToModes: PathToModes = {
    '/slide-preview': OutlineModes.PREVIEW,
    '/reader': OutlineModes.READER,
};

export const OutlineSideBar = (props: any) => {
    const query = useQuery();

    const projectId = query.get('projectId');

    const styles = useOutlineSidebarStyles();
    const Projects = useSelector((state: RootState) => state.Projects);
    const [selectedProject] = Projects.filter((project) => project.id === projectId);
    const { mode, projectId: selectedProjectId } = useSelector((state: RootState) => state.ProjectOutline);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const [loading, setLoading] = useState<boolean>(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const fetchOutline = async (projectId: string) => {
        try {
            setLoading(true);
            const outlineResponse = await getOutline(projectId);
            dispatch(setOutline({ outline: outlineResponse, projectId }));
            setLoading(false);
        } catch (e) {
            if (e instanceof Error)
                showAlert({
                    isVisible: true,
                    type: alertTypes.error,
                    message: e.message,
                });
        }
    };

    const createNewPage = async () => {
        if (selectedProject.id !== '-1') {
            try {
                const updatedOutline = await createOutlinePage(selectedProject.id);
                dispatch(setOutline({ outline: updatedOutline, projectId: selectedProject.id }));
                dispatch(updateOutlineProject({ outline: updatedOutline, projectId: selectedProject.id }));
            } catch (error) {
                if (error instanceof Error)
                    dispatch(
                        showAlert({
                            isVisible: true,
                            type: alertTypes.error,
                            message: error.message,
                        })
                    );
            }
        }
    };

    useEffect(() => {
        if (selectedProject && selectedProject.id !== '-1' && selectedProject.id !== selectedProjectId)
            fetchOutline(selectedProject.id);
    }, [selectedProject, selectedProjectId]);

    // to check if it is in slide preview mode or reader mode
    useEffect(() => {
        if (pathnameToModes[pathname]) dispatch(updateMode({ mode: pathnameToModes[pathname] }));
    }, [pathname]);

    return (
        <>
            {loading || !selectedProject ? (
                <Box>Loading outline...</Box>
            ) : (
                <Box className={mode === OutlineModes.READER ? styles.readerViewRoot : styles.root}>
                    <Paper
                        elevation={0}
                        square={true}
                        className={mode === OutlineModes.READER ? styles.paperinReader : styles.paper}
                    >
                        {mode === OutlineModes.PREVIEW && (
                            <Typography variant="h5" className={styles.title}>
                                {selectedProject.title}
                            </Typography>
                        )}
                        {mode === OutlineModes.PREVIEW && (
                            <Typography variant="body1" className={styles.caption}>
                                <span className={styles.sidebarHeading}>
                                    Continue editing your project outline below
                                </span>
                            </Typography>
                        )}
                        <Outline projectId={selectedProject.id} />
                    </Paper>
                    {selectedProject.id !== '-1' && <AddCircularBtn onClick={createNewPage} />}
                </Box>
            )}
        </>
    );
};
