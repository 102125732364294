import { Component } from 'react';
import html2canvas from 'html2canvas';
import { State, Props } from './types';
import { playImageCapturedSound } from '../../../utils';

const scaleBy = window.devicePixelRatio;

export class ScreenCapture extends Component<Props, State> {
    static defaultProps = {
        onStartCapture: (itemId: string) => null,
        onEndCapture: () => null,
        startCapturing: () => null,
    };

    state: State = {
        on: false,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        isMouseDown: false,
        windowWidth: 0,
        windowHeight: 0,
        cropPositionTop: 0,
        cropPositionLeft: 0,
        cropWidth: 0,
        cropHeight: 0,
        imageURL: '',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        canvasData: null,
        isCropingAreaDone: false,
        itemId: null,
    };

    componentDidUpdate() {
        // crop area and generate image once we have all the data
        // image item id, canvas and user stop cropping
        if (
            this.state.itemId &&
            this.state.canvasData &&
            this.state.canvasData.canvas &&
            this.state.isCropingAreaDone
        ) {
            const {
                cropPositionTop,
                cropPositionLeft,
                cropWidth,
                cropHeight,
                canvasData: { canvas, leftOffset, topOffset },
            } = this.state;
            const croppedCanvas = document.createElement('canvas');
            const croppedCanvasContext = croppedCanvas.getContext('2d');

            croppedCanvas.width = cropWidth * scaleBy;
            croppedCanvas.height = cropHeight * scaleBy;
            if (croppedCanvasContext) {
                croppedCanvasContext.drawImage(
                    canvas,
                    (cropPositionLeft - leftOffset) * scaleBy,
                    (cropPositionTop - topOffset) * scaleBy,
                    cropWidth * scaleBy,
                    cropHeight * scaleBy,
                    0,
                    0,
                    cropWidth * scaleBy,
                    cropHeight * scaleBy
                );
            }
            if (croppedCanvas) {
                this.changeCrosshair();
                this.props.onEndCapture(
                    this.state.itemId as string,
                    croppedCanvas.toDataURL(),
                    croppedCanvas.width,
                    croppedCanvas.height
                );
                this.resetState();
            }
        }
    }

    resetState = () => {
        this.setState({
            on: false,
            startX: 0,
            startY: 0,
            isMouseDown: false,
            canvasData: null,
            isCropingAreaDone: false,
            itemId: null,
        });
    };

    componentDidMount = () => {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('keydown', this.handleEscape);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('keydown', this.handleEscape);
    };

    handleEscape = (e: any) => {
        if (e.keyCode === 27 && this.state.on) {
            this.props.onEndCapture(this.state.itemId as string, '', 0, 0);
            this.changeCrosshair();
            this.setState({
                on: false,
                isMouseDown: false,
                startX: 0,
                startY: 0,
            });
        }
    };

    handleWindowResize = () => {
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.setState({
            windowWidth,
            windowHeight,
        });
    };

    handleStartCapture = (itemId: string) => this.setState({ on: true, itemId });

    handleMouseMove = (e: any) => {
        if (!this.state.on) return;
        const { isMouseDown, windowWidth, windowHeight, startX, startY } = this.state;

        let cropPositionTop = startY;
        let cropPositionLeft = startX;
        const endX = e.clientX;
        const endY = e.clientY;
        const isStartTop = endY >= startY;
        const isStartBottom = endY <= startY;
        const isStartLeft = endX >= startX;
        const isStartRight = endX <= startX;
        const isStartTopLeft = isStartTop && isStartLeft;
        const isStartTopRight = isStartTop && isStartRight;
        const isStartBottomLeft = isStartBottom && isStartLeft;
        const isStartBottomRight = isStartBottom && isStartRight;
        let cropWidth = 0;
        let cropHeight = 0;

        let top = 0;
        let right = 0;
        let bottom = 0;
        let left = 0;

        if (isMouseDown) {
            if (isStartTopLeft) {
                top = startY;
                right = windowWidth - endX;
                bottom = windowHeight - endY;
                left = startX;

                cropWidth = endX - startX;
                cropHeight = endY - startY;
            } else if (isStartTopRight) {
                top = startY;
                right = windowWidth - startX;
                bottom = windowHeight - endY;
                left = endX;

                cropWidth = startX - endX;
                cropHeight = endY - startY;
                cropPositionLeft = endX;
            } else if (isStartBottomLeft) {
                top = endY;
                right = windowWidth - endX;
                bottom = windowHeight - startY;
                left = startX;
                cropWidth = endX - startX;
                cropHeight = startY - endY;
                cropPositionTop = endY;
            } else if (isStartBottomRight) {
                top = endY;
                right = windowWidth - startX;
                bottom = windowHeight - startY;
                left = endX;

                cropWidth = startX - endX;
                cropHeight = startY - endY;
                cropPositionLeft = endX;
                cropPositionTop = endY;
            }
        }

        this.setState({
            top,
            left,
            right,
            bottom,
            cropWidth,
            cropHeight,
            cropPositionTop: cropPositionTop,
            cropPositionLeft: cropPositionLeft,
        });
    };

    handleMouseDown = (e: any) => {
        if (!this.state.on || (this.state.startX !== 0 && this.state.startY !== 0)) return;
        const startX = e.clientX;
        const startY = e.clientY;

        this.setState({
            startX,
            startY,
            cropPositionTop: startY,
            cropPositionLeft: startX,
            isMouseDown: true,
        });

        // stop scrolling while snipping
        const documentContainer = document.getElementById('document-container');
        if (documentContainer) documentContainer.style.overflow = 'hidden';

        const pdfWraperContainer: HTMLElement | null = document.getElementById('pdf-wrapper');

        const pdfWraperContainerRects = pdfWraperContainer?.getClientRects();
        const leftOffset = (pdfWraperContainerRects && pdfWraperContainerRects[0].left) || 0;
        const topOffset = (pdfWraperContainerRects && pdfWraperContainerRects[0].top) || 0;

        if (pdfWraperContainer) {
            html2canvas(pdfWraperContainer, {
                scale: scaleBy,
            }).then((canvas) => this.setState({ canvasData: { canvas, leftOffset, topOffset } }));
        }
    };

    handleMouseUp = (e: any) => {
        if (!this.state.on) return;
        this.setState(
            {
                on: false,
                isMouseDown: false,
            },
            this.handleClickTakeScreenShot
        );

        // start scrolling again after user snip
        const documentContainer = document.getElementById('document-container');
        if (documentContainer) documentContainer.style.overflow = 'auto';
    };

    handleClickTakeScreenShot = () => {
        const { cropWidth, cropHeight } = this.state;
        // return if user just click not croped
        if (cropWidth === 0 || cropHeight === 0) {
            this.setState({
                on: true,
                startX: 0,
                startY: 0,
            });
            return;
        }

        playImageCapturedSound();
        this.props.startCapturing();
        this.setState({ isCropingAreaDone: true });
    };

    changeCrosshair = () => {
        const pdWrapper = document.getElementById('pdf-wrapper');
        if (pdWrapper) pdWrapper.style.cursor = 'default';
    };

    renderChild = () => {
        const { children } = this.props;

        const props = {
            onStartCapture: this.handleStartCapture,
        };

        if (typeof children === 'function') return children(props);

        return children;
    };

    render() {
        const { on, isMouseDown, top, left, right, bottom } = this.state;

        return (
            <div onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
                {this.renderChild()}

                {on && isMouseDown && top !== 0 && left !== 0 && right !== 0 && bottom !== 0 && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 10,
                            top,
                            left,
                            right,
                            bottom,
                            background: 'rgba(0, 0, 0, 0.3)',
                        }}
                    />
                )}
            </div>
        );
    }
}
