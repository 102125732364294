import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../assets/colors';
import { alertBarHeight } from '../../../../constants';

export const useThemeDrawerStyles = makeStyles(() => ({
    drawerTogglerContainer: {
        backgroundColor: colors.white,
        display: 'flex',
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 11,
        borderLeft: `1px solid ${colors.disabledMenu}`,
    },
    themeListDrawer: {
        width: 500,
        paddingTop: 65,
        '& h3': {
            paddingLeft: 35,
            fontSize: 25,
            color: colors.text2,
        },
    },
    themeListContainer: {
        position: 'relative',
        height: '100%',
    },
    themeThumbnail: {
        padding: '16px 65px 10px 35px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.selectedThumbnailBG,
        },
        '& h4': {
            fontWeight: 400,
            fontSize: 16,
            color: colors.black,
            margin: '0 0 5px 0',
        },
    },
    currentTheme: {
        backgroundColor: colors.selectedThumbnailBG,
        pointerEvents: 'none',
    },
    divider: {
        margin: '0px 60px 0px 30px',
        height: 2,
        backgroundColor: colors.skyGray,
    },
    loaderBackdrop: {
        zIndex: 20,
        position: 'absolute',
    },
    loader: {
        margin: 'auto auto auto auto',
        color: colors.white,
    },
    alertBarMargin: {
        marginTop: alertBarHeight,
    },
}));
