import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            display: 'flex',
            columnGap: 10,
        },
        listItemIcon: {
            color: colors.gray,
            opacity: 0.5,
            width: 25,
            height: 25,
            overflow: 'visible',
            marginRight: 10,
        },
        popoverBtn: {
            alignSelf: 'center',
            marginBottom: 20,
        },
        addButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 15,
        },
        linkStyle:{
            textDecoration: 'none',
            color: 'inherit',
        },
    })
);
