import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RefreshIcon } from '../../../assets/icons';
import { getAllProjects, getProjectPaperFiles } from '../../../backend/project';
import { alertTypes } from '../../../constants';
import { RootState } from '../../../redux/combineReducers';
import { showAlert, updatePaperFileArray } from '../../../redux/reducers';
import { updateAllProjects } from '../../../redux/reducers/projects';
import { PaperFile } from '../../../types';
import { useStyles } from './style';

interface PaperFilesRefresherProps {
    projectId: string;
}
export const PaperFilesRefresher = ({ projectId }: PaperFilesRefresherProps) => {
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const speakerId = useSelector((state: RootState) => state?.Speaker?.id);
    const classes = useStyles();

    const fetchProjectPaperFiles = async () => {
        if (refreshing) return;
        setRefreshing(true);
        try {
            const project = await getProjectPaperFiles(projectId);
            const allprojects = await getAllProjects(speakerId as string);
            dispatch(updatePaperFileArray({ projectId, paperFile: project?.paperFiles as PaperFile[] }));
            dispatch(updateAllProjects({ allProjects: allprojects }));
            setRefreshing(false);
        } catch (error) {
            if (error instanceof Error) {
                setRefreshing(false);
                dispatch(showAlert({ isVisible: true, message: error.message, type: alertTypes.error }));
            }
        }
    };
    return (
        <Tooltip title="Refresh">
            <IconButton size="small" onClick={fetchProjectPaperFiles} data-testid={'paper-file-refresher'}>
                {refreshing ? <CircularProgress size={24} className={classes.circularProgress} /> : <RefreshIcon />}
            </IconButton>
        </Tooltip>
    );
};
