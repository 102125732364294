import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ErrorIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <rect width="24" height="24" fill="white" fillOpacity="0.01" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7664 3.98257C13.0093 4.08325 13.2301 4.23081 13.416 4.41682L19.584 10.5838C19.7701 10.7698 19.9178 10.9906 20.0186 11.2336C20.1194 11.4767 20.1712 11.7372 20.1712 12.0003C20.1712 12.2634 20.1194 12.524 20.0186 12.767C19.9178 13.0101 19.7701 13.2309 19.584 13.4168L13.416 19.5838C13.2301 19.7698 13.0093 19.9174 12.7664 20.0181C12.5234 20.1187 12.263 20.1706 12 20.1706C11.737 20.1706 11.4766 20.1187 11.2336 20.0181C10.9906 19.9174 10.7699 19.7698 10.584 19.5838L4.41598 13.4168C4.22982 13.2309 4.08213 13.0101 3.98136 12.767C3.8806 12.524 3.82874 12.2634 3.82874 12.0003C3.82874 11.7372 3.8806 11.4767 3.98136 11.2336C4.08213 10.9906 4.22982 10.7698 4.41598 10.5838L10.584 4.41682C10.7699 4.23081 10.9906 4.08325 11.2336 3.98257C11.4766 3.8819 11.737 3.83008 12 3.83008C12.263 3.83008 12.5234 3.8819 12.7664 3.98257ZM11.2929 13.7069C11.4804 13.8945 11.7348 13.9998 12 13.9998C12.2652 13.9998 12.5195 13.8945 12.7071 13.7069C12.8946 13.5194 13 13.265 13 12.9998V7.99982C13 7.7346 12.8946 7.48025 12.7071 7.29271C12.5195 7.10517 12.2652 6.99982 12 6.99982C11.7348 6.99982 11.4804 7.10517 11.2929 7.29271C11.1053 7.48025 11 7.7346 11 7.99982V12.9998C11 13.265 11.1053 13.5194 11.2929 13.7069ZM11.2929 16.7069C11.4804 16.8945 11.7348 16.9998 12 16.9998C12.2652 16.9998 12.5195 16.8945 12.7071 16.7069C12.8946 16.5194 13 16.265 13 15.9998C13 15.7346 12.8946 15.4802 12.7071 15.2927C12.5195 15.1052 12.2652 14.9998 12 14.9998C11.7348 14.9998 11.4804 15.1052 11.2929 15.2927C11.1053 15.4802 11 15.7346 11 15.9998C11 16.265 11.1053 16.5194 11.2929 16.7069Z"
                fill="#BF2600"
            />
        </SvgIcon>
    );
};
