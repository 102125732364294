import Auth from '@aws-amplify/auth';
import { Box, CircularProgress, Fade, Modal, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteSpeaker } from '../../../../../backend/speaker';
import { alertTypes } from '../../../../../constants';
import { showAlert } from '../../../../../redux/reducers';
import { Speaker } from '../../../../../types';
import { MuiCMButton } from '../../../../common';
import { style } from './style';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    speaker: Speaker;
}

export const DeleteAccountModal: React.FC<Props> = ({ open, setOpen, speaker }) => {
    const classes = style();
    const history = useHistory();
    const { id } = speaker;
    const [deleting, setDeleting] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteSpeaker = async () => {
        setDeleting(true);
        try {
            await deleteSpeaker(id!);
            // this removes aws cognito and other auth stuff which confilicts with login page
            localStorage.clear();
            Auth.signOut();
        } catch (error) {
            if (error instanceof Error) {
                dispatch(
                    showAlert({
                        isVisible: true,
                        type: alertTypes.error,
                        message: error.message,
                    })
                );
            }
        }
        setDeleting(false);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose} data-testid="del-account-modal">
            <Fade in={open}>
                <Box className={classes.paper}>
                    <Box className={classes.header}>
                        <WarningIcon className={classes.warningIcon} />
                        <Typography variant="h6" className={classes.title}>
                            Are you sure you want to delete your account?
                        </Typography>
                    </Box>
                    <Typography variant="body2" className={classes.subtitle}>
                        You cannot undo this action. This action will erase all data associated with your account
                    </Typography>
                    <Box className={classes.actions}>
                        <MuiCMButton
                            loading={deleting}
                            spinnerSize={25}
                            variant="outlined"
                            fullWidth
                            onClick={handleDeleteSpeaker}
                            className={classes.deleteBtn}
                        >
                            Delete permanently
                        </MuiCMButton>
                        <MuiCMButton onClick={handleClose} role="cancel-del-account" className={classes.cancelBtn}>
                            Cancel
                        </MuiCMButton>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};
