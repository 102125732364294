import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ProjectInterface } from '../../../../types';
import { useQuery } from '../../../../utils';
import { MuiCMButton } from '../../../common';
import { style } from './style';

interface NoPaperFileAlertProps {
    selectedProject: ProjectInterface;
}

export const NoPaperFileAlert = ({ selectedProject }: NoPaperFileAlertProps) => {
    const history = useHistory();
    const classes = style();
    const [open, setOpen] = useState<boolean>(false);
    const query = useQuery();
    const key = query.get('key');
    const doi = query.get('doi');

    useEffect(() => {
        if (key || doi) return setOpen(false);
        else if (selectedProject?.paperFiles?.length) return setOpen(false);
        else setOpen(true);
    }, [selectedProject]);

    const onClose = () => {
        setOpen(false);
        history.goBack();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="no-paperFile-title"
            aria-describedby="no-paperFile-description"
            className={classes.modal}
        >
            <div className={classes.content}>
                <h3 className={classes.title}>Whoops!</h3>
                <p> You don’t have any papers to open in reader view</p>
                <MuiCMButton onClick={onClose} className={classes.okBtn}>
                    Ok
                </MuiCMButton>
            </div>
        </Modal>
    );
};
