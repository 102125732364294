import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Storage from '@aws-amplify/storage';
import { Fade, Modal } from '@material-ui/core';
import CanvasDraw from 'react-canvas-draw';
import { emitCustomEvent } from 'react-custom-events';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../../../../../../assets/colors';
import { updateImageItem } from '../../../../../../../../backend/outline';
import { alertTypes } from '../../../../../../../../constants';
import { RootState } from '../../../../../../../../redux/combineReducers';
import { showAlert } from '../../../../../../../../redux/reducers';
import { imageDetailsType, OutlineItemProperties } from '../../../../../../../../types';
import { combineDrawing } from '../../../../../../../../utils/index';
import { MuiCMButton } from '../../../../../../../common';
import { style } from './style';

Storage.configure({
    level: 'private',
    contentType: 'image',
});

interface ImageMarkupProps {
    openMarkupModal: boolean;
    toggleMarkupModal: () => void;
    imageDetails: imageDetailsType;
    setOpenMarkupModal: Dispatch<SetStateAction<boolean>>;
    setImageDetails: Dispatch<SetStateAction<imageDetailsType>>;
    setUpdatedProperties: Dispatch<SetStateAction<OutlineItemProperties>>;
    citation: string;
    itemId: string;
    pageId: string;
}

export const ImageMarkup = ({
    openMarkupModal,
    toggleMarkupModal,
    imageDetails,
    setOpenMarkupModal,
    setImageDetails,
    setUpdatedProperties,
    citation,
    itemId,
    pageId,
}: ImageMarkupProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [canvasResolution, setCanvasResolution] = useState({
        height: 400,
        width: 400,
    });
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const saveableCanvas: any = useRef(null);
    const classes = style();
    const dispatch = useDispatch();

    useEffect(() => {
        const canvas: any = document?.getElementsByClassName(`canvas`);
        if (canvas && canvas.length > 0) canvas[0].style['position'] = 'relative';
    }, [canvasResolution]);

    const handleImageDetails = async () => {
        let img = document.createElement('img');
        img.src = await imageDetails.url;
        await new Promise((resolve) => {
            img.onload = resolve;
        });
        setCanvasResolution({ height: img.height, width: img.width });
    };

    useEffect(() => {
        handleImageDetails();
    }, [imageDetails]);

    const emitOutlinePageUpdatedEvent = () => {
        emitCustomEvent('outline-page-updated', {
            outlinePageId: pageId,
        });
    };

    const handleSaveImage = async () => {
        setLoading(true);
        try {
            const { dataUri, file } = combineDrawing(saveableCanvas, imageDetails.originalImageKey);
            const modifiedKey =
                imageDetails.key.search(`modified--${imageDetails.originalImageKey}`) == -1
                    ? `modified--${imageDetails.originalImageKey}`
                    : imageDetails.key;

            const { key }: any = await Storage.put(modifiedKey, file, { cacheControl: 'no-cache' });
            const updatedImageItem = await updateImageItem(
                key,
                citation,
                imageDetails.originalImageKey,
                itemId,
                speakerId as string
            );

            emitOutlinePageUpdatedEvent();

            setUpdatedProperties(updatedImageItem.properties);
            setOpenMarkupModal(false);
            setImageDetails({ ...imageDetails, url: dataUri, key: modifiedKey });
            setLoading(false);
        } catch (error) {
            setLoading(false);

            if (error instanceof Error)
                dispatch(showAlert({ isVisible: true, type: alertTypes.error, message: error.message }));
        }
    };

    const handleCancel = () => {
        saveableCanvas.current.clear();
        setOpenMarkupModal(false);
    };

    return (
        <Modal open={openMarkupModal} onClose={toggleMarkupModal} className={classes.modal}>
            <Fade in={openMarkupModal}>
                <div className={classes.contentContainer}>
                    {imageDetails?.url && (
                        <div className={classes.canvasWrapper}>
                            <CanvasDraw
                                ref={saveableCanvas}
                                lazyRadius={5}
                                brushRadius={2}
                                brushColor={colors.red}
                                catenaryColor={colors.red}
                                hideGrid={true}
                                disabled={false}
                                imgSrc={imageDetails.url}
                                canvasHeight={canvasResolution.height}
                                canvasWidth={canvasResolution.width}
                                className="canvas"
                            />
                        </div>
                    )}
                    <div className={classes.btnContainer}>
                        <MuiCMButton onClick={handleCancel} fullWidth variant="outlined">
                            Cancel
                        </MuiCMButton>
                        <MuiCMButton onClick={handleSaveImage} className={classes.saveBtn} loading={loading} fullWidth>
                            {loading ? 'Saving...' : 'Save'}
                        </MuiCMButton>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
