import React from 'react';

export const CustomElement = ({ attributes, children, element }: any) => {
    switch (element.type) {
        case 'bulletedList':
            return <ul {...attributes}>{children}</ul>;
        case 'listItem':
            return <li {...attributes}>{children}</li>;
        case 'numberedList':
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};
