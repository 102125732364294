export default {
    test: {
        key: 'experiment_value',
    },
    api: {
        baseUrl: 'http://localhost:6033/experiment/graphql',
    },
    gtm: {
        auth: 'ycJEZdt82gLFpnrvR1w7wA',
        preview: 'env-3',
    },
    cloudFront: {
        url: 'https://files-experiment.nimdone.com',
    },
    featureFlags: {
        pricing: false,
        freeTrial: false,
        allPaper: false,
        paperReferences: true,
        hashFiles: true,
        onboarding: false,
        signupPage: true, // Show signup page even if user is loggedIn
        proxyUrl: true,
        pptxTheme: true,
    },
    stripe: {
        freeTrialDays: 14,
    },
};

// experiment env  baseUrl
// https://40uovz05w5.execute-api.us-east-1.amazonaws.com/experiment/graphql
// http://localhost:6033/experiment/graphql
