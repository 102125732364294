import { ErrorComponent } from '../common/errorComponent';

export const InternalServerError = () => (
    <ErrorComponent
        error="500: Internal server error"
        description={
            <p data-testid="internal-error-desc">
                Sorry, something went wrong on our end. We are doing our best <br /> to fix the problem.
            </p>
        }
    />
);
