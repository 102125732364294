import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            alignContent: 'center',
            padding: 10,
        },
        logoContainer: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        logo: {
            width: 204,
            height: 210,
        },
        formContainer: {
            width: 350,
        },
        heading: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 24,
            textAlign: 'center',
            color: colors.smoke,
        },
        input: {
            marginBottom: 12,
        },
        button: {
            marginTop: 12,
            fontWeight: 'normal',
        },
        noAccountText: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 14,
            letterSpacing: 0.2,
            color: colors.smoke,
            textAlign: 'center',
            marginTop: 24,
            cursor: 'pointer',
        },
        signupText: {
            color: colors.linkBlue,
        },
        error: {
            color: colors.error,
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0.2,
        },
        forgotPassText: {
            color: colors.linkBlue,
            display: 'block',
            cursor: 'pointer',
        },
    })
);
