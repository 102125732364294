import { style } from './style';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { colors } from '../../../assets/colors';
import { ErrorIcon } from '../../../assets/icons';
import './style.scss';

type AsyncDropdownProps = {
    onChange?: any;
    value?: any;
    placeholder?: string;
    label?: string;
    name?: string;
    id?: any;
    containerClassName?: string;
    type?: string;
    error?: string;
    loadOptions?: any;
};

export const AsyncDropdown = ({
    onChange,
    value,
    placeholder,
    label,
    name,
    id,
    containerClassName,
    error,
    loadOptions,
}: AsyncDropdownProps) => {
    const classes = style();

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: colors.smoke,
            width: '100%',
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'row',
            marginRight: '3px',
        }),
        singleValue: (provided: any, state: any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
                color: colors.smoke,
            };
        },
        menuList: (provided: any) => ({
            ...provided,
            textAlign: 'left',
            overflowX: 'hidden',
        }),
        menu: (provided: any) => ({
            ...provided,
            fontSize: 16,
            fontFamily: 'Roboto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            zIndex:2,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            fontSize: '1em',
            fontWeight: 400,
        }),
        container: (provided: any) => ({
            ...provided,
            border: `1px groove ${colors.smoke}`,
        }),
    };
    return (
        <div className={containerClassName ? `${classes.container} ${containerClassName}` : classes.container}>
            <h2 className={classes.label}>{label}</h2>
            <AsyncSelect
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                loadOptions={loadOptions}
                components={{ Placeholder }}
                name={name}
                defaultValue={value}
                styles={customStyles}
                cacheOptions
                defaultOptions
            />

            {!!error && (
                <div className={classes.errorContainer}>
                    <ErrorIcon className={classes.errorImage} alt="error" />
                    <p className={classes.errorText}> {error} </p>
                </div>
            )}
        </div>
    );
};

const Placeholder = (props: any) => {
    return <components.Placeholder {...props} />;
};
