import { forwardRef, memo } from 'react';
import { Tooltip } from '@material-ui/core';
import { colors } from '../../../../../assets/colors';
import { ErrorIcon } from '../../../../../assets/icons';
import { withStyles, Theme } from '@material-ui/core/styles';
import { style } from './style';

const DoiFailedTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        padding: 16,
        backgroundColor: colors.pinkishRed,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 450,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: 4,
    },
    arrow: {
        color: colors.pinkishRed,
    },
}))(Tooltip);

export const DoiExtractionFailedTooltipComp = () => {
    const classes = style();
    const ErrorIconForToolTip = forwardRef((props: any, ref: any) => (
        <div {...props} ref={ref}>
            <ErrorIcon className={classes.errorIcon} />
        </div>
    ));
    return (
        <DoiFailedTooltip
            arrow
            title={
                <div className={classes.tooltipContainer}>
                    <ErrorIcon className={classes.errorIconLarge} />
                    <div>
                        <div className={classes.tooltipHeading}>DOI not found</div>
                        <div className={classes.tooltipDescription}>
                            This PDF needs to be given a DOI manually. Otherwise, its citation cannot be used in your
                            presentation.
                        </div>
                    </div>
                </div>
            }
        >
            <ErrorIconForToolTip />
        </DoiFailedTooltip>
    );
};

export const DoiExtractionFailedTooltip = memo(DoiExtractionFailedTooltipComp);
