import { useState } from 'react';
import { style } from './style';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { OutlineItemType } from '../../../../../../types';
import { ImageIcon, TextLinesIcon, TextListIcon } from '../../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, toggleSavingOutlineItem } from '../../../../../../redux/reducers';
import { emitCustomEvent } from 'react-custom-events';
import { updateItems } from '../../../../../../redux/reducers/projectOutline';
import { alertTypes } from '../../../../../../constants';
import { RootState } from '../../../../../../redux/combineReducers';
import { deleteItem as deleteOutlineItem } from '../../../../../../backend/outline';

export interface OutlineItemWrapperProps {
    itemLostFocus: () => void;
    children: any;
    itemType: OutlineItemType;
    pageId: string;
    itemId: string;
    imageKey?: string | undefined | null;
}

export const OutlineItemWrapper = ({
    itemLostFocus,
    itemType,
    pageId,
    itemId,
    imageKey,
    children,
}: OutlineItemWrapperProps) => {
    const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const { updatingItems } = useSelector((state: RootState) => state.ProjectOutline);
    const isUpdating = updatingItems.includes(itemId);
    const classes = style({ isUpdating });
    const dispatch = useDispatch();

    const handleMouseLeave = () => {
        setShowDeleteIcon(false);
        itemLostFocus();
    };

    const handleMouseEnter = () => {
        setShowDeleteIcon(true);
    };

    const deleteItem = async () => {
        try {
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, itemId }));
            const updatedItems = await deleteOutlineItem(itemId, speakerId as string, pageId, imageKey || undefined);

            emitCustomEvent('outline-page-updated', {
                outlinePageId: pageId,
            });

            dispatch(updateItems({ pageId, items: updatedItems }));
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        type: alertTypes.error,
                        message: error.message,
                    })
                );
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
        }
    };

    return (
        <Box className={classes.container} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
            {itemType === OutlineItemType.TEXT && <TextLinesIcon className={classes.textIcon} viewBox={'0 0 35 35'} />}
            {itemType === OutlineItemType.LIST && <TextListIcon width={40} height={36} />}
            {itemType === OutlineItemType.IMAGE && <ImageIcon viewBox={'0 0 35 35'} className={classes.icon} />}
            <Box className={classes.formContainer}>
                {children}
                {showDeleteIcon && (
                    <div className={classes.delete} onClick={isUpdating ? () => {} : deleteItem}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </div>
                )}
            </Box>
        </Box>
    );
};
