import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaperFile, PaperFileWithPDF, ProjectInterface } from '../../../types';

const initialState: ProjectInterface[] = [];

const userSlice = createSlice({
    name: 'Projects',
    initialState,
    reducers: {
        setProjects: (state, action: PayloadAction<ProjectInterface[]>) => {
            state = action.payload;
            return state;
        },
        updateOutlineProject: (
            state,
            action: PayloadAction<{ projectId: string; outline: ProjectInterface['outline'] }>
        ) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                state[index]['outline'] = action.payload.outline as ProjectInterface['outline'];
            } else {
                return state;
            }
        },

        addNewProject: (state, action: PayloadAction<ProjectInterface>) => {
            state.unshift(action.payload);
            return state;
        },
        addPaperFileToProject: (state, action: PayloadAction<{ projectId: string; paperFile: PaperFile }>) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                if (state[index].paperFiles) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    state[index]?.paperFiles?.unshift({
                        ...action.payload.paperFile,
                    });
                } else {
                    state[index]['paperFiles'] = [{ ...action.payload.paperFile }];
                }
                state[index].noOfPaperFiles = state[index].noOfPaperFiles + 1;
            }
            return state;
        },
        addMultiplePaperfilesToProject: (
            state,
            action: PayloadAction<{ projectId: string; paperFilesWithPDF: PaperFileWithPDF[] }>
        ) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                action.payload.paperFilesWithPDF.map((paperFileWithBlob) => {
                    if (state[index].paperFiles) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        state[index]?.paperFiles?.unshift({
                            // Newly added papers should appear on the top
                            ...paperFileWithBlob.paperFile,
                        });
                    } else {
                        state[index]['paperFiles'] = [{ ...paperFileWithBlob.paperFile }];
                    }
                    state[index].noOfPaperFiles = state[index].noOfPaperFiles + 1;
                });
            }
            return state;
        },
        removePaperFileFromProject: (state, action: PayloadAction<{ projectId: string; paperFileId: string }>) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                state[index].paperFiles = state[index].paperFiles?.filter(
                    (paperFile: PaperFile) => paperFile.id !== action.payload.paperFileId
                );
                state[index].noOfPaperFiles = state[index].paperFiles?.length
                    ? (state[index].paperFiles?.length as number)
                    : 0;
            }
            return state;
        },
        // update no of paper files of each project
        removePaperFileFromLibrary: (state, action: PayloadAction<{ paperFileId: string }>) => {
            return state.map((item) => {
                let newProjectState = { ...item };

                newProjectState.paperFiles = newProjectState.paperFiles
                    ? newProjectState.paperFiles?.filter(
                          (newProjectState) => newProjectState.id !== action.payload.paperFileId
                      )
                    : undefined;

                newProjectState.noOfPaperFiles = newProjectState.paperFiles?.length as number;

                return newProjectState;
            });
        },
        updatePaperFileOfProject: (
            state,
            action: PayloadAction<{ projectId: string; paperFile: PaperFile; paperFileId: string }>
        ) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                if (state[index].paperFiles) {
                    const paperIndex = state[index].paperFiles?.findIndex(
                        (item: PaperFile) => item.id === action.payload.paperFileId
                    );
                    if ((paperIndex as number) > -1) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        state[index].paperFiles?.splice(paperIndex as number, 1, {
                            ...action.payload.paperFile,
                        });
                    }
                }
            }
            return state;
        },
        updateTitleInProjectList: (state, action: PayloadAction<{ projectId: string; title: string }>) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                if (state[index]) {
                    state[index].title = action.payload.title;
                }
            }
            return state;
        },

        deleteProjectById: (
            state,
            action: PayloadAction<{
                projectId: string;
                deletedPaperFileKeys: string[];
                projects: [{ id: string; noOfPaperFiles: number }];
            }>
        ) => {
            const { projectId, deletedPaperFileKeys, projects } = action.payload;
            return state
                .map((item) => {
                    let newProjectState = { ...item };
                    newProjectState.paperFiles = newProjectState.paperFiles
                        ? newProjectState.paperFiles?.filter(
                              (newProjectState) => deletedPaperFileKeys.indexOf(newProjectState.key as string) === -1
                          )
                        : undefined;
                    newProjectState.noOfPaperFiles = projects.find((project) => project.id === item.id)
                        ?.noOfPaperFiles as number;

                    return newProjectState;
                })
                .filter((project) => project.id !== projectId);
        },

        // update property of paper file in specific Project
        updatePaperFileArray: (state, action: PayloadAction<{ projectId: string; paperFile: PaperFile[] }>) => {
            const index = state.findIndex((item) => item.id === action.payload.projectId);
            if (index > -1) {
                state[index].paperFiles = action.payload.paperFile;
                state[index].noOfPaperFiles = action.payload.paperFile.length;
            }
            return state;
        },

        updateAllProjects: (
            state,
            action: PayloadAction<{
                allProjects: ProjectInterface[];
            }>
        ) => {
            const projects = action.payload.allProjects;
            const newProjectArr: any = projects.map((item: { id: string }) => {
                const selectedProject = state.find((ele) => ele.id === item.id);
                return { ...item, ...selectedProject };
            });

            return newProjectArr;
        },
    },
});

export const {
    setProjects,
    addNewProject,
    addPaperFileToProject,
    addMultiplePaperfilesToProject,
    removePaperFileFromProject,
    updatePaperFileOfProject,
    updateOutlineProject,
    updateTitleInProjectList,
    deleteProjectById,
    updatePaperFileArray,
    removePaperFileFromLibrary,
    updateAllProjects,
} = userSlice.actions;

export default userSlice.reducer;
