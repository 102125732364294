import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferencePaperFile, PaperReferences } from '../../../types';

const initialState: ReferencePaperFile = {};

const paperReferenceSlice = createSlice({
    name: 'PaperReferences',
    initialState,
    reducers: {
        setPaperReferences: (state, action: PayloadAction<{ doi: string; PaperReferences: PaperReferences }>) => {
            const { PaperReferences, doi } = action.payload;
            state[doi] = PaperReferences;
            return state;
        },
        savingPaperReferences: (state, action: PayloadAction<{ childDoi: string; parentDoi: string }>) => {
            const { childDoi, parentDoi } = action.payload;

            const paperToUpdateIndex = state[parentDoi].paperSearchResponse.findIndex((item) => item.doi === childDoi);
            state[parentDoi].paperSearchResponse[paperToUpdateIndex].saving = state[parentDoi].paperSearchResponse[
                paperToUpdateIndex
            ]?.saving
                ? false
                : true;
            return state;
        },
    },
});

export const { setPaperReferences, savingPaperReferences } = paperReferenceSlice.actions;
export default paperReferenceSlice.reducer;
