import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../assets/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background: colors.secondaryButtonOnhover,
        },
        tutorialTitle: {
            padding: 2,
        },
        tutorialHint: {
            fontSize: '14px',
            margin: '4px 0',
        },
        tutorialLink: {
            cursor: 'pointer',
        },
        taskTitle: {
            alignSelf: 'center',
            paddingTop: '9px',
            paddingBottom: '9px',
        },
        taskDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
    })
);

const checkbox = () => ({
    root: {
        color: colors.skyGray,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checked: {
        color: colors.checkCircleRounded,
    },
});

const accordion = () => ({
    root: {
        margin: '0 !important',
        '&:before': {
            opacity: '0.5 !important',
        },
    },
});

const accordionSummary = () => ({
    root: {
        padding: '0 10px 0 5px',
        minHeight: '0 !important',
    },
    content: {
        margin: 0,
        alignItems: 'start',
    },
    expanded: {
        margin: '0 !important',
        minHeight: '0 !important',
    },
});

const accordionDetails = () => ({
    root: {
        padding: '0px 30px 8px 46px',
    },
});

export const compStyles = { checkbox, accordion, accordionSummary, accordionDetails };
