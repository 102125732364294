import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Presentation, Slide } from '../../../types';

interface ProjectPresentation {
    presentation: Presentation | null;
}

const initialState: ProjectPresentation = {
    presentation: null,
};

const userSlice = createSlice({
    name: 'ProjectPresentation',
    initialState,
    reducers: {
        setPresentation: (state, action: PayloadAction<{ presentation: Presentation | null }>) => {
            state.presentation = action.payload.presentation;
            return state;
        },

        updateSlide: (state, action: PayloadAction<{ slide: Slide }>) => {
            if (state.presentation?.slides)
                state.presentation.slides = state.presentation.slides.map((slide) =>
                    slide.id === action.payload.slide.id ? { ...action.payload.slide } : slide
                );
            return state;
        },
    },
});

export const { setPresentation, updateSlide } = userSlice.actions;
export default userSlice.reducer;
