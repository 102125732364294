import { style } from './style';

export const EmptyRow = () => {
    const classes = style();
    return (
        <div className={classes.emptyRowContainer}>
            <div className={classes.filledRectangle}></div>
            <div className={classes.linesContainer}>
                <div className={classes.lineOneContainer} />
                <div className={classes.lineTwoContainer} />
            </div>
        </div>
    );
};
