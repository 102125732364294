import React from 'react';
import Box from '@material-ui/core/Box';

import {
    OutlineItem as OutlineItemInterface,
    OutlineItemType,
    OutlineModes,
} from '../../../../types';
import { useOutlineItemStyles } from './styles';
import { OutlineImageItem, OutlineTextItem, OutlineListItem } from './items';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';

export interface OutlineItemsProps {
    items: OutlineItemInterface[];
    pageId: string;
}

const Items = {
    [OutlineItemType.TEXT]: OutlineTextItem,
    [OutlineItemType.LIST]: OutlineListItem,
    [OutlineItemType.IMAGE]: OutlineImageItem,
};

export const OutlineItems = (props: OutlineItemsProps) => {
    const { items } = props;
    const classes = useOutlineItemStyles();
    const { mode } = useSelector((state: RootState) => state.ProjectOutline);
    const slideItems = [...items];
    return (
        <Box>
            {slideItems
                .sort((first, second) => first.order - second.order)
                .map((item: OutlineItemInterface) => {
                    const Item = Items[item.itemType];

                    return (
                        <Box
                            className={
                                mode === OutlineModes.READER
                                    ? classes.readerItemWrapper
                                    : classes.itemWrapper
                            }
                            key={item.id}
                        >
                            <Item
                                itemId={item.id}
                                properties={item.properties}
                                pageId={props.pageId}
                            />
                        </Box>
                    );
                })}
        </Box>
    );
};
