import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UploadCloudIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="104"
                height="104"
                viewBox="0 0 104 104"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M69.3334 69.3333L52.0001 52L34.6667 69.3333"
                    stroke="#006366"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52 52V91"
                    stroke="#006366"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M88.3565 79.69C92.583 77.3859 95.9218 73.7399 97.846 69.3274C99.7701 64.915 100.17 59.9874 98.9827 55.3224C97.7954 50.6574 95.0883 46.5206 91.2887 43.565C87.4892 40.6094 82.8136 39.0032 77.9998 39H72.5398C71.2282 33.9268 68.7835 29.2168 65.3896 25.2243C61.9957 21.2319 57.7408 18.0607 52.9449 15.9493C48.149 13.838 42.9369 12.8413 37.7003 13.0342C32.4638 13.2271 27.3391 14.6047 22.7116 17.0633C18.0841 19.5218 14.0741 22.9975 10.9832 27.2289C7.89227 31.4603 5.80082 36.3374 4.86607 41.4934C3.93133 46.6495 4.17762 51.9503 5.58642 56.9975C6.99522 62.0447 9.52988 66.7068 12.9998 70.6334"
                    stroke="#006366"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M69.3334 69.3333L52.0001 52L34.6667 69.3333"
                    stroke="#006366"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};
