import { memo, useEffect, useState } from 'react';
import { Toolbar, Divider, IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { WithoutProjects, Projects, ProjectList, AllPapers } from './components';
import { RootState } from '../../redux/combineReducers';
import { showAddProject, showAlert, updatePaperFileArray } from '../../redux/reducers';
import { PaperFile, ProjectInterface } from '../../types';
import { style } from './style';
import { DrawerWrapper, Navbar, Spinner } from '../common';
import { getProjectPaperFiles } from '../../backend/project';
import { alertBarHeight, alertTypes } from '../../constants';
import { useQuery } from '../../utils';
import { colors } from '../../assets/colors';
import config from './../../config/index';
import { routes } from '../pages/routes';

const LibraryView = (props: any) => {
    const [isArticleSelected, setIsSelectArticle] = useState(false);
    const [loadPaperFiles, setLoadPaperFiles] = useState(false);
    const classes = style();
    const dispatch = useDispatch();
    const projectsData: ProjectInterface[] = useSelector((state: RootState) => state.Projects, shallowEqual);
    const history = useHistory();
    const query = useQuery();
    const projectId = query.get('projectId');
    const selectedProject = projectsData.find((item) => item.id === projectId);
    const [paperFileUpdated, setPaperFileUpdated] = useState(false);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const handleAddProject = () => {
        dispatch(showAddProject({ addProjectModal: true }));
    };

    // Update isArticleSelected state when go to Article Section
    useEffect(() => {
        setIsSelectArticle(history?.location?.pathname === '/article');
        if (projectId && !paperFileUpdated) handleSelectProject(projectId);
    }, [projectId, selectedProject, paperFileUpdated]);

    const handleSelectProject = async (projectId: string) => {
        try {
            const filteredProjects = projectsData.find((item) => item.id === projectId);

            let project;
            if (filteredProjects?.paperFiles?.length) {
                project = filteredProjects;
                if (!paperFileUpdated) {
                    dispatch(updatePaperFileArray({ projectId, paperFile: project?.paperFiles as PaperFile[] }));
                }
            } else if (filteredProjects?.noOfPaperFiles && filteredProjects?.noOfPaperFiles > 0) {
                setLoadPaperFiles(true);
                project = await getProjectPaperFiles(projectId);
                dispatch(updatePaperFileArray({ projectId, paperFile: project?.paperFiles as PaperFile[] }));
            }

            setLoadPaperFiles(false);
        } catch (error) {
            if (error instanceof Error) {
                setLoadPaperFiles(false);
                dispatch(showAlert({ isVisible: true, type: alertTypes.error, message: error.message }));
            }
        }
    };

    const handleSelectArticle = () => history.push(routes.article);

    const updatePaperFiles = (projectId: string, paperFileArray: any) => {
        setPaperFileUpdated(true);
        dispatch(updatePaperFileArray({ projectId, paperFile: paperFileArray }));
    };

    return (
        <div className={classes.root} data-testid="library-view">
            <div className={classes.navbar}>
                <Navbar selectedProject={selectedProject} />
            </div>
            <div className={classes.libraryPageMainContent} style={{ marginTop: isVisible ? alertBarHeight : 0 }}>
                <DrawerWrapper>
                    <div className={classes.headingContainer}>
                        <div className={`${classes.headingText} ${classes.textBold}`}>Projects</div>

                        <Tooltip title={'Create Project'}>
                            <IconButton onClick={handleAddProject} size="small">
                                <Add className={classes.addIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <ProjectList isArticleSelected={isArticleSelected} projectId={projectId} />

                    {config.featureFlags.allPapers && (
                        <div
                            role="presentation"
                            onClick={handleSelectArticle}
                            id={'all-papers'}
                            className={classes.headingContainer}
                            style={isArticleSelected ? { backgroundColor: colors.backgroundColor2 } : {}}
                        >
                            <div className={classes.headingText}>All Papers</div>
                            <div className={classes.avatarContainer}>
                                <div className={classes.countContainer}>0</div>
                            </div>
                        </div>
                    )}
                </DrawerWrapper>

                <main className={classes.content}>
                    <Toolbar />
                    <div className={classes.contentConatiner}>
                        {!props.isProjectLoading ? (
                            config.featureFlags.allPapers && isArticleSelected ? (
                                <AllPapers />
                            ) : projectsData.length > 0 && selectedProject ? (
                                <Projects
                                    selectedProject={selectedProject}
                                    loadPaperFiles={loadPaperFiles}
                                    updatePaperFiles={updatePaperFiles}
                                />
                            ) : (
                                <WithoutProjects />
                            )
                        ) : (
                            <div className={classes.spinner}>
                                <Spinner data-testid="loading-projects" />
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export const LibraryViewComp = memo(LibraryView);
