import { Dispatch, SetStateAction } from 'react';
import { Divider, ClickAwayListener } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Author, PaperFile, ProjectInterface } from '../../../../types';
import { RootState } from '../../../../redux/combineReducers';
import { togglePaperSwitcher, togglePaperReferenceSwitcher } from '../../../../redux/reducers';
import { style } from './style';
import { useHistory } from 'react-router';
import { colors } from '../../../../assets/colors';
import { DrawerWrapper, PaperFilesRefresher } from '../../../common';
import config from '../../../../config';
import { routes } from '../../../pages/routes';
import { ChevronRight } from '@material-ui/icons';
import { navbarHeight } from '../../../../constants';

interface PaperSwitcherProps {
    setSwitchPaperFile?: Dispatch<SetStateAction<PaperFile | null>>;
    SelectedPaperFile: PaperFile;
    selectedProject: ProjectInterface;
    toggleDrawer: () => void;
}

export const PaperSwitcher = ({
    setSwitchPaperFile,
    SelectedPaperFile,
    selectedProject,
    toggleDrawer,
}: PaperSwitcherProps) => {
    const ModalHandler = useSelector((state: RootState) => state.ModalHandler);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const classes = style();
    const dispatch = useDispatch();
    const history = useHistory();

    const readablePaperFiles =
        selectedProject?.paperFiles &&
        selectedProject?.paperFiles?.filter((paperFile: PaperFile) => Boolean(paperFile.key) === true);

    const renderPaperAuthor = (primaryAuthor: Author | undefined, secondaryAuthors: Author[] | undefined) => {
        let author = '';
        if (primaryAuthor) {
            author = `${primaryAuthor.firstInitial} ${primaryAuthor.lastName}`;
        }
        if (secondaryAuthors && secondaryAuthors?.length > 0) {
            author = `${author}, ${secondaryAuthors[0].firstInitial} ${secondaryAuthors[0].lastName}`;
        }
        if (author.length > 18) {
            return `${author.substr(0, 18)}...`;
        }
        return author;
    };

    const handleSelectPaperFile = (paperFile: PaperFile) => {
        if (setSwitchPaperFile) {
            setSwitchPaperFile(paperFile);
            toggleDrawer();

            history.push({
                pathname: routes.reader,
                search:
                    '?' +
                    new URLSearchParams({
                        key: paperFile?.key!,
                        projectId: selectedProject?.id,
                    }),
            });
        }
    };

    const showReferencesHandler = () => {
        dispatch(
            togglePaperReferenceSwitcher({ paperReferenceSwitcherDrawer: !ModalHandler.paperReferenceSwitcherDrawer })
        );
        dispatch(togglePaperSwitcher({ paperSwitcherDrawer: !ModalHandler.paperSwitcherDrawer }));
    };

    return (
        <div className={`${classes.root} ${classes.position}`}>
            {ModalHandler.paperSwitcherDrawer && (
                <ClickAwayListener onClickAway={toggleDrawer}>
                    <DrawerWrapper open={ModalHandler.paperSwitcherDrawer} drawerToggler>
                        <div style={{ paddingTop: 10 }}>
                            <div className={classes.refreshButtonContainer}>
                                <PaperFilesRefresher projectId={selectedProject.id} />
                            </div>
                            <div className={classes.drawerHeaderContainer}>
                                <div className={classes.heading}>Other PDFs in this project</div>
                            </div>
                            <div>
                                {readablePaperFiles?.map((paperFile: PaperFile, index: number) => (
                                    <div
                                        key={`${paperFile?.id} ${index}`}
                                        style={{
                                            backgroundColor:
                                                paperFile?.id === SelectedPaperFile.id ? colors.skyGray : '#fff',
                                        }}
                                    >
                                        <div className={classes.paperFileRoot}>
                                            <div
                                                role="presentation"
                                                onClick={() => handleSelectPaperFile(paperFile)}
                                                className={classes.paperFileConatiner}
                                            >
                                                <div className={classes.paperThumbnail} />
                                                <div className={classes.paperDescription}>
                                                    <div>
                                                        <p className={classes.paperTitle}>
                                                            {paperFile?.paper?.title
                                                                ? paperFile?.paper?.title
                                                                : paperFile.name}
                                                        </p>
                                                        <p className={classes.paperAuthor}>
                                                            {renderPaperAuthor(
                                                                paperFile?.paper?.primaryAuthor,
                                                                paperFile?.paper?.secondaryAuthors
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {config.featureFlags.paperReferences &&
                                                paperFile?.id === SelectedPaperFile.id &&
                                                !!SelectedPaperFile.paper?.doi && (
                                                    <div className={classes.reference} onClick={showReferencesHandler}>
                                                        View Refernces
                                                        <span className={classes.forwardIcon}>
                                                            <ChevronRight className={classes.arrowIcon} />
                                                        </span>
                                                    </div>
                                                )}
                                        </div>
                                        <Divider />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </DrawerWrapper>
                </ClickAwayListener>
            )}
        </div>
    );
};
