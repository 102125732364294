import { IconButton } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import CloseIcon from '@material-ui/icons/Close';
import { DoubleArrowDownIcon, NimdoneIcon } from '../../../../assets/icons';
import { MuiCMButton } from '../../../common';

interface Props {
    tutsRef: React.MutableRefObject<HTMLDivElement | null>;
    setCloseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    progress: number;
}

export const WelcomeContent: React.FC<Props> = ({ tutsRef, setCloseModalOpen, progress }) => {
    const classes = useStyles();

    const scrollIntoTutsView = () => {
        if (tutsRef.current) {
            tutsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className={classes.startTutorial} data-testid="onboarding-welcome-section">
            <IconButton
                className={classes.closeTutorial}
                size="small"
                onClick={() => setCloseModalOpen(true)}
                data-testid="close-onboarding-icon-btn"
            >
                <CloseIcon className={classes.closeIcon} />
            </IconButton>
            <NimdoneIcon className={classes.nimdoneLogo} />
            <h3 className={classes.startTutorialTitle}>
                {progress === 100 ? 'You’re a nimdone pro!' : 'Welcome to nimdone!'}
            </h3>
            <div className={classes.startTutorialSubtitle}>
                {progress === 100 ? 'Find tutorial under Help menu' : 'Let’s get started'}
            </div>
            {progress === 100 ? (
                <MuiCMButton
                    variant="contained"
                    className={classes.closeBtn}
                    onClick={() => setCloseModalOpen(true)}
                    data-testid="close-onboarding-btn"
                >
                    Close
                </MuiCMButton>
            ) : (
                <DoubleArrowDownIcon
                    className={classes.scrollDown}
                    onClick={scrollIntoTutsView}
                    data-testid="double-arrow-icon"
                />
            )}
        </div>
    );
};
