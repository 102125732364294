import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
        },
        iconButton: {
            backgroundColor: 'white',
        },
        icon: {
            color: colors.darkTeal,
            fontSize: 20,
        },
        iconButtonContainer: {
            position: 'absolute',
            zIndex: 9,
        },
        icons: {
            display: 'flex',
            border: `1px solid ${colors.darkTeal}`,
            borderRadius: 5,
            width: 65,
            backgroundColor: 'white',
            padding: 3,
            justifyContent: 'space-evenly',
        },
    })
);
