import TagManager from 'react-gtm-module';
import config from  '../../config';

interface ITagMangerArgs {
    gtmId:string
    auth:string
    preview:string
}
export class GTMService {

    //Inializes the Google Tag manager
    public async init(){
        const tagManagerArgs: ITagMangerArgs = {
            gtmId: config.gtm.id,
            auth: config.gtm.auth,
            preview: config.gtm.preview,
        };
       return TagManager.initialize(tagManagerArgs);
    }
    // handles events like new_signup, login etc
    // adds data to the GTM - GA
    public async handleEvent(eventData:any){
        return TagManager.dataLayer({
            dataLayer: eventData
        })
    }

}