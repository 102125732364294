import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ExportIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="17"
                height="17"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.66675 9.91669L7.00008 12.25L9.33342 9.91669"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 7V12.25"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.18 10.5525C12.6872 10.1959 13.0675 9.68694 13.2658 9.09953C13.4641 8.51211 13.47 7.87678 13.2828 7.28575C13.0955 6.69472 12.7248 6.17874 12.2244 5.81268C11.724 5.44662 11.12 5.24954 10.5 5.25002H9.76502C9.58958 4.56628 9.26131 3.93125 8.80492 3.39274C8.34854 2.85422 7.77594 2.42626 7.13023 2.14106C6.48451 1.85587 5.78251 1.72087 5.07708 1.74624C4.37164 1.77161 3.68115 1.95668 3.05759 2.28752C2.43403 2.61836 1.89365 3.08635 1.47713 3.65626C1.06061 4.22616 0.778802 4.88313 0.652924 5.57771C0.527046 6.27229 0.560378 6.98637 0.75041 7.6662C0.940442 8.34603 1.28222 8.9739 1.75002 9.50252"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};
