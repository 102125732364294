export const alertTypes = {
    error: 'ERROR',
    success: 'SUCCESS',
};

export const professionalCategories = [
    { label: 'Faculty', value: 'Faculty' },
    { label: 'Fellow', value: 'Fellow' },
    { label: 'Resident', value: 'Resident' },
    { label: 'Student', value: 'Student' },
    { label: 'Other', value: 'Other' },
];

export const specialties = [
    { label: 'Allergy and immunology', value: 'Allergy and immunology' },
    { label: 'Anesthesiology', value: 'Anesthesiology' },
    { label: 'Dermatology', value: 'Dermatology' },
    { label: 'Diagnostic radiology', value: 'Diagnostic radiology' },
    { label: 'Emergency medicine', value: 'Emergency medicine' },
    { label: 'Family medicine', value: 'Family medicine' },
    { label: 'Internal medicine', value: 'Internal medicine' },
    { label: 'Medical genetics', value: 'Medical genetics' },
    { label: 'Neurology', value: 'Neurology' },
    { label: 'Nuclear medicine', value: 'Nuclear medicine' },
    { label: 'Obstetrics and gynecology', value: 'Obstetrics and gynecology' },
    { label: 'Ophthalmology', value: 'Ophthalmology' },
    { label: 'Pathology', value: 'Pathology' },
    { label: 'Pediatrics', value: 'Pediatrics' },
    {
        label: 'Physical medicine and rehabilitation',
        value: 'Physical medicine and rehabilitation',
    },
    { label: 'Preventive medicine', value: 'Preventive medicine' },
    { label: 'Psychiatry', value: 'Psychiatry' },
    { label: 'Radiation oncology', value: 'Radiation oncology' },
    { label: 'Surgery', value: 'Surgery' },
    { label: 'Urology', value: 'Urology' },
    { label: 'Other', value: 'Other' },
];

export const countries = [
    { label: 'Afghanistan', value: 'Afghanistan' },
    { label: 'Albania', value: 'Albania' },
    { label: 'Algeria', value: 'Algeria' },
    { label: 'Angola', value: 'Angola' },
    { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { label: 'Argentina', value: 'Argentina' },
    { label: 'Armenia', value: 'Armenia' },
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Azerbaijan', value: 'Azerbaijan' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Bahrain', value: 'Bahrain' },
    { label: 'Bangladesh', value: 'Bangladesh' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Belize', value: 'Belize' },
    { label: 'Benin', value: 'Benin' },
    { label: 'Bolivia (Plurinational State of)', value: 'Bolivia (Plurinational State of)' },
    { label: 'Bonaire', value: 'Bonaire' },
    { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
    { label: 'Botswana', value: 'Botswana' },
    { label: 'Brazil', value: 'Brazil' },
    { label: 'Bulgaria', value: 'Bulgaria' },
    { label: 'Burkina Faso', value: 'Burkina Faso' },
    { label: 'Burundi', value: 'Burundi' },
    { label: 'Cambodia', value: 'Cambodia' },
    { label: 'Cameroon', value: 'Cameroon' },
    { label: 'Canada', value: 'Canada' },
    { label: 'Cayman Islands', value: 'Cayman Islands' },
    { label: 'Central African Republic', value: 'Central African Republic' },
    { label: 'Chad', value: 'Chad' },
    { label: 'Chile', value: 'Chile' },
    { label: 'China', value: 'China' },
    { label: 'Colombia', value: 'Colombia' },
    { label: 'Comoros', value: 'Comoros' },
    { label: 'Congo', value: 'Congo' },
    { label: 'Congo, Democratic Republic of the', value: 'Congo, Democratic Republic of the' },
    { label: 'Cook Islands', value: 'Cook Islands' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { label: 'Croatia', value: 'Croatia' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Cyprus', value: 'Cyprus' },
    { label: 'Czech Republic', value: 'Czech Republic' },
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Djibouti', value: 'Djibouti' },
    { label: 'Dominica', value: 'Dominica' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'Ecuador', value: 'Ecuador' },
    { label: 'Egypt', value: 'Egypt' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Eritrea', value: 'Eritrea' },
    { label: 'Estonia', value: 'Estonia' },
    { label: 'Ethiopia', value: 'Ethiopia' },
    { label: 'Fiji', value: 'Fiji' },
    { label: 'Finland', value: 'Finland' },
    { label: 'France', value: 'France' },
    { label: 'Gabon', value: 'Gabon' },
    { label: 'Gambia', value: 'Gambia' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Greece', value: 'Greece' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Guinea', value: 'Guinea' },
    { label: 'Guinea Bissau', value: 'Guinea Bissau' },
    { label: 'Guyana', value: 'Guyana' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Hong Kong, S.A.R. China', value: 'Hong Kong, S.A.R. China' },
    { label: 'Hungary', value: 'Hungary' },
    { label: 'Iceland', value: 'Iceland' },
    { label: 'India', value: 'India' },
    { label: 'Indonesia', value: 'Indonesia' },
    { label: 'Iran (Islamic Republic of)', value: 'Iran (Islamic Republic of)' },
    { label: 'Iraq', value: 'Iraq' },
    { label: 'Ireland', value: 'Ireland' },
    { label: 'Israel', value: 'Israel' },
    { label: 'Italy', value: 'Italy' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Jordan', value: 'Jordan' },
    { label: 'Kazakhstan', value: 'Kazakhstan' },
    { label: 'Kenya', value: 'Kenya' },
    { label: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
    { label: 'Korea, Republic of', value: 'Korea, Republic of' },
    { label: 'Kuwait', value: 'Kuwait' },
    { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { label: 'Lao People’s Democratic Republic', value: 'Lao People’s Democratic Republic' },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Lebanon', value: 'Lebanon' },
    { label: 'Liberia', value: 'Liberia' },
    { label: 'Libya', value: 'Libya' },
    { label: 'Lithuania', value: 'Lithuania' },
    { label: 'Macau, S.A.R. China', value: 'Macau, S.A.R. China' },
    { label: 'Madagascar', value: 'Madagascar' },
    { label: 'Malawi', value: 'Malawi' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'Maldives', value: 'Maldives' },
    { label: 'Mali', value: 'Mali' },
    { label: 'Malta', value: 'Malta' },
    { label: 'Mauritania', value: 'Mauritania' },
    { label: 'Mauritius', value: 'Mauritius' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Micronesia (Federated States of)', value: 'Micronesia (Federated States of)' },
    { label: 'Moldova, Republic of', value: 'Moldova, Republic of' },
    { label: 'Mongolia', value: 'Mongolia' },
    { label: 'Montenegro', value: 'Montenegro' },
    { label: 'Montserrat', value: 'Montserrat' },
    { label: 'Morocco', value: 'Morocco' },
    { label: 'Mozambique', value: 'Mozambique' },
    { label: 'Myanmar', value: 'Myanmar' },
    { label: 'Nepal', value: 'Nepal' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Niger', value: 'Niger' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'Niue', value: 'Niue' },
    { label: 'North Macedonia', value: 'North Macedonia' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Oman', value: 'Oman' },
    { label: 'Pakistan', value: 'Pakistan' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Papua New Guinea', value: 'Papua New Guinea' },
    { label: 'Paraguay', value: 'Paraguay' },
    { label: 'Peru', value: 'Peru' },
    { label: 'Philippines', value: 'Philippines' },
    { label: 'Poland', value: 'Poland' },
    { label: 'Portugal', value: 'Portugal' },
    { label: 'Qatar', value: 'Qatar' },
    { label: 'Romania', value: 'Romania' },
    { label: 'Russian Federation', value: 'Russian Federation' },
    { label: 'Rwanda', value: 'Rwanda' },
    { label: 'Saba', value: 'Saba' },
    { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
    { label: 'Saint Lucia', value: 'Saint Lucia' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { label: 'Samoa', value: 'Samoa' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia' },
    { label: 'Senegal', value: 'Senegal' },
    { label: 'Serbia', value: 'Serbia' },
    { label: 'Seychelles', value: 'Seychelles' },
    { label: 'Sierra Leone', value: 'Sierra Leone' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'Slovakia', value: 'Slovakia' },
    { label: 'Slovenia', value: 'Slovenia' },
    { label: 'Solomon Islands', value: 'Solomon Islands' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'South Africa', value: 'South Africa' },
    { label: 'South Sudan', value: 'South Sudan' },
    { label: 'Spain', value: 'Spain' },
    { label: 'Sri Lanka', value: 'Sri Lanka' },
    { label: 'Sudan', value: 'Sudan' },
    { label: 'Suriname', value: 'Suriname' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
    { label: 'Tajikistan', value: 'Tajikistan' },
    { label: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'Togo', value: 'Togo' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
    { label: 'Tunisia', value: 'Tunisia' },
    { label: 'Turkey', value: 'Turkey' },
    { label: 'Turkmenistan', value: 'Turkmenistan' },
    { label: 'Uganda', value: 'Uganda' },
    { label: 'Ukraine', value: 'Ukraine' },
    { label: 'United Arab Emirates', value: 'United Arab Emirates' },
    {
        label: 'United Kingdom of Great Britain and Northern Ireland',
        value: 'United Kingdom of Great Britain and Northern Ireland',
    },
    { label: 'United States of America', value: 'United States of America' },
    { label: 'Uruguay', value: 'Uruguay' },
    { label: 'Uzbekistan', value: 'Uzbekistan' },
    { label: 'Vanuatu', value: 'Vanuatu' },
    { label: 'Venezuela (Bolivarian Republic of)', value: 'Venezuela (Bolivarian Republic of)' },
    { label: 'Viet Nam', value: 'Viet Nam' },
    { label: 'Yemen', value: 'Yemen' },
    { label: 'Zambia', value: 'Zambia' },
    { label: 'Zimbabwe', value: 'Zimbabwe' },
    { label: 'Other', value: 'Other' },
];

export const NavBarItems = [
    // {
    //     id: '0',
    //     name: 'File',
    // },
    {
        id: '1',
        name: 'View',
    },
    {
        id: '2',
        name: 'Help',
    },
];

export const TUTORIAL_URL = 'https://www.youtube.com/watch?v=5A17LkhSpUk&ab_channel=nimdone/';

export const EMAIL_URL = 'mailto:hello@nimdone.com';

export const PUBMED_URL = 'https://pubmed.ncbi.nlm.nih.gov/';

export const API_TYPE = {
    OPEN: 'open',
    AUTHENTICATED: 'authenticated',
};

export const paperMetaDataStatus = {
    doi: 'Identifying DOI',
    metadata: 'Fetching metadata',
    failedDoi: 'DOI not found',
    failedMetadata: "couldn't fetch metadata",
    paperExist: 'Paper already exist',
};

export const LAYOUT_MENUS = [
    {
        id: 'COVER',
        name: 'Cover',
    },
    {
        id: 'CONTENT',
        name: 'Content',
    },
];
export const pptxThemes = {
    classic: 'Classic',
    moss: 'Moss',
    blackAndWhite: 'Black and White',
    suede: 'Suede',
};

export const stripe = {
    monthlyPlan: {
        id: '1',
        planName: 'INDIVIDUAL',
        price: '$10',
        description: 'Monthly Individual Plan',
    },
    annualPlan: {
        id: '2',
        planName: 'INDIVIDUAL',
        price: '$7',
        description: 'Annual Individual Plan',
    },
};

export const SETTINGS_ITEMS = [
    {
        name: 'Account Settings',
        path: '/settings',
    },
    {
        name: 'Preferences',
        path: '/settings/preferences',
    },
];

export const sampleDoi = '10.1001/archinternmed.2012.940';

export const journalSite = (doi: string) => `https://doi.org/${doi}`;

export const gtm = {
    events: {
        LOGIN: 'login',
        NEW_SIGNUP: 'new_signup',
        SNIP_FROM_PDF: 'Snip From PDF',
        UPLOAD_IMAGE: 'Upload Image',
        TRANSACTION: 'transaction',
        TYPE_TEXT: 'Type Text',
        HIGHLIGHT_FROM_PDF: 'Highlight from PDF',
        PROJECT_ADDED: 'ProjectAdded',
        UPLOAD_PDF: 'UploadPDF',
    },
};

export const DAY_IN_MILLI_SECONDS = 86400000;

export const navbarHeight = 64;
export const alertBarHeight = 50;
export const drawerWidth = 280;
export const proxyLibraries = 'https://libproxy-db.org/';
export const projectTitleLength = 30; // length determine on the basis of project list drawer width so that it won't overflow
export const tabletScreenSize = 768;
export const sampleMetaData = {
    title: 'Myasthenia Gravis: A Review',
    primaryAuthor: { firstInitial: 'Annapurni', lastName: '' },
    secondaryAuthors: [
        { firstInitial: 'Alok', lastName: '' },
        { firstInitial: 'Noha', lastName: '' },
        { firstInitial: 'Mohankumar', lastName: '' },
        { firstInitial: 'Janaki', lastName: '' },
    ],
    publishedDate: '2012',
    journalName: 'Autoimmune Diseases',
    doi: '10.1155/2012/874680',
};
