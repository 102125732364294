import React, { useState, useEffect } from 'react';
import { ListItemText } from '@material-ui/core';
import { useLocation } from 'react-router';

import { menuStyles } from '../styles';
import { StyledMenuItem } from './styledMenuItem';
import { DropDownMenuProps } from './types';
import { StyledMenu } from './styledMenu';

export const FileNewSubMenu = ({
    anchorEl,
    handleClose,
}: DropDownMenuProps) => {
    const classes = menuStyles();

    const [isReader, setIsReader] = useState<boolean>(false);
    const [isSlidePreview, setIsSlidePreview] = useState<boolean>(false);

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes('reader')) setIsReader(true);
        if (pathname.includes('slide-preview')) setIsSlidePreview(true);
    }, [pathname]);

    return (
        <StyledMenu
            placement={'left-start'}
            anchorEl={anchorEl}
            handleClose={handleClose}
        >
            {isReader && (
                <StyledMenuItem>
                    <ListItemText
                        primary={
                            <p className={classes.listItemText}>
                                {'PDF Upload'}
                            </p>
                        }
                    />
                </StyledMenuItem>
            )}
            <StyledMenuItem>
                <ListItemText
                    primary={
                        <p
                            className={
                                isSlidePreview
                                    ? classes.disabledListItemText
                                    : classes.listItemText
                            }
                        >
                            {isSlidePreview
                                ? 'Project Outline is open'
                                : 'Project Outline'}
                        </p>
                    }
                />
            </StyledMenuItem>
        </StyledMenu>
    );
};
