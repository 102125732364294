import { useState, useEffect, useRef } from 'react';
import { IconButton, CircularProgress, ClickAwayListener } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft } from '@material-ui/icons';

import { RootState } from '../../../../redux/combineReducers';
import {
    setpaperFileProjectsDoi,
    showAlert,
    setPaperReferences,
    togglePaperReferenceSwitcher,
    togglePaperSwitcher,
} from '../../../../redux/reducers';

import { style } from './style';
import { DrawerWrapper, Spinner } from '../../../common';
import { PaperFile, ProjectInterface, PaperSearchResponse, PaperSource } from '../../../../types';
import { SavePaperReference } from './saveReferences';
import { getPaperFileProjectsByDoi } from '../../../../backend/paperFileProject';
import { SavePaper } from './savePaper';
import { routes } from '../../../pages/routes';
import { getPaperReferences } from '../../../../backend/paper';
import { alertTypes } from '../../../../constants';

interface PaperReferencesSwitcherProps {
    SelectedPaperFile?: PaperFile;
    selectedProject: ProjectInterface;
    toggleDrawer: () => void;
}

export const PaperReferencesSwitcher = ({
    SelectedPaperFile,
    selectedProject,
    toggleDrawer,
}: PaperReferencesSwitcherProps) => {
    const ModalHandler = useSelector((state: RootState) => state.ModalHandler);
    const speakerId = useSelector((state: RootState) => state.Speaker.id);
    const paperFileProjectsByDoi = useSelector((state: RootState) => state.paperFileProjectDoi);
    const doi = useSelector((state: RootState) => state.PaperFile.paper?.doi);
    const PaperReferences = useSelector((state: RootState) => state.PaperReferences[doi as string]);

    const classes = style();
    const dispatch = useDispatch();
    const history = useHistory();
    const [saveReferenceModal, setSaveReferenceModal] = useState(false);
    const [savePaperModal, setSavePaperModal] = useState(false);
    const [selectedPaper, setSelectedPaper] = useState<PaperSearchResponse>();
    const [referenceLoader, setReferenceLoader] = useState(true);
    const [temp, setTemp] = useState<any>(null);
    const [reference, setReference] = useState<any>(null);
    const [isLoader, setIsLoader] = useState(true);
    const [fetchingPaperFileProject, setFetchingPaperFileProject] = useState<any>(false);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                let isIntersecting = entries[0].isIntersecting;
                if (isIntersecting === true) {
                    setTemp(Date.now());
                }
            },
            { threshold: 1 }
        )
    );

    useEffect(() => {
        if (!doi) {
            dispatch(togglePaperSwitcher({ paperSwitcherDrawer: true }));
            dispatch(togglePaperReferenceSwitcher({ paperReferenceSwitcherDrawer: false }));
            return;
        }
        PaperReferences?.paperSearchResponse?.length ? setReferenceLoader(false) : fetchReferences();
    }, []);

    const fetchReferences = async () => {
        try {
            const presentDois = PaperReferences?.paperSearchResponse?.length
                ? PaperReferences?.paperSearchResponse.map((item: any) => item.doi)
                : [];

            let response = await getPaperReferences(doi as string, presentDois, speakerId as string);
            setTemp(null);
            if (response?.paperSearchResponse?.length < 10) setIsLoader(false);

            if (PaperReferences?.paperSearchResponse?.length) {
                response.paperSearchResponse = [
                    ...PaperReferences?.paperSearchResponse,
                    ...response.paperSearchResponse,
                ];
            }
            setReferenceLoader(false);
            dispatch(setPaperReferences({ PaperReferences: response, doi: doi as string }));
        } catch (error) {
            if (error instanceof Error) {
                setReferenceLoader(false);
                dispatch(
                    showAlert({
                        message: error.message,
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );
            }
        }
    };

    const fetchPaperFileProjectsByDoi = async (doi: string) => {
        setFetchingPaperFileProject(true);
        try {
            const response = await getPaperFileProjectsByDoi(speakerId as string, doi);
            dispatch(setpaperFileProjectsDoi({ doi: doi, paperFileProjects: response }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
        setFetchingPaperFileProject(false);
    };

    const openReaderViewModal = async (paperSearchResponse: PaperSearchResponse, paidPaper: boolean) => {
        let projectList = paperFileProjectsByDoi[paperSearchResponse.doi];
        if (!projectList) {
            fetchPaperFileProjectsByDoi(paperSearchResponse.doi);
        }
        setSelectedPaper(paperSearchResponse);

        if (paidPaper) setSaveReferenceModal(true);
        else setSavePaperModal(true);
    };

    const viewPaper = (doi: string) => {
        toggleDrawer();
        history.push({
            pathname: routes.reader,
            search: '?' + new URLSearchParams({ doi, projectId: selectedProject.id, source: PaperSource.UNPAYWALL }),
        });
    };

    const checkSavedStatus = (doi: string, referenceExist: boolean) =>
        paperFileProjectsByDoi[doi] === undefined ? referenceExist : paperFileProjectsByDoi[doi]?.length > 0;

    const checkSavingStatus = (doi: string, saving: boolean) => (paperFileProjectsByDoi[doi]?.length ? null : saving);

    useEffect(() => {
        if (Boolean(temp) && PaperReferences?.paperSearchResponse?.length) {
            fetchReferences();
        }
    }, [temp, PaperReferences]);

    useEffect(() => {
        let currentElement = reference;
        let currentObserver = observer.current;
        if (currentElement) {
            setIsLoader(true);
            currentObserver.observe(reference);
        }

        return () => {
            if (currentElement && PaperReferences?.paperSearchResponse?.length < 10) {
                currentObserver.unobserve(reference);
            }
        };
    }, [reference]);

    const handleClosePaperReferenceDrawer = () => {
        dispatch(togglePaperSwitcher({ paperSwitcherDrawer: true }));
        dispatch(
            togglePaperReferenceSwitcher({ paperReferenceSwitcherDrawer: !ModalHandler.paperReferenceSwitcherDrawer })
        );
    };

    return (
        <div className={`${classes.root} ${classes.position}`}>
            <ClickAwayListener onClickAway={toggleDrawer}>
                <>
                    {saveReferenceModal && (
                        <SavePaperReference
                            saveReferenceModal={saveReferenceModal}
                            selectedPaper={selectedPaper as PaperSearchResponse}
                            closeModal={() => setSaveReferenceModal(false)}
                            fetchingPaperFileProject={fetchingPaperFileProject}
                        />
                    )}
                    {savePaperModal && (
                        <SavePaper
                            saveReferenceModal={savePaperModal}
                            selectedPaper={selectedPaper as PaperSearchResponse}
                            closeModal={() => setSavePaperModal(false)}
                            fetchingPaperFileProject={fetchingPaperFileProject}
                        />
                    )}

                    {ModalHandler.paperReferenceSwitcherDrawer && (
                        <DrawerWrapper
                            drawerToggler
                            open={ModalHandler.paperReferenceSwitcherDrawer}
                            testId="drawer-view"
                        >
                            <div style={{ paddingTop: 10 }}>
                                <div className={classes.backButtonContainer} onClick={handleClosePaperReferenceDrawer}>
                                    <div className={classes.referenceClose}>Close Refernces</div>
                                    <ChevronLeft className={classes.arrowIcon} />
                                </div>
                                <div className={classes.drawerHeaderContainer}>
                                    <div className={classes.heading}>
                                        {SelectedPaperFile?.paper?.title
                                            ? SelectedPaperFile?.paper?.title
                                            : SelectedPaperFile?.name}
                                    </div>
                                </div>
                                <div className={classes.drawerBodyContainer}>
                                    {!referenceLoader ? (
                                        PaperReferences?.paperSearchResponse?.length ? (
                                            <>
                                                {PaperReferences.paperSearchResponse.map((item: any, index: number) => (
                                                    <div
                                                        className={classes.referenceContainer}
                                                        key={`PaperReferences-${index}`}
                                                    >
                                                        <div className={classes.reference}>
                                                            <div className={classes.referenceTitle}>{item.title}</div>
                                                            <div className={classes.info}>
                                                                <span>
                                                                    {item.primaryAuthor?.firstInitial
                                                                        ? item.primaryAuthor?.firstInitial
                                                                        : '--'}
                                                                </span>
                                                                -
                                                                <span>
                                                                    {item.published_date ? item.published_date : 'Year'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {item.isOpenAccess && item.paperUrl ? (
                                                            <div className={classes.linkDiv}>
                                                                <span
                                                                    onClick={() => viewPaper(item.doi)}
                                                                    className={classes.link}
                                                                    data-testid={`view-${index}`}
                                                                >
                                                                    View
                                                                </span>
                                                                <span
                                                                    onClick={() => openReaderViewModal(item, false)}
                                                                    className={classes.link}
                                                                    data-testid={`save-${index}`}
                                                                >
                                                                    {checkSavedStatus(item.doi, item.referenceExist)
                                                                        ? 'Saved'
                                                                        : 'Save to'}
                                                                </span>
                                                                {checkSavingStatus(item.doi, item.saving) && (
                                                                    <span>
                                                                        <IconButton
                                                                            size="small"
                                                                            data-testid={`saving-loader-${index}`}
                                                                        >
                                                                            <CircularProgress
                                                                                size={'10px'}
                                                                                className={classes.circularProgress}
                                                                            />
                                                                        </IconButton>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={classes.linkDiv}
                                                                onClick={() => openReaderViewModal(item, true)}
                                                            >
                                                                <span
                                                                    className={classes.link}
                                                                    data-testid={`save-ref-${index}`}
                                                                >
                                                                    {checkSavedStatus(item.doi, item.referenceExist)
                                                                        ? 'Reference Saved'
                                                                        : 'Save Reference'}
                                                                    {checkSavingStatus(item.doi, item.saving) && (
                                                                        <span>
                                                                            <IconButton
                                                                                size="small"
                                                                                data-testid={`saving-loader-${index}`}
                                                                            >
                                                                                <CircularProgress
                                                                                    size={'10px'}
                                                                                    className={classes.circularProgress}
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                                {isLoader && (
                                                    <div ref={setReference} className={classes.loaderDiv}>
                                                        <CircularProgress size={20} style={{ color: '#000' }} />
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className={classes.noReference}>
                                                {!!PaperReferences?.status && PaperReferences?.status}
                                            </div>
                                        )
                                    ) : (
                                        <div className={classes.spinnerDiv} data-testid="ref-loader">
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DrawerWrapper>
                    )}
                </>
            </ClickAwayListener>
        </div>
    );
};
