import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const OpenInNewIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.33398 2.66602H2.66732C2.3137 2.66602 1.97456 2.80649 1.72451 3.05654C1.47446 3.30659 1.33398 3.64573 1.33398 3.99935V13.3327C1.33398 13.6863 1.47446 14.0254 1.72451 14.2755C1.97456 14.5255 2.3137 14.666 2.66732 14.666H12.0007C12.3543 14.666 12.6934 14.5255 12.9435 14.2755C13.1935 14.0254 13.334 13.6863 13.334 13.3327V8.66602"
                    stroke="#006266"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M10 2H14V6" stroke="#006266" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M14.0007 2L9.33398 6.66667"
                    stroke="#006266"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};
