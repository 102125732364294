import React from 'react';
import { style } from './style';
import Popover from '@material-ui/core/Popover';
import AddIcon from '@material-ui/icons/Add';

export const PlusButton = ({
    onClick,
    id,
    buttonClassName,
    open,
    popoverContent,
    anchorEl,
    handleClose,
    testId,
}: ButtonProps) => {
    const classes = style();
    return (
        <>
            <div
                onClick={onClick}
                id={id}
                className={buttonClassName ? `${classes.button} ${buttonClassName}` : classes.button}
                data-testid={testId}
            >
                <AddIcon className={classes.plusIcon} />
            </div>
            <Popover
                id={`popover ${id}`}
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {popoverContent}
            </Popover>
        </>
    );
};

type ButtonProps = {
    onClick?: any;
    id?: any;
    buttonClassName?: string;
    open: boolean;
    popoverContent?: any;
    handleClose: Function;
    anchorEl?: any;
    testId?: any;
};
