import { IconButton, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React,{useEffect} from 'react';
import { useSlate } from 'slate-react';
import { colors } from '../../../../../../../../assets/colors';
import { BlockFormatType } from '../types';
import { toggleBlock, isBlockActive } from './common';

interface TextEditorToggleButtonPropsType {
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    blockFormat: BlockFormatType;
}

export const TextEditorToggleButton: React.FC<TextEditorToggleButtonPropsType> = ({ Icon, blockFormat }) => {
    const editor = useSlate();

    const handleToggle = () => toggleBlock(editor, blockFormat);
    const isActive = (): boolean => {
        return isBlockActive(editor, blockFormat);
    };

    return (
        <IconButton size="small" onClick={handleToggle}>
            <Icon
                style={{
                    color: isActive() ? colors.black : colors.disabledMenu,
                }}
            />
        </IconButton>
    );
};
