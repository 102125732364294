import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';
import { navbarHeight } from '../../../constants';

const navbarStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: `${navbarHeight}px !important`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appBar: {
        backgroundColor: colors.white,
    },
    avatar: {
        width: 28,
        height: 28,
    },
    avatarContainer: {
        marginLeft: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.black,
        borderRadius: 0,
    },
    exportIcon: {
        paddingTop: 5,
        color: '#fff',
    },
    name: {
        fontSize: 15,
        margin: 0,
        paddingLeft: 10,
    },
    downChevron: {
        height: 20,
        width: 20,
        padding: 3,
    },
    menu: {
        color: colors.black,
        padding: '3px 5px',
        fontSize: 16,
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: 'normal',
        '&:active': {
            color: colors.skyGray,
        },
    },
    clickedMenu: {
        color: colors.black,
        padding: '3px 5px',
        fontSize: 16,
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: 'normal',
        backgroundColor: colors.skyGray,
        '&:hover': {
            backgroundColor: colors.skyGray,
        },
    },
    hoveredMenu: {
        color: colors.black,
        padding: '3px 5px',
        fontSize: 16,
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: 'normal',
        '&:hover': {
            backgroundColor: colors.skyGray,
        },
    },
    menuContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    logoContainer: {
        paddingRight: 20,
        display: 'flex',
        alignItems: 'center',
        height: 52,
        cursor: 'pointer',
    },
    exportButton: {
        width: 'auto',
        height: '26px',
        borderRadius: '6px',
        color: colors.white,
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 20,
        textTransform: 'none',
        backgroundColor: colors.primaryButton,
        '&:hover': {
            backgroundColor: colors.primaryButtonOnhover,
        },
        '&:focus': {
            border: `1px solid ${colors.darkTeal}`,
            boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
        },
        '&:active': {
            backgroundColor: colors.primaryButtonOnPress,
        },
        padding: 15,
    },
    savingIndicator: {
        color: colors.disabledMenu,
        fontStyle: 'italic',
        fontSize: 15,
        marginLeft: 3,
    },
    logo: {
        width: 110,
        height: 52,
    },
    freeTrialMessage: {
        color: colors.primaryButton,
    },
    bold: {
        fontWeight: 'bold',
    },
    extendTrial: {
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: 14,
    },
}));

const menuStyles = makeStyles(() => ({
    menuRoot: {
        padding: 0,
    },
    icon: {
        color: colors.disabledMenu,
        fontSize: 17,
    },
    listItemIcon: {
        paddingRight: 8,
        paddingTop: 2,
        minWidth: 20,
    },
    listItemText: {
        fontSize: 15,
        margin: 0,
    },
    disabledListItemText: {
        fontSize: 15,
        margin: 0,
        color: colors.disabledMenu,
    },
    divider: {
        margin: '0px 5px',
    },
}));

export { navbarStyles, menuStyles };
