import { Divider, ListItemText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpeakerOnboardingPreference } from '../../../../backend/speakerPreferences';
import config from '../../../../config';
import { alertTypes, EMAIL_URL } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import { updateSpeakerPreference, showAlert } from '../../../../redux/reducers';
import { menuStyles } from '../styles';
import { StyledMenu } from './styledMenu';
import { StyledMenuItem } from './styledMenuItem';
import { DropDownMenuProps } from './types';

export const HelpMenu = ({ anchorEl, handleClose }: DropDownMenuProps) => {
    const classes = menuStyles();
    const dispatch = useDispatch();
    const speaker = useSelector((state: RootState) => state.Speaker);
    const speakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);

    const navigateToWatchTutorial = async () => {
        dispatch(updateSpeakerPreference({ showOnboarding: true }));
        try {
            await updateSpeakerOnboardingPreference(speaker.id!, true);
        } catch (error) {
            dispatch(updateSpeakerPreference({ showOnboarding: false }));
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
        handleClose();
    };

    const navigateToDownloadExtension = () => {
        window.open(config.extension.downloadURL, '_blank', 'noopener,noreferrer');
        handleClose();
    };

    const navigateToEmail = () => {
        window.open(EMAIL_URL);
        handleClose();
    };

    return (
        <StyledMenu placement={'top-start'} anchorEl={anchorEl} handleClose={handleClose}>
            <StyledMenuItem
                onClick={navigateToWatchTutorial}
                disabled={speakerPreferences && speakerPreferences.showOnboarding}
                data-testid="open-onboarding-tutorial"
            >
                <ListItemText primary={<p className={classes.listItemText}>Open Onboarding Tutorial</p>} />
            </StyledMenuItem>
            <StyledMenuItem onClick={navigateToDownloadExtension}>
                <ListItemText primary={<p className={classes.listItemText}>Add Chrome Extension</p>} />
            </StyledMenuItem>
            <Divider className={classes.divider} />
            <StyledMenuItem onClick={navigateToEmail}>
                <ListItemText primary={<p className={classes.listItemText}>Contact us</p>} />
            </StyledMenuItem>
        </StyledMenu>
    );
};
