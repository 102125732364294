import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/colors';

export const useOutlineSlidePreviewStyles = makeStyles(() => ({
    readerViewRoot: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },

    caption: {
        color: colors.text,
        fontSize: '1.1em',
        display: 'flex',
    },

    slideItem: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
        height: 200,
    },
    emptySlides: {
        height: 160,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
