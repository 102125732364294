import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../assets/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeTutorial: {
            position: 'absolute',
            top: '10px',
            right: '10px',
        },
        closeIcon: {
            width: 20,
            height: 20,
            color: colors.darkTeal,
        },
        nimdoneLogo: {
            width: 100,
            height: 100,
        },
        startTutorial: {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            paddingTop: 26,
            paddingBottom: 12,
        },
        startTutorialTitle: {
            fontSize: 21,
            marginBottom: 4,
        },
        startTutorialSubtitle: {
            fontSize: 16,
            color: colors.smoke,
        },
        scrollDown: {
            width: 37,
            height: 45,
            marginTop: 4,
            color: 'transparent',
            cursor: 'pointer',
        },
        closeBtn: {
            marginTop: 16,
            fontSize: '14px',
            fontWeight: 'normal',
            padding: '3.4px 12px',
        },
    })
);
