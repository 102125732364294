import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            color: 'black',
            width: '100%',
            boxShadow: 'none',
        },
        container: {
            width: '100%',
            position: 'fixed',
        },
        alertContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        alertIcon: {
            height: 24,
            width: 24,
            paddingRight: 10,
        },
        alertClose: {
            color: '#0052CC',
            padding: '0 10px',
            cursor: 'pointer',
        },
        error: {
            backgroundColor: colors.pinkishRed,
            borderRadius: '0px',
        },
        success: {
            backgroundColor: ' #E3FCEF',
        },
    })
);
