import React from 'react';
import { Reader } from '../pdfReader/index';

export const PdfReaderPage = () => {
    return (
        <div>
            <Reader />
        </div>
    );
};
