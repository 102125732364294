import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { speakerEmailVerification } from '../../../backend/speaker';
import config from '../../../config';
import { alertTypes } from '../../../constants';
import { showAlert } from '../../../redux/reducers';
import { useQuery } from '../../../utils';
import { MuiCMButton } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

export const SignupSuccess = () => {
    const [status, setStatus] = useState<boolean>(false);
    const classes = style();
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();

    const code: string | null | undefined = query.get('confirmation_code');
    const username: string | null | undefined = query.get('username');

    const handleExtensionBtn = () => window.open(config.extension.downloadURL, '_blank');

    const verifySpeaker = async () => {
        if (code && username) {
            try {
                await Auth.confirmSignUp(username, code);
                await speakerEmailVerification(username, true);
                setStatus(true);
            } catch (error) {
                const err = error as any;
                if (err) {
                    setStatus(false);
                    if (err.code === 'NotAuthorizedException')
                        dispatch(
                            showAlert({
                                message: 'Looks like your account is already confirmed.',
                                type: alertTypes.error,
                                isVisible: true,
                            })
                        );
                    else {
                        dispatch(
                            showAlert({
                                message: err.message,
                                type: alertTypes.error,
                                isVisible: true,
                            })
                        );
                    }
                }
                handleLoginRedirect();
            }
        } else handleLoginRedirect();
    };

    useEffect(() => {
        verifySpeaker();
    }, []);

    const handleLoginRedirect = async () => history.push(routes.login);

    return (
        <div className={classes.container}>
            {status && (
                <div className={classes.content}>
                    <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} />
                    <p className={classes.congratulations}>Congratulations! You are now ready to use nimdone</p>
                    <MuiCMButton onClick={handleExtensionBtn} className={classes.btn} fullWidth>
                        Get the nimdone extension
                    </MuiCMButton>
                    <p className={classes.extensionLater}>
                        Want to get the extenstion later?{' '}
                        <span className={classes.blueText} onClick={handleLoginRedirect}>
                            Go to your nimdone library
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};
