import { useState } from 'react';
import { style } from './style';
import { ListItem, ListItemText, List } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { PlusButton } from '../../../../../common';
import { PUBMED_URL } from '../../../../../../constants';

interface AddPapersProps {
    toggleUploadPdfModal: (state: boolean) => void;
}

export const AddPaper = ({ toggleUploadPdfModal }: AddPapersProps) => {
    const classes = style();
    const [optionsAnchor, setOptionsAnchor] = useState<HTMLButtonElement | null>(null);

    const handleClickBtn = (event: any) => {
        setOptionsAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setOptionsAnchor(null);
    };
    const openOptions = Boolean(optionsAnchor);

    const itemHandler = (name: string) => {
        if (name === 'toggleUploadPdfModal') toggleUploadPdfModal(true);
        setOptionsAnchor(null);
    };

    const options = (
        <List>
            <ListItem button onClick={() => itemHandler('toggleUploadPdfModal')}>
                <div className={classes.listItem}>
                    <BackupOutlinedIcon className={classes.listItemIcon} fontSize="large" />
                    <ListItemText primary="Upload PDF" />
                </div>
            </ListItem>
            <ListItem button>
                <a href={PUBMED_URL} target="_blank" className={classes.linkStyle}>
                    <div className={classes.listItem}>
                        <SearchOutlinedIcon className={classes.listItemIcon} fontSize="large" />
                        <ListItemText primary="Search on Pubmed" />
                    </div>
                </a>
            </ListItem>
        </List>
    );

    return (
        <div className={classes.addButtonContainer}>
            <PlusButton
                onClick={handleClickBtn}
                handleClose={handleClose}
                open={openOptions}
                popoverContent={options}
                buttonClassName={classes.popoverBtn}
                anchorEl={optionsAnchor}
                testId="addPapers"
            />
        </div>
    );
};
