import { Button, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { style } from './style';
import { showAddProject } from '../../../../redux/reducers';
import { useDispatch } from 'react-redux';

export const WithoutProjects = () => {
    const classes = style();
    const dispatch = useDispatch();

    const handleAddProject = () => {
        dispatch(showAddProject({ addProjectModal: true }));
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.emptyContainer}>
                    <div className={classes.filledSquare}></div>
                    <div className={classes.filledRowsContainer}>
                        <div className={classes.filledRowOne} />
                        <div className={classes.filledRowTwo} />
                        <div className={classes.filledRowThree} />
                    </div>

                    <div className={classes.buttonContainer}>
                        <IconButton
                            className={classes.buttonCon}
                            size="small"
                            aria-label="add"
                            onClick={handleAddProject}
                        >
                            <Add className={classes.buttonIcon} />
                        </IconButton>
                    </div>
                </div>
                <div className={classes.emptyText}>You don't have any projects in Nimdone yet.</div>
                <div>
                    <Button variant="contained" className={classes.button} onClick={handleAddProject}>
                        Create New Project
                    </Button>
                </div>
            </div>
        </div>
    );
};
