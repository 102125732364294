import { Box, Modal, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectCitations } from '../../../../../../backend/project';
import { alertTypes } from '../../../../../../constants';
import { RootState } from '../../../../../../redux/combineReducers';
import { showAlert } from '../../../../../../redux/reducers';
import { PaperDataType, ProjectPFCitation } from '../../../../../../types';
import { generateMetaData, useQuery } from '../../../../../../utils';
import { AsyncDropdown, MuiCMButton } from '../../../../../common';
import { importCitationStyles } from './styles';

interface Props {
    close: () => void;
    addCitation: (citation: string) => void;
    isImportModalVisble: boolean;
}

export const ImportCitation = ({ close, addCitation, isImportModalVisble }: Props) => {
    const styles = importCitationStyles();

    const query = useQuery();
    const projectId = query.get('projectId');

    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const Projects = useSelector((state: RootState) => state.Projects);
    const SpeakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);
    const [citation, setCitation] = useState<string>('');
    const dispatch = useDispatch();

    const fetchOptions = async () => {
        try {
            const projectsMetaData: PaperDataType[] = [];
            const style = SpeakerPreferences.citationStyle;
            Projects.find((project) => project.id === projectId)?.paperFiles?.map((paper) => {
                const paperMetaData = paper.paper;
                if (!paperMetaData) {
                    return;
                }
                const metaData = generateMetaData(paperMetaData);
                projectsMetaData.push(metaData as PaperDataType);
            });
            const paperFiles: ProjectPFCitation[] = await getProjectCitations(
                projectsMetaData as PaperDataType[],
                style as string
            );
            return paperFiles.map((item: ProjectPFCitation) => ({
                label: item.citation,
                value: item.citation,
            }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        message: error.message,
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );
        }
    };

    const loadOptions = (inputText: string, callback: any) => {
        setTimeout(async () => callback(await fetchOptions()), 1000);
    };

    const addToOutline = () => {
        if (citation !== '') addCitation(citation);
    };

    return (
        <Modal
            open={isImportModalVisble}
            onClose={close}
            aria-labelledby="import-citation-dialogue"
            aria-describedby="import-citation-dialogue"
            className={styles.importModal}
        >
            <Paper className={styles.root}>
                <p className={styles.title}>Import citation</p>

                <Box>
                    <AsyncDropdown
                        placeholder="Select from papers"
                        name="citations"
                        key="citations"
                        value={citation}
                        label="Select from Papers"
                        id="citations"
                        onChange={(val: { label: string; value: string }) => setCitation(val.value)}
                        loadOptions={loadOptions}
                    />
                </Box>
                <Box className={styles.footer}>
                    <MuiCMButton onClick={addToOutline} className={styles.button} fullWidth>
                        Add to outline
                    </MuiCMButton>
                    <MuiCMButton onClick={close} className={styles.cancelBtn} variant="outlined" fullWidth>
                        Cancel
                    </MuiCMButton>
                </Box>
            </Paper>
        </Modal>
    );
};
