import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        createBtnContainer: {
            display: 'flex',
            border: '1px solid #EAEAEA',
            boxSizing: 'border-box',
            borderRadius: '5px',
            marginTop: 10,
            cursor: 'pointer',
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            background: 'white',
        },
        createBtnIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        createBtnIcon: {
            fontSize: '16px',
            color: colors.text2,
        },
        createBtnText: {
            fontSize: '14px',
            lineHeight: '16px',
            display: 'flex',
            letterSpacing: '0.25px',
            color: colors.text2,
        },
    })
);
