import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalHandler {
    savePaperModal: boolean;
    addProjectModal: boolean;
    paperSwitcherDrawer: boolean;
    paperReferenceSwitcherDrawer: boolean;
}

const initialState: ModalHandler = {
    savePaperModal: false,
    addProjectModal: false,
    paperSwitcherDrawer: false,
    paperReferenceSwitcherDrawer: false,
};

const userSlice = createSlice({
    name: 'ModalHandler',
    initialState,
    reducers: {
        showAddProject: (state, action: PayloadAction<{ addProjectModal: boolean }>) => {
            state.addProjectModal = action.payload.addProjectModal;
            return state;
        },
        showSavePaper: (state, action: PayloadAction<{ savePaperModal: boolean }>) => {
            state.savePaperModal = action.payload.savePaperModal;
            return state;
        },

        togglePaperSwitcher: (state, action: PayloadAction<{ paperSwitcherDrawer: boolean }>) => {
            state.paperSwitcherDrawer = action.payload.paperSwitcherDrawer;
            return state;
        },
        togglePaperReferenceSwitcher: (state, action: PayloadAction<{ paperReferenceSwitcherDrawer: boolean }>) => {
            state.paperReferenceSwitcherDrawer = action.payload.paperReferenceSwitcherDrawer;
            return state;
        },
    },
});

export const { showAddProject, showSavePaper, togglePaperSwitcher, togglePaperReferenceSwitcher } = userSlice.actions;
export default userSlice.reducer;
