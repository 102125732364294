import { combineReducers } from '@reduxjs/toolkit';

//Import reducers
import SpeakerReducer from './reducers/speaker';
import PaperFileReducer from './reducers/paperFile';
import ProjectsReducer from './reducers/projects';
import ModalHandler from './reducers/modalHandler';
import AlertHandler from './reducers/alertHandler';
import ProjectOutline from './reducers/projectOutline';
import ProjectPresentation from './reducers/projectPresentation';
import PaperReferences from './reducers/paperReferences';
import paperFileProjectDoi from './reducers/paperFileProjectDoi';
import SpeakerPreferences from './reducers/speakerPreferences';

const rootReducer = combineReducers({
    Speaker: SpeakerReducer,
    PaperFile: PaperFileReducer,
    Projects: ProjectsReducer,
    ModalHandler,
    AlertHandler,
    ProjectOutline,
    ProjectPresentation,
    paperFileProjectDoi,
    PaperReferences,
    SpeakerPreferences,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
