import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { memo } from 'react';
import { colors } from '../../../../assets/colors';

/**
 * Material UI Button with customized styling for different variant and colors.
 * just like the Material UI Button component, use the variant prop to change the
 * style of the button and the color prop to change the color/type of the button
 */
const CustomizedButton = withStyles(() => ({
    root: {
        fontSize: '20px',
        borderRadius: 8,
        '& .MuiButton-label': {
            textTransform: 'initial',
        },
        '&:focus': {
            outline: `3px solid ${colors.btnOutlineClr}`,
        },
    },
    containedPrimary: {
        background: colors.primaryButton,
        '&:hover': {
            background: colors.primaryButtonOnhover,
        },
        '&:active': {
            background: colors.primaryButtonOnPress,
        },
    },
    outlinedPrimary: {
        border: `2px solid ${colors.primaryButton}`,
        color: colors.primaryButton,
        '&:hover': {
            background: colors.secondaryButtonOnhover,
            border: `2px solid ${colors.primaryButton}`,
        },
        '&:active': {
            background: colors.secondaryButtonOnPress,
        },
    },
    disabled: {
        borderWidth: '2px !important',
    },
}))(Button);

interface Props extends ButtonProps {
    loading?: boolean;
    spinnerSize?: number;
}

/**
 * Nimdone common button component
 * @param loading - display loading spinner with disabled status and CircularProgress icon.
 * @param spinnerSize - custom size of the spinner when using the loading prop.
 * @param props - rest of material ui button props.
 */

const MuiCMButtonComp = (props: Props) => {
    const { loading, spinnerSize, children, variant, ...otherProps } = props;
    return (
        <CustomizedButton
            disabled={loading}
            startIcon={loading && <CircularProgress color="inherit" size={spinnerSize ? spinnerSize : 20} />}
            disableElevation={true}
            color="primary"
            variant={variant ? variant : 'contained'}
            {...otherProps}
        >
            {children}
        </CustomizedButton>
    );
};

export const MuiCMButton = memo(MuiCMButtonComp);
