import { green } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            width: '300px',
            background: colors.white,
            boxShadow: ' 0px 20px 28px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            padding: 25,
            maxHeight: 400,
            overflow: 'hidden',
        },
        title: {
            fontSize: '16px',
            lineHeight: '19px',
            color: colors.darkGray,
            marginTop: 0,
            flex: 1,
        },
        renderProjectsContainer: {
            maxHeight: 200,
            overflow: 'auto',
        },
        emptyProjectText: { color: colors.text2, fontSize: '14px', textAlign: 'center' },
        projectRow: {
            display: 'flex',
            border: `1px solid ${colors.brightGray}`,
            boxSizing: 'border-box',
            borderRadius: '5px',
            marginBottom: 10,
            '& .Mui-checked': {
                color: green[600],
            },
        },
        projectTitle: {
            fontSize: '14px',
            lineHeight: '16px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.25px',
            color: colors.text2,
        },
        createBtnContainer: {
            display: 'flex',
            border: `1px solid ${colors.brightGray}`,
            boxSizing: 'border-box',
            borderRadius: '5px',
            marginTop: 10,
            cursor: 'pointer',
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            background: 'white',
        },
        createBtnIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        createBtnIcon: {
            fontSize: '16px',
            color: colors.text2,
        },
        createBtnText: {
            fontSize: '14px',
            lineHeight: '16px',
            display: 'flex',
            letterSpacing: '0.25px',
            color: colors.text2,
        },
        flex: {
            display: 'flex',
        },
        savingIndicator: {
            color: colors.disabledMenu,
            fontStyle: 'italic',
            marginRight: '5px',
        },
        modalHeader: {
            display: 'flex',
            position: 'sticky',
            top: '0px',
            background: 'white',
            paddingTop: '10px',
        },
    })
);
