import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        content: {
            padding: '35px 48px',
            background: colors.white,
            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px ${colors.boxShadow}`,
            borderRadius: 8,
            position: 'relative',
        },
        title: {
            fontSize: 23,
            color: colors.text2,
            marginTop: 0,
            marginBottom: 0,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
        },
        subtitle: {
            marginTop: 3,
            color: colors.smoke,
            width: '75%', // used percentage becuase the width of the InputModal is dynamic
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        errMsg: {
            color: colors.error,
            fontSize: 12,
            textAlign: 'center',
        },
        input: {
            marginBottom: 20,
        },
        button: {
            paddingLeft: 23,
            paddingRight: 23,
        },
        infoIcon: {
            color: colors.darkTeal,
        },
    })
);
