import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const TitleIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="34"
                rx="2.5"
                fill="white"
                stroke="#BABABA"
            />
            <path
                d="M22.5582 11.082L22.6901 13.877H22.3561C22.2916 13.3848 22.2038 13.0332 22.0924 12.8223C21.9108 12.4824 21.6676 12.2334 21.3629 12.0752C21.0641 11.9111 20.6686 11.8291 20.1764 11.8291H18.4977V20.9346C18.4977 21.667 18.5768 22.124 18.735 22.3057C18.9577 22.5518 19.3004 22.6748 19.7633 22.6748H20.1764V23H15.1227V22.6748H15.5446C16.0485 22.6748 16.4059 22.5225 16.6168 22.2178C16.7457 22.0303 16.8102 21.6025 16.8102 20.9346V11.8291H15.3776C14.8209 11.8291 14.4254 11.8701 14.1911 11.9521C13.8864 12.0635 13.6256 12.2773 13.4088 12.5938C13.192 12.9102 13.0631 13.3379 13.0221 13.877H12.6881L12.8288 11.082H22.5582Z"
                fill="#BABABA"
            />
        </SvgIcon>
    );
};
