import { useLocation } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import sound from '../assets/audio/camera-capture-sound.wav';
import { SpeakerSubscriptionStatuses } from '../types';
import { DAY_IN_MILLI_SECONDS } from '../constants';
const audio = new Audio(sound);

function dataURLtoFile(dataurl, filename) {
    // dataurl is in format of data:image/png;base64,iVBO.....
    // spliting after data:image/png;base64,
    let arr = dataurl.split(',');

    let mime = arr[0].match(/:(.*?);/)[1]; // finds image/png in data:image/png;base64
    let bstr = atob(arr[1]); // The atob() method decodes a base-64 encoded string i.e iVBO..... .
    let n = bstr.length; // length of iVBO.....

    // The Uint8Array typed array represents an array
    // of 8-bit unsigned integers. The contents are initialized to 0.
    // Once established, you can reference elements in the array using the
    // object's methods, or using standard array index syntax (that is, using bracket notation).

    //   Uint8Array()
    // Creates a new Uint8Array object.
    let u8arr = new Uint8Array(n);

    while (n--) {
        // read https://javascript.info/blob
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

// script for react-canvas-draw for image annotation
// https://github.com/embiem/react-canvas-draw/issues/62#issuecomment-718116981
export const combineDrawing = (canvasRef, filename) => {
    let width = canvasRef.current.props.canvasWidth;
    let height = canvasRef.current.props.canvasHeight;
    let background = canvasRef.current.canvasContainer.children[0]; // background image placed in canvas
    let drawing = canvasRef.current.canvasContainer.children[1]; // annotation done by user
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // composite now
    canvas.getContext('2d').drawImage(background, 0, 0);
    canvas.getContext('2d').globalAlpha = 1.0;
    canvas.getContext('2d').drawImage(drawing, 0, 0);

    let dotIndex = filename.lastIndexOf('.');
    let imageExt = filename.substring(dotIndex);
    let dataUri = canvas.toDataURL(`image/${imageExt}`);

    let file = dataURLtoFile(dataUri, filename);

    return { dataUri, file };
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const playImageCapturedSound = () => audio.play();

export const matchProxyUrlFormat = (url) => {
    let proxyUrlFormat = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (url.match(proxyUrlFormat) && url.endsWith('$@')) {
        return true;
    } else {
        return false;
    }
};

export const createSHA256Hash = (item) => sha256(JSON.stringify(item)).toString();

/*
Subscription statuses
    - NEW_USER
    - ACTIVE_USER
    - TRIALING_USER
    - CANCELLED_TRAILING
    - CANCELLED_SUBSCRIPTION
    - ALLOWED_USER - buggy combination (not possible)
    - NOT_ALLOWED_USER - buggy combination (not possible)
*/
export const getSpeakerSubscriptionStatus = (allowUser, trialEnds, isSubscriptionActive) => {
    /*
    ACTIVE_USER
        - allowUser = true
        - trialEnds = xyz
        - isSubscriptionActive = true

        trialEnds must be less then now because stripe only charge user if trial ends and then we will change isSubscriptionActive to true with webhook
        Didn't add the condition to handle any issue if lambda was unable to change it (in any case)
    */
    if (allowUser && trialEnds && isSubscriptionActive) {
        return {
            status: SpeakerSubscriptionStatuses.ACTIVE_USER,
        };
    } else if (allowUser && trialEnds && !isSubscriptionActive) {
        /*
    TRIALING_USER
        - allowUser = true
        - trialEnds = xyz & xyz > now
        - isSubscriptionActive = false
    
        TrialEnds will be greater then now other wise our cron job lambda will change allowUser to false or isSubscriptionActive to true
        Didn't add condition in  case of any bug or issue
    */

        return {
            status: SpeakerSubscriptionStatuses.TRIALING_USER,
            freeTrialRemainingDays: Math.ceil((parseInt(trialEnds) - Date.now()) / DAY_IN_MILLI_SECONDS),
        };
    } else if (!allowUser && trialEnds && !isSubscriptionActive) {
        /*
    CANCELLED_TRAILING
        - allowUser = false
        - trialEnds = xyz
        - isSubscriptionActive = false

        trialEnds must be less then now because cron job lambda only change allowUser to false if trial ends complete
        Didn't add the condition to handle any issue if lambda was unable to change it (in any case)
    */
        return {
            status: SpeakerSubscriptionStatuses.CANCELLED_TRAILING,
        };
    } else if (!allowUser && trialEnds && isSubscriptionActive) {
        /*
    CANCELLED_SUBSCRIPTION
        - allowUser = false
        - trialEnds = xyz
        - isSubscriptionActive = true

        TrialEnds must be less then now
        Didn't add the condition to handle any issue if lambda was unable to change it (in any case)
    */
        return {
            status: SpeakerSubscriptionStatuses.CANCELLED_SUBSCRIPTION,
        };
    } else if (!allowUser && !trialEnds && !isSubscriptionActive) {
        /*
    NEW_USER
    User who just signs up but didn't complete payment checkout to start a free trial
        - allowUser = false
        - trialEnds = null/undefined
        - isSubscriptionActive = false
    */
        return {
            status: SpeakerSubscriptionStatuses.NEW_USER,
        };
    } else {
        /*
    To handle buggy combintion of those values (not possible these combination but to handle bugs if any)
    Condition one:
       - allowUser : false
       - trialEnds : null/undefined
       - isSubscriptionActive : true

    Condition two:
       - allowUser : true
       - trialEnds : null/undefined
       - isSubscriptionActive : false

    Condition three:
       - allowUser : true
       - trialEnds : null/undefined
       - isSubscriptionActive : true
    */
        return {
            status: allowUser ? SpeakerSubscriptionStatuses.ALLOWED_USER : SpeakerSubscriptionStatuses.NOT_ALLOWED_USER,
        };
    }
};

export const generateMetaData = (paperMetaData) => {
    return {
        doi: paperMetaData?.doi,
        primaryAuthor: {
            firstInitial: paperMetaData?.primaryAuthor?.firstInitial,
            lastName: paperMetaData?.primaryAuthor?.lastName,
        },
        secondaryAuthors: paperMetaData?.secondaryAuthors?.map((author) => {
            return { firstInitial: author.firstInitial, lastName: author.lastName };
        }),
        title: paperMetaData?.title,
        journalName: paperMetaData?.journalName,
        publishedDate: paperMetaData?.publishedDate,
    };
};

export const formatHighlightedText = (selection) =>
    selection.toString().replace(/[\r\n\x0B\x0C\u0085\u2028\u2029\s\s+]+/g, ' ');
