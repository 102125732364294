import Auth from '@aws-amplify/auth';
import { Divider, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router';
import { getStripeCustomerPortalUrl } from '../../../../backend/speaker';
import { menuStyles } from '../styles';
import { StyledMenu } from './styledMenu';
import { StyledMenuItem } from './styledMenuItem';
import { DropDownMenuProps } from './types';
import config from '../../../../config';
import { routes } from '../../../pages/routes';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../../redux/reducers';
import { alertTypes } from '../../../../constants';

export const NavMenu = ({ anchorEl, handleClose }: DropDownMenuProps) => {
    const classes = menuStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const navigateToSettings = () => {
        history.push(routes.settings);
    };
    const navigateToSpeakerPref = () => {
        history.push(routes.speakerPreferences);
    };

    const navigateToBillings = async () => {
        let speakerObj: any = await localStorage.getItem('unauth_speaker');
        let speaker: any = JSON.parse(speakerObj);
        getStripeCustomerPortalUrl(speaker.stripeCustomerId)
            .then((url: string) => {
                window.open(url, '_blank');
                handleClose();
            })
            .catch((error: NodeJS.ErrnoException) => {
                dispatch(showAlert({ isVisible: true, message: error.message, type: alertTypes.error }));
            });
    };

    const signOut = async () => {
        await Auth.signOut();

        history.push(routes.login);
    };

    return (
        <StyledMenu placement={'top-start'} anchorEl={anchorEl} handleClose={handleClose}>
            <StyledMenuItem onClick={navigateToSettings}>
                <ListItemText primary={<p className={classes.listItemText}>Account Settings</p>} />
            </StyledMenuItem>
            <StyledMenuItem onClick={navigateToSpeakerPref}>
                <ListItemText primary={<p className={classes.listItemText}>Preferences</p>} />
            </StyledMenuItem>
            {config.featureFlags.pricing && (
                <StyledMenuItem onClick={navigateToBillings}>
                    <ListItemText primary={<p className={classes.listItemText}>Manage Billing</p>} />
                </StyledMenuItem>
            )}
            <Divider className={classes.divider} />
            <StyledMenuItem onClick={signOut}>
                <ListItemText primary={<p className={classes.listItemText}>Log Out</p>} />
            </StyledMenuItem>
        </StyledMenu>
    );
};
