import Auth from '@aws-amplify/auth';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '../../assets/icons/arrowForwardIcon';
import { createStripeCheckout } from '../../backend/speaker';
import { alertTypes, stripe } from '../../constants';
import { RootState } from '../../redux/combineReducers';
import { showAlert } from '../../redux/reducers';
import { Alert, SpeakerSubscriptionStatuses } from '../../types';
import { MuiCMButton, Toggle } from '../common';
import { routes } from '../pages/routes';
import { style } from './style';
import config from '../../config';

interface PlanInput {
    planId: string;
    name: string;
    email: string;
}
interface Loading {
    btn: string;
}

interface CurrentPlan {
    id: string;
    planName: string;
    price: string;
    description: string;
}

const Pricing = () => {
    const dispatch = useDispatch();

    const history = useHistory();
    const [submit, setSubmitting] = useState<Loading>({
        btn: '',
    });
    const [selected, setSelected] = React.useState(stripe.annualPlan.id);

    const [currentPlan, setCurrentPlan] = useState<CurrentPlan>(stripe.annualPlan);
    const [alertMessage, setAlertMessage] = useState<Alert>({
        message: '',
        type: '',
        isVisible: false,
    });

    const { name, email, isSubscriptionActive, subscriptionStatus } = useSelector((state: RootState) => state.Speaker);
    const classes = style();

    useEffect(() => {
        dispatch(showAlert(alertMessage));
    }, [alertMessage]);

    useEffect(() => {
        const url: any = window.location;
        const params = new URL(url.href).searchParams;
        const planId: string | null = params.get('planId');
        if (planId) {
            if (planId === stripe.monthlyPlan.id) {
                handlePlanChange(stripe.monthlyPlan.id);
            } else {
                handlePlanChange(stripe.annualPlan.id);
            }
        }
    }, []);

    const subscribePlan = async ({ planId, name, email }: PlanInput) => {
        try {
            setSubmitting({ btn: planId });
            const speakerObj: any = localStorage.getItem('unauth_speaker');
            if (!name) {
                const speaker: any = JSON.parse(speakerObj);
                name = speaker.name;
            }
            if (!email) {
                const speaker: any = JSON.parse(speakerObj);
                email = speaker.email;
            }
            createStripeCheckout(planId, name, email)
                .then((url: string) => {
                    setSubmitting({ btn: '' });
                    window.location.href = url;
                })
                .catch((err: NodeJS.ErrnoException) => {
                    setSubmitting({ btn: '' });
                    setAlertMessage({
                        message: 'Something went wrong while posting data to database',
                        isVisible: true,
                        type: alertTypes.error,
                    });
                });
        } catch (err) {
            setSubmitting({ btn: '' });
            setAlertMessage({
                message: 'Something went wrong while posting data to database',
                isVisible: true,
                type: alertTypes.error,
            });
        }
    };
    const goBack = async () => {
        await Auth.signOut();
        history.push(routes.login);
    };

    const handlePlanChange = (plan: string) => {
        switch (plan) {
            case stripe.monthlyPlan.id:
                setCurrentPlan(stripe.monthlyPlan);
                break;
            case stripe.annualPlan.id:
                setCurrentPlan(stripe.annualPlan);
                break;
            default:
                setCurrentPlan(stripe.annualPlan);
        }
        setSelected(plan);
    };

    const loading = submit.btn === stripe.annualPlan.id || submit.btn === stripe.monthlyPlan.id;

    return (
        <Fragment>
            <div className={classes.container}>
                <Box className={classes.mainHeader}>
                    <Box onClick={goBack} className={classes.backbuttonContainer}>
                        <ArrowBack />
                        <h6>back</h6>
                    </Box>
                    <Box>
                        <h1 className={classes.mainHeader}>Select your plan </h1>
                        <p className={classes.pricingSubtitle}>*our payments are processed by Stripe Inc</p>
                    </Box>
                    <Box className={classes.selectPlan}>
                        <Toggle
                            selected={selected}
                            handlePlanChange={handlePlanChange}
                            monthLabel={'monthly'}
                            yearLabel={'Annual'}
                        />
                        <Box className={classes.planInfo}>
                            <Box className={classes.forwardArrow}>
                                <ArrowForwardIcon />
                            </Box>
                            <p className={classes.planText}>save 30% on the annual plan!</p>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.planContainer}>
                    <div className={classes.plan}>
                        <div className={classes.topBar}></div>
                        <h1 className={classes.heading}>INDIVIDUALS</h1>
                        <h1 className={classes.pricingPlan}>
                            {currentPlan.price} <span className={classes.subtext}> / mo</span>
                        </h1>
                        {currentPlan.id == stripe.annualPlan.id && (
                            <p className={classes.priceText}>An annual savings of $36</p>
                        )}

                        {subscriptionStatus === SpeakerSubscriptionStatuses.NEW_USER && config.featureFlags.freeTrial && (
                            <MuiCMButton
                                onClick={() => subscribePlan({ planId: currentPlan.id, name, email })}
                                className={classes.btn}
                                loading={loading}
                            >
                                START MY FREE TRIAL
                            </MuiCMButton>
                        )}

                        {subscriptionStatus !== SpeakerSubscriptionStatuses.NEW_USER && config.featureFlags.freeTrial && (
                            <MuiCMButton
                                onClick={() => subscribePlan({ planId: currentPlan.id, name, email })}
                                className={classes.btn}
                                loading={loading}
                            >
                                SELECT
                            </MuiCMButton>
                        )}
                        <div className={classes.planDescription}>
                            <ul>
                                {subscriptionStatus === SpeakerSubscriptionStatuses.NEW_USER &&
                                    config.featureFlags.freeTrial && (
                                        <li className={classes.pricingPoint}>
                                            <p className={classes.innerText}>
                                                FREE {config.stripe.freeTrialDays}-days trial (no credit card required)
                                            </p>
                                        </li>
                                    )}
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>Create unlimited presentations</p>
                                </li>
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>1-click access to PDFs on PubMed</p>
                                </li>
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>Built-in snipping tool</p>
                                </li>
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>Automatically generate citations</p>
                                </li>
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>Easily export to PowerPoint</p>
                                </li>
                                <li className={classes.pricingPoint}>
                                    <p className={classes.innerText}>Organize your PDF library</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Box className={classes.institutionPlan} p={1}>
                        <h1 className={classes.heading}>INSTITUTIONS</h1>
                        <div className={classes.institutionDescWrapper}>
                            <p className={classes.institutionDesc}>
                                nimdone can provide volume licenses for all your students and staff. Get in touch for
                                details.
                            </p>
                        </div>
                        <a href="mailto:hello@nimdone.com" className={classes.contactUsLink}>
                            <MuiCMButton className={classes.contactButton}>CONTACT SALES</MuiCMButton>
                        </a>
                    </Box>
                </Box>
            </div>
        </Fragment>
    );
};

export default Pricing;
