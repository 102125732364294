import { Auth } from 'aws-amplify';
import { FormikProps, withFormik } from 'formik';
import React, { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { alertTypes } from '../../../constants';
import { showAlert } from '../../../redux/reducers';
import { forgotPasswordValidation } from '../../../validation';
import { MuiCMButton, TextInput } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

interface FormValues {
    email: string;
}

interface OtherProps {
    setEmailSent: Dispatch<React.SetStateAction<boolean>>;
    dispatch: Dispatch<any>;
}

const InnerForm = (props: FormikProps<FormValues>) => {
    const classes = style();
    const { values, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                placeholder="yourname@gmail.com"
                name="email"
                key="email"
                value={values.email}
                label="E-mail address"
                id="email"
                onChange={handleChange}
                error={errors.email && touched.email ? errors.email : ''}
            />
            <MuiCMButton loading={isSubmitting} className={classes.button} type="submit" fullWidth>
                Submit
            </MuiCMButton>
        </form>
    );
};

const MyForm = withFormik<OtherProps, FormValues>({
    mapPropsToValues: () => ({
        email: '',
    }),
    validationSchema: forgotPasswordValidation,
    handleSubmit: (values, { setSubmitting, props }) => {
        const { setEmailSent, dispatch } = props;
        const { email } = values;
        // Send confirmation code to user's email
        Auth.forgotPassword(email)
            .then(() => {
                setSubmitting(false);
                setEmailSent(true);
            })
            .catch((error: NodeJS.ErrnoException) => {
                if (error.code === 'UserNotFoundException') {
                    dispatch(
                        showAlert({
                            message: 'An account with this email does not exist',
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );
                } else {
                    dispatch(
                        showAlert({
                            message: error.message,
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );
                }
                setSubmitting(false);
            });
    },
})(InnerForm);

export const ForgotPassword = () => {
    const classes = style();
    const [emailSent, setEmailSent] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} />
            <div className={classes.formContainer}>
                {emailSent ? (
                    <p className={classes.headline}>
                        Email sent!
                        <br />
                        You should see it in your inbox very soon.
                    </p>
                ) : (
                    <>
                        <span className={classes.headline}>
                            Enter your email below and we'll send you instructions to change your password.
                        </span>
                        <MyForm setEmailSent={setEmailSent} dispatch={dispatch} />
                        <p className={classes.returnLoginText}>
                            Back to &nbsp;
                            <span className={classes.loginText} onClick={() => history.push(routes.login)}>
                                Login
                            </span>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};
