import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

const leftMargin = 35;
const smallSpace = 8;

export const useOutlineTextItemStyles = makeStyles(() => ({
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: `${smallSpace}px`,
        width: `calc(100% - ${leftMargin - smallSpace}px)`,
    },
    textOptionContainer: {
        display: 'flex',
        marginLeft: `${smallSpace}px`,
        flex: 1,
        alignItems: 'center',
    },
    textOption: {
        color: colors.darkTeal,
        cursor: 'pointer',
        margin: 0,
        fontSize: 12,
        fontWeight: 600,
    },
    orText: {
        color: colors.grayText,
    },
    textWrapper: {
        '&.Mui-focused': {
            border: `solid ${colors.elephant} 1px`,
        },
        padding: 0,
    },
    text: {
        fontSize: '16px',
        paddingBottom: 0,
        padding: 0,
        width: '100%',
        border: 0,
    },
    citation: {
        fontSize: '12px',
        width: '100%',
        color: colors.darkTeal,
        fontWeight: 400,
    },
    transparentButton: {
        backgroundColor: 'transparent',
        border: 'none',
    },
}));
