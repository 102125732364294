import { withAuthenticator } from 'aws-amplify-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Auth } from '../components/auth';
import { ErrorBoundary } from '../components/common';
import { Pages } from '../components/pages';
import store from '../redux/store';
import { GTMService } from '../services/gtmService';

function App() {
    const gtmService = new GTMService();

    gtmService
        .init()
        .then((res) => console.log('GTM Service initialized', res))
        .catch((err) => console.log('failed to connect GTM', err));

    const PagesWithAuth = withAuthenticator(Pages, false, [<Auth key="Auth container" />]);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary>
                    <PagesWithAuth />
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
