import React from 'react';
import { LayoutIconProps } from '../types';

export const Content2TextDefault = ({
    selected,
    onClickHandler,
    id
}: LayoutIconProps) => {
    const handleClick = () => {
        if (onClickHandler) onClickHandler(id as string);
    };

    return (
        <div
            role={'presentation'}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
        >
            <svg
                width="75"
                height="50"
                viewBox="0 0 75 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="1" y="1" width="73" height="48" rx="3" fill="white" />
                <rect
                    x="40.3569"
                    y="14"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="40.356"
                    y="17.9737"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="40.356"
                    y="21.9473"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="40.356"
                    y="25.921"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="40.356"
                    y="29.8947"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="40.356"
                    y="33.8683"
                    width="13.1578"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12.001"
                    y="14"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12"
                    y="17.9737"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12"
                    y="21.9473"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12"
                    y="25.921"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12"
                    y="29.8947"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="12"
                    y="33.8683"
                    width="13.1578"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="1"
                    y="1"
                    width="73"
                    height="48"
                    rx="3"
                    stroke={selected ? '#A0A4A8' : '#E8E8E8'}
                    strokeWidth="2"
                />
            </svg>
        </div>
    );
};
