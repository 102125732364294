import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

const style = makeStyles(() =>
    createStyles({
        root: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
        },
        pageContent: {
            padding: 10,
            textAlign: 'center',
        },
        pageHeader: {
            color: colors.smoke,
            fontSize: 37,
            fontWeight: 500,
            margin: 0,
        },
        pageDescription: {
            textAlign: 'center',
            color: colors.smoke,
            fontSize: 15,
        },
        buttonContainer: {
            display: 'flex',
        },
        buttonInnerContainer: {
            padding: '0px 10px',
        },
        button: {
            marginTop: 24,
            backgroundColor: colors.primaryButton,
            '&:hover': {
                backgroundColor: colors.primaryButtonOnhover,
                borderColor: colors.primaryButton,
            },
            '&:focus': {
                border: `1px solid ${colors.darkTeal}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.primaryButtonOnPress,
            },
        },
        outlinedButton: {
            marginTop: 24,
            color: colors.primaryButton,
            borderColor: colors.primaryButton,
            '&:hover': {
                backgroundColor: colors.secondaryButtonOnhover,
                borderColor: colors.primaryButton,
            },
            '&:focus': {
                border: `1px solid ${colors.darkTeal}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.secondaryButtonOnPress,
            },
        },
    })
);

export { style };
