import { Grid, Modal } from '@material-ui/core';
import { useState } from 'react';
import { MuiCMButton, TextInput } from '../..';
import { HelpInfoTooltip } from '../../helpInfoToolTip';
import { style } from './style';

interface InputModalProps {
    title: string;
    subtitle?: string | JSX.Element;
    helpInfo?: string | JSX.Element;
    inputLabel: string;
    placeholder: string;
    value: string | undefined;
    defaultValue?: string | undefined;
    titleError?: string;
    handleInputChange: (e: any) => void;
    loading: boolean;
    handleSubmit: () => void;
    closeModal: () => void;
    isOpen: boolean;
    modalPrimaryBtnText?: string;
    modalSecondaryBtnText?: string;
    modalContentwidth?: number;
    disablePrimaryBtn?: boolean;
    createBtnId?: string;
}

export const InputModal = ({
    title,
    subtitle,
    helpInfo,
    inputLabel,
    placeholder,
    value,
    defaultValue,
    titleError,
    loading,
    isOpen,
    handleInputChange,
    handleSubmit,
    closeModal,
    modalPrimaryBtnText = 'Create',
    modalSecondaryBtnText = 'Cancel',
    modalContentwidth,
    disablePrimaryBtn,
    createBtnId,
}: InputModalProps) => {
    const classes = style();
    const [inputError, setInputError] = useState<string>('');

    const onKeyPress = (event: any) => {
        if (event.code === 'Enter') {
            handleConfirm();
        }
    };

    const handleConfirm = () => {
        if (value && value.trim()) {
            setInputError('');
            handleSubmit();
        } else setInputError('Input cannot be empty');
    };

    const handleModalClose = () => {
        closeModal();
        setInputError('');
    };

    const handleChange = (e: any) => {
        const { value } = e.target;
        if (value.trim()) setInputError('');
        else setInputError('Input cannot be empty');
        handleInputChange(e);
    };

    return (
        <Modal
            open={isOpen}
            aria-labelledby="add-project-title"
            aria-describedby="add-project-description"
            className={classes.modal}
            onClose={handleModalClose}
        >
            <div className={classes.content} style={{ width: modalContentwidth }}>
                <Grid container spacing={1} justifyContent={'space-between'}>
                    <Grid item>
                        <p className={classes.title}>{title}</p>
                    </Grid>
                    {helpInfo && <HelpInfoTooltip title={helpInfo} />}
                </Grid>
                {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
                <TextInput
                    error={titleError || inputError}
                    placeholder={placeholder}
                    label={inputLabel}
                    name={inputLabel}
                    onChange={handleChange}
                    value={value}
                    defaultValue={defaultValue}
                    containerClassName={classes.input}
                    autoFocus={true}
                    onKeyPress={onKeyPress}
                    dataTestId="input-modal-input"
                />
                <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                        <MuiCMButton
                            variant="outlined"
                            onClick={handleModalClose}
                            className={classes.button}
                            role="input-modal-secondary-btn"
                        >
                            {modalSecondaryBtnText}
                        </MuiCMButton>
                    </Grid>
                    <Grid item>
                        <MuiCMButton
                            loading={loading}
                            disabled={disablePrimaryBtn || !value}
                            className={classes.button}
                            onClick={handleConfirm}
                            role="input-modal-primary-btn"
                            data-testid={createBtnId}
                        >
                            {modalPrimaryBtnText}
                        </MuiCMButton>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};
