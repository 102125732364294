import { CircularProgress, Grid } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { colors } from '../../../../assets/colors';
import { updatePreferences } from '../../../../backend/speakerPreferences';
import { alertTypes, proxyLibraries, sampleDoi } from '../../../../constants';
import CitationStyles from '../../../../constants/citationStyles.json';
import { updateSpeakerPreference } from '../../../../redux/reducers';
import { Alert } from '../../../../types';
import { speakerPreferencesValidation } from '../../../../validation';
import { Dropdown, MuiCMButton, TextInput } from '../../../common';
import { ProxyModalHelpContent } from '../../../common/papersList/components/closedAccessInfo/proxyModalInfo';
import { style } from '../style';
import { HelpInfoTooltip } from '../../../common/helpInfoToolTip';
import { ToggleSwitch } from '../../../common/toggleSwitch';
import mergedConfigs from '../../../../config';

interface AccSettingsFormValues {
    citationStyle: string;
    proxyUrl: string;
    fullTextModal: boolean;
}

interface AccSettingsFormProps {
    setAlertMessage: Dispatch<SetStateAction<Alert>>;
    speakerId: string;
    citationStyle: string;
    dispatch: Dispatch<any>;
    sampleCitation: string;
    setCitationStyle: (value: string) => void;
    savedCitationStyle?: string;
    helpInfo?: string | JSX.Element;
    proxyUrl: string;
    fullTextModal: boolean;
}

const InnerForm = (props: AccSettingsFormProps & FormikProps<AccSettingsFormValues>) => {
    const classes = style();
    const {
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
        sampleCitation,
        setCitationStyle,
    } = props;

    const handleDropdownValue = (val: any) => {
        setCitationStyle(val.value);
        setFieldValue('citationStyle', val.value, true);
    };

    const handleChangeProxyurl = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFieldValue('proxyUrl', value, true);
    };

    const handleToggle = () => {
        if (values.fullTextModal === false) {
            setFieldValue('fullTextModal', !values.fullTextModal, true);
        } else {
            setFieldValue('fullTextModal', !values.fullTextModal, true);
        }
    };

    return (
        <form className={classes.accSettingForm} onSubmit={handleSubmit}>
            <Dropdown
                placeholder="Citation Style"
                name="citationStyle"
                key="Citation Style"
                value={{
                    label: values.citationStyle,
                    value: values.citationStyle,
                }}
                label="Citation Style"
                id="citationStyle"
                onChange={(val: any) => handleDropdownValue(val)}
                error={errors.citationStyle && touched.citationStyle ? errors.citationStyle : ''}
                options={CitationStyles}
            />
            <div>
                <p className={classes.exampleCitationText}>Example Citation ({sampleDoi})</p>
                <div className={classes.exampleCitation}>
                    {sampleCitation || (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={12} style={{ color: colors.gray }} />
                        </div>
                    )}
                </div>
            </div>
            {mergedConfigs.featureFlags.proxyUrl && (
                <div className={classes.proxyUrlContainer}>
                    <Grid container spacing={1} justifyContent={'space-between'}>
                        <div className={classes.proxyUrl}>
                            <Grid item>
                                <p className={classes.proxyUrlText}>
                                    Enter Proxy Url: &nbsp;
                                    <a className={classes.link} target="_blank" href={proxyLibraries}>
                                        Find your Proxy Url here
                                    </a>
                                </p>
                            </Grid>
                        </div>
                        <HelpInfoTooltip title={<ProxyModalHelpContent />} />
                        <TextInput
                            placeholder="Eg: http://libaccess.sjlibrary.org/login?url=$@"
                            name="proxyUrl"
                            key="proxyUrl"
                            id="proxyUrl"
                            onChange={handleChangeProxyurl}
                            value={values.proxyUrl}
                            containerClassName={classes.input}
                            error={errors.proxyUrl && touched.proxyUrl ? errors.proxyUrl : ''}
                        />

                        <p className={classes.toggleBody}>
                            Remind me to enter proxy Url
                            <ToggleSwitch selected={values.fullTextModal} setSelected={handleToggle} />
                        </p>
                    </Grid>
                </div>
            )}

            <MuiCMButton loading={isSubmitting} spinnerSize={25} type="submit" className={classes.button}>
                {isSubmitting ? 'Saving...' : 'Save Changes'}
            </MuiCMButton>
        </form>
    );
};

export const PreferencesForm = withFormik<AccSettingsFormProps, AccSettingsFormValues>({
    mapPropsToValues: (props) => {
        return {
            citationStyle: props.citationStyle?.replace(/-/g, ' ').replace(/\w+/g, function(w) {
                return w[0].toUpperCase() + w.slice(1).toLowerCase();
            }),
            proxyUrl: !!props.proxyUrl ? props.proxyUrl : '',
            fullTextModal: props.fullTextModal,
        };
    },
    validationSchema: speakerPreferencesValidation,
    handleSubmit: async (values, { setSubmitting, props }) => {
        const { citationStyle, proxyUrl, fullTextModal } = values;
        const { speakerId, setAlertMessage, dispatch } = props;
        await updatePreferences(speakerId, citationStyle, proxyUrl, fullTextModal)
            .then((res) => {
                if (res) {
                    dispatch(updateSpeakerPreference(res));
                    setAlertMessage({
                        message: 'Your new preferences saved succesfully',
                        isVisible: true,
                        type: alertTypes.success,
                    });
                } else
                    setAlertMessage({
                        message: 'Something went wrong',
                        isVisible: true,
                        type: alertTypes.error,
                    });
                setSubmitting(false);
            })
            .catch((error: NodeJS.ErrnoException) => {
                setAlertMessage({
                    message: `Something went wrong while posting data to database ${error.message}`,
                    isVisible: true,
                    type: alertTypes.error,
                });
                setSubmitting(false);
            });
    },
})(InnerForm);
