import { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { getPaperFileProjects } from '../../../../backend/paperFileProject';
import {
    showAddProject,
    showSavePaper,
    removePaperFileFromProject,
    showAlert,
    setCreateProjectWithPaper,
    addPaperFileToProject,
} from '../../../../redux/reducers';

import { addPaperFileProject, removePaperFileProject } from '../../../../backend/paperFileProject';
import { style } from './style';
import { alertTypes } from '../../../../constants';
import { colors } from '../../../../assets/colors';
import { ReaderViewModalWrapper } from '../';

interface SavePaperProps {
    setIsSaved: (flag: boolean) => void;
    selectedProject: any;
}
export const SavePaper = ({ setIsSaved, selectedProject }: SavePaperProps) => {
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [savingProject, setSavingProject] = useState<boolean>(false);
    const [disableCheckBoxes, setDisableCheckBoxes] = useState<boolean>(false);
    const paperFile = useSelector((state: RootState) => state.PaperFile);
    const ModalHandler = useSelector((state: RootState) => state.ModalHandler);
    const Projects = useSelector((state: RootState) => state.Projects);
    const classes = style();
    const dispatch = useDispatch();

    useEffect(() => {
        if (paperFile.id) fetchPaperFileProjects();
    }, [paperFile.id, Projects]);

    const fetchPaperFileProjects = async () => {
        try {
            const paperFileProjects = await getPaperFileProjects(paperFile?.id as string);
            const projectIds = paperFileProjects.map((paperFileProject) => paperFileProject.project.id);
            setSelectedProjects(projectIds);
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    };

    const createNewProjectModal = () => {
        dispatch(showSavePaper({ savePaperModal: false }));
        dispatch(setCreateProjectWithPaper({ isCreateProjectWithPaper: true }));
        dispatch(showAddProject({ addProjectModal: true }));
    };

    const handleChangeSelection = async (checked: boolean, projectId: string) => {
        setDisableCheckBoxes(true);
        try {
            setSavingProject(true);
            if (checked) {
                await removePaperFileProject(projectId, paperFile.id as string);
                setSavingProject(false);
                dispatch(
                    removePaperFileFromProject({
                        projectId: projectId,
                        paperFileId: paperFile.id as string,
                    })
                );
                const index = selectedProjects.indexOf(projectId);
                const projectIds = [...selectedProjects];
                projectIds.splice(index, 1);
                setSelectedProjects(projectIds);
                if (selectedProject?.id === projectId) {
                    dispatch(
                        removePaperFileFromProject({
                            projectId: projectId,
                            paperFileId: paperFile.id as string,
                        })
                    );
                    setIsSaved(false);
                }
            } else {
                await addPaperFileProject(projectId, paperFile.id as string);
                dispatch(addPaperFileToProject({ projectId: projectId, paperFile: paperFile }));
                if (selectedProject?.id === projectId) {
                    setIsSaved(true);
                }
                setSavingProject(false);
                dispatch(showSavePaper({ savePaperModal: false }));
                setSelectedProjects([...selectedProjects, projectId]);
            }
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
        setDisableCheckBoxes(false);
    };

    const renderProjects = (
        <div className={classes.renderProjectsContainer}>
            {Projects.length === 0 && <p className={classes.emptyProjectText}>You do not have any project</p>}
            {Projects.map((project) => (
                <div key={project.id} className={classes.projectRow}>
                    <Checkbox
                        onChange={(event) => handleChangeSelection(!event.target.checked, project.id)}
                        checked={selectedProjects.includes(project.id)}
                        size="small"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        disabled={disableCheckBoxes}
                    />
                    <p
                        className={classes.projectTitle}
                        style={{ color: disableCheckBoxes ? colors.grayText : 'inherit' }}
                    >
                        {project.title}
                    </p>
                </div>
            ))}
        </div>
    );

    const handleSavePaperModal = () => {
        if (!disableCheckBoxes) {
            dispatch(showSavePaper({ savePaperModal: false }));
        }
    };

    const close = () => dispatch(showSavePaper({ savePaperModal: false }));

    return (
        <ReaderViewModalWrapper
            title={'Save PDF to...'}
            onClose={handleSavePaperModal}
            close={close}
            open={ModalHandler.savePaperModal}
            loader={savingProject}
            handleCreate={createNewProjectModal}
            createBtnLabel="Create new project..."
        >
            {renderProjects}
        </ReaderViewModalWrapper>
    );
};
