import React from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

import { DropDownMenuProps } from './types';

const useStyles = makeStyles({
    popper: {
        zIndex: 1401,
    },
    paper: {
        width: 220,
        borderRadius: 4,
    },
});

export const StyledMenu = ({ anchorEl, handleClose, children, placement }: DropDownMenuProps) => {
    const classes = useStyles();
    return (
        <Popper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            role={undefined}
            transition
            disablePortal
            placement={placement}
            className={classes.popper}
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={Boolean(anchorEl)} id="menu-list-grow">
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};
