import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        emptyRowContainer: {
            marginTop: 10,
            marginBottom: 10,
            display: 'flex',
        },
        filledRectangle: {
            height: 17,
            width: 17,
            backgroundColor: '#E6E6E6',
        },
        linesContainer: {
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        lineOneContainer: {
            height: 3,
            backgroundColor: '#E6E6E6',
            width: 25,
            marginBottom: 3,
        },
        lineTwoContainer: {
            height: 3,
            backgroundColor: '#E6E6E6',
            width: 45,
            marginBottom: 3,
        },
    })
);
