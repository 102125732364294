import { Avatar, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaperCitation } from '../../../backend/paper';
import { alertTypes, sampleMetaData } from '../../../constants';
import { RootState } from '../../../redux/combineReducers';
import { showAlert } from '../../../redux/reducers';
import { Alert } from '../../../types';
import { PreferencesForm } from './components/preferencesForm';
import { style } from './style';

export const Preferences = () => {
    const classes = style();
    const { id: speakerId, email, name } = useSelector((state: RootState) => state.Speaker);
    const speakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);
    const matches = name.match(/\b(\w)/g);
    const acronym = matches?.join('');
    const dispatch = useDispatch();
    const [alertMessage, setAlertMessage] = useState<Alert>({
        message: '',
        type: '',
        isVisible: false,
    });
    const [sampleCitation, setSampleCitation] = useState<string>('');
    const { citationStyle, proxyUrl, showFullTextModal } = speakerPreferences;
    const [selectedCitationStyle, setSelectedCitationStyle] = useState<string>('');

    useEffect(() => {
        setSelectedCitationStyle(citationStyle);
    }, [citationStyle, setSelectedCitationStyle]);

    useEffect(() => {
        dispatch(showAlert(alertMessage));
    }, [alertMessage]);

    useEffect(() => {
        if (selectedCitationStyle) {
            (async () => {
                try {
                    setSampleCitation('');
                    const { citation } = await getPaperCitation(sampleMetaData, selectedCitationStyle, speakerId!);
                    setSampleCitation(citation as string);
                } catch (err) {
                    setAlertMessage({
                        message: 'Unable to fetch sample citation',
                        type: alertTypes.error,
                        isVisible: true,
                    });
                }
            })();
        }
    }, [selectedCitationStyle, setAlertMessage, setSampleCitation]);

    return (
        <div className={classes.settingContainer} data-testid="pref">
            <Typography variant="h2" className={classes.heading}>
                Preferences
            </Typography>
            {email && (
                <div className={classes.settingContent}>
                    <Avatar className={classes.avatarDefault}>{acronym}</Avatar>
                    {selectedCitationStyle && (
                        <PreferencesForm
                            sampleCitation={sampleCitation}
                            citationStyle={selectedCitationStyle}
                            savedCitationStyle={citationStyle}
                            setCitationStyle={setSelectedCitationStyle}
                            setAlertMessage={setAlertMessage}
                            speakerId={speakerId as string}
                            dispatch={dispatch}
                            proxyUrl={proxyUrl}
                            fullTextModal={showFullTextModal}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
