import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        container: {
            marginTop: '10px',
            position: 'sticky',
            left: 0,
            right: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        iconButton: {
            backgroundColor: colors.primaryButton,
            width: 66,
            marginBottom: 30,
            borderRadius: 58,
            height: 40,
            '&:hover': {
                backgroundColor: colors.primaryButtonOnhover,
            },
            '&:focus': {
                border: `1px solid ${colors.darkTeal}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.primaryButtonOnPress,
            },
        },
        addIcon: {
            color: colors.white,
        },
    })
);
