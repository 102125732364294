import config from '../config';
import { API_TYPE } from '../constants';
import { getToken } from './overrides';

const sendPostRequest = async (body: string) => {
    const token = process.env.NODE_ENV !== 'test' ? await getToken() : '';
    try {
        const response = await fetch(config.api.baseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: token,
            },
            body: body,
        });
        const jsonResponse = await response.json();

        return jsonResponse;
    } catch (error) {
        console.log('Error in request is: ', error);
        throw error;
    }
};

/**
 * Used for unauthenticated/open apis.
 * @param body
 * @returns
 */
const sendOpenPostRequest = async (body: string) => {
    try {
        const response = await fetch(`${config.api.baseUrl}/open`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
        });
        const jsonResponse = await response.json();

        return jsonResponse;
    } catch (error) {
        console.log('Error in request is: ', error);
        throw error;
    }
};

export const executeGraphqlCall = async <T>(
    queryName: string,
    query: string,
    type?: string,
    variables?: any
): Promise<T> => {
    const body = JSON.stringify({ query: query.replace(/\s+/g, ' ').trim(), variables });

    try {
        const res = type === API_TYPE.OPEN ? await sendOpenPostRequest(body) : await sendPostRequest(body);
        if (res?.errors) {
            // this condition is specific to the below use case of updateOutlinePageTitle
            // if we have title and we erase it completly then the title equals to ""
            // we return that "" in this codition otherwise it will throw error
            if (queryName === 'updateOutlinePageTitle' && res.data?.queryName === '') return res.data[queryName];

            if (res?.errors) {
                console.log('Errors', res?.errors);
                const errorMessages = res?.errors?.reduce(
                    (allErrors: any, error: any) => `${allErrors}\n${error.message}`,
                    ''
                );
                throw new Error(`\n${errorMessages}`);
            }
        }

        return res.data[queryName];
    } catch (error) {
        console.log(`Error while calling ${queryName}: `, error);
        throw error;
    }
};
