import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert } from '../../../types';

const initialState: Alert = {
    message: '',
    type: '',
    isVisible: false,
};

const userSlice = createSlice({
    name: 'AlertHandler',
    initialState,
    reducers: {
        showAlert: (
            state,
            action: PayloadAction<{
                message: string;
                type: string;
                isVisible: boolean;
            }>
        ) => {
            return { ...action.payload };
        },
        hideAlert: (state, action: PayloadAction) => {
            return initialState;
        },
    },
});

export const { showAlert, hideAlert } = userSlice.actions;
export default userSlice.reducer;
