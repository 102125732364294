import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const UploadPdfIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_5485_48934)">
                    <path
                        d="M10.6673 10.6667L8.00065 8L5.33398 10.6667"
                        stroke="#006366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M8 8V14" stroke="#006366" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M13.5943 12.2603C14.2445 11.9058 14.7582 11.3449 15.0542 10.6661C15.3502 9.98723 15.4118 9.22914 15.2291 8.51144C15.0464 7.79375 14.6299 7.15732 14.0454 6.70261C13.4609 6.2479 12.7415 6.0008 12.001 6.00031H11.161C10.9592 5.21981 10.5831 4.4952 10.0609 3.88098C9.53877 3.26675 8.88418 2.77888 8.14635 2.45405C7.40852 2.12922 6.60665 1.97589 5.80103 2.00557C4.99541 2.03525 4.20699 2.24718 3.49507 2.62543C2.78314 3.00367 2.16622 3.53838 1.6907 4.18937C1.21517 4.84036 0.89341 5.59067 0.749603 6.38391C0.605797 7.17715 0.643687 7.99267 0.860426 8.76916C1.07716 9.54564 1.46711 10.2629 2.00095 10.867"
                        stroke="#006366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.6673 10.6667L8.00065 8L5.33398 10.6667"
                        stroke="#006366"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5485_48934">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};
