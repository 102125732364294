import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '280px',
            position: 'fixed',
            bottom: 0,
            left: theme.spacing(2),
            zIndex: 1300,
        },
        body: {
            position: 'relative',
        },
        contentWrapper: {
            padding: 3,
            background: 'linear-gradient(180deg, #006366 24.9%, #1DB0AE 113.85%)',
            height: '270px',
            width: '100%',
            position: 'relative',
        },
        content: {
            background: '#fff',
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        linearProgress: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
        accordionTitle: {
            fontSize: 12,
        },
        expandMoreIcon: {
            color: '#fff',
            alignSelf: 'end',
        },
        expandMoreIconBtn: {
            alignSelf: 'end',
        },
    })
);

const linearProgress = () => ({
    root: {
        height: 8,
        borderRadius: 4,
        margin: '3px 6px 0px 6px',
    },
    barColorPrimary: {
        backgroundColor: '#fff',
    },
    colorPrimary: {
        background: 'rgba(255, 255, 255, 0.4)',
    },
    bar: {
        borderRadius: 4,
    },
});

const accordion = () => ({
    root: {
        background: 'transparent',
        margin: '0px !important',
    },
});
const accordionSummary = (): any => ({
    root: {
        padding: '0 3px 0 6px',
        minHeight: 'unset',
        position: 'relative',
        background: 'linear-gradient(180deg, #006366 24.9%, #1DB0AE 113.85%)',
        borderRadius: '8px 8px 0px 0px',
    },
    content: {
        margin: '0 !important',
        paddingTop: '12px',
        paddingBottom: '5px',
        color: '#fff',
    },
    expanded: {
        minHeight: '0 !important',
        margin: '0 !important',
        color: '#fff',
    },
    expandIcon: {
        padding: 0,
        alignSelf: 'end',
    },
});
const accordionDetails = () => ({
    root: {
        padding: 0,
        marginTop: '-3px',
    },
});

const backdrop = (): any => ({
    root: {
        position: 'absolute',
        zIndex: 10,
    },
});

export const compStyles = { linearProgress, accordion, accordionSummary, accordionDetails, backdrop };
