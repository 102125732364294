import { Box, Divider, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../assets/colors';
import { OutlineSlidePreview } from '../outlineSlidePreview';
import { useQuery } from '../../utils';
import { RootState } from '../../redux/combineReducers';
import { alertBarHeight, alertTypes, navbarHeight } from '../../constants';
import config from '../../config';
import { DrawerToggler, Navbar } from '../common';
import { getAllThemes } from '../../backend/theme';
import { ProjectInterface, Theme } from '../../types';
import { OutlineSideBar } from '../outlineSideBar/index';
import { ThemeDrawer } from '../slidePreview/components/themeDrawer';
import { showAlert } from '../../redux/reducers';

const theme = createTheme({
    palette: {
        background: {
            default: '#EDEDED',
        },
    },
    typography: {
        fontFamily: 'Roboto',
    },
});

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        overflow: 'hidden',
        marginRight: config.featureFlags.pptxTheme && 20,
    },
    navBar: {
        zIndex: 1300,
    },
    outlineContainer: {
        width: '55%',
        height: '100%',
        overflow: 'auto',
    },
    divider: {
        width: '2px',
        backgroundColor: colors.elephant,
    },
    slidesContainer: {
        width: 'calc(45% - 28px);',
        display: 'flex',
        marginLeft: '12px',
        marginRight: '14px',
        height: '100%',
        overflow: 'auto',
    },
    previewContainer: {
        height: window.innerHeight - navbarHeight,
        overflow: 'hidden',
    },
}));

export const SlidePreviewPage = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const projectId = query.get('projectId');
    const projectsData: ProjectInterface[] = useSelector((state: RootState) => state.Projects);
    const selectedProject = projectsData.find((item) => item.id === projectId);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const classes = useStyles();
    const [themes, setThemes] = useState<Theme[] | null>(null);

    useEffect(() => {
        getAllThemes()
            .then((themes) => {
                setThemes(themes);
            })
            .catch((error) => {
                if (error instanceof Error) {
                    dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
                }
            });
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Box className={classes.root} data-testid="slide-preview">
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.navBar}>
                        <Navbar selectedProject={selectedProject} />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{ paddingTop: isVisible ? 65 : 30 }}
                        className={classes.previewContainer}
                    >
                        <Box className={classes.outlineContainer}>
                            <OutlineSideBar />
                        </Box>
                        <Divider orientation="vertical" className={classes.divider} />
                        <Box className={classes.slidesContainer}>
                            <OutlineSlidePreview />
                        </Box>
                    </Grid>
                </Grid>
                {config.featureFlags.pptxTheme && <ThemeDrawer themes={themes} />}
            </Box>
        </MuiThemeProvider>
    );
};
