import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { navbarHeight } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import { OutlineModes } from '../../../../types';
import { style } from './style';

interface AddCircularBtnProps {
    onClick: () => void;
    applyNavbarHeight?: boolean;
}

export const AddCircularBtn = ({ onClick, applyNavbarHeight = true }: AddCircularBtnProps) => {
    const classes = style();
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);
    const { mode } = useSelector((state: RootState) => state.ProjectOutline);

    return (
        <div
            className={classes.container}
            style={{ bottom: isVisible && mode === OutlineModes.READER && applyNavbarHeight ? navbarHeight + 30 : 30 }}
        >
            <IconButton onClick={onClick} className={classes.iconButton}>
                <Add className={classes.addIcon} />
            </IconButton>
        </div>
    );
};
