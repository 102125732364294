import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

const numberWidth = 35;
const smallSpace = 8;

export interface StyleProps {
    updating: boolean;
}

export const useOutlinePageStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        margin: '24px -24px -24px -24px',
        padding: '24px',
        border: '3px solid',
        borderColor: 'transparent',
        '&:hover': {
            border: '3px solid',
            borderImageSlice: 1,
            borderImageSource: colors.nimbdoneGreen,
        },
        display: 'flex',
        flexDirection: 'column',
    },
    rootInReader: {
        paddingBottom: '15px',
        paddingRight: '10px',
        border: '3px solid',
        borderColor: 'transparent',
        '&:hover': {
            border: '3px solid',
            borderImageSlice: 1,
            borderImageSource: colors.nimbdoneGreen,
        },
        display: 'flex',
        flexDirection: 'column',
    },
    numberContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    number: {
        width: `${numberWidth}px`,
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.elephant,
        fontWeight: 700,
    },
    numberDivider: {
        width: `calc(100% - ${numberWidth}px)`,
        borderTop: `1px dashed ${colors.smoke};`,
    },
    titleContainer: {
        marginTop: `${smallSpace}px`,
    },
    titleIcon: {
        marginLeft: `${numberWidth}px`,
        width: `${numberWidth}px`,
        height: `${numberWidth}px`,
    },
    titleIconInReader: {
        marginLeft: `15px`,
        width: `${numberWidth}px`,
        height: `${numberWidth}px`,
    },
    titleInput: {
        marginLeft: `${smallSpace}px`,
        fontSize: '16px',
        width: `calc(100% - ${numberWidth * 2 + smallSpace * 2}px)`,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    plusIconSection: {
        marginTop: `${2 * smallSpace}px`,
        transition: 'margin 1s',
    },
    plusIcon: {
        marginLeft: `${numberWidth}px`,
        width: `${numberWidth}px`,
        height: `${numberWidth}px`,
        cursor: 'pointer',
    },
    plusIconInReader: {
        marginLeft: `15px`,
        width: `${numberWidth}px`,
        height: `${numberWidth}px`,
        cursor: 'pointer',
    },
    delete: {
        display: 'flex',
        alignItems: 'center',
        cursor: ({ updating }) => (updating ? 'not-allowed' : 'pointer'),
        alignSelf: 'flex-end',
    },
    deleteIcon: {
        width: '16px',
        height: '16px',
        color: ({ updating }) => (updating ? colors.gray : colors.black),
    },
    deleteText: {
        marginLeft: '12px',
        fontSize: '14px',
        color: ({ updating }) => (updating ? colors.gray : colors.error),
    },
    deleteContainer: {
        marginTop: '16px',
        height: 25,
    },
    actionsConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    titleSlide: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '120%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: colors.gray,
        marginLeft: '12px',
    },
    toolTip: {
        position: 'absolute',
        left: '8.33%',
        right: '8.34%',
        top: '8.33%',
        bottom: '8.33%',
        border: `1px solid ${colors.darkTeal}`,
    },
    titleTag: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'fit-content',
        columnGap: '0.4rem',
    },
}));

export const useTooltipsStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: colors.skyGray,
        color: colors.black,
        fontStyle: 'italic',
    },
}));
