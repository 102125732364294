import { Grid } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Cover } from './cover.svg';
import { ReactComponent as Content } from './content.svg';

export const SuedeThemeThumbnail = () => {
    const svgProps: React.SVGProps<SVGSVGElement> = {
        width: '100%',
        height: '100%',
        style: {
            filter: 'drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.25))',
            padding: '10px 0',
        },
    };
    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <Cover {...svgProps} />
            </Grid>
            <Grid item xs={6}>
                <Content {...svgProps} />
            </Grid>
        </Grid>
    );
};
