import CircularProgress from '@material-ui/core/CircularProgress';
import { style } from './style';

export const OutlineLoader = () => {
    const classes = style();
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress color="inherit" size={16} />
            <p style={{fontSize:12,marginTop:3}}>Loading Outline...</p>
        </div>
    );
};

