import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const AddIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="34"
                    height="34"
                    rx="2.5"
                    stroke="#BABABA"
                />
                <path
                    d="M11.4688 17.2812H17.1055V11.6562H18.6406V17.2812H24.2774V18.8281H18.6406V24.4062H17.1055V18.8281H11.4688V17.2812Z"
                    fill="#C4C4C4"
                />
            </svg>
        </SvgIcon>
    );
};
