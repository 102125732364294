import React from 'react';
import { pptxThemes } from '../../../constants';
import { BlackAndWhiteThemeThumbnail } from './blackAndWhite';
import { ClassicThemeThumbnail } from './Classic';
import { MossThemeThumbnail } from './Moss';
import { SuedeThemeThumbnail } from './Suede';

const { classic, moss, blackAndWhite, suede } = pptxThemes;
export const themeThumbnailComponents = {
    [classic]: ClassicThemeThumbnail,
    [moss]: MossThemeThumbnail,
    [suede]: SuedeThemeThumbnail,
    [blackAndWhite]: BlackAndWhiteThemeThumbnail,
};
