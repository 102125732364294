import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
        },
        label: {
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: 12,
            letterSpacing: 0.2,
            color: colors.text,
            marginBottom: 0,
        },
        errorContainer: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            margin: 0,
            marginTop: 2,
        },
        errorText: {
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            color: colors.error,
            margin: 0,
            marginLeft: 2,
        },
        errorImage: {
            height: 15,
            width: 15,
        },
    })
);
