import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../assets/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 660,
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 20px 28px rgba(0, 0, 0, 0.25)',
            padding: theme.spacing(3, 5, 5),
            borderRadius: '8px',
        },
        title: {
            fontSize: 24,
            color: colors.smoke,
        },
        subtitle: {
            fontSize: 16,
            color: colors.smoke,
            '& a': {
                fontWeight: 'bold',
            },
            margin: 0,
        },
        closeBtn: {
            display: 'block',
            margin: '24px auto 0 auto',
            fontWeight: 'normal',
            paddingLeft: 24,
            paddingRight: 24,
        },
    })
);
