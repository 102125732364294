import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';
export const style = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        container: {
            boxShadow: '0px 0px 5px #E5E9F2',
            borderRadius: 8,
            width: '55%',
            backgroundColor: 'white',
            height: 300,
            marginTop: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        button: {
            backgroundColor: colors.primaryButton,
            color: 'white',
            '&:focus':{
                border: `1px solid ${colors.darkTeal}`,
                boxShadow:"0 0 0 2px #fff ,0 0 0 5px #00B1B8"
            },
            '&:hover': {
                backgroundColor: colors.primaryButtonOnhover,
            },
            '&:active':{
                backgroundColor: colors.primaryButtonOnPress,
            }
        },
        emptyContainer: {
            width: 140,
            height: 75,
            border: '2px solid #F3F3F3',
            borderRadius: 10,
            padding: 10,
            display: 'flex',
            margin: 20,
            position: 'relative',
        },
        buttonContainer: {
            position: 'absolute',
            bottom: -10,
            right: -10,
            zIndex: 3,
        },
        buttonCon: {
            backgroundColor: colors.primaryButton,
        },
        buttonIcon: {
            color: 'white',
        },
        emptyText: {
            color: colors.smoke,
            padding: 20,
        },
        filledSquare: {
            width: 50,
            height: '100%',
            borderRadius: 5,
            backgroundColor: colors.darkTeal,
        },
        filledRowsContainer: {
            paddingLeft: 10,
            flex: 1,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        filledRowOne: {
            width: 30,
            backgroundColor: 'lightgray',
            height: 3,
            marginBottom: 4,
        },
        filledRowTwo: {
            width: 50,
            backgroundColor: 'lightgray',
            height: 3,
            marginBottom: 4,
        },
        filledRowThree: {
            width: 45,
            backgroundColor: 'lightgray',
            height: 3,
        },
    })
);
