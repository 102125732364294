import { AppBar, Avatar, Button, CircularProgress, IconButton, Link, Toolbar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { transform } from 'buble';
import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image, Presentation, render, Shape, Slide as PresentationSlide, Text, Line } from 'react-pptx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ExportIcon, NimdoneBetaIcon, SlidePreviewWhite } from '../../../assets/icons';
import config from '../../../config';
import { alertTypes, navbarHeight, NavBarItems } from '../../../constants';
import { RootState } from '../../../redux/combineReducers';
import { routes } from '../../pages/routes';
import { Alert } from '../alert';
import {
    InformationModal as InformationModalType,
    ProjectInterface,
    Slide,
    SpeakerSubscriptionStatuses,
} from '../../../types';
import { InformationModal } from '../modals/informationModal';
import { FileMenu } from './dropDownMenus/fileMenu';
import { HelpMenu } from './dropDownMenus/helpMenu';
import { NavMenu } from './dropDownMenus/navMenu';
import { ViewMenu } from './dropDownMenus/viewMenu';
import { navbarStyles } from './styles';
import { getStripeCustomerPortalUrl } from '../../../backend/speaker';
import { showAlert } from '../../../redux/reducers';

const primitives = {
    Presentation,
    Slide: PresentationSlide,
    Image,
    Text,
    Shape,
    Line,
};

interface NavbarProps {
    selectedProject?: ProjectInterface;
}

export const Navbar = ({ selectedProject }: NavbarProps) => {
    const classes = navbarStyles();
    const location = useLocation();
    const { pathname } = location;
    const history = useHistory();
    const dispatch = useDispatch();

    const Speaker = useSelector((state: RootState) => state.Speaker);
    const ProjectOutline = useSelector((state: RootState) => state.ProjectOutline);
    const { presentation } = useSelector((state: RootState) => state.ProjectPresentation);
    const Projects: ProjectInterface[] = useSelector((state: RootState) => state.Projects);
    const slides: Slide[] = presentation?.slides || [];
    const [exporting, setExporting] = useState<boolean>(false);
    const [clicked, setClicked] = useState<string | null>(null);
    const [isShownExportButton, setIsShownExportButton] = useState(false);
    const [isShownViewSlidesButton, setIsShownViewSlidesButton] = useState(false);
    const [viewAnchorEl, setViewAnchorEl] = useState<null | HTMLElement>(null);
    const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);
    const [fileAnchorEl, setFileAnchorEl] = useState<null | HTMLElement>(null);
    const [navAnchorEl, setNavAnchorEl] = useState<null | HTMLElement>(null);
    const { subscriptionStatus, freeTrialRemainingDays } = Speaker;
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);
    const [extendingTrial, setExtendingTrial] = useState(false);

    let [showInformationModal, setShowInformationModal] = useState<InformationModalType>({
        type: '',
        open: false,
        title: '',
        closeBtnLabel: '',
        successBtnLabel: '',
        cancelBtnLabel: '',
        subTitle: <></>,
        footer: <></>,
        helpInfo: <></>,
        cancelBtnLoading: false,
        handleConfirm: () => {},
        handleClose: () => {},
        dataTestId: '',
    });

    useEffect(() => {
        if (!viewAnchorEl && !helpAnchorEl && !fileAnchorEl) setClicked(null);
    }, [viewAnchorEl, helpAnchorEl, fileAnchorEl]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id: string, toCheck: boolean) => {
        if (toCheck && !clicked) return;

        setClicked(id);
        if (id === '0') {
            setFileAnchorEl(event.currentTarget);
            setViewAnchorEl(null);
            setHelpAnchorEl(null);
        } else if (id === '1') {
            setViewAnchorEl(event.currentTarget);
            setFileAnchorEl(null);
            setHelpAnchorEl(null);
        } else if (id === '2') {
            setHelpAnchorEl(event.currentTarget);
            setViewAnchorEl(null);
            setFileAnchorEl(null);
        } else if (id === '3') {
            setNavAnchorEl(event.currentTarget);
        }
    };

    const closeViewMenu = () => setViewAnchorEl(null);
    const closeHelpMenu = () => setHelpAnchorEl(null);
    const closeFileMenu = () => setFileAnchorEl(null);
    const closeNavMenu = () => setNavAnchorEl(null);

    const transpile = (code: any, callback: any, onError: any) => {
        try {
            const result = transform(code, {
                objectAssign: 'Object.assign',
                transforms: {
                    dangerousForOf: true,
                    dangerousTaggedTemplateString: true,
                },
            });

            const res = new Function('React', 'ReactPPTX', ...Object.keys(primitives), result.code);
            res(React, { render: (doc: any) => callback(doc) }, ...Object.values(primitives));
        } catch (e) {
            if (onError) {
                onError(e);
            }
        }
    };

    useEffect(() => {
        if (pathname === routes.slidePreview) setIsShownExportButton(true);
        if (pathname === routes.reader && selectedProject?.id) setIsShownViewSlidesButton(true);
    }, [pathname, setIsShownExportButton, setIsShownViewSlidesButton, selectedProject]);

    const clickHandler = (e: React.MouseEvent<HTMLElement>, id: string, toCheck: boolean) => {
        handleClick(e, id, toCheck);
        setClicked(id);
    };

    const exportSlides = () => {
        setExporting(true);
        const _slides: string[] = slides.map((item: Slide) => {
            return item.markup;
        });
        const slideMarkup = `ReactPPTX.render(
            <Presentation> \n ${_slides.join('\n')} \n </Presentation>
            );`.replaceAll('<br/>', '');

        transpile(
            slideMarkup,
            function(doc: any) {
                render(doc)
                    .then(async (buffer) => {
                        try {
                            var blob = new Blob([buffer], {
                                type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            });
                            saveAs(
                                blob,
                                `${selectedProject?.title ? selectedProject?.title : ''} ${moment().format(
                                    'YY-MM-DD'
                                )} at ${moment().format('h.mm.ss A')}.pptx`
                            );
                            setExporting(false);
                        } catch (err) {
                            setExporting(false);
                            console.log('Error is: ', err);
                        }
                    })
                    .catch(() => {
                        setExporting(false);

                        setShowInformationModal({
                            type: alertTypes.error,
                            open: true,
                            title: 'Export Failed',
                            closeBtnLabel: 'Close',
                            cancelBtnLabel: '',
                            successBtnLabel: '',
                            subTitle: '',
                            footer: '',
                            helpInfo: '',
                            cancelBtnLoading: false,
                            handleConfirm: () => exportSlidesError(),
                            handleClose: () => closeModal(),
                            dataTestId: '',
                        });
                    });
            },
            function(err: any) {
                setExporting(false);
                console.log(err);
            }
        );
    };

    const closeModal = () => {
        setShowInformationModal({ ...showInformationModal, open: false });
    };

    const exportSlidesError = () => {
        closeModal();
    };

    const handleSlidePreviewRedirect = () =>
        history.push({
            pathname: routes.slidePreview,
            search: location.search,
        });

    const handleHomeRedirect = () =>
        history.push({
            pathname: routes.library,
            search: selectedProject?.id
                ? '?' + new URLSearchParams({ projectId: selectedProject?.id })
                : Projects.length > 0
                ? '?' + new URLSearchParams({ projectId: Projects[0].id })
                : undefined,
        });

    const showTrialEndsMessage =
        config.featureFlags.pricing &&
        config.featureFlags.freeTrial &&
        subscriptionStatus === SpeakerSubscriptionStatuses.TRIALING_USER;

    const navigateToBillings = async () => {
        setExtendingTrial(true);
        const speakerObj = localStorage.getItem('unauth_speaker');
        const speaker = JSON.parse(speakerObj!);
        getStripeCustomerPortalUrl(speaker.stripeCustomerId)
            .then((url: string) => {
                setExtendingTrial(false);
                window.open(url, '_blank');
            })
            .catch((error: NodeJS.ErrnoException) => {
                setExtendingTrial(false);
                dispatch(showAlert({ isVisible: true, message: error.message, type: alertTypes.error }));
            });
    };
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.menuContainer} data-testid="navbar-menu-container">
                        <div
                            className={classes.logoContainer}
                            data-testid="nimdone-logo-container"
                            role="presentation"
                            onClick={handleHomeRedirect}
                        >
                            <NimdoneBetaIcon viewBox={'0 0 95 29'} className={classes.logo} />
                        </div>

                        {NavBarItems.map((item) => (
                            <Button
                                key={item.id}
                                className={
                                    clicked
                                        ? clicked === item.id
                                            ? classes.clickedMenu
                                            : classes.hoveredMenu
                                        : classes.menu
                                }
                                onMouseEnter={(event: React.MouseEvent<HTMLElement>) =>
                                    handleClick(event, item.id, true)
                                }
                                onClick={(e) => clickHandler(e, item.id, false)}
                                data-testid={`navbar-menu-${item.id}`}
                            >
                                {item.name}
                            </Button>
                        ))}

                        <span className={classes.savingIndicator}>
                            {!ProjectOutline?.error
                                ? ProjectOutline?.savingOutlineItem
                                    ? 'Saving...'
                                    : 'Autosaved'
                                : 'Error'}
                        </span>
                    </div>
                    {showTrialEndsMessage && (
                        <div className={classes.freeTrialMessage}>
                            Your trial ends in<span className={classes.bold}>&nbsp;{freeTrialRemainingDays} days</span>
                            &nbsp;-&nbsp;
                            <Link
                                underline="always"
                                color="inherit"
                                component="button"
                                className={classes.extendTrial}
                                onClick={navigateToBillings}
                                disabled={extendingTrial}
                            >
                                {extendingTrial ? (
                                    <CircularProgress color="inherit" thickness={5} size={16} />
                                ) : (
                                    'EXTEND NOW'
                                )}
                            </Link>
                        </div>
                    )}
                    {selectedProject && isShownViewSlidesButton && (
                        <Button
                            onClick={handleSlidePreviewRedirect}
                            className={classes.exportButton}
                            startIcon={<SlidePreviewWhite className={classes.exportIcon} viewBox="0 0 20 20" />}
                        >
                            View Slides
                        </Button>
                    )}
                    {isShownExportButton && (
                        <Button
                            onClick={exportSlides}
                            className={classes.exportButton}
                            startIcon={<ExportIcon className={classes.exportIcon} viewBox="0 0 18 18" />}
                        >
                            {exporting ? <CircularProgress style={{ color: 'white' }} size={16} /> : 'Export'}
                        </Button>
                    )}

                    <IconButton className={classes.avatarContainer} onClick={(e) => clickHandler(e, '3', false)}>
                        <Avatar alt={Speaker ? Speaker.name : 'User Name'} className={classes.avatar} />
                        <p className={classes.name}>{Speaker ? Speaker.name : 'User Name'}</p>
                        <ExpandMoreIcon className={classes.downChevron} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <ViewMenu anchorEl={viewAnchorEl} handleClose={closeViewMenu} selectedProject={selectedProject} />
            <HelpMenu anchorEl={helpAnchorEl} handleClose={closeHelpMenu} />
            <FileMenu anchorEl={fileAnchorEl} handleClose={closeFileMenu} />
            <NavMenu anchorEl={navAnchorEl} handleClose={closeNavMenu} />
            <Alert top={navbarHeight} />

            <InformationModal
                type={showInformationModal.type}
                open={showInformationModal.open}
                title={showInformationModal.title}
                closeBtnLabel={showInformationModal.closeBtnLabel}
                successBtnLabel={showInformationModal.successBtnLabel}
                cancelBtnLabel={showInformationModal.cancelBtnLabel}
                subTitle={showInformationModal.subTitle}
                footer={showInformationModal.footer}
                helpInfo={showInformationModal.helpInfo}
                cancelBtnLoading={showInformationModal.cancelBtnLoading}
                handleConfirm={showInformationModal.handleConfirm}
                handleClose={showInformationModal.handleClose}
                dataTestId={showInformationModal.dataTestId}
            />
        </div>
    );
};
