import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        content: {
            padding: 44,
            background: colors.white,
            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 5px ${colors.boxShadow}`,
            borderRadius: 8,
            width: 433,
            boxSizing: 'border-box',
            textAlign: 'center',
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            fontSize: 24,
            color: colors.smoke,
            marginTop: 0,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
        },
        okBtn: {
            marginTop: 23,
            fontSize: 16,
        },
    })
);
