import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSubscriptionDetails } from '../../../backend/speaker';
import { gtm, stripe } from '../../../constants';
import { GTMService } from '../../../services/gtmService';
import { Invoice, SpeakerSubscriptionStatuses } from '../../../types';
import { getSpeakerSubscriptionStatus } from '../../../utils';
import { MuiCMButton } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

export const SubscriptionSuccess = () => {
    const classes = style();
    const history = useHistory();
    const [invoice, setInvoice] = useState<Invoice>({
        id: '',
        nextBillingDate: '',
        amountDue: 0,
        customerEmail: '',
        planId: '',
        emailVerified: false,
        trialEnds: null,
        isSubscriptionActive: null,
        allowUser: null,
    });
    const [currentPlan, setCurrentPlan] = useState<any>('');
    const [isLoading, setLoading] = useState<any>(false);

    useEffect(() => {
        let speakerObj: any = localStorage.getItem('unauth_speaker');
        let speaker: any = JSON.parse(speakerObj);
        if (!!speaker.stripeCustomerId) {
            setLoading(true);
            getSubscriptionDetails(speaker.stripeCustomerId)
                .then(async (inv: Invoice) => {
                    await triggerEvent(inv);
                    setLoading(false);
                    setInvoice(inv);
                    const url: any = window.location;
                    const params = new URL(url.href).searchParams;
                    const planId: string | null = params.get('planId');
                    setCurrentPlan(planId);
                })
                .catch((err: any) => {
                    console.log('ERROR' + err);
                    setLoading(false);
                });
        }
    }, []);

    const triggerEvent = async (inv: Invoice) => {
        const gtmService = new GTMService();
        await gtmService.handleEvent({
            event: gtm.events.TRANSACTION,
            ecommerce: {
                purchase: {
                    actionField: {
                        id: inv?.id, // Transaction ID. Required for purchases and refunds.
                        revenue: inv?.amountDue, // Total transaction value (incl. tax and shipping)
                        tax: '', // if not applicable then leave it as blank
                        shipping: '', // if not applicable then leave it as blank
                        coupon: '', // if not applicable then leave it as blank
                    },
                    products: [
                        {
                            // List of productFieldObjects.
                            name: getPlanName(inv?.planId), // Name or ID is required.
                            id: inv?.planId, // In our case Product ID maybe only Two
                            price: inv?.amountDue,
                            category: getPlanName(inv?.planId), // Professional or Beginner etc
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    };

    const navigateToLogin = () => history.push(routes.login);

    const getPlanName = (stripePlanId?: string): string => {
        let planId: string;
        if (!!invoice.planId) {
            planId = invoice.planId;
        } else if (!!stripePlanId) {
            planId = stripePlanId;
        } else {
            planId = '';
        }
        switch (planId) {
            case stripe.monthlyPlan.id:
                return stripe.monthlyPlan.description;
            case stripe.annualPlan.id:
                return stripe.annualPlan.description;
            default:
                return 'No Plan';
        }
    };

    const subscriptionStatus = invoice
        ? getSpeakerSubscriptionStatus(invoice?.allowUser, invoice?.trialEnds, invoice?.isSubscriptionActive).status
        : SpeakerSubscriptionStatuses.TRIALING_USER;
    return (
        <div className={classes.mainContainer}>
            {isLoading ? (
                <CircularProgress className={classes.loader} />
            ) : (
                <div className={classes.container}>
                    <h1 className={classes.heading}>
                        {subscriptionStatus === SpeakerSubscriptionStatuses.TRIALING_USER
                            ? 'Thank you for your signing up!'
                            : 'Thank you for your payment!'}
                    </h1>
                    {!invoice ||
                        (invoice && !invoice.emailVerified && (
                            <div className={classes.descriptionContainer}>
                                <p className={classes.description}>
                                    You’ll be receiving a confirmation email at{' '}
                                    <strong>{invoice?.customerEmail}</strong> shortly. Please verify your account before
                                    logging in.
                                </p>
                            </div>
                        ))}
                    <div className={classes.orderSummaryContainer}>
                        <div className={classes.summaryHeader}>
                            <p className={classes.headerText}>Subscription Summary</p>
                        </div>
                        <div className={classes.sumamryContent}>
                            <p>{subscriptionStatus === SpeakerSubscriptionStatuses.TRIALING_USER
                            ? 'We will remind you to setup a payment method 7 days before your trial ends.'
                            : 'Your card will be charged immediately'}</p>
                            <p className={classes.orderDetail}>
                                <strong>Plan: {getPlanName()}</strong>
                            </p>
                            <p className={classes.orderDetail}>
                                <strong>Price: {'$' + invoice?.amountDue + '.00'}</strong>
                            </p>
                            <Divider/>
                            {subscriptionStatus !== SpeakerSubscriptionStatuses.TRIALING_USER &&
                                <div className={classes.summaryBottomText}>
                                    <p className={classes.textContent}>
                                        You will be charged ${invoice?.amountDue}/{currentPlan === '1' ? 'month' : 'year'},
                                        starting on&nbsp;{invoice?.nextBillingDate}.
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                        <MuiCMButton onClick={navigateToLogin} fullWidth>
                            continue to nimdone
                        </MuiCMButton>
                    </div>
                </div>
            )}
        </div>
    );
};
