import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            alignContent: 'center',
            padding: 10,
        },
        formContainer: {
            width: 350,
        },
        headline: {
            display: 'block',
            fontSize: '16px',
            color: colors.smoke,
            marginBottom: '24px',
        },
        button: {
            marginTop: 24,
            fontWeight: 'normal',
        },
        logo: {
            width: 204,
            height: 210,
        },
        returnLoginText: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0.2,
            color: colors.smoke,
            textAlign: 'center',
            marginTop: 24,
            cursor: 'pointer',
        },
        loginText: {
            color: colors.blue,
        },
    })
);
