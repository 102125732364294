import { Button, Modal, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { RootState } from '../../../redux/combineReducers';
import { SpeakerSubscriptionStatuses } from '../../../types';
import { routes } from '../../pages/routes';
import { style } from './style';

interface FreeTrialEndedModalProps {
    open: boolean;
}

export const FreeTrialEndedModal = ({ open }: FreeTrialEndedModalProps) => {
    const { subscriptionStatus } = useSelector((state: RootState) => state.Speaker);
    const classes = style();
    const history = useHistory();
    const title =
        subscriptionStatus === SpeakerSubscriptionStatuses.CANCELLED_TRAILING
            ? 'Your free trial has ended'
            : 'Your subscription has ended';

    return (
        <Modal
            open={open}
            aria-labelledby="add-project-title"
            aria-describedby="add-project-description"
            className={classes.modal}
        >
            <div className={classes.contentContainer}>
                <div className={classes.logoContainer}>
                    <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} />
                </div>
                <div className={classes.content}>
                    <div>
                        <Typography className={classes.freeTrialEndedMessage}>{title}</Typography>
                    </div>
                    <div className={classes.thankyouMessage}>
                        <Typography>
                            Thanks for giving nimdone a try. To continue using nimdone,{' '}
                            <span className={classes.bold}>please subscribe to a paid plan</span>.
                        </Typography>
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.subscribeButton}
                            onClick={() => history.push(routes.selectPlan)}
                        >
                            Subscribe Now
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
