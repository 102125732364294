import { IconButton, Tooltip } from '@material-ui/core';
import { DoubleArrow } from '@material-ui/icons';
import { useStyles } from './styles';

interface DrawerTogglerProps {
    toggleDrawer: () => void;
    open: boolean;
    arrowStyle: any;
}

export const DrawerToggler = ({ toggleDrawer, open, arrowStyle }: DrawerTogglerProps) => {
    const classes = useStyles();
    return (
        <div className={classes.mainContainer} role="presentation" onClick={toggleDrawer} data-testid="drawer-toggler">
            <Tooltip title={open ? 'Open' : 'Close'}>
                <IconButton size="small" color="inherit" aria-label="open drawer">
                    <DoubleArrow style={{ ...arrowStyle }} className={classes.arrowIcon} />
                </IconButton>
            </Tooltip>
        </div>
    );
};
