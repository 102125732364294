import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';
import { drawerWidth, navbarHeight } from '../../../../constants';

export const style = makeStyles(() => ({
    root: {
        display: 'flex',
        height: '100%',
    },

    drawerHeaderContainer: {
        display: 'flex',
        paddingBottom: 20,
        alignItems: 'center',
    },
    heading: {
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: colors.text2,
        padding: '0px 15px',
        flex: 1,
    },
    refreshButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 10,
        paddingRight: 10,
    },
    paperThumbnail: {
        width: 40,
        height: 50,
        marginRight: 5,
        backgroundColor: 'gray',
    },
    paperFileRoot: {
        padding: '10px 25px',
    },
    paperFileConatiner: {
        cursor: 'pointer',
        height: 90,
        display: 'flex',
        justifyContent: 'center',

        alignItems: 'center',
        alignContent: 'center',
    },
    paperDescription: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',
        overflowWrap: 'anywhere',
        height: '100%',
    },
    paperTitle: {
        color: colors.text2,
        fontSize: 15,
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkitLineClamp': '2',
        '-webkitBoxOrient': 'vertical',
    },
    paperAuthor: {
        color: colors.infoGrayText,
        fontSize: 13,
        margin: 0,
    },
    position: {
        position: 'absolute',
        left: 27,
    },
    paperNameDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    reference: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '140%',
        letterSpacing: '0.2px',
        display: 'flex',
        color: colors.darkTeal,
        justifyContent: 'right',
        height: 20,
        alignItems: 'center',
        cursor: 'pointer',
    },
    forwardIcon: {
        marginLeft: '5px',
        marginTop: 1,
    },
    arrowIcon: {
        color: colors.darkTeal,
        height: 16,
    },
}));
