import React, { useState, useEffect } from 'react';
import { Divider, ListItemText } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useLocation } from 'react-router';

import { StyledMenuItem } from './styledMenuItem';
import { DropDownMenuProps } from './types';
import { StyledMenu } from './styledMenu';
import { menuStyles } from '../styles';
import { FileNewSubMenu } from './fileNewSubMenu';

export const FileMenu = ({ anchorEl, handleClose }: DropDownMenuProps) => {
    const classes = menuStyles();

    const [isReader, setIsReader] = useState<boolean>(false);
    const [fileNewSubAnchorEl, setFileNewSubAnchorEl] =
        useState<null | HTMLElement>(null);

    useEffect(() => {
        if (!anchorEl) setFileNewSubAnchorEl(null);
    }, [anchorEl]);

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes('reader')) setIsReader(true);
    }, [pathname]);

    const openSubMenu = (event: React.MouseEvent<HTMLElement>) =>
        setFileNewSubAnchorEl(event.currentTarget);
    const closeSubMenu = () => setFileNewSubAnchorEl(null);

    return (
        <StyledMenu
            placement={'top-start'}
            anchorEl={anchorEl}
            handleClose={handleClose}
        >
            <StyledMenuItem onClick={openSubMenu} onMouseEnter={openSubMenu}>
                <ListItemText
                    primary={<p className={classes.listItemText}>New</p>}
                />
                <ArrowRightIcon />
            </StyledMenuItem>
            <Divider className={classes.divider} />
            <StyledMenuItem onMouseEnter={() => setFileNewSubAnchorEl(null)}>
                <ListItemText
                    primary={
                        <p
                            className={
                                isReader
                                    ? classes.disabledListItemText
                                    : classes.listItemText
                            }
                        >
                            Export Slides
                        </p>
                    }
                />
            </StyledMenuItem>

            <FileNewSubMenu
                anchorEl={fileNewSubAnchorEl}
                handleClose={closeSubMenu}
            />
        </StyledMenu>
    );
};
