import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createProject, createProjectWithPaperFileProject } from '../../../backend/project';
import { gtm } from '../../../constants';
import { RootState } from '../../../redux/combineReducers';
import { addNewProject, setCreateProjectWithPaper, showAddProject, showAlert } from '../../../redux/reducers';
import { GTMService } from '../../../services/gtmService';
import { routes } from '../../pages/routes';
import { InputModal } from '../modals/inputModal';

export const AddProject = () => {
    const [projectTitle, setProjectTitle] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const speakerId = useSelector((state: RootState) => state?.Speaker?.id);
    const paperFile = useSelector((state: RootState) => state?.PaperFile);
    const ModalHandler = useSelector((state: RootState) => state?.ModalHandler);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleInputChange = (event: any) => {
        setProjectTitle(event.target.value);
    };

    const handleCreateProject = async () => {
        setLoading(true);
        if (speakerId) {
            try {
                let project;
                if (paperFile.isCreateProjectWithPaper) {
                    project = await createProjectWithPaperFileProject(
                        speakerId as string,
                        projectTitle,
                        paperFile.id as string
                    );
                    const originalPaperFile = { ...paperFile };
                    delete originalPaperFile.isCreateProjectWithPaper;
                    delete originalPaperFile.citation;
                    project.paperFiles = [originalPaperFile];
                    project.noOfPaperFiles = 1;
                    dispatch(addNewProject(project));
                    dispatch(setCreateProjectWithPaper({ isCreateProjectWithPaper: false }));
                } else {
                    project = await createProject(speakerId as string, projectTitle);
                    project.noOfPaperFiles = 0;
                    dispatch(addNewProject(project));
                    if (history.location.pathname === routes.library)
                        history.push({
                            pathname: routes.library,
                            search: '?' + new URLSearchParams({ projectId: project.id }),
                        });
                }

                dispatch(showAddProject({ addProjectModal: false }));

                setProjectTitle('');
                const gtmService = new GTMService();
                await gtmService.handleEvent({
                    event: gtm.events.PROJECT_ADDED,
                    userId: speakerId,
                });
                setLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    handleOnClose();
                    setLoading(false);
                    setProjectTitle('');
                    dispatch(showAlert({ isVisible: true, message: error.message, type: 'ERROR' }));
                }
            }
        }
    };

    const handleOnClose = () => dispatch(showAddProject({ addProjectModal: false }));

    return (
        <InputModal
            title={'Name your new project'}
            placeholder={'Project Name'}
            inputLabel={'Project Name'}
            value={projectTitle}
            loading={loading}
            isOpen={ModalHandler.addProjectModal}
            handleInputChange={handleInputChange}
            handleSubmit={handleCreateProject}
            closeModal={handleOnClose}
            createBtnId={'createBtnId'}
        />
    );
};
