import React from 'react';
import { LayoutIconProps } from '../types';

export const Cover0TextDefault = ({
    selected,
    onClickHandler,
    id
}: LayoutIconProps):JSX.Element => {
    const handleClick = () => {
        if (onClickHandler) onClickHandler(id as string);
    };
    return (
        <div
            role={'presentation'}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
            data-testid="Cover-0Text-Default"
        >
            <svg
                width="75"
                height="50"
                viewBox="0 0 75 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="1" y="0" width="73" height="48" rx="3" fill="white" />
                <rect
                    x="21.3569"
                    y="23"
                    width="32.6429"
                    height="4"
                    fill="#C4C4C4"
                />
                <rect
                    x="1"
                    y="1"
                    width="73"
                    height="48"
                    rx="3"
                    stroke={selected ? '#A0A4A8' : '#E8E8E8'}
                    strokeWidth="2"
                />
            </svg>
        </div>
    );
};
