import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

const leftMargin = 35;
const smallSpace = 8;

export const style = makeStyles(() => ({
    root: {
        padding: `${smallSpace} px`,
    },
    Icon: {
        width: `${leftMargin}px`,
        height: `${leftMargin}px`,
        fill: 'none',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: `${smallSpace}px`,
        width: `calc(100% - ${leftMargin * 2 + smallSpace * 2}px)`,
    },
    text: {
        fontSize: '16px',
        paddingBottom: 0,
        padding: 0,
        width: '100%',
        border: 0,
    },
    caption: {
        fontSize: '12px',
        padding: 0,
        width: '100%',
        color: colors.elephant,
        fontWeight: 400,
    },
    textListIcon: {
        width: 34,
        height: 34,
    },
}));
