import { Fade, Modal } from '@material-ui/core';
import React from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { UploadCloudIcon } from '../../../../../../assets/icons';
import { MuiCMButton } from '../../../../../common';
import { style } from './style';

interface Props {
    open: boolean;
    toggleUploadPdfModal: (state: boolean) => void;
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

export const UploadPdfModel: React.FC<Props> = ({ open, toggleUploadPdfModal, getRootProps, getInputProps }) => {
    const classes = style();
    return (
        <Modal
            aria-labelledby="pdf-upload-modal"
            aria-describedby="pdf-upload-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => toggleUploadPdfModal(false)}
            closeAfterTransition
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <div className={classes.uploadModalContent}>
                    <div>
                        <h1 className={classes.uploadModalHeading}>UPLOAD PDFS</h1>
                        <p className={classes.uploadModalsubHeading}>
                            Upload pdfs of articles you would like to use in your project.
                        </p>
                        <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                            <input data-testid="upload-pdfs-input" {...getInputProps()} />
                            <UploadCloudIcon className={classes.UploadCloudIcon} viewBox={'0 0 104 104'} />
                            <p className={classes.uploadModalDnDText}>Drag and drop your files here</p>
                            <p className={classes.uploadModalDnDText}>or</p>
                            <MuiCMButton className={classes.uploadModalBtn}>Browse Files</MuiCMButton>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};
