import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const useStyles = makeStyles({
    dialogContent: {
        padding: '36px 48px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '140%',
        letterSpacing: '0.2px',
        color: colors.smoke,
        marginBottom: '8px',
    },
    IconContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '13px',
        marginRight: '8px',
    },
    warningIcon: {
        width: '23px',
        height: '23px',
        color: colors.warningIcon,
    },
    warningText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: colors.smoke,
        marginTop: '0px',
        maxWidth: '746px',
        textAlign: 'center',
    },
    ButtonContainer: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    cancelBtn: {
        width: '111px',
        marginLeft: '12px',
        fontWeight: 'normal',
    },
    deleteBtn: {
        minWidth: '235px',
        maxWidth: '254px',
    },
});
