import { useState, useEffect } from 'react';
import { Divider, ListItemText, ListItemIcon } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';

import { menuStyles } from '../styles';
import { StyledMenuItem } from './styledMenuItem';
import { SlidePreview, Reader, Library, SearchIcon } from '../../../../assets/icons';
import { PUBMED_URL } from '../../../../constants';
import { DropDownMenuProps } from './types';
import { StyledMenu } from './styledMenu';
import { routes } from '../../../pages/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { PaperFile } from '../../../../types';

export const ViewMenu = ({ anchorEl, handleClose, selectedProject }: DropDownMenuProps) => {
    const classes = menuStyles();
    const [disableReader, setDisableReader] = useState<boolean>(false);
    const [disableSlidePreview, setDisableSlidePreview] = useState<boolean>(false);
    const [disableLibrary, setDisableLibrary] = useState<boolean>(false);
    const projects = useSelector((state: RootState) => state.Projects);

    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (pathname.includes(routes.reader)) setDisableReader(true);
        if (pathname.includes(routes.slidePreview)) setDisableSlidePreview(true);
        if (pathname.includes(routes.library)) setDisableLibrary(true);
    }, [pathname]);

    const handlePubmedMenu = () => window.open(PUBMED_URL, '_blank', 'noopener,noreferrer');

    const handleNavigation = (path: string) => {
        // also add
        // projectId in case of reader, slide preview and library pages
        // and key in case of reader page
        const readablePaperFiles =
            selectedProject?.paperFiles &&
            selectedProject?.paperFiles?.filter((paperFile: PaperFile) => Boolean(paperFile.key) === true);
        const projectId = selectedProject?.id || (projects.length && projects[0].id) || undefined;

        const search =
            projectId &&
            '?' +
                `${
                    path === routes.reader && readablePaperFiles?.length
                        ? new URLSearchParams({ projectId, key: readablePaperFiles[0]?.key! })
                        : new URLSearchParams({ projectId })
                }`;

        history.push({
            pathname: path,
            search,
        });
    };

    const slidePreviewHandeler = () => handleNavigation(routes.slidePreview);

    return (
        <StyledMenu placement={'top-start'} anchorEl={anchorEl} handleClose={handleClose}>
            <>
                <StyledMenuItem
                    onClick={slidePreviewHandeler}
                    disabled={projects.length !== 0 ? (disableSlidePreview ? true : false) : true}
                    data-testid="slidePreview"
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <SlidePreview />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <p className={classes.listItemText}>
                                {disableSlidePreview ? 'Slide Preview is open' : 'Slide Preview'}
                            </p>
                        }
                    />
                </StyledMenuItem>

                <StyledMenuItem onClick={() => !disableReader && handleNavigation(routes.reader)}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <Reader />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <p className={disableReader ? classes.disabledListItemText : classes.listItemText}>
                                {disableReader ? 'Reader is open' : 'Reader'}
                            </p>
                        }
                    />
                </StyledMenuItem>

                <Divider className={classes.divider} />
                <StyledMenuItem onClick={() => handleNavigation(routes.library)}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <Library />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <p className={disableLibrary ? classes.disabledListItemText : classes.listItemText}>
                                {disableLibrary ? 'My Library is open' : 'My Library'}
                            </p>
                        }
                    />
                </StyledMenuItem>

                <StyledMenuItem onClick={handlePubmedMenu}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={<p className={classes.listItemText}>Pubmed</p>} />
                </StyledMenuItem>
            </>
        </StyledMenu>
    );
};
