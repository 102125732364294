import { executeGraphqlCall } from './api';
import { Outline, OutlineItem } from '../types';
import { Descendant } from 'slate';

export const getOutline = async (projectId: string): Promise<Outline> => {
    const body = `{
        outlineByProject(projectId: "${projectId}")
        {
           id, pages{ id, title, number, items{ id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } } }
        }
    }`;

    return executeGraphqlCall<Outline>('outlineByProject', body);
};

export const createTextItem = async (
    outlinePageId: string,
    speakerId: string,
    text?: Descendant[],
    citation?: string
): Promise<OutlineItem[]> => {
    const variables = { data: { speakerId, outlinePageId, list: false }, text, citation };

    const body = `mutation($data: CreateOutlineItem!, $text: [OutlineItemTextPropertyInput!], $citation:String){
        createOutlineTextItem(data:$data, text:$text, citation:$citation){ id, order, itemType, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } } 
        }`;

    return executeGraphqlCall<OutlineItem[]>('createOutlineTextItem', body, undefined, variables);
};
export const createListItem = async (
    outlinePageId: string,
    speakerId: string,
    text?: Descendant[],
    citation?: string
): Promise<OutlineItem[]> => {
    const variables = { data: { speakerId, outlinePageId, list: true }, text, citation };

    const body = `mutation($data: CreateOutlineItem!, $text: [OutlineItemTextPropertyInput!], $citation:String){
        createOutlineTextItem(data:$data, text:$text, citation:$citation){ id, order, itemType, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } } 
        }`;

    return executeGraphqlCall<OutlineItem[]>('createOutlineTextItem', body, undefined, variables);
};

export const createOutlineCitationItem = async (outlinePageId: string): Promise<OutlineItem[]> => {
    const body = `mutation {
    createOutlineCitationItem(outlinePageId: "${outlinePageId}") {id, itemType, order, properties}
    }`;

    return executeGraphqlCall<OutlineItem[]>('createOutlineCitationItem', body);
};

export const updateTextItem = async (
    text: Descendant[],
    citation: string,
    itemId: string,
    speakerId: string
): Promise<OutlineItem> => {
    const variables = {
        text,
        citation: citation || '',
    };
    const body = `mutation updateOutlineTextItem($text: [OutlineItemTextPropertyInput!]!, $citation:String!){ 
        updateOutlineTextItem(outlineItemId: "${itemId}", citation: $citation, text: $text,speakerId:"${speakerId}",list:false){ id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } } 
    }`;

    return executeGraphqlCall<OutlineItem>('updateOutlineTextItem', body, undefined, variables);
};

export const updateListItem = async (
    text: Descendant[],
    citation: string,
    itemId: string,
    speakerId: string
): Promise<OutlineItem> => {
    const variables = {
        text,
        citation: citation || '',
    };
    const body = `mutation updateOutlineTextItem($text: [OutlineItemTextPropertyInput!]!, $citation:String!){ 
        updateOutlineTextItem(outlineItemId: "${itemId}", citation: $citation, text: $text,speakerId:"${speakerId}",list:true){ id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } } 
    }`;

    return executeGraphqlCall<OutlineItem>('updateOutlineTextItem', body, undefined, variables);
};

export const createImageItem = async (outlinePageId: string, speakerId: string): Promise<OutlineItem[]> => {
    const variables = { data: { speakerId, outlinePageId } };
    const body = `mutation($data: CreateImageOutlineItem!){
    createOutlineImageItem(data: $data) {id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, }}
    }`;

    return executeGraphqlCall<OutlineItem[]>('createOutlineImageItem', body, undefined, variables);
};

export const updateImageItem = async (
    image_key: string | null,
    citation: string,
    originalImageKey: string | null,
    itemId: string,
    speakerId: string,
    width: number | null = null,
    height: number | null = null
): Promise<OutlineItem> => {
    originalImageKey = originalImageKey || '';
    image_key = image_key || '';
    const variables = {
        citation: citation || '',
    };
    const body = `mutation updateOutlineImageItem($citation: String!) {
        updateOutlineImageItem(
          outlineItemId: "${itemId}", citation: $citation, originalImageKey:"${originalImageKey}", imageKey: "${image_key}",speakerId:"${speakerId}",width:${width},height:${height}) {
          id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, }
        }
    }`;

    return executeGraphqlCall<OutlineItem>('updateOutlineImageItem', body, undefined, variables);
};

export const deleteItem = async (
    itemId: string,
    speakerId: string,
    pageId?: string,
    imageKey?: string
): Promise<OutlineItem[]> => {
    const body = `mutation {
        deleteOutlineItem(outlineItemId: "${itemId}",speakerId:"${speakerId}", pageId:"${pageId}", imageKey:"${imageKey}") { id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, } }
    }`;

    return executeGraphqlCall<OutlineItem[]>('deleteOutlineItem', body);
};

export const createOutlinePage = async (projectId: string, title?: string): Promise<Outline> => {
    const body = `mutation {
        createOutlinePage(projectId: "${projectId}",title: "${title || ''}")
        {
            id,
            pages { id, title, number, items{ id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, }}}
        }
    }`;

    return executeGraphqlCall<Outline>('createOutlinePage', body);
};

export const updateOutlinePageTitle = async (
    title: string,
    outlinePageId: string,
    speakerId: string
): Promise<string> => {
    const body = `mutation {
        updateOutlinePageTitle(pageId: "${outlinePageId}", title: "${title}",speakerId:"${speakerId}")
    }`;

    return executeGraphqlCall<string>('updateOutlinePageTitle', body);
};

export const deleteOutlinePage = async (outlinePageId: string, speakerId: string): Promise<Outline> => {
    const body = `mutation {
        deleteOutlinePage(outlinePageId: "${outlinePageId}", speakerId: "${speakerId}")
        {
            id,
            pages { id, title, number, items{ id, itemType, order, properties{ text{ type, text, bold, italic, underline, numberedList, bulletedList, children{ text, bold, italic, underline, numberedList, bulletedList, type, children{ text, bold, italic, underline, numberedList, bulletedList, } } } citation, imageKey, originalImageKey, }}}
        }
    }`;

    return executeGraphqlCall<Outline>('deleteOutlinePage', body);
};
