import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        settingContainer: {
            marginTop: 82,
            marginLeft: 52,
        },
        heading: {
            fontWeight: 500,
            fontSize: '20px',
        },
        settingContent: {
            width: '441px',
            marginTop: 18,
            padding: 33,
            background: colors.white,
            borderRadius: '8px',
            boxShadow: `0px 0px 5px ${colors.boxShadow}`,
        },
        avatarDefault: {
            fontSize: '18px',
            padding: '27px',
            margin: 'auto',
            background: colors.avatarUsername,
        },
        accSettingForm: {
            marginTop: 12,
        },
        button: {
            width: '210px',
            fontWeight: 'normal',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '24px',
        },
        delAccText: {
            marginTop: 20,
            width: '80%',
            color: colors.smoke,
        },
        delAccPaper: {
            display: 'flex',
            marginTop: '16px',
            boxShadow: `0px 0px 5px ${colors.boxShadow}`,
        },
        delAccButton: {
            margin: '16px auto 14px auto',
            '& .MuiButton-label': {
                textTransform: 'capitalize',
                fontSize: '16px',
                color: '#EA2027',
            },
        },
    })
);
