import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        navbar: {
            zIndex: 1300,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
        },
        libraryPageMainContent: {
            display: 'flex',
            flex: 1,
            height: '100%',
        },
        content: {
            flexGrow: 1,
            backgroundColor: colors.grayBackground,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        searchContainer: {
            height: 60,
            display: 'flex',
            padding: 10,
            alignItems: 'flex-end',
        },
        searchIconContainer: {
            marginRight: 10,
            color: colors.elephant,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        searchIcon: {
            fontSize: 16,
        },
        searchText: {
            color: colors.elephant,
            fontWeight: 400,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
        },
        headingContainer: {
            display: 'flex',
            padding: 15,
        },
        headingText: {
            flex: 1,
            color: colors.text,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 900,
        },
        addIcon: {
            fontSize: 19,
            color: colors.darkTeal,
        },
        contentConatiner: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: '#000000',
        },

        popover: {
            width: '100%',
            height: '100%',
            paddingRight: 15,
        },
        accountSettings: {
            padding: 15,
            cursor: 'pointer',
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        avatar: {
            height: 20,
            width: 20,
            backgroundColor: '#F3F3F3',
            color: colors.text,
            fontSize: 13,
        },
        countContainer: {
            borderRadius: 10,
            backgroundColor: '#F3F3F3',
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 13,
            width: 20,
            height: 20,
        },
        textBold: {
            fontWeight: 'bold',
        },
        spinner: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);
