import React, { useState, useEffect } from 'react';
import { Paper, Popper, CircularProgress } from '@material-ui/core';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import { layoutMenuStyles } from './style';
import { alertTypes, LAYOUT_MENUS } from '../../../../../../constants/index';
import { Slide as SlideIntreface } from '../../../../../../types';
import { getLayouts } from '../../../../../../backend/layout';
import { Layout } from '../../../../../../types';
import { LayoutIcon, layoutOptionsMapping } from '../../../../../../assets/icons/layoutIcons';
import { updatePresentationSlide } from '../../../../../../backend/slide';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, updateSlide } from '../../../../../../redux/reducers';
import { RootState } from '../../../../../../redux/combineReducers';

export const LayoutMenu = ({
    layoutMenuAnchor,
    slide,
    close,
}: {
    layoutMenuAnchor: null | HTMLElement;
    close: () => void;
    slide: SlideIntreface;
}) => {
    const classes = layoutMenuStyles();
    const {
        outlinePage: { items: outlineItems },
        layout: { id: layoutId, layoutType },
    } = slide;

    const [selectedTab, setSelectedTab] = useState<string>(layoutType);
    const [selectedLayout, setSelectedLayout] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [layoutList, setLayoutList] = useState<Layout[]>([]);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const { presentation } = useSelector((state: RootState) => state.ProjectPresentation);

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedLayout(layoutId);
    }, [layoutId, setSelectedLayout]);

    // update the slide layout
    const handleSelectedLayout = async (value: string) => {
        setSelectedLayout(value);
        try {
            const updatedSlide = await updatePresentationSlide(slide.id, value, speakerId as string);
            dispatch(updateSlide({ slide: updatedSlide }));
            close();
        } catch (error) {
            console.log('Error in updating slide is: ', error);
            if (error instanceof Error) alert(error.message || 'Error in updating slide');
        }
    };

    useEffect(() => {
        let mediaItems = 0;
        let textItems = 0;

        outlineItems.forEach(({ itemType }) => {
            if (itemType === 'text' || itemType === 'list') {
                textItems = textItems + 1;
            } else if (itemType === 'image') {
                mediaItems = mediaItems + 1;
            }
        });

        (async () => {
            try {
                setLayoutList([]);
                setLoading(true);
                const layouts = await getLayouts(textItems, mediaItems, selectedTab, presentation!.themeId);
                const filteredLayouts = layouts.filter((item) => layoutOptionsMapping[item.name]);
                setLayoutList(filteredLayouts);
                setLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    dispatch(
                        showAlert({
                            message: error.message,
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );
                }
                setLoading(false);
            }
        })();
    }, [selectedTab, outlineItems]);

    return (
        <Popper
            anchorEl={layoutMenuAnchor}
            open={Boolean(layoutMenuAnchor)}
            role={undefined}
            transition
            disablePortal
            placement={'left-start'}
            className={classes.popper}
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={close}>
                            <div>
                                <div className={classes.tabs}>
                                    {LAYOUT_MENUS.map(({ id, name }) => (
                                        <div
                                            key={id}
                                            role={'presentation'}
                                            onClick={() => setSelectedTab(id)}
                                            className={selectedTab === id ? classes.selectedTab : classes.tab}
                                        >
                                            {name}
                                        </div>
                                    ))}
                                </div>
                                <div className={classes.layoutMenus}>
                                    {loading ? (
                                        <div className={classes.loader}>
                                            <CircularProgress size={15} className={classes.loaderSpinner} />
                                        </div>
                                    ) : (
                                        layoutList.map(({ id, name }) => {
                                            return (
                                                <div key={id} className={classes.layoutIconContainer}>
                                                    <LayoutIcon
                                                        key={id}
                                                        id={id}
                                                        name={name}
                                                        selected={selectedLayout === id}
                                                        onClickHandler={handleSelectedLayout}
                                                    />
                                                </div>
                                            );
                                        })
                                    )}

                                    {!loading && layoutList.length === 0 && (
                                        <div className={classes.emptyContainer}>No layout option available</div>
                                    )}
                                </div>
                            </div>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};
