/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:98d23eb3-07c4-4512-8374-8f1693a97f42",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zd3r5p70x",
    "aws_user_pools_web_client_id": "1a3kusvl7rhmger0p9hhrobte0",
    "oauth": {
        "domain": "nimdone-prod.auth.us-east-1.amazoncognito.com"
    },
    "aws_user_files_s3_bucket": "nimdone-user-files-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;