import { useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/combineReducers';
import {
    showAddProject,
    showSavePaper,
    showAlert,
    setCreateProjectWithPaper,
    deletePaperFileProjectDoi,
    addPaperFileProjectDoi,
    savingPaperReferences,
} from '../../../../../redux/reducers';

import { removePaperFileProjectById } from '../../../../../backend/paperFileProject';
import { style } from './style';
import { alertTypes } from '../../../../../constants';
import { colors } from '../../../../../assets/colors';
import { PaperJobResType, PaperSearchResponse, PaperSource } from '../../../../../types';
import { getFreePaperByJobId, getFreePaperWithProjectID } from '../../../../../backend/paperFileProject';
import { ReaderViewModalWrapper } from '../../readerViewModalWrapper';

interface SavePaperProps {
    saveReferenceModal: boolean;
    selectedPaper: PaperSearchResponse;
    closeModal: () => void;
    fetchingPaperFileProject: boolean;
}
export const SavePaper = ({
    saveReferenceModal,
    selectedPaper,
    closeModal,
    fetchingPaperFileProject,
}: SavePaperProps) => {
    const [savingProject, setSavingProject] = useState<boolean>(false);
    const [disableCheckBoxes, setDisableCheckBoxes] = useState<boolean>(false);
    const Projects = useSelector((state: RootState) => state.Projects);
    const classes = style();
    const dispatch = useDispatch();
    const paperFilesProjectList = useSelector(
        (state: RootState) => state.paperFileProjectDoi[selectedPaper?.doi ? selectedPaper.doi : '']
    );
    const speakerId = useSelector((state: RootState) => state.Speaker.id);
    const [projectAdded, setProjectAdded] = useState(false);
    const addProjectModal = useSelector((state: RootState) => state.ModalHandler.addProjectModal);
    const doi = useSelector((state: RootState) => state.PaperFile.paper?.doi);
    const paperFileProjectsByDoi = useSelector((state: RootState) => state.paperFileProjectDoi);

    useEffect(() => {
        (async () => {
            if (!addProjectModal && projectAdded) {
                await handleChangeSelection(false, Projects[0].id);
                closeModal();
            }
        })();
    }, [addProjectModal]);

    const createNewProjectModal = () => {
        dispatch(showSavePaper({ savePaperModal: false }));
        dispatch(setCreateProjectWithPaper({ isCreateProjectWithPaper: false }));
        dispatch(showAddProject({ addProjectModal: true }));
        setProjectAdded(true);
    };

    const handleChangeSelection = async (checked: boolean, projectId: string) => {
        setDisableCheckBoxes(true);
        try {
            setSavingProject(true);

            if (checked) {
                const paperFileProject = paperFileProjectsByDoi[selectedPaper.doi].find(
                    (item) => item.projectId === projectId
                );

                await removePaperFileProjectById(paperFileProject?.id as string);
                dispatch(deletePaperFileProjectDoi({ doi: selectedPaper.doi, projectId }));
                setSavingProject(false);
            } else {
                let paper = {
                    ...selectedPaper,
                    source: PaperSource.UNPAYWALL,
                };
                delete paper.referenceExist;
                delete paper.saving;

                dispatch(savingPaperReferences({ parentDoi: doi as string, childDoi: selectedPaper.doi }));
                await addPaper(paper, speakerId ? speakerId : '', projectId);

                dispatch(savingPaperReferences({ parentDoi: doi as string, childDoi: selectedPaper.doi }));
            }
        } catch (error) {
            setSavingProject(false);
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
        setDisableCheckBoxes(false);
    };

    // add paper to project
    const addPaper = async (paperSearchResponse: PaperSearchResponse, SpeakerId: string, projectId: string) => {
        try {
            // get status of paper
            const response = await getPaper(paperSearchResponse, SpeakerId, projectId);
            if (response.status === 'completed') {
                dispatch(
                    addPaperFileProjectDoi({
                        doi: selectedPaper.doi,
                        projectId,
                        paperFileProjectId: response.paperFileProjectId as string,
                    })
                );
                setSavingProject(false);
            } else {
                setSavingProject(false);
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: 'Unable to save the paper. Please try again',
                        type: alertTypes.error,
                    })
                );
            }
        } catch (err) {
            setSavingProject(false);
            dispatch(
                showAlert({
                    isVisible: true,
                    message: 'Unable to save the paper. Please try again',
                    type: alertTypes.error,
                })
            );
        }
    };

    //  Check the status of paper
    // - if saved return true
    // - else not saved, save it and return true
    const getPaper = async (
        paperSearchResponse: PaperSearchResponse,
        SpeakerId: string,
        projectId: string
    ): Promise<PaperJobResType> => {
        try {
            const response = await getFreePaperWithProjectID(paperSearchResponse, SpeakerId, projectId);
            if (response.status === 'completed') {
                return response;
            } else {
                return await pollPaper(
                    paperSearchResponse,
                    response.jobId as string,
                    response.keyToPaperInStorage as string
                );
            }
        } catch (err) {
            throw err;
        }
    };
    // check if the paper downloading is complete and get the paper data
    const pollPaper = async (
        paperSearchResponse: PaperSearchResponse,
        jobId: string,
        keyToPaperInStorage: string
    ): Promise<PaperJobResType> => {
        return new Promise((resolve, reject) => {
            const timer = setInterval(async () => {
                try {
                    const jobResponse = await getFreePaperByJobId(paperSearchResponse, jobId, keyToPaperInStorage);
                    if (jobResponse.status === 'completed') {
                        clearInterval(timer);
                        resolve(jobResponse);
                    } else if (jobResponse.status === 'error') {
                        clearInterval(timer);
                        resolve(jobResponse);
                    }
                } catch (err) {
                    clearInterval(timer);
                    reject(false);
                    throw err;
                }
            }, 1000);
        });
    };

    const renderProjects = (
        <div className={classes.renderProjectsContainer}>
            {Projects.length === 0 && <p className={classes.emptyProjectText}>You do not have any project</p>}
            {Projects.map((project) => (
                <div key={project.id} className={classes.projectRow}>
                    <Checkbox
                        onChange={(event) => handleChangeSelection(!event.target.checked, project.id)}
                        checked={
                            !!(
                                paperFilesProjectList &&
                                paperFilesProjectList.find((item) => item.projectId === project.id)
                            )
                        }
                        size="small"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        disabled={disableCheckBoxes || fetchingPaperFileProject}
                    />
                    <p
                        className={classes.projectTitle}
                        style={{ color: disableCheckBoxes || fetchingPaperFileProject ? colors.grayText : 'inherit' }}
                    >
                        {project.title}
                    </p>
                </div>
            ))}
        </div>
    );

    return (
        <ReaderViewModalWrapper
            onClose={closeModal}
            close={closeModal}
            open={saveReferenceModal}
            loader={savingProject}
            title="Save PDF to..."
            handleCreate={createNewProjectModal}
            createBtnLabel="Create new project..."
        >
            {renderProjects}
        </ReaderViewModalWrapper>
    );
};
