import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            alignContent: 'center',
            padding: 10,
        },
        formContainer: {
            width: 350,
        },
        headline: {
            display: 'block',
            fontSize: '16px',
            color: colors.smoke,
            marginBottom: '24px',
        },
        button: {
            marginTop: 12,
            fontWeight: 'normal',
        },
        input: {
            marginBottom: 12,
        },
        resetPassSuccess: {
            textAlign: 'center',
            display: 'block',
            fontSize: '16px',
            color: colors.smoke,
            marginBottom: '24px',
        },
        loginLinkText: {
            textAlign: 'center',
            color: colors.linkBlue,
            display: 'block',
            cursor: 'pointer',
        },
        logo: {
            width: 204,
            height: 210,
        },
    })
);
