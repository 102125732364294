import React, { useCallback, useMemo } from 'react';
import { FormatItalic, FormatBold, FormatUnderlined } from '@material-ui/icons';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { CustomElement } from './components/customElement';
import { Leaf } from './components/leaf';
import { TextEditorToggleButton } from './components/toggleButton';
import { useTextEditorStyles } from './style';
import { TextEditorPropsType } from './types';
import { HOTKEYS, toggleMark } from './components/common';
import isHotkey from 'is-hotkey';

export const OutlineTextEditor: React.FC<TextEditorPropsType> = ({ textChange, value }) => {
    const classes = useTextEditorStyles();
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const renderElement = useCallback((props) => <CustomElement {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    return (
        <div className={classes.root}>
            <Slate editor={editor} value={value} onChange={(newValue) => textChange(newValue)}>
                <div className={classes.toolbar}>
                    <TextEditorToggleButton Icon={FormatBold} textFormat="bold" />
                    <TextEditorToggleButton Icon={FormatItalic} textFormat="italic" />
                    <TextEditorToggleButton Icon={FormatUnderlined} textFormat="underline" />
                </div>
                <Editable
                    placeholder="Start typing here..."
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    spellCheck
                    autoFocus
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                                event.preventDefault();
                                const mark = HOTKEYS[hotkey];
                                toggleMark(editor, mark);
                            }
                        }
                    }}
                />
            </Slate>
        </div>
    );
};
