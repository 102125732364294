import React from 'react';
import { style } from './style';
import Select, { components, InputActionMeta } from 'react-select';
import { colors } from '../../../assets/colors';
import './style.scss';
import { ErrorIcon } from '../../../assets/icons/error';

type option = {
    value: any;
    label: any;
};

type DropdownProps = {
    onChange: Function;
    value?: any;
    placeholder?: string;
    label?: string;
    name?: string;
    id?: any;
    containerClassName?: string;
    type?: string;
    error?: string;
    options: Array<option>;
    onInputChange?: (name: string, actionMeta: InputActionMeta) => void;
    isFilterOptions?: boolean;
    disable?: boolean;
};

export const Dropdown = ({
    onChange,
    value,
    placeholder,
    label,
    name,
    id,
    containerClassName,
    error,
    options,
    onInputChange,
    isFilterOptions,
    disable,
}: DropdownProps) => {
    const classes = style();

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: colors.smoke,
            width: '100%',
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'row',
            marginRight: '3px',
        }),
        singleValue: (provided: any, state: any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
                color: colors.smoke,
            };
        },
        menuList: (provided: any) => ({
            ...provided,
            textAlign: 'left',
            overflowX: 'hidden',
        }),
        menu: (provided: any) => ({
            ...provided,
            fontSize: 16,
            fontFamily: 'Roboto',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            zIndex: 2,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            fontSize: '1em',
            fontWeight: 'normal',
        }),
        container: (provided: any) => ({
            ...provided,
            border: `1px groove ${colors.smoke}`,
        }),
    };

    const customOptions = [...options];

    const InputChangeFunc = (name: string, actionMeta: InputActionMeta) => {
        if (onInputChange) onInputChange(name, actionMeta);
        if (document.getElementsByClassName('MyDropdown__menu-list')[0])
            document.getElementsByClassName('MyDropdown__menu-list')[0].scrollTop = 0;
    };

    return (
        <div className={containerClassName ? `${classes.container} ${containerClassName}` : classes.container}>
            <h2 className={classes.label}>{label}</h2>
            <Select
                id={id}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onChange(ev)}
                placeholder={placeholder}
                options={customOptions}
                components={{ Placeholder, Input }}
                name={name}
                defaultValue={value}
                styles={customStyles}
                onInputChange={(name, actionMeta) => InputChangeFunc(name, actionMeta)}
                filterOption={isFilterOptions ? () => true : undefined}
                isDisabled={disable}
                className={'MyDropdown'}
                classNamePrefix={'MyDropdown'}
                isSearchable
            />

            {!!error && (
                <div className={classes.errorContainer}>
                    <ErrorIcon className={classes.errorImage} />
                    <p className={classes.errorText}> {error} </p>
                </div>
            )}
        </div>
    );
};

const Placeholder = (props: any) => <components.Placeholder {...props} />;

const Input = ({ autoComplete, ...props }: any) => <components.Input {...props} autoComplete="new-password" />;
