import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            flex: 1,
            padding: 25,
        },
        projectTitle: {
            color: '#37352',
            fontSize: 17,
            fontWeight: 600,
            marginRight: 10,
        },
        timeContainer: {
            color: colors.gray,
        },
        mainContainer: {
            marginTop: 20,
        },
        button: {
            color: colors.darkTeal,
            border: `1px solid ${colors.darkTeal}`,
            backgroundColor: 'white',
            width: '90%',
            height: 32,
            borderRadius: 6,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
        },
        buttonLabel: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
        },
        buttonContainer: {
            marginTop: 40,
        },
        paperCount: {
            color: colors.stone,
            fontWeight: 900,
        },
        articlesCount: {
            fontWeight: 400,
            fontSize: 14,
        },
        viewInReader: {
            color: colors.darkTeal,
            fontWeight: 400,
            fontSize: 14,
            borderBottom: `1px solid ${colors.darkTeal}`,
            cursor: 'pointer',
        },
    })
);
