import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
            border: 'none',
        },
        contentContainer: {
            display: 'flex',
            padding: 20,
            backgroundColor: colors.grayBackground,
            outline: 'none',
            border: 'none',
            flexDirection: 'column',
            maxWidth: '80vw !important',
            maxHeight: '80vh !important',
            overflow: 'hidden',
        },
        canvasWrapper: {
            overflow: 'auto',
        },
        btnContainer: {
            width: '100%',
            display: 'flex',
            columnGap: 10,
            marginTop: 15,
        },
        saveBtn: {
            fontWeight: 'normal',
        },
    })
);
