import { Theme } from '../types';
import { executeGraphqlCall } from './api';

export const getAllThemes = async (): Promise<Theme[]> => {
    const body = `query GetAllThemes {
        getAllThemes {
          id
          name
          order
        }
      }`;
    return executeGraphqlCall<Theme[]>('getAllThemes', body);
};
