import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { colors } from '../colors';
export interface Props {
    width?: number;
    height?: number;
}

export const TextListIcon = ({ height = 34, width = 34 }: Props) => {
    return (
        <div
            style={{
                width,
                height,
                border: '1px solid #C4C4C4',
                borderRadius: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <FormatListBulletedIcon style={{ color: colors.elephant }} />
        </div>
    );
};
