import { makeStyles } from '@material-ui/core';

export const layoutMenuStyles = makeStyles({
    popper: {
        zIndex: 100,
    },
    paper: {
        width: 168,
        minHeight: 100,
        overflow: 'hidden',
        boxShadow: '0px 20px 28px rgba(0, 0, 0, 0.25)',
        borderRadius: 0,
    },
    tabs: {
        height: 35,
        borderBottom: '1px solid lightgray',
        display: 'flex',
    },
    tab: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        '&:active': {
            color: 'gray',
        },
    },
    selectedTab: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        fontWeight: 'bold',
        '&:active': {
            color: 'gray',
        },
    },
    layoutIconContainer: {
        padding: 3,
        float: 'left',
    },
    layoutMenus: {
        flex: 1,
        maxHeight: 180,
        padding: 3,
        overflow: 'auto',
    },
    loaderSpinner: {
        color: 'gray',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingTop: 20,
    },
    emptyContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
        display: 'flex',
        color: 'gray',
        fontSize: 10,
    },
});
