import { createStyles, makeStyles } from '@material-ui/core/styles';

export const style = makeStyles(() =>
    createStyles({
        loaderContainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: 'gray',
            flexDirection: 'column',
            backgroundColor:'white',
            paddingTop:10
        },
    })
);
