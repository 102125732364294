import { style } from './style';

interface ToggleSwitchProps {
    selected: boolean;
    setSelected: (value: boolean) => void;
}

export const ToggleSwitch = ({ selected, setSelected }: ToggleSwitchProps) => {
    const classes = style();

    const toggleSelected = () => {
        setSelected(!selected);
    };

    return (
        <div
            className={`${classes.toggleContainer} ${!selected && classes.disabled}`}
            onClick={toggleSelected}
            data-testid="toggleButton"
        >
            <div className={`${classes.text} ${!selected && classes.disabledText}`}>{selected ? 'On' : 'Off'}</div>
            <div className={`${classes.toggleButton} ${selected ? classes.toggleOn : classes.disabledButton}`}></div>
        </div>
    );
};
