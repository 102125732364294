export default {
    test: {
        key: 'ci_value',
    },
    api: {
        baseUrl: 'https://w14x2stu0i.execute-api.us-east-1.amazonaws.com/ci/graphql',
    },
    gtm: {
        id: 'GTM-KP839RF',
        auth: 'ycJEZdt82gLFpnrvR1w7wA',
        preview: 'env-3',
    },
    cloudFront: {
        url: 'https://files-ci.nimdone.com',
    },
    featureFlags: {
        pricing: true,
        freeTrial: true,
        allPaper: false,
        paperReferences: true,
        onboarding: true,
        signupPage: true, // Show signup page even if user is loggedIn
        proxyUrl: true,
        pptxTheme: true,
    },
    stripe: {
        freeTrialDays: 14,
    },
    extension: {
        downloadURL: 'https://chrome.google.com/webstore/detail/nimdone-ci/immhgpmhodapocbimjejdnmidifebild',
    },
};
