import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
        },
        contentContainer: {
            width: 630,
            height: 375,
            backgroundColor: 'white',
            borderRadius: 8,
            outline: 'none',
            display: 'flex',
        },
        logoContainer: {
            width: 240,
            backgroundColor: '#DEEBFF',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        logo: {
            width: 204,
            height: 210,
        },
        content: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 22,
            flexDirection: 'column',
        },
        freeTrialEndedMessage: {
            fontSize: 22,
            color: 'black',
            letterSpacing: '-0.02em',
            fontWeight: 550,
        },
        thankyouMessage: {
            padding: '25px 0px',
        },
        bold: {
            fontWeight: 500,
        },
        subscribeButton: {
            width: 170,
            marginTop: 15,
            color: colors.white,
            borderColor: colors.primaryButton,
            backgroundColor: colors.primaryButton,
            borderRadius: '8px',
            fontSize:18,
            textTransform:'none',
            '&:hover': {
                backgroundColor: colors.primaryButton,
                borderColor: colors.primaryButton,
            },
            '&:focus': {
                border: `1px solid ${colors.primaryButton}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.primaryButton,
            },
        },
    })
);
