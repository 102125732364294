import { executeGraphqlCall } from './api';
import { Presentation, Slide } from '../types';

export const getPresentation = async (projectId: string): Promise<Presentation> => {
    const body = `{
        presentationByProject(projectId: "${projectId}")
        {
            id,
            themeId,
            slides { id, number, markup, layout{ id, name, layoutType},outlinePage{id,number,items{itemType}}}
        }
    }`;

    return executeGraphqlCall<Presentation>('presentationByProject', body);
};

export const updatePresentationSlide = async (id: string, layoutId: string, speakerId: string): Promise<Slide> => {
    const body = `mutation {
        updateSlide(
      data:{
        id:"${id}", layoutId:"${layoutId}",speakerId:"${speakerId}"}){
            id, number, markup, layout{ id, name, layoutType},outlinePage{id,number,items{itemType}}
      }}`;
    return executeGraphqlCall<Slide>('updateSlide', body);
};

export const getSlide = async (outlinePageId: string): Promise<Slide> => {
    const body = `{
        slide(outlinePageId: "${outlinePageId}")
        {
          id, number, markup, layout{ id, name, layoutType, snippet},outlinePage{id,number,items{itemType}}
        }
    }`;

    return executeGraphqlCall<Slide>('slide', body);
};
