import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        navbar: {
            zIndex: 1300,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
        },
        settingsPageMainContent: {
            display: 'flex',
            flex: 1,
            height: '100%',
        },
        accountSettings: {
            padding: 15,
            cursor: 'pointer',
        },
    })
);
