import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import { OutlineModes, OutlineItemProperties, OutlineItemType, TEXT_OPTIONS } from '../../../../../../types';
import { useOutlineTextItemStyles } from './styles';
import { updateListItem } from '../../../../../../backend/outline';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSavingOutlineItem, showAlert } from '../../../../../../redux/reducers';
import { alertTypes, gtm } from '../../../../../../constants';
import { RootState } from '../../../../../../redux/combineReducers';
import { CitationComp, OutlineItemWrapper, TextOptions } from '../../components';
import { updateOutlineItem, enableHighlightingText } from '../../../../../../redux/reducers/projectOutline';
import { OutlineTextEditor } from './textEditor';
import { Descendant } from 'slate';
import { isEqual } from 'lodash';
import { emitCustomEvent } from 'react-custom-events';
import { GTMService } from '../../../../../../services/gtmService';
import { formatHighlightedText } from '../../../../../../utils';

export interface OutlineTextItemProps {
    itemId: string;
    pageId: string;
    properties: OutlineItemProperties;
}

export const OutlineListItem = ({ itemId, properties, pageId }: OutlineTextItemProps) => {
    const classes = useOutlineTextItemStyles();
    const [text, setText] = useState<Descendant[]>([
        {
            type: 'paragraph',
            children: [
                {
                    text: '',
                    bold: false,
                    italic: false,
                    underline: false,
                    numberedList: false,
                    bulletedList: false,
                },
            ],
        },
    ]);
    const [itemUpdated, setItemUpdated] = useState<boolean>(false);
    const [citation, setCitation] = useState<string>('');
    const [textOption, setTextOption] = useState<TEXT_OPTIONS>(TEXT_OPTIONS.TYPE_TEXT);
    const [isTextOptionVisible, setIsTextOptionVisible] = useState<boolean>(true);
    const { mode } = useSelector((state: RootState) => state.ProjectOutline);
    const { citation: paperCitation } = useSelector((state: RootState) => state.PaperFile);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const dispatch = useDispatch();

    const emitOutlinePageUpdatedEvent = () => {
        emitCustomEvent('outline-page-updated', {
            outlinePageId: pageId,
        });
    };

    const itemLostFocus = async (
        updated: boolean | undefined = false,
        citationText: string | undefined = '',
        highlightedTextItem: Descendant[] | null = null
    ) => {
        if (itemUpdated || updated) {
            try {
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, itemId }));
                await updateListItem(
                    highlightedTextItem || text,
                    citation || citationText,
                    itemId,
                    speakerId as string
                );
                emitOutlinePageUpdatedEvent();
                setCitation(citationText && citationText !== '' ? citationText : citation);
                const updateItemProperties = {
                    text: highlightedTextItem || text,
                    citation: citationText && citationText !== '' ? citationText : citation,
                };
                dispatch(
                    updateOutlineItem({
                        pageId,
                        itemId,
                        properties: updateItemProperties,
                    })
                );
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
                setItemUpdated(false);
                await triggerGTMEvent();
            } catch (error) {
                if (error instanceof Error)
                    dispatch(
                        showAlert({
                            isVisible: true,
                            type: alertTypes.error,
                            message: error.message,
                        })
                    );
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
            }
        }
    };

    const triggerGTMEvent = async () =>
        await new GTMService().handleEvent({
            event: textOption === TEXT_OPTIONS.TYPE_TEXT ? gtm.events.TYPE_TEXT : gtm.events.HIGHLIGHT_FROM_PDF,
            userId: speakerId,
        });

    const textChanged = (value: Descendant[]) => {
        if (isEqual(value, text) === false) {
            setText(value);
            setItemUpdated(true);
        }
    };

    useEffect(() => {
        const outlineItemProperties: OutlineItemProperties = properties;

        if (outlineItemProperties.text) {
            setText(outlineItemProperties.text);
        }
        setCitation(
            outlineItemProperties.citation && outlineItemProperties.citation !== 'null'
                ? outlineItemProperties.citation
                : ''
        );
    }, []);

    const setOption = (value: TEXT_OPTIONS) => {
        setIsTextOptionVisible(false);
        setTextOption(value);
    };

    const addHighlightedText = () => {
        dispatch(enableHighlightingText({ enableHighlight: false }));
        changePdfCursor('default');
        let highlightedText = '';
        const selection = window.getSelection();
        if (selection && selection.toString() !== '') {
            highlightedText = formatHighlightedText(selection);
        }
        if (highlightedText !== '') {
            const highlightedTextItem: Descendant[] = [
                {
                    type: 'paragraph',
                    children: [
                        {
                            text: highlightedText,
                            bold: false,
                            italic: false,
                            underline: false,
                            numberedList: false,
                            bulletedList: false,
                        },
                    ],
                },
            ];
            setText(highlightedTextItem);
            setOption(TEXT_OPTIONS.TYPE_TEXT);
            setCitation(paperCitation ? paperCitation : '');
            itemLostFocus(true, paperCitation || '', highlightedTextItem);
        }
    };

    useEffect(() => {
        if (!properties.text && properties.citation) setIsTextOptionVisible(true);
    }, [citation, text]);

    const handleHighlightFromPDF = async () => {
        dispatch(enableHighlightingText({ enableHighlight: true }));
        setOption(TEXT_OPTIONS.HIGHLIGHT);
        changePdfCursor('text');
    };

    const changePdfCursor = (cursor: string) => {
        const pdWrapper = document.getElementById('pdf-wrapper');
        if (pdWrapper) pdWrapper.style.cursor = cursor;
    };

    const handleTypeText = async () => {
        setOption(TEXT_OPTIONS.TYPE_TEXT);
    };
    return (
        <OutlineItemWrapper
            pageId={pageId}
            itemId={itemId}
            itemLostFocus={itemLostFocus}
            itemType={OutlineItemType.LIST}
        >
            <>
                {mode === OutlineModes.READER && isTextOptionVisible && !properties.text && !properties.citation ? (
                    <TextOptions handleHighlightFromPDF={handleHighlightFromPDF} handleTypeText={handleTypeText} />
                ) : textOption === TEXT_OPTIONS.TYPE_TEXT ? (
                    <Box className={classes.textContainer}>
                        <OutlineTextEditor value={text} textChange={textChanged} />
                        <CitationComp setItemUpdated={setItemUpdated} citation={citation} setCitation={setCitation} />
                    </Box>
                ) : (
                    <Box className={classes.textOptionContainer}>
                        <button
                            role="presentation"
                            onClick={addHighlightedText}
                            className={`${classes.textOption} ${classes.transparentButton}`}
                        >
                            Add highlighted text to outline
                        </button>
                    </Box>
                )}
            </>
        </OutlineItemWrapper>
    );
};
