import { executeGraphqlCall } from './api';
import { Institution, getMatchedInstitutionResponse } from '../types';
import { API_TYPE } from '../constants';

export const getAllInstitutions = async (): Promise<Institution[]> => {
    const body = `{
  getAllInstitutions{
    id
    name   
  }
}`;
    return executeGraphqlCall<Institution[]>('getAllInstitutions', body);
};

export const getMatchedInstitution = async (
    name: string,
    offset: number,
    country: string
): Promise<getMatchedInstitutionResponse[]> => {
    const variables = { data: { name, offset, country } };
    const body = `query GetMatchedInstitution($data: getMatchedInstitutionInput!) {
      getMatchedInstitution(data: $data) {
        label
        value
      }
    }`;
    return executeGraphqlCall<getMatchedInstitutionResponse[]>('getMatchedInstitution', body, API_TYPE.OPEN, variables);
};
