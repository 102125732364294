import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertTypes } from '../../../../../constants';
import { InformationModal as InformationModalType, PaperFile, SpeakerPreferences } from '../../../../../types';
import { style } from './style';
import { UploadPdfIcon, OpenInNewIcon } from '../../../../../assets/icons';
import { colors } from '../../../../../assets/colors';
import { RootState } from '../../../../../redux/combineReducers';
import { InputModal } from '../../../modals/inputModal';
import { ProxyModalHelpContent } from './proxyModalInfo';
import { journalSite } from '../../../../../constants';
import { updateProxyUrl } from '../../../../../backend/speakerPreferences';
import { updateSpeakerPreference } from '../../../../../redux/reducers';
import { matchProxyUrlFormat } from '../../../../../utils';
import { InformationModal } from '../../../modals/informationModal';
import { FullTextModal } from './fullTextModal';
import mergedConfigs from '../../../../../config';

interface ClosedAccessInfoProps {
    handleOpenUploadModal: (event: any) => void;
    paperFile: PaperFile;
}

const redirectToJournalSite = (speakerPreferences: SpeakerPreferences, paperFile: PaperFile) => {
    const newUrl = mergedConfigs.featureFlags.proxyUrl ? speakerPreferences?.proxyUrl?.slice(0, -2) : '';
    if (paperFile.paper?.doi) window.open(!!newUrl ? newUrl : '' + journalSite(paperFile.paper?.doi), '_blank');
};

const ClosedAccessInfoComp = ({ handleOpenUploadModal, paperFile }: ClosedAccessInfoProps) => {
    const classes = style();
    const dispatch = useDispatch();
    const [proxyUrl, setProxyUrl] = useState<string>('');
    const [updatingProxyUrl, setUpdatingProxyUrl] = useState(false);
    const [openProxyUrlModal, setOpenProxyUrlModal] = useState(false);
    const [ProxyUrlError, setProxyUrlError] = useState('');
    let [showInformationModal, setShowInformationModal] = useState<InformationModalType>({
        type: '',
        open: false,
        title: '',
        closeBtnLabel: '',
        successBtnLabel: '',
        cancelBtnLabel: '',
        subTitle: '',
        cancelBtnLoading: false,
        handleConfirm: () => {},
        handleClose: () => {},
        dataTestId: '',
    });
    const [showFullTextModal, setShowFullTextModal] = useState<boolean>(false);
    const speaker = useSelector((state: RootState) => state.Speaker);
    const speakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);

    useEffect(() => {
        setProxyUrl(speakerPreferences?.proxyUrl);
    }, []);

    const openFullTextModal = useCallback(() => {
        if (speakerPreferences?.showFullTextModal && mergedConfigs.featureFlags.proxyUrl) {
            setShowFullTextModal(true);
        } else redirectToJournalSite(speakerPreferences, paperFile);
    }, [speakerPreferences?.showFullTextModal, speakerPreferences?.proxyUrl]);

    const closeFullTextModal = useCallback(() => setShowFullTextModal(false), []);

    const continueTojournalSite = useCallback(() => {
        closeFullTextModal();
        redirectToJournalSite(speakerPreferences, paperFile);
    }, [speakerPreferences?.proxyUrl]);

    const toggleProxyUrlModal = useCallback(() => {
        setOpenProxyUrlModal(!openProxyUrlModal);
        closeFullTextModal();
    }, []);

    const handleCloseProxyUrlModal = useCallback(() => setOpenProxyUrlModal(false), []);

    const handleChangeProxyUrl = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setProxyUrl(value);
    }, []);

    const enterProxyUrlAgain = useCallback(() => {
        closeFullTextModal();
        setOpenProxyUrlModal(true);
    }, []);

    const closeInfoModal = useCallback(() => setShowInformationModal({ ...showInformationModal, open: false }), []);

    const submitProxyUrl = async () => {
        if (matchProxyUrlFormat(proxyUrl)) {
            try {
                setUpdatingProxyUrl(true);
                let res = await updateProxyUrl(speaker.id!, proxyUrl);
                setOpenProxyUrlModal(!openProxyUrlModal);
                setUpdatingProxyUrl(false);
                setShowInformationModal({
                    type: alertTypes.success,
                    open: true,
                    title: 'Proxy URL was accepted',
                    successBtnLabel: 'Close',
                    closeBtnLabel: '',
                    cancelBtnLabel: '',
                    cancelBtnLoading: false,
                    handleConfirm: closeInfoModal,
                    dataTestId: '',
                    subTitle: '',
                    footer: '',
                    helpInfo: '',
                });

                dispatch(updateSpeakerPreference({ proxyUrl: res.proxyUrl }));
            } catch (error) {
                setOpenProxyUrlModal(!openProxyUrlModal);
                setUpdatingProxyUrl(false);
                if (error instanceof Error) {
                    setShowInformationModal({
                        type: alertTypes.error,
                        open: true,
                        title: 'Error',
                        successBtnLabel: 'Enter again',
                        closeBtnLabel: '',
                        cancelBtnLabel: 'Cancel',
                        subTitle: `Something went wrong while posting data to database ${error.message}`,
                        cancelBtnLoading: false,
                        handleConfirm: () => enterProxyUrlAgain(),
                        handleClose: () => closeFullTextModal(),
                        dataTestId: '',
                    });
                }
            }
        } else {
            setProxyUrlError('Enter url in this format: http://libaccess.sjlibrary.org/login?url=$@');
        }
    };

    return (
        <div className={classes.itemText} style={{ color: colors.darkTeal }}>
            <div className={classes.saveRefOptions}>
                <div className={classes.uploadFileStatus} onClick={handleOpenUploadModal}>
                    <UploadPdfIcon viewBox="0 0 16 16" style={{ fontSize: 15 }} />
                    &nbsp;
                    <span className={classes.uploadFileStatusText}>Upload PDF</span>
                </div>
                {paperFile.paper?.doi && (
                    <div className={classes.uploadFileStatus} onClick={openFullTextModal} data-testid="accessFullText">
                        <OpenInNewIcon viewBox="0 0 16 16" fontSize="inherit" />
                        &nbsp;
                        <span className={classes.uploadFileStatusText}>Access Full Text</span>
                    </div>
                )}
            </div>

            <InputModal
                title="Enter the Proxy Url"
                subtitle=""
                closeModal={handleCloseProxyUrlModal}
                titleError={ProxyUrlError || ''}
                handleInputChange={handleChangeProxyUrl}
                handleSubmit={submitProxyUrl}
                isOpen={openProxyUrlModal}
                helpInfo={<ProxyModalHelpContent />}
                value={proxyUrl}
                placeholder="Eg: http://libaccess.sjlibrary.org/login?url=$@"
                inputLabel="Enter Url"
                loading={updatingProxyUrl}
                modalPrimaryBtnText="Update"
                modalContentwidth={600}
                disablePrimaryBtn={false}
            />
            <FullTextModal
                open={showFullTextModal}
                toggleProxyUrlModal={toggleProxyUrlModal}
                handleConfirm={continueTojournalSite}
                handleClose={closeFullTextModal}
            />
            <InformationModal
                type={showInformationModal.type}
                open={showInformationModal.open}
                title={showInformationModal.title}
                closeBtnLabel={showInformationModal.closeBtnLabel}
                successBtnLabel={showInformationModal.successBtnLabel}
                cancelBtnLabel={showInformationModal.cancelBtnLabel}
                subTitle={showInformationModal.subTitle}
                footer={showInformationModal.footer}
                helpInfo={showInformationModal.helpInfo}
                cancelBtnLoading={showInformationModal.cancelBtnLoading}
                handleConfirm={showInformationModal.handleConfirm}
                handleClose={showInformationModal.handleClose}
                dataTestId={showInformationModal.dataTestId}
            />
        </div>
    );
};

export const ClosedAccessInfo = memo(ClosedAccessInfoComp);
