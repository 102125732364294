export const routes = {
    root: '/',
    library: '/library',
    reader: '/reader',
    slidePreview: '/slide-preview',
    internalServerError: '/internal-server-error',
    settings: '/settings',
    selectPlan: '/select-plan',
    subscription: '/subscription/',
    article: '/article',
    confirm: '/confirm/',
    resetPassword: '/reset-password/',
    signup: '/signup',
    login: '/login',
    forgotPassword: '/forgot-password',
    get speakerPreferences() {
        return this.settings.concat('/preferences');
    },
};

export const authRoutes = [
    routes.confirm,
    routes.resetPassword,
    routes.signup,
    routes.login,
    routes.forgotPassword,
    routes.subscription,
    routes.selectPlan,
];
