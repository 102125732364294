import { Presentation } from '../types';
import { executeGraphqlCall } from './api';

export const updatePresentationTheme = async (
    presentationId: string,
    themeId: string,
    speakerId: string
): Promise<Presentation | null> => {
    const variables = {
        data: {
            presentationId,
            themeId,
            speakerId,
        },
    };
    const body = `mutation UpdatePresentationTheme($data: UpdatePresentationThemeInput!) {
        updatePresentationTheme(data: $data) {
          id
          themeId
          slides {
            id
            number
            markup
            layout {
              id
              name
              layoutType
            }
            outlinePage {
              id
              number
              items {
                itemType
              }
            }
          }
        }
      }`;

    return executeGraphqlCall<Presentation>('updatePresentationTheme', body, undefined, variables);
};
