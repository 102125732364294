import { EmptyRow } from './components/emptyRow';
import { style } from './style';

export const WithoutPaper = () => {
    const classes = style();
    return (
        <div className={classes.mainContainer}>
            <div className={classes.container}>
                <div className={classes.tildEmptyRowsContainer}>
                    {[1, 2, 3].map((_item, index) => (
                        <EmptyRow key={index} />
                    ))}
                </div>
                <div className={classes.straightContainer}>
                    <div className={classes.parentContainer}>
                        <div className={classes.filedSquareContainer} />
                        <div className={classes.emptyRowContainers}>
                            <div className={classes.emptyRowOne} />
                            <div className={classes.emptyRowTwo} />
                        </div>
                    </div>
                    {[1, 2].map((_item, index) => (
                        <EmptyRow key={index} />
                    ))}
                </div>
            </div>
            <div className={classes.textTwo}>You don't have any PDFs associated with this project yet.</div>
            <div className={classes.textOne}>Drop a .pdf file here, or use the ‘+’ button below!</div>
        </div>
    );
};
