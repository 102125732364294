import { IconButton, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { useSlate } from 'slate-react';
import { colors } from '../../../../../../../../assets/colors';
import { BlockFormatType, TextFormatType } from '../types';
import { isMarkActive, toggleBlock, toggleMark, isBlockActive } from './common';

interface TextEditorToggleButtonPropsType {
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    textFormat?: TextFormatType;
    blockFormat?: BlockFormatType;
}

export const TextEditorToggleButton: React.FC<TextEditorToggleButtonPropsType> = ({
    Icon,
    textFormat,
    blockFormat,
}) => {
    const editor = useSlate();

    const handleToggle = () => {
        if (textFormat) {
            toggleMark(editor, textFormat);
        } else if (blockFormat) {
            toggleBlock(editor, blockFormat);
        }
    };
    const isActive = (): boolean => {
        if (textFormat) {
            return isMarkActive(editor, textFormat);
        } else if (blockFormat) {
            return isBlockActive(editor, blockFormat);
        } else {
            return false;
        }
    };
    return (
        <IconButton size="small" onClick={handleToggle}>
            <Icon
                style={{
                    color: isActive() ? colors.black : colors.disabledMenu,
                }}
            />
        </IconButton>
    );
};
