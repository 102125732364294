import { executeGraphqlCall } from './api';
import {
    Paper,
    PaperJobResType,
    RemoveFromLibrary,
    PaperFile,
    PaperFileProject,
    PaperStatus,
    PaperSearchResponse,
    PaperDataType,
} from '../types';
import config from '../config';
import { getToken } from './overrides';

export const getPapers = async (): Promise<Paper[]> => {
    const body = `{
    papers
        {
          id, doi, title, isOpenAccess, 
          primaryAuthor { firstInitial, lastName },
          secondaryAuthors { firstInitial, lastName }
        }
  }`;
    return executeGraphqlCall<Paper[]>('papers', body);
};

export const getPaperFiles = async (speakerId: string): Promise<PaperFile[]> => {
    const variables = { data: speakerId };

    const body = `query Query($data: String!) {
        getPaperFiles(data: $data) {
            id
            key
            status
            name
            createdAt
            updatedAt
            paper {
              id
              doi
              title
              isOpenAccess
              journalName
              publishedDate
              createdAt
              updatedAt
              primaryAuthor{
                id 
                firstInitial 
                lastName 
                createdAt 
                updatedAt
              }
              secondaryAuthors{
                id 
                firstInitial 
                lastName 
                createdAt 
                updatedAt
              }
            }
        }
      }`;
    return executeGraphqlCall<PaperFile[]>('getPaperFiles', body, undefined, variables);
};

export const getPaperByDoi = async (
    doi: string,
    speakerId: string,
    source: string,
    pmcid?: string
): Promise<PaperJobResType> => {
    const variables = { data: { speakerId, doi, source, pmcid } };
    const body = `
    query getPaperByDoi($data:GetPaperByDoi!) {
        getPaperByDoi(data:$data){
            url
            status
            doi
            keyToPaperInStorage
            jobId
            paperFile {
              id
              key
              status
              name 
              createdAt
              updatedAt
              paper{
                id
                doi
                title
                isOpenAccess
                journalName
                publishedDate
                createdAt
                updatedAt
                primaryAuthor{
                  lastName
                  firstInitial
                }
                secondaryAuthors{
                  firstInitial
                  lastName
                }
              }
            }
        }
    }`;
    return executeGraphqlCall<PaperJobResType>('getPaperByDoi', body, undefined, variables);
};

export const getPaperByJobId = async (
    doi: string,
    speakerId: string,
    jobId: string,
    keyToPaperInStorage: string
): Promise<PaperJobResType> => {
    const variables = {
        data: {
            doi,
            speakerId,
            jobId,
            keyToPaperInStorage,
        },
    };
    const body = `
    query getPaperByJobId($data:GetPaperByJobId!) {
        getPaperByJobId(data:$data){
            url
            status
            doi
            keyToPaperInStorage
            jobId
            paperFile {
              id
              key
              status
              name 
              createdAt
              updatedAt
              paper{
                id
                doi
                title
                isOpenAccess
                journalName
                publishedDate
                createdAt
                updatedAt
                primaryAuthor{
                  lastName
                  firstInitial
                }
                secondaryAuthors{
                  firstInitial
                  lastName
                }
              }
            }
        }
    }`;
    return executeGraphqlCall<PaperJobResType>('getPaperByJobId', body, undefined, variables);
};

export const getPaperCitation = async (paper: PaperDataType, style: string, speakerId: string): Promise<Paper> => {
    const variables = {
        paper,
        style: style.toLowerCase(),
        speakerId,
    };
    const body = `query GetPaperCitation( $paper: PaperDataType!, $style: String!, $speakerId:String!){
        getPaperCitation(paper:$paper, style:$style, speakerId:$speakerId){
            citation
        }
  }`;
    return executeGraphqlCall<Paper>('getPaperCitation', body, undefined, variables);
};

export const addPaperFromDoi = async (doi: string, paperFileId: string, status: PaperStatus): Promise<PaperFile> => {
    const variables = {
        data: {
            doi,
            paperFileId,
            status,
        },
    };
    const body = `
    mutation addPaperFromDoi ($data:AddPaperFromDoiInput!){
        addPaperFromDoi(data:$data){
            id
            paper{
              id
              doi
              title
              isOpenAccess
              journalName
              publishedDate
              createdAt
              updatedAt
              primaryAuthor{ 
                  id 
                  firstInitial 
                  lastName 
                  createdAt 
                  updatedAt  
              } secondaryAuthors {
                  id 
                  firstInitial
                  lastName
                  createdAt
                  updatedAt
              }
            }
            key
            status
            name
            createdAt
            updatedAt
            }
        }`;
    return executeGraphqlCall<PaperFile>('addPaperFromDoi', body, undefined, variables);
};

export const getPaperInfo = async (pmid: string) => {
    const token = await getToken();
    const query = {
        query: `{getPaperInfo(pmid: "${pmid}") {doi, isOpenAccess, downloadUrl}}`,
    };
    return await fetch(config.api.baseUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token,
        },
        body: JSON.stringify(query),
    })
        .then(async (res) => {
            const oaResult = await res.json();
            console.log('Open Access Response', oaResult);
            if (oaResult.data && oaResult.data.getPaperInfo) {
                return oaResult.data.getPaperInfo;
            } else {
                throw new Error('Open Access Request Failed');
            }
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

export const getPaperInfoWithDOI = async (doi: string) => {
    const token = await getToken();
    const query = {
        query: `{getPaperInfoWithDOI(doi: "${doi}") {doi, isOpenAccess, downloadUrl}}`,
    };
    return await fetch(config.api.baseUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token,
        },
        body: JSON.stringify(query),
    })
        .then(async (res) => {
            const oaResult = await res.json();
            console.log('Open Access Response', oaResult);
            if (oaResult.data && oaResult.data.getPaperInfoWithDOI) {
                return oaResult.data.getPaperInfoWithDOI;
            } else {
                throw new Error('Open Access Request Failed');
            }
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

export const addPaper = async (speakerId: string, projectId: string, item: File): Promise<PaperFileProject> => {
    const token = await getToken();
    const formData = new FormData();
    formData.append(
        'operations',
        `{"query":"mutation addPaper($paper:Upload!, $projectId:String!, $speakerId:String!){ addPaper(projectId:$projectId, speakerId:$speakerId, paper:$paper){ id,  paperFile{ id, name, key, status,createdAt, paper{id, doi, isOpenAccess, title,  journalName, publishedDate,  updatedAt, createdAt, primaryAuthor{ id, firstInitial, lastName, createdAt, updatedAt  }secondaryAuthors{, id, firstInitial, lastName, createdAt, updatedAt}}}}}", "variables": { "paper": null, "projectId":"${projectId}", "speakerId": "${speakerId}" }}`
    );
    formData.append('map', '{ "0": ["variables.paper"]  }');
    formData.append('0', item);
    try {
        const response: any = await fetch(config.api.baseUrl, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: formData,
        });
        const jsonResponse = await response.json();
        if (jsonResponse?.error) {
            const errorMessages =
                jsonResponse?.error === 'Request Entity Too Large'
                    ? 'File size exceeds maximum limit 10 MB.'
                    : jsonResponse?.error;
            throw new Error(`\n${errorMessages}`);
        }
        if (!jsonResponse?.data['addPaper']) {
            if (jsonResponse?.errors) {
                const errorMessages = jsonResponse?.errors?.reduce(
                    (allErrors: any, error: any) => `${allErrors}\n-> ${error.message}`,
                    ''
                );
                throw new Error(`\n${errorMessages}`);
            }
            throw new Error('Something went wrong');
        }

        return jsonResponse?.data['addPaper'];
    } catch (error) {
        console.log('Error in request is: ', error);
        throw error;
    }
};

export const uploadPdfForPaper = async (
    speakerId: string,
    paperFileId: string,
    item: File
): Promise<PaperFile | null> => {
    const token = await getToken();
    const formData = new FormData();
    formData.append(
        'operations',
        `{"query":"mutation UploadPdfForPaper(  $PaperFileId: String!,  $SpeakerId: String!,  $Paper: Upload!) {  uploadPdfForPaper(paperFileID: $PaperFileId, speakerId: $SpeakerId, paper: $Paper) {id, key, status, name, createdAt, updatedAt, paper {id, doi, title, isOpenAccess, journalName, publishedDate, createdAt, updatedAt, primaryAuthor { id, firstInitial, lastName} secondaryAuthors { id, firstInitial, lastName}}}}", "variables": { "Paper": null, "PaperFileId":"${paperFileId}", "SpeakerId": "${speakerId}" }}`
    );
    formData.append('map', '{ "0": ["variables.Paper"]  }');
    formData.append('0', item);
    try {
        const response: any = await fetch(config.api.baseUrl, {
            method: 'POST',
            headers: {
                authorization: token,
            },
            body: formData,
        });

        const jsonResponse = await response.json();
        if (!jsonResponse?.data['uploadPdfForPaper']) {
            if (jsonResponse?.errors) {
                const errorMessages = jsonResponse?.errors?.reduce(
                    (allErrors: any, error: any) => `${allErrors}\n-> ${error.message}`,
                    ''
                );
                throw new Error(`\n${errorMessages}`);
            }
            throw new Error('Something went wrong');
        }

        return jsonResponse?.data['uploadPdfForPaper'];
    } catch (error) {
        console.log('Error in request is: ', error);
        throw error;
    }
};

export const getPaperByKey = async (key: string, speakerId: string): Promise<PaperJobResType> => {
    const variables = { data: { speakerId, key } };
    const body = `
    query getPaperByKey($data:GetPaperByKey!) {
        getPaperByKey(data:$data){
            url
            paperFile {
              id
              key
              status
              name 
              createdAt
              updatedAt
              paper{
                id
                doi
                title
                isOpenAccess
                journalName
                publishedDate
                createdAt
                updatedAt
                primaryAuthor{
                  lastName
                  firstInitial
                }
                secondaryAuthors{
                  firstInitial
                  lastName
                }
              }
            }
        }
    }`;
    return executeGraphqlCall<PaperJobResType>('getPaperByKey', body, undefined, variables);
};
export const getPaperReferences = async (
    doi: string,
    presentDois: string[],
    speakerId: string
): Promise<{ paperSearchResponse: PaperSearchResponse[]; status: string }> => {
    const variables = { data: { doi, presentDois, speakerId } };
    const body = `
    query GetPaperReferences($data: PaperReferencesInput!) {
        getPaperReferences(data: $data) {
        status
        paperSearchResponse {
            doi
            journal_name
            isOpenAccess
            response
            paperUrl
            title
            published_date
            referenceExist
          
            primaryAuthor {
              firstInitial
              lastName
            }
            secondaryAuthors {
              firstInitial
              lastName
            }
        }
          }
    }`;
    return executeGraphqlCall<{ paperSearchResponse: PaperSearchResponse[]; status: string }>(
        'getPaperReferences',
        body,
        undefined,
        variables
    );
};

export const removePaperFileFromLibrary = async (
    paperFileId: string,
    speakerId: string,
    paperId: string,
    keyToPaperInStorage: string
): Promise<RemoveFromLibrary> => {
    const variables = {
        data: { paperFileId, speakerId, keyToPaperInStorage, paperId },
    };
    const body = `mutation removePaperFileFromLibrary($data:PaperLibraryInput!) {
        removePaperFileFromLibrary(data:$data){
            isPaperDeleted
            deletedPaperFileId
            updatedPaperFile {
                id
                key
                status
                name 
                createdAt
                updatedAt
                paper{
                  id
                  doi
                  title
                  isOpenAccess
                  journalName
                  publishedDate
                  createdAt
                  updatedAt
                  primaryAuthor{
                    lastName
                    firstInitial
                  }
                  secondaryAuthors{
                    firstInitial
                    lastName
                  }
                }
            }
        }
    }`;
    return executeGraphqlCall<RemoveFromLibrary>('removePaperFileFromLibrary', body, undefined, variables);
};
