import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addPaperFromDoi, getPaperFiles } from '../../../../backend/paper';
import { removePaperFileFromLibrary } from '../../../../backend/paper';
import { alertTypes, paperMetaDataStatus } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import { setPaperFile, showAlert, togglePaperSwitcher, updatePaperFileOfProject } from '../../../../redux/reducers';
import {
    PaperFile,
    PaperStatusList,
    ProjectInterface,
    InformationModal as InformationModalType,
} from '../../../../types';
import { WithoutPaper } from '../project/components/withoutPaper';
import { PapersList } from '../../../common/papersList';
import { style } from './style';
import { useQuery } from '../../../../utils';
import { routes } from '../../../pages/routes';

export const AllPapers = () => {
    const classes = style();
    const history = useHistory();
    const dispatch = useDispatch();
    const [temp, setTemp] = useState(Date.now());
    const speakerId = useSelector((state: RootState) => state?.Speaker?.id);
    const [allPaperFiles, setAllPaperFiles] = useState<PaperFile[]>([]);
    const projectsData: ProjectInterface[] = useSelector((state: RootState) => state.Projects);
    const query = useQuery();
    const projectId = query.get('projectId');
    const selectedProject = projectsData.find((item) => item.id === projectId);

    useEffect(() => {
        speakerId && getAllpaperFiles();
    }, [speakerId]);

    const getAllpaperFiles = async () => {
        try {
            const paperFiles = await getPaperFiles(speakerId as string);
            setAllPaperFiles(paperFiles);
        } catch (error) {
            if (error instanceof Error) {
                dispatch(showAlert({ isVisible: true, type: alertTypes.error, message: error.message }));
            }
        }
    };

    const openReaderView = () => {
        if (selectedProject?.paperFiles)
            dispatch(
                setPaperFile({
                    ...selectedProject?.paperFiles[0],
                    isCreateProjectWithPaper: false,
                })
            );
        if (selectedProject?.paperFiles && selectedProject?.paperFiles?.length > 1)
            dispatch(togglePaperSwitcher({ paperSwitcherDrawer: true }));
        else dispatch(togglePaperSwitcher({ paperSwitcherDrawer: false }));
        if (selectedProject?.paperFiles)
            history.push({
                pathname: routes.reader,
                search:
                    '?' +
                    new URLSearchParams({
                        projectId: selectedProject?.id as string,
                        key: selectedProject?.paperFiles[0]?.key as string,
                    }),
            });
    };

    const handleInputDoi = (paperFile: PaperFile, value: string) => {
        const paperFileIndex = allPaperFiles.findIndex((item) => item.id === paperFile.id);
        const paperFiles = [...allPaperFiles];
        paperFiles[paperFileIndex]!.paper = {
            ...paperFiles[paperFileIndex]!.paper,
            doi: value,
            update: true,
        };

        setAllPaperFiles(paperFiles);
    };

    const handleManualDoi = async (paperFile: PaperFile) => {
        const paperFileIndex = allPaperFiles.findIndex((item) => item.id === paperFile.id);
        const paperFiles = [...allPaperFiles];
        const itemUpdated = paperFiles[paperFileIndex].paper?.update;
        try {
            if (itemUpdated) {
                paperFiles[paperFileIndex].loading = itemUpdated;
                paperFiles[paperFileIndex].status = itemUpdated ? paperMetaDataStatus.metadata : '';
                setAllPaperFiles(paperFiles);
                setTemp(Date.now());

                const updatedPaperFile: PaperFile = await addPaperFromDoi(
                    paperFile.paper?.doi as string,
                    paperFile.id as string,
                    PaperStatusList.empty
                );

                paperFiles[paperFileIndex] = updatedPaperFile;
                paperFiles[paperFileIndex].loading = false;
                setAllPaperFiles(paperFiles);
                setTemp(Date.now());
                dispatch(
                    showAlert({
                        message: 'The DOI for the paper has been updated successfully',
                        type: alertTypes.success,
                        isVisible: true,
                    })
                );
            }
        } catch (error) {
            paperFiles[paperFileIndex] = {
                ...paperFile,
                loading: false,
                status: paperMetaDataStatus.failedMetadata,
            };

            setAllPaperFiles(paperFiles);
            setTemp(Date.now());

            if (error instanceof Error)
                dispatch(
                    showAlert({
                        message: `Updating the DOI failed: ${error.message}`,
                        type: alertTypes.error,
                        isVisible: true,
                    })
                );
        }
    };

    // update the status of specific paper in redux
    const updateStatusOfPaper = (status: string, paperFile: PaperFile) => {
        dispatch(
            updatePaperFileOfProject({
                projectId: projectId as string,
                paperFile: { ...paperFile, loading: !!status, status: status ? status : undefined },
                paperFileId: paperFile.id,
            })
        );
    };

    // Remove Paper File  from Project
    const deletePaperFromLibrary = async (paperFile: PaperFile) => {
        try {
            await removePaperFileFromLibrary(
                paperFile.id as string,
                speakerId as string,
                paperFile?.paper?.id as string,
                paperFile.key as string
            );
            const paperFiles = allPaperFiles.filter((item: PaperFile) => item.id !== paperFile.id);
            updateStatusOfPaper('', paperFile);
            setAllPaperFiles(paperFiles);
        } catch (error) {
            if (error instanceof Error) {
                dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
            }
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.mainContainer}>
                <span className={classes.paperCount} role="paper-count">
                    {allPaperFiles?.length} Papers &nbsp;
                    <span
                        role="presentation"
                        onClick={selectedProject?.paperFiles?.length ? openReaderView : () => {}}
                        className={classes.viewInReader}
                        style={!selectedProject?.paperFiles?.length ? { opacity: 0.5 } : {}}
                    >
                        View all in reader
                    </span>
                </span>

                {allPaperFiles?.length ? (
                    <PapersList
                        paperFiles={allPaperFiles as []}
                        temp={temp}
                        handleManualDoi={handleManualDoi}
                        isArticleSelected={true}
                        deletePaperFromLibrary={deletePaperFromLibrary}
                    />
                ) : (
                    <WithoutPaper />
                )}
            </div>
        </div>
    );
};
