import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            padding: 15,
            display: 'flex',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: colors.grayBackground,
            },
        },
        projectTitle: {
            flex: 1,
            color: colors.text,
            width: '90%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '10%',
        },
        avatar: {
            height: 20,
            width: 20,
            backgroundColor: '#F3F3F3',
            color: colors.text,
            fontSize: 13,
        },
        countContainer: {
            borderRadius: 13,
            backgroundColor: '#F3F3F3',
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 13,
            width: 22,
            height: 22,
        },
        textBold: {
            fontWeight: 'bold',
        },
    })
);
