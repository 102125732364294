import { makeStyles } from '@material-ui/core/styles';

export const useOutlineStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-35px',
        marginTop: '32px',
    },
    rootInReader: {
        marginLeft: '0px',
        marginTop: '10px',
    },
}));
