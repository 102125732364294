import { Box, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import WarningIcon from '@material-ui/icons/Warning';
import { MuiCMButton } from '../../../../../common';
import { useStyles } from './styles';

interface IDeleteProjectDialogProps {
    open: boolean;
    projectTitle: string;
    projectId: string;
    handleClose: any;
    isDeleting: boolean;
    onDeleteProject: (id: string) => void;
}

export default function DeleteProjectDialog({
    open,
    projectTitle,
    projectId,
    handleClose,
    isDeleting,
    onDeleteProject,
}: IDeleteProjectDialogProps) {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
            >
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" justifyContent="center">
                        <span className={classes.IconContainer}>
                            <WarningIcon className={classes.warningIcon} />
                        </span>
                        <h3 className={classes.headerText}>{`Are you sure you want to delete "${projectTitle}"?`}</h3>
                    </Box>
                    <Box maxWidth="xs">
                        <p className={classes.warningText}>
                            This will delete the outline, slides, presentation, and papers permanently. Are you sure you
                            want to proceed?
                        </p>
                    </Box>
                    <div className={classes.ButtonContainer}>
                        <MuiCMButton
                            onClick={() => onDeleteProject(projectId)}
                            loading={isDeleting}
                            className={classes.deleteBtn}
                            variant="outlined"
                            fullWidth
                        >
                            Delete Permanently
                        </MuiCMButton>
                        <MuiCMButton onClick={handleClose} className={classes.cancelBtn}>
                            Cancel
                        </MuiCMButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
