import { Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { BlockFormatType, TextFormatType } from '../types';

export const LIST_TYPES = ['numberedList', 'bulletedList'];

export const HOTKEYS: { [key: string]: TextFormatType } = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};

export const isMarkActive = (editor: any, format: TextFormatType) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: any, format: TextFormatType) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
    ReactEditor.focus(editor);
};

export const isBlockActive = (editor: any, format: BlockFormatType) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });
    return !!match;
};

export const toggleBlock = (editor: any, format: BlockFormatType) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    // [removing/changing block type] unwrap/remove the <ul> or <ol> nodes first which is wrapped when adding block
    Transforms.unwrapNodes(editor, {
        // only effective if match function returns true
        match: (n) => {
            if (!Editor.isEditor(n) && SlateElement.isElement(n)) {
                return LIST_TYPES.includes(n.type);
            } else return false;
        },
        split: true,
    });

    // [toggle block] creates a <li> element in Nodes or change <li> to paragraph to remove the block
    const newProperties: Partial<SlateElement> = {
        type: isActive ? 'paragraph' : isList ? 'listItem' : format,
    };

    Transforms.setNodes(editor, newProperties);

    // [adding block] wrap a <ul> or <ol> element over the <li> element to make a complete html list
    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
    ReactEditor.focus(editor);
};
