import { makeStyles } from '@material-ui/core';
import { colors } from '../../../assets/colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        backgroundColor: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 26,
        borderRight: '1px solid lightgray',
        cursor: 'pointer',
    },
    arrowIcon: {
        fontSize: 22,
        cursor: 'pointer',
        color: colors.darkTeal,
    },
}));
