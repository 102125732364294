import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        body: {
            flex: 1,
            overflow: 'auto',
        },
        addProjectContainer: {
            display: 'flex',
            padding: '0px 12.5vh 0px 12.5vh',
            height: 70,
            alignItems: 'center',
        },
        iconButtonConatiner: {
            cursor: 'pointer',
            width: '126px',
            height: '40px',
            borderRadius: '2px',
            border: `1px solid ${colors.primaryButton}`,
            color: colors.white,
            backgroundColor: colors.primaryButton,
            '&:hover': {
                backgroundColor: colors.primaryButtonOnhover,
            },
            '&:focus': {
                border: `1px solid ${colors.darkTeal}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.primaryButtonOnPress,
            },
        },
        zoomButtonsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        savePaperButtonContainer: {
            flex: 1,
            justifyContent: 'flex-end',
            display: 'flex',
        },
        zoomScale: {
            color: colors.white,
            fontSize: 12,
            fontWeight: 600,
            width: 40,
            padding: '4px',
            textAlign: 'center',
            backgroundColor: colors.text,
            margin: '0px 5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid transparent',
            outline: 'none',
        },
        white: {
            color: colors.white,
        },
        iconButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        icon: { fontSize: 16, marginRight: 10 },
        iconLabel: {
            fontSize: 16,
        },
        iconContainer: { display: 'flex' },
        documentContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            overflow: 'auto',
        },
        doiMsgContainer: {
            backgroundColor: colors.pinkishRed,
            display: 'flex',
            padding: '10px 10px 10px 33px',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'space-between',
        },
        errorIcon: {
            height: 24,
            width: 24,
        },
        doiErrorMsg: {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: 0.1,
            color: colors.darkNavyBlue,
            marginLeft: 10,
        },
        manualDoiText: {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0.1,
            textTransform: 'uppercase',
            color: colors.linkBlue,
            textAlign: 'right',
            cursor: 'pointer',
        },
        doiMsgLeft: {
            display: 'flex',
            alignItems: 'center',
        },
        myCustomPage: {
            '& $react-pdf__Page__canvas': {
                width: '100% !important',
            },
        },
        wrapperDivSmall: {
            maxWidth: window.innerWidth - window.innerWidth * 0.3,
            height: window.innerHeight - 121,
        },
        wrapperDivFull: {
            maxWidth: 1200,
            height: window.innerHeight - 121,
        },
    })
);
