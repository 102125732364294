import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        loaderContainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: colors.white,
            flexDirection: 'column',
        },
    })
);
