import { Auth } from 'aws-amplify';
import { FormikProps, withFormik } from 'formik';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { alertTypes } from '../../../constants';
import { showAlert } from '../../../redux/reducers';
import { useQuery } from '../../../utils';
import { resetPasswordValidation } from '../../../validation';
import { MuiCMButton, TextInput } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

interface FormValues {
    password: string;
    repeatPassword: string;
}

interface OtherProps {
    code: string;
    username: string;
    dispatch: Dispatch<any>;
    setPassUpdated: Dispatch<React.SetStateAction<boolean>>;
}

const InnerForm = (props: FormikProps<FormValues>) => {
    const classes = style();
    const { values, handleChange, errors, touched, isSubmitting, handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                placeholder="Password"
                name="password"
                key="password"
                value={values.password}
                label="Password"
                id="password"
                onChange={handleChange}
                containerClassName={classes.input}
                type="password"
                error={errors.password && touched.password ? errors.password : ''}
            />
            <TextInput
                placeholder="Repeat password"
                name="repeatPassword"
                key="Repeat password"
                value={values.repeatPassword}
                label="Repeat password"
                id="Repeat password"
                onChange={handleChange}
                containerClassName={classes.input}
                type="password"
                error={errors.repeatPassword && touched.repeatPassword ? errors.repeatPassword : ''}
            />
            <MuiCMButton loading={isSubmitting} className={classes.button} fullWidth type="submit">
                Submit
            </MuiCMButton>
        </form>
    );
};

const MyForm = withFormik<OtherProps, FormValues>({
    mapPropsToValues: () => ({
        password: '',
        repeatPassword: '',
    }),
    validationSchema: resetPasswordValidation,
    handleSubmit: (values, { setSubmitting, props }) => {
        const { setPassUpdated, dispatch, username, code } = props;
        const { password } = values;

        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(username, code, password)
            .then(() => {
                setSubmitting(false);
                setPassUpdated(true);
            })
            .catch((error: NodeJS.ErrnoException) => {
                if (error.code === 'ExpiredCodeException')
                    dispatch(
                        showAlert({
                            message: 'Your verification link is expired. Please try again.',
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );
                else
                    dispatch(
                        showAlert({
                            message: error.message,
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );

                setSubmitting(false);
            });
    },
})(InnerForm);

export const ResetPassword = () => {
    const [code, setCode] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>(null);
    const dispatch = useDispatch();
    const classes = style();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        const newCode: string | null = query.get('confirmation_code');
        const newUsername: string | null = query.get('username');

        setCode(newCode);
        setUsername(newUsername);
    }, []);

    const [passUpdated, setPassUpdated] = useState(false);

    const handleLoginPage = () => history.push(routes.login);

    return (
        <div className={classes.container}>
            <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} />
            <div className={classes.formContainer}>
                {passUpdated ? (
                    <>
                        <p className={classes.resetPassSuccess}>Congrats, your password has been updated!</p>
                        <span className={classes.loginLinkText} onClick={handleLoginPage}>
                            Back to log-in page
                        </span>
                    </>
                ) : (
                    <>
                        {code && username && (
                            <>
                                <span className={classes.headline}>Reset your password.</span>
                                <MyForm
                                    code={code}
                                    username={username}
                                    setPassUpdated={setPassUpdated}
                                    dispatch={dispatch}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
