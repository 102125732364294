import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            flex: 1,
            padding: 25,
        },
        projectTitleContainer: {
            display: 'flex',
        },
        projectTitle: {
            color: '#37352',
            fontSize: 17,
            fontWeight: 600,
            marginRight: 10,
            width: '198px',
            paddingLeft: 4,
            display: 'flex',
            alignItems: 'center',
        },
        timeContainer: {
            color: 'gray',
        },
        centeredContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        refreshButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            float: 'right',
            flex: 1,
        },
        viewSlidesBtn: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
            width: 150,
            borderRadius: 6,
            borderWidth: '1px',
            '&:hover': {
                borderWidth: '1px',
            },
        },
        readPapersBtn: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
            width: 150,
            marginLeft: '10px',
            borderRadius: 6,
        },
        paperCount: {
            color: colors.stone,
            fontWeight: 900,
        },
        emptyContainer: {
            width: 140,
            height: 75,
            border: '2px solid #F3F3F3',
            borderRadius: 10,
            padding: 10,
            display: 'flex',
            margin: 20,
            position: 'relative',
        },
        info: {
            display: 'flex',
            width: '100%',
            margin: '40px 0px',
        },
        spinner: {
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        iconContainer: {
            paddingRight: '18.33px',
            paddingLeft: '15.33px',
        },
        iconStyles: {
            fontSize: 16,
            color: colors.iconColor,
        },
        selectedState: {
            backgroundColor: colors.disabledMenu,
        },
        unSelectedState: {
            backgroundColor: colors.grayBackground,
        },
        inputText: {
            color: colors.black,
            fontSize: 17,
            fontWeight: 600,
            marginRight: 10,
            border: '1px solid transparent',
            backgroundColor: 'transparent',
            padding: 5,
        },
        buttonsContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
        },
    })
);
