export const colors = {
    smoke: '#666666',
    text: '#37352F',
    darkTeal: '#006366',
    white: '#fff',
    black: '#000000',
    gray: '#808080',
    error: '#DE350B',
    blue: 'blue',
    linkBlue: '#2F80ED',
    grayBackground: '#F9F9FA',
    text2: '#52575C',
    elephant: '#C4C4C4',
    skyGray: '#EDEDED',
    grayText: '#B2B2B2',
    primaryButton: '#006266',
    nimbdoneGreen: 'linear-gradient(180deg, #59C896 0%, #25B1A3 100%)',
    avatarUsername: 'linear-gradient(225deg, #28ADCF 15.52%, #22B573 81.03%), #006366',
    backgroundColor: '#525659',
    disabledMenu: '#BABABA',
    boxShadow: '#bfc4cf',
    brushColor: '#444',
    catenaryColor: '#0a0302',
    red: 'red',
    inactiveStates: '#A0A4A8',
    textfieldBg: '#CACCCF',
    dividerBorderDisabledState: '#E8E8E8',
    warning: '#D49708',
    success: '#3E31B8',
    boxShadow2: '#E5E9F2',
    border: '#E6E6E6',
    backgroundColor2: '#CCCCCC',
    pinkishRed: '#FFEBE6',
    darkNavyBlue: '#172B4D',
    blue1: '#2F80ED',
    lighBlueBackground: '#DEEBFF',
    marketingPageText: '#2C2F3B',
    lightTeal: '#DEF4EA',
    planSwitchBackground: '#20828A',
    linkBlue: '#2F80ED',
    stone: '#A6A6A6',
    iconColor: `#25282B`,
    warningIcon: '#FF8B00',
    checkCircleRounded: '#A3CB38',
    primaryButtonOnhover: '#00767A',
    primaryButtonOnPress: '#008A8F',
    secondaryButtonOnhover: '#E3F2F3',
    secondaryButtonOnPress: '#CCE8E8',
    infoGrayText: '#A9AEB8',
    btnOutlineClr: '#00B1B8',
    darkGray: '#4B4D52',
    brightGray: '#EAEAEA',
    selectedThumbnailBG: '#F6F6F6',
};
