import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        readerPage: {
            flex: 1,
            backgroundColor: colors.backgroundColor,
            height: '100vh',
            flexDirection: 'column',
            display: 'flex',
            overflow: 'hidden',
            position: 'relative',
        },

        readerNavbar: {
            zIndex: 1300,
            height: 'auto',
        },

        readerContent: {
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
            height: '100vh',
        },

        wrapperContainer: {
            flex: 1,
            display: 'flex',
        },
    })
);
