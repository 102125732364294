import { Container, Divider, Paper, Slide } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { alertBarHeight } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import { ProjectInterface } from '../../../../types';
import { DrawerToggler } from '../../../common';

import { OutlineSideBar } from '../../../outlineSideBar';
import { style } from './style';

interface ProjectDetailsProps {
    onStartCapture: any;
    selectedProject: ProjectInterface;
    handleOutlineDrawer: (value: boolean) => void;
}

export const ProjectDetail = ({ onStartCapture, selectedProject, handleOutlineDrawer }: ProjectDetailsProps) => {
    const [outlineDrawerOpen, setOutlineDrawerOpen] = useState(true);
    const classes = style();
    const location = useLocation();
    const history = useHistory();
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    useCustomEventListener('snip-image', ({ itemId }: { itemId: string }) => {
        const pdWrapper = document.getElementById('pdf-wrapper');
        if (pdWrapper) pdWrapper.style.cursor = 'crosshair';
        onStartCapture(itemId);
    });

    const toggleOutlineDrawer = () => {
        setOutlineDrawerOpen(!outlineDrawerOpen);
        handleOutlineDrawer(!outlineDrawerOpen);
    };

    const handleUnSelectProject = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('projectId');
        history.push({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    };

    return (
        <div className={classes.root} style={{ width: `${outlineDrawerOpen ? '30%' : 'auto'}` }}>
            <Slide direction="left" in={outlineDrawerOpen} appear={false} mountOnEnter unmountOnExit>
                <Paper className={classes.outlineContainter}>
                    <Container className={classes.drawerHeader}>
                        <div className={classes.projectBox} role="presentation" onClick={handleUnSelectProject}>
                            <div className={classes.drawerNavbar}>
                                <div className={classes.backButtonContainer}>
                                    <KeyboardArrowLeft className={classes.backButton} />
                                </div>
                                <div className={classes.drawerNavbarText}>
                                    <p>Projects</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Divider />
                    <Container className={classes.contentContainer}>
                        <div>
                            <p className={classes.projectTitle}>{selectedProject?.title}</p>
                            <p className={classes.subHeadingContainer}>
                                <span className={classes.subHeading}>Edit your Project Outline below</span>
                            </p>
                        </div>
                        <OutlineSideBar isProjectDetail={true} />
                    </Container>
                </Paper>
            </Slide>
            <DrawerToggler
                arrowStyle={{ transform: outlineDrawerOpen ? 'rotate(0deg)' : 'rotate(-180deg)' }}
                toggleDrawer={toggleOutlineDrawer}
                open={!outlineDrawerOpen}
            />
        </div>
    );
};
