import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        listContainer: {
            overflow: 'auto',
            maxHeight: '200px',
        },
        slideContainer: {
            border: '1px solid #EAEAEA',
            boxSizing: 'border-box',
            borderRadius: '5px',
            height: 45,
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center',
            padding: '0px 10px',
            cursor: 'pointer',
            color: colors.text2,
            fontSize: 14,
            '&:hover': {
                border: `1px solid ${colors.darkTeal}`,
                backgroundColor: colors.secondaryButtonOnhover,
            },
            '&:active': {
                border: `1px solid ${colors.darkTeal}`,
                backgroundColor: colors.secondaryButtonOnPress,
            },
        },
        slected: {
            border: `1px solid ${colors.darkTeal}`,
            backgroundColor: colors.secondaryButtonOnPress,
        },
        slideFull: {
            backgroundColor: colors.skyGray,
            border: `1px solid ${colors.disabledMenu}`,
            color: colors.smoke,
            cursor: 'not-allowed',
            '&:hover': {
                backgroundColor: colors.skyGray,
                border: `1px solid ${colors.disabledMenu}`,
            },
            '&:active': {
                backgroundColor: colors.skyGray,
                border: `1px solid ${colors.disabledMenu}`,
            },
        },
        fullFlex: {
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        indexText: {
            paddingRight: 10,
        },
        slideFullText: {
            fontSize: 12,
        },
    })
);
