import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpeakerPreferences } from '../../../types';

const initialState: SpeakerPreferences = {
    id: '',
    citationStyle: '',
    showOnboarding: false,
    proxyUrl: '',
    showFullTextModal: true,
};

const speakerPreferenceSlice = createSlice({
    name: 'SpeakerPreferences',
    initialState,
    reducers: {
        updateSpeakerPreference: (state, action: PayloadAction<Partial<SpeakerPreferences>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
    },
});

export const { updateSpeakerPreference } = speakerPreferenceSlice.actions;
export default speakerPreferenceSlice.reducer;
