/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:381cfda0-a4b5-4744-98ab-e5592c129708',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_egx4ZhMql',
    aws_user_pools_web_client_id: '6k3v6qjuuv1hr863rk47jtt7jt',
    oauth: {
        domain: 'nimdone-experiment.auth.us-east-1.amazoncognito.com',
    },
    aws_user_files_s3_bucket: 'nimdone-user-files-experiment',
    aws_user_files_s3_bucket_region: 'us-east-1',
};

export default awsmobile;
