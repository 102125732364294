import { Add } from '@material-ui/icons';
import { style } from './style';

interface CreateButtonProps {
    handleCreate: () => void;
    title: string;
}
export const CreateButton = ({ handleCreate, title }: CreateButtonProps) => {
    const classes = style();

    return (
        <div role="presentation" onClick={handleCreate} className={classes.createBtnContainer} data-testid="create-btn">
            <div className={classes.createBtnIconContainer}>
                <Add className={classes.createBtnIcon} />
            </div>

            <p className={classes.createBtnText}>{title}</p>
        </div>
    );
};
