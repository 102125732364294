import { FormikProps, withFormik } from 'formik';
import { Dispatch } from 'react';

import { updateSpeakerSetting } from '../../../../backend/speaker';
import { alertTypes, countries, professionalCategories, specialties } from '../../../../constants';
import { showAlert } from '../../../../redux/reducers';
import { loginSpeaker } from '../../../../redux/reducers/speaker';
import { Speaker } from '../../../../types';
import { accountSettingsValidation } from '../../../../validation';
import { Dropdown, MuiCMButton, TextInput, InstitutionSelect } from '../../../common';
import { style } from '../style';

interface AccSettingsFormValues {
    name: string;
    professionalCategory: string;
    specialty: string;
    institutionId: string;
    institutionName: string | JSX.Element;
    country: string;
}

interface AccSettingsFormProps {
    speaker: Speaker;
    dispatch: Dispatch<any>;
}

const InnerForm = (props: AccSettingsFormProps & FormikProps<AccSettingsFormValues>) => {
    const classes = style();
    const { values, handleChange, handleSubmit, setFieldValue, errors, touched, isSubmitting } = props;

    const handleDropdownValue = (val: any, name: string) => {
        setFieldValue(name, val.value, true);
    };
    const handleInstitutionValue = (val: any) => {
        setFieldValue('institutionId', val.value);
        setFieldValue('institutionName', val.label);
    };

    return (
        <form className={classes.accSettingForm} onSubmit={handleSubmit}>
            <TextInput
                placeholder="Name"
                name="name"
                key="name"
                value={values.name}
                label="Name"
                id="name"
                onChange={handleChange}
                error={errors.name && touched.name ? errors.name : ''}
            />
            <Dropdown
                placeholder="Professional Category"
                name="professionalCategory"
                key="Professional Category"
                value={{
                    label: values.professionalCategory,
                    value: values.professionalCategory,
                }}
                label="Professional Category"
                id="professionalCategory"
                onChange={(val: any) => handleDropdownValue(val, 'professionalCategory')}
                error={errors.professionalCategory && touched.professionalCategory ? errors.professionalCategory : ''}
                options={professionalCategories}
            />
            <Dropdown
                placeholder="Specialty"
                name="specialty"
                key="Specialty"
                value={{ label: values.specialty, value: values.specialty }}
                label="Specialty"
                id="specialty"
                onChange={(val: any) => handleDropdownValue(val, 'specialty')}
                error={errors.specialty && touched.specialty ? errors.specialty : ''}
                options={specialties}
            />
            <Dropdown
                placeholder="Select Country"
                name="country"
                key="Country"
                value={{
                    label: values.country,
                    value: values.country,
                }}
                label="Country"
                id="country"
                onChange={(val: any) => handleDropdownValue(val, 'country')}
                disable={!Boolean(values.country)}
                error={errors.country && touched.country ? errors.country : ''}
                options={countries}
            />
            <InstitutionSelect
                error={errors.institutionId && touched.institutionId ? errors.institutionId : ''}
                onChange={(val: any) => handleInstitutionValue(val)}
                value={{
                    value: values.institutionId,
                    label: values.institutionName,
                }}
                country={values.country}
            />
            <MuiCMButton loading={isSubmitting} spinnerSize={25} type="submit" className={classes.button}>
                {isSubmitting ? 'Saving...' : 'Save Changes'}
            </MuiCMButton>
        </form>
    );
};

export const AccSettingsForm = withFormik<AccSettingsFormProps, AccSettingsFormValues>({
    mapPropsToValues: (props) => {
        const { speaker } = props;

        return {
            name: speaker.name,
            institutionId: speaker.institution.id,
            institutionName: speaker.institution.name,
            specialty: speaker.specialty,
            professionalCategory: speaker.professionalCategory,
            country: speaker.country,
        };
    },
    validationSchema: accountSettingsValidation,
    handleSubmit: async (values, { setSubmitting, props }) => {
        const { name, professionalCategory, institutionId, specialty, country } = values;
        const { speaker, dispatch } = props;
        const email = speaker.email;
        await updateSpeakerSetting(email, name, professionalCategory, specialty, institutionId, country)
            .then((res) => {
                if (res) {
                    dispatch(loginSpeaker(res));
                    dispatch(
                        showAlert({
                            message: 'Your new account setting saved succesfully',
                            isVisible: true,
                            type: alertTypes.success,
                        })
                    );
                } else
                    dispatch(
                        showAlert({
                            message: 'Something went wrong',
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );

                setSubmitting(false);
            })
            .catch(() => {
                dispatch(
                    showAlert({
                        message: 'Something went wrong',
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );

                setSubmitting(false);
            });
    },
})(InnerForm);
