import React from 'react';
import { Button } from '@material-ui/core';
import { style } from './style';
import { useHistory } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { ProjectInterface } from '../../../types';
import { RootState } from '../../../redux/combineReducers';
import { useSelector } from 'react-redux';

interface ErrorComponentInterface {
    error: string;
    description: React.ReactNode;
}

export const ErrorComponent = ({ error, description }: ErrorComponentInterface) => {
    const classes = style();
    const history = useHistory();
    const projectsData: ProjectInterface[] = useSelector((state: RootState) => state.Projects);

    const navigateToLibrary = () => {
        if (projectsData.length) history.replace(`${routes.library}?projectId=${projectsData[0].id}`);
        else history.replace(routes.library);
    };

    return (
        <div className={classes.root}>
            <div className={classes.pageContent}>
                <p className={classes.pageHeader}>{error}</p>
                <div className={classes.pageDescription}>{description}</div>
            </div>
            <div className={classes.buttonContainer}>
                <div className={classes.buttonInnerContainer}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={navigateToLibrary}>
                        Go back to Nimdone
                    </Button>
                </div>

                <div className={classes.buttonInnerContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.outlinedButton}
                        onClick={() => window.open('mailto:hello@nimdone.com')}
                    >
                        Contact Us
                    </Button>
                </div>
            </div>
        </div>
    );
};
