import React from 'react';
import { style } from './style';
import { ErrorIcon } from '../../../assets/icons';

export interface TextInputProps {
    onChange?: React.ChangeEventHandler;
    onKeyPress?: React.KeyboardEventHandler;
    value?: string | undefined;
    defaultValue?: string | undefined;
    placeholder?: string;
    label?: string;
    name?: string;
    id?: any;
    containerClassName?: string;
    type?: string;
    error?: string;
    page?: string;
    autoFocus?: boolean;
    dataTestId?: string;
}

export const TextInput = ({
    onChange,
    onKeyPress,
    value,
    defaultValue,
    placeholder,
    label,
    name,
    id,
    containerClassName,
    type,
    error,
    page = '',
    autoFocus = false,
    dataTestId,
}: TextInputProps) => {
    const classes = style({ page });
    return (
        <div className={containerClassName ? `${classes.container} ${containerClassName}` : classes.container}>
            <h2 className={classes.label}>{label}</h2>
            <input
                onChange={onChange}
                onKeyPress={onKeyPress}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                name={name}
                id={id}
                className={classes.value}
                type={type}
                autoFocus={autoFocus}
                data-testid={dataTestId}
            />
            {!!error && (
                <div className={classes.errorContainer}>
                    <ErrorIcon className={classes.errorImage} />
                    <p className={classes.errorText}> {error} </p>
                </div>
            )}
        </div>
    );
};
