import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/combineReducers';
import { style } from './style';
import { createListItem, createOutlinePage, createTextItem } from '../../../../../../backend/outline';
import { setOutline, showAlert, updateOutlineProject } from '../../../../../../redux/reducers';
import { alertTypes } from '../../../../../../constants';
import { Descendant } from 'slate';
import { toggleSavingOutlineItem, updateItems } from '../../../../../../redux/reducers/projectOutline';
import { OutlineItemType, ProjectInterface } from '../../../../../../types';
import { ReaderViewModalWrapper } from '../../../';
import { InputModal } from '../../../../../common';

interface HighlightedTextOptionsProps {
    selectedItemType: string | null;
    highlightedText: string;
    close: () => void;
    selectedProject: ProjectInterface;
}

interface NewSlideState {
    slideTitle: string;
    creating: boolean;
    openModal: boolean;
    slideCreationError: string;
}

export const SlidesListModal = ({
    selectedItemType,
    highlightedText,
    close,
    selectedProject,
}: HighlightedTextOptionsProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedPages, setSelectedPages] = useState<string[]>([]);
    const { outline } = useSelector((state: RootState) => state.ProjectOutline);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const { citation: paperCitation } = useSelector((state: RootState) => state.PaperFile);

    const [{ slideTitle, creating, openModal, slideCreationError }, setNewSlideData] = useReducer(
        (state: Partial<NewSlideState>, newState: Partial<NewSlideState>) => ({ ...state, ...newState }),
        {
            slideTitle: '',
            creating: false,
            openModal: false,
            slideCreationError: '',
        }
    );

    const classes = style();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedItemType) setSelectedPages([]);
    }, [selectedItemType, setSelectedPages]);

    const closeNewSlideModal = () =>
        setNewSlideData({ slideTitle: '', creating: false, openModal: false, slideCreationError: '' });

    const handleError = (error: any) => {
        handleLoading(false);
        if (error instanceof Error)
            dispatch(
                showAlert({
                    isVisible: true,
                    type: alertTypes.error,
                    message: error.message,
                })
            );
    };

    const handleLoading = (value: boolean) => {
        dispatch(toggleSavingOutlineItem({ savingOutlineItem: value }));
        setLoading(value);
    };

    // add new page/slide
    const createNewPage = async () => {
        if (selectedProject.id !== '-1') {
            try {
                setNewSlideData({ creating: true });
                handleLoading(true);
                const updatedOutline = await createOutlinePage(selectedProject.id, slideTitle || '');
                dispatch(setOutline({ outline: updatedOutline, projectId: selectedProject.id }));
                dispatch(updateOutlineProject({ outline: updatedOutline, projectId: selectedProject.id }));
                const pageId = updatedOutline.pages[updatedOutline.pages.length - 1].id;
                // add highlighted text as item to this slide
                await addOutlineItem(pageId, selectedItemType as string);
                closeNewSlideModal();
                handleLoading(false);
                setNewSlideData({ creating: false });
                close();
            } catch (error) {
                handleError(error);
            }
        }
    };

    const addOutlineItem = async (pageId: string, itemType: string) => {
        if (!selectedPages.includes(pageId)) {
            const text =
                itemType === OutlineItemType.TEXT
                    ? [
                          {
                              type: 'paragraph',
                              children: [
                                  {
                                      text: highlightedText,
                                      bold: false,
                                      italic: false,
                                      underline: false,
                                      numberedList: false,
                                      bulletedList: false,
                                  },
                              ],
                          },
                      ]
                    : [
                          {
                              type: 'bulletedList',
                              children: [
                                  {
                                      type: 'listItem',
                                      children: [
                                          {
                                              text: highlightedText,
                                              bold: false,
                                              italic: false,
                                              underline: false,
                                              numberedList: false,
                                              bulletedList: false,
                                          },
                                      ],
                                  },
                              ],
                          },
                      ];
            const createItem = itemType === OutlineItemType.TEXT ? createTextItem : createListItem;
            const newItems = await createItem(pageId, speakerId as string, text as Descendant[], paperCitation || '');
            dispatch(updateItems({ pageId, items: newItems }));
            setSelectedPages([...selectedPages, pageId]);
        }
    };

    const addItem = async (pageId: string, selectedItemType: string) => {
        try {
            handleLoading(true);
            await addOutlineItem(pageId, selectedItemType);
            handleLoading(false);

            close();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <ReaderViewModalWrapper
            title={'Add highlighted text to...'}
            close={close}
            open={Boolean(selectedItemType)}
            loader={loading}
            onClose={close}
            handleCreate={() => setNewSlideData({ openModal: true })}
            createBtnLabel="Create new slide..."
        >
            <div className={classes.listContainer}>
                {outline?.pages.map((item, index) => {
                    return (
                        <div
                            role={'presentation'}
                            onClick={() =>
                                item.items.length !== 2 ? addItem(item.id, selectedItemType as string) : {}
                            }
                            id={item.id}
                            className={`${classes.slideContainer} ${selectedPages.includes(item.id) &&
                                classes.slected} ${item.items.length === 2 && classes.slideFull}`}
                        >
                            <div className={classes.fullFlex}>
                                <span className={classes.indexText}>{index + 1}.</span>
                                {item.title}
                            </div>
                            {item.items.length === 2 && <span className={classes.slideFullText}>SLIDE FULL</span>}
                        </div>
                    );
                })}
            </div>

            <InputModal
                title={'Name your new slide'}
                placeholder={'Slide Name'}
                inputLabel={'Slide Name'}
                value={slideTitle || ''}
                titleError={slideCreationError || ''}
                loading={Boolean(creating)}
                isOpen={Boolean(openModal)}
                handleInputChange={({ target: { value } }) => setNewSlideData({ slideTitle: value })}
                handleSubmit={createNewPage}
                closeModal={closeNewSlideModal}
            />
        </ReaderViewModalWrapper>
    );
};
