import React, { useState } from 'react';
import { OutlineItemType, OutlinePage as OutlinePageType, OutlineModes } from '../../../../types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import { useOutlinePageStyles, useTooltipsStyles } from './styles';
import { OutlineItems } from '../outlineItems';
import { AddItemMenu } from './components/menu';
import { updateOutlinePageTitle, createImageItem, createTextItem, createListItem } from '../../../../backend/outline';
import { AddIcon, TitleIcon } from '../../../../assets/icons';
import { toggleSavingOutlineItem, updateItems, updatePageTitle } from '../../../../redux/reducers/projectOutline';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { colors } from '../../../../assets/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { showAlert } from '../../../../redux/reducers';
import { alertTypes } from '../../../../constants';
import { emitCustomEvent } from 'react-custom-events';
import { HelpInfoTooltip } from '../../../common/helpInfoToolTip';
import { TitleSlideHelpContent } from './titleSlideInfo';

export interface OutlinePageProps {
    page: OutlinePageType;
    onDelete: (pageId: string) => void;
}

const createItemFunctions = {
    [OutlineItemType.TEXT]: createTextItem,
    [OutlineItemType.LIST]: createListItem,
    [OutlineItemType.IMAGE]: createImageItem,
};

export const OutlinePage = ({ page, onDelete }: OutlinePageProps) => {
    const tooltipStyles = useTooltipsStyles();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [title, setTitle] = useState<string>(page.title);
    const [titleUpdated, setTitleUpdated] = useState<boolean>(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [focused, setFocused] = useState<boolean>(false);
    const { mode, updatingPages } = useSelector((state: RootState) => state.ProjectOutline);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const { items, id: pageId } = page;
    const updating = updatingPages?.includes(pageId);
    const styles = useOutlinePageStyles({ updating });

    const dispatch = useDispatch();

    const showAddItemMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (items.length < 2) {
            setMenuAnchor(event.currentTarget);
        }
    };

    const closeAddItemMenu = () => {
        setMenuAnchor(null);
    };

    const onNewItemClicked = async (type: OutlineItemType) => {
        try {
            closeAddItemMenu();

            const create = createItemFunctions[type];

            if (create == null) {
                return;
            }

            dispatch(toggleSavingOutlineItem({ savingOutlineItem: true }));
            const newItems = await create(pageId, speakerId as string);

            emitCustomEvent('outline-page-updated', {
                outlinePageId: pageId,
            });
            dispatch(updateItems({ pageId, items: newItems }));
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: false }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
        }
    };

    const titleChanged = (event: any) => {
        setTitle(event.target.value);
        setTitleUpdated(true);
    };

    const titleLostFocus = async () => {
        if (titleUpdated) {
            try {
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: true }));
                const updatedTitle = await updateOutlinePageTitle(title, pageId, speakerId as string);

                dispatch(updatePageTitle({ title: updatedTitle, pageId: pageId }));

                // Outline page changes
                // to fetch the updated slide for respective page
                emitCustomEvent('outline-page-updated', {
                    outlinePageId: pageId,
                });

                setTitle(updatedTitle);
                setTitleUpdated(false);
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, error: false }));
            } catch (error) {
                if (error instanceof Error) {
                    dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, error: true }));
                    dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
                }
            }
        }
    };

    const handleTooltipOpen = () => {
        if (items.length > 1) {
            setTooltipOpen(true);
        }
    };
    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <Box
            className={mode === OutlineModes.READER ? styles.rootInReader : styles.root}
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
        >
            <Box className={styles.numberContainer}>
                <Typography className={styles.number}>{page.number + 1}</Typography>
                <Divider className={styles.numberDivider} />
            </Box>
            {page.number === 0 && (
                <Box data-testid={'title-tag'} className={styles.titleTag}>
                    <p className={styles.titleSlide}>TITLE PAGE</p>
                    <HelpInfoTooltip place={'right-start'} title={<TitleSlideHelpContent />} />
                </Box>
            )}
            <Box data-testid={'addTitleField'} className={styles.titleContainer} onMouseLeave={titleLostFocus}>
                <TitleIcon
                    className={mode === OutlineModes.READER ? styles.titleIconInReader : styles.titleIcon}
                    viewBox={'0 0 35 35'}
                />

                <TextField
                    value={title}
                    onChange={titleChanged}
                    className={styles.titleInput}
                    placeholder="Click to add title"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 90 }}
                />
            </Box>
            <OutlineItems items={items} pageId={pageId} />
            <Box className={styles.actionsWrapper}>
                <div className={styles.actionsConatiner}>
                    <Tooltip
                        open={tooltipOpen}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                        title="2 item limit per page, excluding title block"
                        placement="right-end"
                        classes={tooltipStyles}
                    >
                        <div className={styles.plusIconSection}>
                            <AddIcon
                                data-testid={'addMenuIcon'}
                                htmlColor={items.length > 1 ? colors.skyGray : colors.white}
                                onClick={showAddItemMenu}
                                className={mode === OutlineModes.READER ? styles.plusIconInReader : styles.plusIcon}
                                viewBox={'0 0 35 35'}
                            />
                        </div>
                    </Tooltip>

                    <div className={styles.deleteContainer}>
                        {focused && page.number !== 0 && (
                            <Box
                                data-testid={'delete-tag'}
                                className={styles.delete}
                                onClick={() => (updating ? {} : onDelete(pageId))}
                            >
                                <DeleteIcon className={styles.deleteIcon} />
                                <Typography className={styles.deleteText}>Delete this page</Typography>
                            </Box>
                        )}
                    </div>
                </div>
            </Box>
            <AddItemMenu onClose={closeAddItemMenu} onItemSelected={onNewItemClicked} anchorElement={menuAnchor} />
        </Box>
    );
};
