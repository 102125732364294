import { useState, useEffect, useRef } from 'react';
import { style } from './style';
import { Storage } from '@aws-amplify/storage';
import { CircularProgress, IconButton } from '@material-ui/core';
import { updateImageItem } from '../../../../../../backend/outline';
import { OutlineItemProperties, OutlineItemType } from '../../../../../../types';
import { toggleSavingOutlineItem, showAlert } from '../../../../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { alertTypes, gtm } from '../../../../../../constants';
import { imageDetailsType, OutlineModes } from '../../../../../../types';
import { RootState } from '../../../../../../redux/combineReducers';
import config from '../../../../../../config';
import { ImageMarkup } from './components/markupModal';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import moment from 'moment';
import { CitationComp, OutlineItemWrapper } from '../../components';
import { updateOutlineItem } from '../../../../../../redux/reducers/projectOutline';
import { GTMService } from '../../../../../../services/gtmService';
import { CancelOutlined } from '@material-ui/icons';

Storage.configure({
    level: 'private',
    contentType: 'image',
});

export interface OutlineImageItemProps {
    itemId: string;
    pageId: string;
    properties: OutlineItemProperties;
}

export const OutlineImageItem = ({ itemId, properties, pageId }: OutlineImageItemProps) => {
    const [imageDetails, setImageDetails] = useState<imageDetailsType>({
        url: '',
        key: '',
        originalImageKey: '',
    });
    const [citation, setCitation] = useState('');
    const [itemUpdated, setItemUpdated] = useState<boolean>(false);
    const [updatedProperties, setUpdatedProperties] = useState<OutlineItemProperties>({
        imageKey: null,
        citation: null,
        originalImageKey: null,
    });
    const [showMarkupOption, setShowMarkupOption] = useState(false);
    const [openMarkupModal, setOpenMarkupModal] = useState(false);
    const [imageLoad, setImageLoad] = useState(true);
    const [startSnipping, setStartSnipping] = useState<boolean>();
    const { citation: paperCitation } = useSelector((state: RootState) => state.PaperFile);
    const classes = style();
    const dispatch = useDispatch();
    const { mode } = useSelector((state: RootState) => state.ProjectOutline);
    const { id: speakerId, identityId } = useSelector((state: RootState) => state.Speaker);
    const imageRef: any = useRef(null);
    const imageInput: any = useRef(null);
    const [capturing, setCapturing] = useState<boolean>(false);
    const [showUploadOptions, setShowUploadsOptions] = useState<boolean>(false);

    useEffect(() => {
        setCitation(properties.citation && properties.citation !== 'null' ? properties.citation : '');
        if (properties.imageKey && properties.originalImageKey)
            setImageDetails({
                url: `${config.cloudFront.url}/private/${identityId}/${properties.imageKey}`,
                key: properties.imageKey,
                originalImageKey: properties.originalImageKey,
            });
    }, [properties, identityId, setImageDetails,setCitation]);

    const emitOutlinePageUpdatedEvent = () => {
        emitCustomEvent('outline-page-updated', {
            outlinePageId: pageId,
        });
    };

    const itemLostFocus = async () => {
        if (itemUpdated) {
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, itemId }));
            try {
                const updatedImageItem = await updateImageItem(null, citation, null, itemId, speakerId as string);

                emitOutlinePageUpdatedEvent();

                setUpdatedProperties(updatedImageItem.properties);
                const newProperties = updatedImageItem.properties;
                setCitation(newProperties.citation && newProperties.citation !== 'null' ? newProperties.citation : '');

                dispatch(updateOutlineItem({ pageId, itemId, properties: updatedImageItem.properties }));

                setItemUpdated(false);
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
            } catch (error) {
                if (error instanceof Error)
                    dispatch(showAlert({ isVisible: true, type: alertTypes.error, message: error.message }));
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
            }
        }
    };

    const editItem = async () => {
        if (mode === OutlineModes.READER) setShowUploadsOptions(true);
        else if (imageInput && imageInput.current) imageInput.current.click();
    };

    const editImageItem = async () => {
        setShowUploadsOptions(false);
        if (imageInput && imageInput.current) imageInput.current.click();
    };

    const onInputImage = async (ev: any, update: boolean = false) => {
        triggerGtmEvent(gtm.events.UPLOAD_IMAGE);
        const image = ev?.target?.files[0];
        if (image) {
            if (!image.name.match(/.(jpg|jpeg|png|gif)$/i)) alert('not an image');
            else {
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, itemId }));
                const name = `${moment().format('YY-MM-DD')} at ${moment().format('h.mm.ss A')}`;

                setImageDetails({
                    key: name,
                    url: URL.createObjectURL(image),
                    originalImageKey: name,
                });
                try {
                    const { key }: any = await Storage.put(name, image, { cacheControl: 'no-cache' });
                    const updatedImageItem = await updateImageItem(
                        key,
                        citation,
                        name,
                        itemId,
                        speakerId as string,
                        imageRef?.current?.naturalWidth as number,
                        imageRef?.current?.naturalHeight as number
                    );

                    emitOutlinePageUpdatedEvent();

                    setUpdatedProperties(updatedImageItem.properties);
                    dispatch(
                        updateOutlineItem({
                            pageId,
                            itemId,
                            properties: updatedImageItem.properties,
                        })
                    );
                    setShowUploadsOptions(false);
                    dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
                } catch (error) {
                    if (error instanceof Error)
                        dispatch(
                            showAlert({
                                isVisible: true,
                                type: alertTypes.error,
                                message: error.message,
                            })
                        );
                    dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
                }
            }
        }
    };

    const uploadSnippedImage = ({ image, width, height }: { image: string | null; width: number; height: number }) => {
        if (startSnipping && image && image !== '' && image !== 'data:,') {
            setCapturing(false);
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, itemId }));
            const name = `Snipped ${moment().format('YY-MM-DD')} at ${moment().format('h.mm.ss A')}`;
            setImageDetails({
                key: name,
                url: image,
                originalImageKey: name,
            });
            if (paperCitation) setCitation(paperCitation);

            setStartSnipping(false);
            (async () => {
                try {
                    const imageData = await fetch(image);
                    const blobData = await imageData.blob();
                    const { key }: any = await Storage.put(name, blobData, {
                        contentType: 'image/jpeg',
                        level: 'private',
                        cacheControl: 'no-cache',
                    });
                    const updatedImageItem = await updateImageItem(
                        key,
                        paperCitation ? paperCitation : citation,
                        name,
                        itemId,
                        speakerId as string,
                        width,
                        height
                    );
                    triggerGtmEvent(gtm.events.SNIP_FROM_PDF);
                    emitOutlinePageUpdatedEvent();
                    setUpdatedProperties(updatedImageItem.properties);
                    setShowUploadsOptions(false);
                    dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
                    dispatch(updateOutlineItem({ pageId, itemId, properties: updatedImageItem.properties }));
                } catch (error) {
                    if (error instanceof Error)
                        dispatch(
                            showAlert({
                                isVisible: true,
                                type: alertTypes.error,
                                message: error.message,
                            })
                        );
                    setStartSnipping(false);
                    dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId, itemId }));
                    setShowUploadsOptions(false);
                }
            })();
        } else setStartSnipping(false);
    };

    useCustomEventListener(`snip-image-result-${itemId}`, uploadSnippedImage);

    const snipImage = () => {
        emitCustomEvent('snip-image', { itemId });
        setStartSnipping(true);
    };

    const toggleMarkupModal = () => {
        setOpenMarkupModal(!openMarkupModal);
    };

    const triggerGtmEvent = (event: string) => {
        const gtmService = new GTMService();
        gtmService.handleEvent({
            event,
            userId: speakerId,
        });
    };

    const handleEditImage = () => {
        setShowUploadsOptions(false);
    };

    useCustomEventListener(`start-capturing`, () => setCapturing(Boolean(startSnipping)));

    return (
        <OutlineItemWrapper
            imageKey={updatedProperties.imageKey}
            pageId={pageId}
            itemId={itemId}
            itemLostFocus={itemLostFocus}
            itemType={OutlineItemType.IMAGE}
        >
            {capturing ? (
                <div className={classes.circularProgressContainer}>
                    <CircularProgress size={20} className={classes.circularProgress} />
                </div>
            ) : startSnipping ? (
                <div className={classes.holdingContent}>Highlight the area you’d like to snip.</div>
            ) : (
                <div className={classes.content}>
                    {imageDetails.originalImageKey ? (
                        <div
                            onMouseEnter={() => setShowMarkupOption(true)}
                            onMouseLeave={() => setShowMarkupOption(false)}
                            className={classes.imagePreviewContainer}
                        >
                            <div style={{ opacity: showMarkupOption && imageLoad ? 0.2 : 1 }}>
                                <p className={classes.imageName}>{imageDetails.originalImageKey}</p>
                                <img
                                    ref={imageRef}
                                    src={imageDetails.url}
                                    className={classes.previewImage}
                                    alt="uploaded image"
                                    loading="eager"
                                    onError={() => setImageLoad(false)}
                                />
                            </div>
                            {showMarkupOption && imageLoad && (
                                <div className={classes.updateTextContainer}>
                                    {showUploadOptions && mode === OutlineModes.READER ? (
                                        <>
                                            <span
                                                className={classes.updateTextStyle}
                                                onClick={snipImage}
                                                role="presentation"
                                            >
                                                Snip PDF
                                            </span>
                                            <span className={classes.orText}>or</span>
                                            <span className={classes.updateTextStyle} onClick={editImageItem}>
                                                Upload
                                            </span>
                                            <span>
                                                <IconButton aria-label="cancel" onClick={handleEditImage} size="small">
                                                    <CancelOutlined style={{ fontSize: 'initial' }} />
                                                </IconButton>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                className={classes.updateTextStyle}
                                                onClick={() => setOpenMarkupModal(true)}
                                            >
                                                Markup
                                            </span>
                                            <span className={classes.orText}>or</span>
                                            <span className={classes.updateTextStyle} onClick={editItem}>
                                                Replace
                                            </span>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={classes.labelContainer}>
                            {mode === OutlineModes.READER && (
                                <div className={classes.snipContainer}>
                                    <span className={classes.snipImage} onClick={snipImage} role="presentation">
                                        Snip from PDF
                                    </span>
                                    &nbsp;or&nbsp;
                                </div>
                            )}
                                <div className={classes.inputContainer}>
                                    <span className={classes.inputLabel}>Upload Image</span>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        placeholder=""
                                        id="imageURI"
                                        className={classes.inputImage}
                                        alt="image"
                                        onChange={onInputImage}
                                        name="image"
                                    />
                                </div>
                        </div>
                    )}
                    <CitationComp setItemUpdated={setItemUpdated} citation={citation} setCitation={setCitation} />
                </div>
            )}

            {openMarkupModal && (
                <ImageMarkup
                    imageDetails={imageDetails}
                    openMarkupModal={openMarkupModal}
                    toggleMarkupModal={toggleMarkupModal}
                    setOpenMarkupModal={setOpenMarkupModal}
                    setImageDetails={setImageDetails}
                    itemId={itemId}
                    setUpdatedProperties={setUpdatedProperties}
                    citation={citation}
                    pageId={pageId}
                />
            )}
            <input
                ref={imageInput}
                type="file"
                accept="image/*"
                placeholder=""
                id="updatedImageURI"
                style={{ visibility: 'hidden', height: 40, width: 40 }}
                className={classes.updatedImageInput}
                alt="Updated image"
                onChange={(e: any) => onInputImage(e, true)}
                name="updated image"
            />
        </OutlineItemWrapper>
    );
};
