import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DoubleArrowDownIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="37" height="45" viewBox="0 0 37 45">
            <path
                d="M9.25 13.875L18.5 23.125L27.75 13.875"
                stroke="#006366"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.25 21.875L18.5 31.125L27.75 21.875"
                stroke="#006366"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
