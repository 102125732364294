import React from 'react';
import { ErrorComponent } from '../common/errorComponent';

export const PageNotFound = () => {
    return (
        <ErrorComponent
            error="404: Page not found"
            description={
                <p>
                    Sorry, the content you’re looking for doesn’t exist. Either
                    it was <br /> removed or the link was mistyped.
                </p>
            }
        />
    );
};
