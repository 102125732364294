import * as yup from 'yup'; // for everything
import { matchProxyUrlFormat } from '../utils';

export const signupValidation = yup.object().shape({
    name: yup.string().required('This field is required'),
    email: yup
        .string()
        .email('Please enter in the format: yourname@example.com')
        .required('This field is required'),
    password: yup
        .string()
        .min(8, 'Password must contain atleast 8 characters')
        .required('This field is required'),
    repeatPassword: yup
        .string()
        .required('This field is required')
        .oneOf([yup.ref('password')], 'Password does not match'),
});

export const loginValidation = yup.object().shape({
    email: yup
        .string()
        .email('Please enter in the format: yourname@example.com')
        .required('This field is required'),
    password: yup.string().required('This field is required'),
});

export const accountSettingsValidation = yup.object().shape({
    name: yup.string().required('This field is required'),
    professionalCategory: yup.string().required('This field is required'),
    specialty: yup.string().required('This field is required'),
    institutionId: yup.string().required('This field is required'),
    country: yup.string().required('This field is required'),
});

export const speakerPreferencesValidation = yup.object().shape({
    citationStyle: yup.string().required('This field is required'),
    proxyUrl: yup.string().test('test-name', '', function(value) {
        if (value) {
            if (!matchProxyUrlFormat(value)) {
                const { path, createError } = this;
                return createError({
                    path,
                    message: 'Enter url in this format: http://libaccess.sjlibrary.org/login?url=$@',
                });
            } else {
                return true;
            }
        } else return true;
    }),

    fullTextModal: yup.boolean(),
});

export const forgotPasswordValidation = yup.object().shape({
    email: yup
        .string()
        .email('Please enter in the format: yourname@example.com')
        .required('This field is required'),
});

export const resetPasswordValidation = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password must contain atleast 8 characters')
        .required('This field is required'),
    repeatPassword: yup
        .string()
        .required('This field is required')
        .oneOf([yup.ref('password')], 'Password does not match'),
});
