import { useEffect, useState } from 'react';
import { style } from './style';
import { IconButton, Tooltip } from '@material-ui/core';
import { SlidesListModal } from './components';
import { FormatListBulleted, Reorder } from '@material-ui/icons';
import { OutlineItemType, TEXT_OPTIONS } from '../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { formatHighlightedText, useQuery } from '../../../../utils';

interface HighlightedTextOptionsProps {
    children: any;
    scale: number;
}

export const HighlightedTextOptions = ({ scale, children }: HighlightedTextOptionsProps) => {
    const [selectionStart, setSelectionStart] = useState<boolean>(false);
    const [highlightedText, setHighlightedText] = useState<string>('');
    const [{ mouseUpY, mouseUpX }, setMouseUpPosition] = useState<{ mouseUpY: number; mouseUpX: number }>({
        mouseUpY: 0,
        mouseUpX: 0,
    });
    const [selectedItemType, setSelectedItemType] = useState<string | null>(null);
    const [textOption, setTextOption] = useState<TEXT_OPTIONS>(TEXT_OPTIONS.TYPE_TEXT);

    const Projects = useSelector((state: RootState) => state.Projects);
    const query = useQuery();
    const projectId = query.get('projectId');
    const [selectedProject] = Projects.filter((project) => project.id === projectId);
    const { enableHighlight } = useSelector((state: RootState) => state.ProjectOutline);

    const classes = style();

    const removeListner = () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
    };

    useEffect(() => {
        document.addEventListener('selectionchange', handleSelectionChange);
        return removeListner;
    }, []);

    const handleSelectionChange = () => {
        const selection = window.getSelection();
        if (selection && selection.toString() !== '') setSelectionStart(true);
        else if (!selection || selection?.toString() === '') {
            setSelectionStart(false);
        }
    };

    const handleMouseUp = (e: any) => {
        var elementId = (e.srcElement || e.target).id;
        if (selectionStart && elementId !== 'text-icon' && elementId !== 'list-icon') {
            const selection = window.getSelection();
            if (selection && selection.toString() !== '' && selection.toString() !== highlightedText) {
                setHighlightedText(formatHighlightedText(selection));

                // calculate height and width of document container and scrolling offsets
                const documentContainer = document.getElementById('highlight-wrapper');
                const documentContainerRects = documentContainer?.getClientRects();
                let leftOffset = (documentContainerRects && documentContainerRects[0].left) || 0;
                const totalWidth = e.clientX + (documentContainer && documentContainer?.scrollLeft) || 0;

                let topOffset = (documentContainerRects && documentContainerRects[0].top) || 120;
                const totalHeight = e.clientY + (documentContainer && documentContainer?.scrollTop) || 0;

                setMouseUpPosition({
                    mouseUpX: totalWidth - leftOffset,
                    mouseUpY: totalHeight - topOffset + scale * 10,
                });
            }
        }
    };

    const handleMouseDown = (e: any) => {
        var elementId = (e.srcElement || e.target).id;
        const selection = window.getSelection();
        if (
            selection &&
            selectionStart &&
            selection.toString() !== highlightedText &&
            elementId !== 'text-icon' &&
            elementId !== 'list-icon'
        ) {
            setSelectionStart(false);
            // selection.deleteFromDocument();
            setMouseUpPosition({ mouseUpX: 0, mouseUpY: 0 });
        }
    };

    const toggleModal = (itemType?: string) => {
        setSelectedItemType(itemType || null);
        if (!itemType) {
            setHighlightedText('');
            setSelectionStart(false);
        }
    };

    const closeModal = () => {
        setSelectedItemType(null);
        setHighlightedText('');
        setSelectionStart(false);
    };

    return (
        <div className={classes.root} id="highlight-wrapper" onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>
            {children}

            {!!(mouseUpY && mouseUpX && selectionStart && Boolean(highlightedText)) &&
                textOption === TEXT_OPTIONS.TYPE_TEXT &&
                !enableHighlight && (
                    <div className={classes.iconButtonContainer} style={{ top: mouseUpY, left: mouseUpX }}>
                        <div className={classes.icons}>
                            <Tooltip title="Add text item">
                                <IconButton
                                    id="text-icon"
                                    onClick={() => toggleModal(OutlineItemType.TEXT)}
                                    size="small"
                                    className={classes.iconButton}
                                >
                                    <Reorder id="text-icon" className={classes.icon} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Add list item">
                                <IconButton
                                    id="list-icon"
                                    onClick={() => toggleModal(OutlineItemType.LIST)}
                                    size="small"
                                    className={classes.iconButton}
                                >
                                    <FormatListBulleted id="list-icon" className={classes.icon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )}

            <SlidesListModal
                selectedProject={selectedProject}
                selectedItemType={selectedItemType}
                highlightedText={highlightedText}
                close={closeModal}
            />
        </div>
    );
};
