import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: 'relative',
            background: colors.grayBackground,
        },
        outlineContainter: {
            width: '100%',
            height: 'calc(100vh - 27px)',
            minHeight: 'calc(100vh -27px)',
            overflow: 'auto',
            background: colors.grayBackground,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 0px 5px #E5E9F2',
            height: '60px',
            padding: 15,
            background: colors.white,
        },
        subHeadingContainer: {
            display: 'flex',
        },
        subHeading: {
            color: colors.smoke,
            flex: 1,
        },
        syncText: {
            color: colors.disabledMenu,
            fontStyle: 'italic',
            fontSize: 13,
        },
        projectTitle: {
            margin: 0,
            fontSize: 20,
            color: colors.text2,
        },
        contentContainer: {
            flex: 1,
            backgroundColor: colors.grayBackground,
            padding: '15px 15px 80px 15px',
        },
        drawerNavbar: {
            display: 'flex',
            cursor: 'pointer',
        },
        backButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        drawerNavbarText: {
            color: 'gray',
        },
        viewSlidesContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        innerContainer: {
            display: 'flex',
            cursor: 'pointer',
        },
        slidesIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        slidesIcon: {
            color: 'gray',
            fontSize: 18,
            marginRight: 5,
        },
        viewSlidesText: {
            color: 'gray',
        },
        backButton: {
            color: 'gray',
        },
        projectBox: {
            flex: 1,
            display: 'flex',
        },
    })
);
