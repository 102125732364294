import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CheckboxProps,
    Typography,
    withStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addSpeakerOnboarding } from '../../../../backend/onboarding';
import { alertTypes } from '../../../../constants';
import { showAlert } from '../../../../redux/reducers';
import { OnboardingSection, SpeakerOnboarding } from '../../../../types';
import { MuiCMLink } from '../../../common';
import { compStyles, useStyles } from './style';

interface Props {
    onboardingSections: OnboardingSection[] | undefined;
    setSpeakerOnboardings: React.Dispatch<React.SetStateAction<SpeakerOnboarding[] | undefined>>;
    speakerOnboardings: SpeakerOnboarding[] | undefined;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    speakerId: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const GreenCheckbox = withStyles(compStyles.checkbox)((props: CheckboxProps) => (
    <Checkbox
        color="default"
        disableRipple
        disableFocusRipple
        disableTouchRipple
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<CheckCircleIcon />}
        {...props}
    />
));

const StyledAccordion = withStyles(compStyles.accordion)(Accordion);
const StyledAccordionSummary = withStyles(compStyles.accordionSummary)(AccordionSummary);
const StyledAccordionDetails = withStyles(compStyles.accordionDetails)(AccordionDetails);

export const TutorialsContent: React.FC<Props> = ({
    onboardingSections,
    setSpeakerOnboardings,
    speakerOnboardings,
    setProgress,
    speakerId,
    setLoading,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const tutorialWatched = (onboardingId: string): boolean => {
        if (speakerOnboardings?.length)
            return speakerOnboardings.some((speakerOnboarding) => speakerOnboarding.onboardingId === onboardingId);
        else return false;
    };

    useEffect(() => {
        // first we get how many tutorials are there in all onboarding sections
        // then we check if any onboarding tutorial exists in speakerOnboardings. if it is, we count this as watched by increasing tutorialsCompleted counter
        // then we get the progress in percentage by dividing tutorialsCompleted by totalTutorials
        if (onboardingSections && speakerOnboardings) {
            let allTutorials = 0;
            let tutorialsCompleted = 0;
            for (const onboardingSection of onboardingSections) {
                const onboards = onboardingSection.onboards;
                allTutorials = allTutorials + onboards.length;
                for (const onboarding of onboards) {
                    if (tutorialWatched(onboarding.id)) tutorialsCompleted = tutorialsCompleted + 1;
                }
            }
            const progress = Math.round((tutorialsCompleted / allTutorials) * 100);
            setProgress(progress);
        }
    }, [onboardingSections, speakerOnboardings]);

    const handleOnboardingClick = async (onboardingId: string) => {
        if (!tutorialWatched(onboardingId)) {
            setLoading(true);
            try {
                const speakerOnboarding = await addSpeakerOnboarding(speakerId, onboardingId);
                setSpeakerOnboardings((prevSpeakerOnboardings) => {
                    if (prevSpeakerOnboardings) {
                        return [...prevSpeakerOnboardings, speakerOnboarding];
                    }
                    return [speakerOnboarding];
                });
                setLoading(false);
            } catch (error) {
                if (error instanceof Error)
                    dispatch(
                        showAlert({
                            isVisible: true,
                            message: error.message,
                            type: alertTypes.error,
                        })
                    );
                setLoading(false);
            }
        }
    };
    return (
        <div className={classes.container} data-testid="onboardings-list">
            {onboardingSections &&
                onboardingSections.map((onboardingSection, index) => (
                    <Fragment key={onboardingSection.id}>
                        <Typography
                            variant="body2"
                            className={classes.tutorialTitle}
                            data-testid={`section-${index}-title`}
                        >
                            {onboardingSection.title}
                        </Typography>
                        <div data-testid={`section-${index}-tutorials`}>
                            {onboardingSection.onboards.map((onboarding, index) => (
                                <StyledAccordion elevation={0} square key={index}>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        IconButtonProps={{ size: 'small', edge: 'start' }}
                                    >
                                        <GreenCheckbox checked={tutorialWatched(onboarding.id)} />
                                        <Typography className={classes.taskTitle}>{onboarding.title}</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <div className={classes.taskDetails}>
                                            <p className={classes.tutorialHint}>
                                                <strong>HINT:&nbsp;</strong>
                                                {onboarding.hint}
                                            </p>
                                            <MuiCMLink
                                                href={onboarding.videoLink}
                                                target="_blank"
                                                underline="always"
                                                rel="noreferrer"
                                                className={classes.tutorialLink}
                                                onClick={() => handleOnboardingClick(onboarding.id)}
                                            >
                                                Task video title link
                                            </MuiCMLink>
                                        </div>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                            ))}
                        </div>
                    </Fragment>
                ))}
        </div>
    );
};
