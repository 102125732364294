import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pdfjs } from 'react-pdf';
import { useHistory, useLocation } from 'react-router';

import {
    ViewerWrapper,
    ProjectSelector,
    SavePaper,
    ProjectDetail,
    PaperSwitcher,
    Loader,
    NoPaperFileAlert,
    PaperReferencesSwitcher,
} from './components';

import { RootState } from '../../redux/combineReducers';
import {
    setCitation,
    showAlert,
    setPaperFile,
    togglePaperReferenceSwitcher,
    togglePaperSwitcher,
    resetPaperFile,
} from '../../redux/reducers';
import { getPaperByDoi, getPaperByJobId, getPaperCitation, getPaperByKey } from '../../backend/paper';

import { DrawerToggler, Navbar } from '../common';
import { style } from './style';
import { alertBarHeight, alertTypes } from '../../constants';
import { PaperDataType, PaperFile } from '../../types';
import { generateMetaData, useQuery } from '../../utils';
import config from '../../config';

/** It is latest script provided in react-pdf library v5*/
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export function Reader() {
    const query = useQuery();
    const classes = style();
    const dispatch = useDispatch();
    const doi = query.get('doi');
    const key = query.get('key');
    const source = query.get('source');
    const projectId = query.get('projectId');
    const pmcid = query.get('pmcid');
    const history = useHistory();
    const location = useLocation();

    const Projects = useSelector((state: RootState) => state.Projects);
    const SelectedPaperFile = useSelector((state: RootState) => state.PaperFile);
    const ModalHandler = useSelector((state: RootState) => state.ModalHandler);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const SpeakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [loadingPaper, setLoadingPaper] = useState<boolean>(false);
    const [paperUrl, setPaperUrl] = useState('');
    const [outlineDrawerOpen, setOutlineDrawerOpen] = useState(true);
    const [onStartCaptureRef, setOnStartCaptureRef] = useState(() => () => {});
    const [drawerToggler, setDrawerToggler] = useState(false);
    const [openReferenceDrawer, setOpenReferenceDrawer] = useState(false);
    const [switchPaperFile, setSwitchPaperFile] = useState<PaperFile | null>(null);

    const [selectedProject] = Projects.filter((project) => project.id === projectId);
    const { id: paperFileId, paper } = SelectedPaperFile;

    const readablePaperFiles =
        selectedProject?.paperFiles &&
        selectedProject?.paperFiles?.filter((paperFile: PaperFile) => Boolean(paperFile.key) === true);

    useEffect(() => {
        if (ModalHandler.paperSwitcherDrawer) setDrawerToggler(true);
    }, []);

    /** if user enter incorrect project id */
    useEffect(() => {
        if (Projects.length && projectId) {
            const [_selectedProject] = Projects.filter((project) => project.id === projectId);
            if (!_selectedProject) {
                dispatch(showAlert({ isVisible: true, type: alertTypes.error, message: 'Project not found' }));
            }
        }
    }, [Projects]);

    useEffect(() => {
        const prevTitle = document.title;
        const documentTitle = paper ? paper.title : SelectedPaperFile.name;
        document.title = `nimdone - ${documentTitle}`;
        return () => {
            document.title = prevTitle;
        };
    }, [paper]);

    const handleOutlineDrawer = (value: boolean) => setOutlineDrawerOpen(value);

    const initiatePaperFetch = async () => {
        if (doi) handlePaperByDoi(doi, source as string, pmcid as string);
        else if (key) handlePaperByKey(key);
        else if (readablePaperFiles?.length) {
            history.push({
                pathname: location.pathname,
                search: '?' + new URLSearchParams({ projectId: selectedProject?.id, key: readablePaperFiles[0]?.key! }),
            });
        }
    };

    useEffect(() => {
        if (speakerId) {
            initiatePaperFetch();
        }

        return () => {
            dispatch(resetPaperFile());
        };
    }, [speakerId, switchPaperFile, doi, key, projectId]);

    useEffect(() => {
        setIsSaved(selectedProject?.paperFiles?.some((paperFile) => paperFileId == paperFile.id) ? true : false);
    }, [paperFileId, selectedProject]);

    const fetchCitation = async (paperFile: PaperFile) => {
        const paper = paperFile.paper;

        if (!paper?.doi || paper?.doi === 'undefined') return;

        try {
            const style = SpeakerPreferences.citationStyle;
            const metaData = generateMetaData(paper);
            const { citation } = await getPaperCitation(metaData as PaperDataType, style, speakerId!);
            dispatch(setCitation({ citation: citation as string }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    };

    const pollPaper = async (doi: string, speakerId: string, jobId: string, keyToPaperInStorage: string) => {
        try {
            const jobResponse = await getPaperByJobId(doi, speakerId, jobId, keyToPaperInStorage);
            if (jobResponse?.status === 'completed') {
                dispatch(
                    setPaperFile({
                        ...jobResponse.paperFile!,
                        isCreateProjectWithPaper: false,
                    })
                );
                setPaperUrl(jobResponse.url as string);
                fetchCitation(jobResponse.paperFile!);
                setLoadingPaper(false);
            } else {
                setTimeout(() => pollPaper(doi, speakerId, jobId, keyToPaperInStorage), 1000);
            }
        } catch (error) {
            setLoadingPaper(false);

            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    };

    //check paper if not exists start downloading
    const handlePaperByDoi = async (_doi: string, source: string, pmcid?: string) => {
        setLoadingPaper(true);
        try {
            const response = await getPaperByDoi(_doi, speakerId as string, source, pmcid);
            if (response.status === 'completed') {
                dispatch(setPaperFile({ ...response.paperFile!, isCreateProjectWithPaper: false }));
                setPaperUrl(response.url as string);
                fetchCitation(response.paperFile!);
                setLoadingPaper(false);
            } else {
                pollPaper(_doi, speakerId!, response.jobId!, response.keyToPaperInStorage!);
            }
        } catch (error) {
            setLoadingPaper(false);
            if (error instanceof Error)
                dispatch(showAlert({ isVisible: true, message: error.message, type: alertTypes.error }));
        }
    };

    const handlePaperByKey = async (key: string) => {
        setLoadingPaper(true);
        try {
            const response = await getPaperByKey(key, speakerId as string);
            dispatch(setPaperFile({ ...response.paperFile!, isCreateProjectWithPaper: false }));
            fetchCitation(response.paperFile!);
            setPaperUrl(response.url as string);
            setLoadingPaper(false);
        } catch (error) {
            setLoadingPaper(false);
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    };

    const toggleDrawer = () => {
        if (drawerToggler) {
            if (ModalHandler.paperReferenceSwitcherDrawer) setOpenReferenceDrawer(true);
            dispatch(togglePaperSwitcher({ paperSwitcherDrawer: false }));
            dispatch(togglePaperReferenceSwitcher({ paperReferenceSwitcherDrawer: false }));
        } else {
            if (openReferenceDrawer) {
                dispatch(togglePaperReferenceSwitcher({ paperReferenceSwitcherDrawer: true }));
                setOpenReferenceDrawer(false);
            } else dispatch(togglePaperSwitcher({ paperSwitcherDrawer: true }));
        }
        setDrawerToggler(!drawerToggler);
    };

    return (
        <div className={classes.readerPage} data-testid="reader-page">
            <div className={classes.readerNavbar}>
                <Navbar selectedProject={selectedProject} />
            </div>

            <div className={classes.readerContent} style={{ marginTop: isVisible ? alertBarHeight : 0 }}>
                {!!projectId && (
                    <DrawerToggler
                        toggleDrawer={toggleDrawer}
                        open={!drawerToggler}
                        arrowStyle={{
                            transform: drawerToggler ? 'rotate(-180deg)' : 'rotate(0deg)',
                        }}
                    />
                )}

                {selectedProject?.id && (
                    <PaperSwitcher
                        setSwitchPaperFile={setSwitchPaperFile}
                        SelectedPaperFile={SelectedPaperFile}
                        selectedProject={selectedProject}
                        toggleDrawer={toggleDrawer}
                    />
                )}

                {selectedProject?.id &&
                    config.featureFlags.paperReferences &&
                    ModalHandler.paperReferenceSwitcherDrawer && (
                        <PaperReferencesSwitcher
                            SelectedPaperFile={SelectedPaperFile}
                            selectedProject={selectedProject}
                            toggleDrawer={toggleDrawer}
                        />
                    )}

                <div className={classes.wrapperContainer}>
                    {loadingPaper ? (
                        <Loader />
                    ) : (
                        paperUrl && (
                            <ViewerWrapper
                                setOnStartCaptureRef={setOnStartCaptureRef}
                                outlineDrawerOpen={outlineDrawerOpen}
                                isSaved={isSaved}
                                paperUrl={paperUrl}
                                selectedProject={selectedProject}
                                setLoadingPaper={setLoadingPaper}
                            />
                        )
                    )}
                </div>

                {selectedProject?.id ? (
                    <ProjectDetail
                        handleOutlineDrawer={handleOutlineDrawer}
                        onStartCapture={onStartCaptureRef}
                        selectedProject={selectedProject}
                    />
                ) : (
                    <ProjectSelector />
                )}

                <SavePaper setIsSaved={setIsSaved} selectedProject={selectedProject} />
            </div>

            <NoPaperFileAlert selectedProject={selectedProject} />
        </div>
    );
}
