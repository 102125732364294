import { makeStyles } from '@material-ui/core/styles';

export const useSlideStyles = makeStyles(() => ({
    readerViewRoot: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    slideContainer: {
        paddingBottom: 10,
    },
    slidePreviewContainer: {
        display: 'flex',
    },
    slidePreview: {
        display: 'flex',
        flex: 1,
        backgroundColor: 'white',
    },
    slideItem: {
        flex: 1,
        width: '100%',
        display: 'flex',
    },
    layoutIconOuterContainer: {
        marginLeft: 3,
    },
    layoutIconContainer: {
        cursor: 'pointer',
    },
}));
