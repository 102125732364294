import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const NimdoneIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="100" height="100" viewBox="0 0 100 100">
            <path
                d="M37.6309 60.8912C37.6309 67.7224 43.1688 73.2603 50 73.2603C56.8313 73.2603 62.369 67.7224 62.369 60.8912C62.369 54.06 56.8313 48.5221 50 48.5221C43.1688 48.5221 37.6309 54.06 37.6309 60.8912Z"
                fill="url(#paint0_linear_1999_16123)"
            />
            <path d="M68.1795 33.851H79.026V23.0044H68.1795V33.851Z" fill="url(#paint1_linear_1999_16123)" />
            <path d="M58.6314 17.8103H69.478V6.96374H58.6314V17.8103Z" fill="url(#paint2_linear_1999_16123)" />
            <path d="M72.8389 6.96375H79.8026V-3.29018e-05H72.8389V6.96375Z" fill="url(#paint3_linear_1999_16123)" />
            <path
                d="M67.1101 49.8918V62.6508C67.1101 66.4517 65.6263 70.1024 62.975 72.8259L62.2313 73.59C59.4852 76.4104 55.7162 78.0013 51.7798 78.0013H39.0006V100H60.9993V86.477C60.9993 82.6762 62.4829 79.0255 65.1342 76.3021C67.88 73.4815 71.6494 71.8905 75.5858 71.8905H89.1088V49.8918H67.1101Z"
                fill="url(#paint4_linear_1999_16123)"
            />
            <path
                d="M39.0007 21.7824V35.3053C39.0007 39.1061 37.5171 42.7569 34.8657 45.4803H34.8656C32.1197 48.3007 28.3504 49.8916 24.4141 49.8916H10.8912V71.8904H32.8899V59.1312C32.8899 55.3304 34.3735 51.6796 37.0248 48.9563L37.7685 48.1924C40.5145 45.3719 44.2837 43.7808 48.2201 43.7808H60.9994V21.7822H39.0007V21.7824Z"
                fill="url(#paint5_linear_1999_16123)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1999_16123"
                    x1="50"
                    y1="13.8287"
                    x2="50"
                    y2="94.6554"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1999_16123"
                    x1="73.6028"
                    y1="13.8287"
                    x2="73.6028"
                    y2="94.6552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1999_16123"
                    x1="64.0547"
                    y1="13.8286"
                    x2="64.0547"
                    y2="94.6554"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1999_16123"
                    x1="76.3209"
                    y1="13.8286"
                    x2="76.3209"
                    y2="94.6555"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1999_16123"
                    x1="64.0547"
                    y1="13.8287"
                    x2="64.0547"
                    y2="94.6554"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1999_16123"
                    x1="35.9453"
                    y1="13.8287"
                    x2="35.9453"
                    y2="94.6555"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#29ABE2" />
                    <stop offset="1" stopColor="#22B573" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
