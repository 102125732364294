import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

const smallSpace = 8;

export const useOutlineItemStyles = makeStyles(() => ({
    itemWrapper: {
        display: 'flex',
        marginTop: `${2 * smallSpace}px`,
        justifyContent: 'space-between',
        marginLeft: 35,
    },
    readerItemWrapper: {
        display: 'flex',
        marginTop: `${2 * smallSpace}px`,
        justifyContent: 'space-between',
        marginLeft: 15,
    },
    delete: {
        display: 'flex',
        alignItems: 'baseline',
        cursor: 'pointer',
    },
    deleteIcon: {
        width: '12px',
        height: '22px',
    },
    deleteText: {
        marginLeft: '12px',
        fontSize: '14px',
        color: colors.error,
    },
}));
