import Box from '@material-ui/core/Box';

import { textOptionStyle } from './styles';

export interface TextOptionProps {
    handleHighlightFromPDF: () => void;
    handleTypeText: () => void;
}

export const TextOptions = ({ handleHighlightFromPDF, handleTypeText }: TextOptionProps) => {
    const classes = textOptionStyle();

    return (
        <Box className={classes.textOptionContainer}>
            <p className={classes.textOption}>
                <button
                    className={`${classes.textOption} ${classes.transparentButton}`}
                    role="presentation"
                    onClick={handleHighlightFromPDF}
                >
                    Highlight from PDF
                </button>
                <span className={classes.orText}> or </span>
                <span role="presentation" onClick={handleTypeText}>
                    Type Text
                </span>
            </p>
        </Box>
    );
};
