import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { TextInputProps } from '.';
import { colors } from '../../../assets/colors';

export const style = makeStyles<Theme, TextInputProps>((theme) =>
    createStyles({
        container: {
            width: '100%',
            // Edge
            '& ::-webkit-input-placeholder': {
                opacity: 0.5,
                color: colors.disabledMenu,
            },

            //  Internet Explorer 10-11
            '& ::-ms-input-placeholder': {
                opacity: 0.5,
                color: colors.disabledMenu,
            },

            '& ::placeholder': {
                opacity: 0.5,
                color: colors.disabledMenu,
            },
        },
        label: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0.2,
            color: colors.text,
            marginBottom: 0,
        },
        value: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 16,
            letterSpacing: 0.15,
            color: colors.smoke,
            width: '100%',
            border: `1px groove ${colors.smoke}`,
            outline: 'none',
            padding: `5px 10px`,
            boxSizing: 'border-box',
            height: ({ page }) => (page == 'doiModal' ? 40 : 30),
        },
        errorContainer: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            margin: 0,
            marginTop: 2,
        },
        errorText: {
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            color: colors.error,
            margin: 0,
            marginLeft: 2,
        },
        errorImage: {
            height: 15,
            width: 15,
        },
    })
);
