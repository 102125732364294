import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

const leftMargin = 35;

export interface StyleProps {
    isUpdating: boolean;
}

export const style = makeStyles<Theme, StyleProps>(() =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'start',
            width: '100%',
        },
        formContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
        },
        delete: {
            display: 'flex',
            alignItems: 'baseline',
            cursor: ({isUpdating})=>isUpdating?'not-allowed':'pointer',
            justifyContent: 'flex-end',

        },
        deleteIcon: {
            width: 15,
            height: 25,
            color: ({ isUpdating }) => (isUpdating ? colors.gray : colors.black),
        },
        textIcon: {
            width: `${leftMargin}px`,
            height: `${leftMargin}px`,
        },
        icon: {
            height: 35,
            width: 35,
        },
    })
);
