import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        uploadFileStatus: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        uploadFileStatusText: {
            textDecoration: 'underline',
        },
        saveRefOptions: {
            display: 'grid',
            gridAutoFlow: 'row',
            gridGap: 4,
        },
        itemText: {
            flex: 1,
            display: 'flex',
            color: colors.text2,
            fontSize: 13,
            fontWeight: 500,
        },
        link: {
            color: colors.blue1,
            cursor: 'pointer',
        },
    })
);
