import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: colors.lighBlueBackground,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: colors.white,
            boxShadow: '0px 20px 28px rgba(0, 0, 0, 0.25)',
            borderRadius: '8px',
            minWidth: '400px',
            minHeight: '300px',
            padding:50,
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
                maxHeight: '482px',
            },
        },
        logoContainer: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },

        heading: {
            fontFamily: 'Roboto',
            fontSize: 24,
            textAlign: 'center',
            color: colors.text,
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            marginBottom: '1.5rem',
        },
        descriptionContainer: {
            width: '366px',
            height: '48px',
            marginBottom: '16px',
        },
        description: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 16,
            textAlign: 'center',
            color: colors.smoke,
            margin: '-4px 0',
            fontStyle: 'normal',
            lineHeight: '120%',
        },
        orderSummaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px 0px 8px',
            width: '320px',
            minHeight: '175px',
            background: colors.white,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
        },
        summaryHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 8px 8px 12px',
            background: colors.boxShadow2,
            borderRadius: '4px 4px 0px 0px',
            width: '94%',
            height: '35px',
        },
        headerText: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '120%',
            color: colors.smoke,
        },
        loader: {
            color: colors.smoke,
        },
        sumamryContent: {
            height: 'auto',
            width: 'auto',
            color: colors.text,
            padding: '0px 8px',
        },
        orderDetail: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '120%',
            letterSpacing: '0.2px',
            color: colors.text,
        },
        summaryBottomText: {
            display: 'flex',
            flexDirection: 'column',
            width: '296px',
            height: '46px',
        },
        textContent: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '140%',
            letterSpacing: '0.2px',
            color: colors.smoke,
        },
        input: {
            marginBottom: 12,
        },
        buttonWrapper: {
            minWidth: 297,
            marginTop: 32,
        },
    })
);
