import { layoutOptionsMappingInterface } from './types';
import { useLayoutIconStyle } from './styles';
// cover layout icon imports
import { Cover0TextDefault } from './cover/cover0TextDefault';
import { Cover1TextDefault } from './cover/cover1TextDefault';
import { Cover1MediaDefault } from './cover/cover1MediaDefault';
import { Cover1MediaOther1 } from './cover/cover1MediaOther1';
import { Cover1MediaOther2 } from './cover/cover1MediaOther2';
import { Cover1MediaOther3 } from './cover/cover1MediaOther3';
import { Cover1Text1MediaDefault } from './cover/cover1Text1MediaDefault';
import { Cover1Text1MediaOther1 } from './cover/cover1Text1MediaOther1';
import { Cover1Text1MediaOther2 } from './cover/cover1Text1MediaOther2';
import { Cover1Text1MediaOther3 } from './cover/cover1Text1MediaOther3';

// content layout icon imports
import { Content1TextDefault } from './content/content1TextDefault';
import { Content1TextOther } from './content/content1TextOther';
import { Content2TextDefault } from './content/content2TextDefault';
import { Content2TextOther } from './content/content2TextOther';
import { Content1MediaDefault } from './content/content1MediaDefault';
import { Content2MediaDefault } from './content/content2MediaDefault';
import { Content2MediaOther } from './content/content2MediaOther';
import { Content1Text1MediaDefault } from './content/content1Text1MediaDefault';
import { Content1Text1MediaOther1 } from './content/content1Text1MediaOther1';
import { Content1Text1MediaOther2 } from './content/content1Text1MediaOther2';
import { Content1Text1MediaOther3 } from './content/content1Text1MediaOther3';
import React from 'react';

export const layoutOptionsMapping: layoutOptionsMappingInterface = {
    'Cover-0Text-Default': Cover0TextDefault,
    'Cover-1Text-Default': Cover1TextDefault,
    'Cover-1Media-Default': Cover1MediaDefault,
    'Cover-1Media-Other1': Cover1MediaOther1,
    'Cover-1Media-Other2': Cover1MediaOther2,
    'Cover-1Media-Other3': Cover1MediaOther3,
    'Cover-1Text-1Media-Default': Cover1Text1MediaDefault,
    'Cover-1Text-1Media-Other1': Cover1Text1MediaOther1,
    'Cover-1Text-1Media-Other2': Cover1Text1MediaOther2,
    'Cover-1Text-1Media-Other3': Cover1Text1MediaOther3,
    'Content-1Text-Default': Content1TextDefault,
    'Content-1Text-Other': Content1TextOther,
    'Content-2Text-Default': Content2TextDefault,
    'Content-2Text-Other': Content2TextOther,
    'Content-1Media-Default': Content1MediaDefault,
    'Content-2Media-Default': Content2MediaDefault,
    'Content-2Media-Other': Content2MediaOther,
    'Content-1Text-1Media-Default': Content1Text1MediaDefault,
    'Content-1Text-1Media-Other1': Content1Text1MediaOther1,
    'Content-1Text-1Media-Other2': Content1Text1MediaOther2,
    'Content-1Text-1Media-Other3': Content1Text1MediaOther3,
};

export const LayoutIcon = ({
    name,
    selected,
    onClickHandler,
    id,
}: {
    id?: string;
    name: string;
    selected?: boolean;
    onClickHandler?: (value: string) => void;
}): JSX.Element => {
    const classes = useLayoutIconStyle();
    const LayoutOption = layoutOptionsMapping[name];
    return (
        <div>
            {LayoutOption ? (
                <LayoutOption
                    id={id}
                    selected={selected}
                    onClickHandler={onClickHandler}
                />
            ) : (
                <div className={classes.iconPlaceHolder}></div>
            )}
        </div>
    );
};
