import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutlineModes, Outline, OutlineItem, OutlineItemProperties, ProjectOutline } from '../../../types';

const initialState: ProjectOutline = {
    savingOutlineItem: false,
    mode: OutlineModes.PREVIEW,
    outline: null,
    error: false,
    projectId: null,
    updatingPages: [],
    updatingItems: [],
    enableHighlight: false,
};

const userSlice = createSlice({
    name: 'ProjectOutline',
    initialState,
    reducers: {
        toggleSavingOutlineItem: (
            state,
            action: PayloadAction<{
                savingOutlineItem: boolean;
                error?: boolean;
                deletePage?: boolean;
                pageId?: string;
                itemId?: string;
            }>
        ) => {
            const { savingOutlineItem, pageId, error, itemId, deletePage } = action.payload;
            state.savingOutlineItem = savingOutlineItem;
            state.error = !!error;
            //  if pageId exists
            //     - savingOutlineItem=true
            //         added that page id in the updating pages list
            //         to disable deletion of that page until it is complete
            //         - deletePage = true
            //             Added all items of that page in the updating outline items lists
            //             to disable deletion of those pages
            //     - savingOutlineItem=false
            //         Remove that page from updating list and now will be
            //         available to delete

            if (pageId) {
                if (savingOutlineItem) {
                    state.updatingPages = [...state.updatingPages, pageId];
                    if (deletePage) {
                        const items =
                            state.outline?.pages.filter((item) => item.id === pageId)[0].items.map((item) => item.id) ||
                            [];
                        state.updatingItems = [...state.updatingItems, ...items];
                    }
                } else {
                    const index = state.updatingPages.indexOf(pageId);
                    if (index > -1) state.updatingPages.splice(index, 1);
                }
            }

            // if itemId exists
            //     - savingOutlineItem=true
            //         added that item id in updating items lists
            //         to disable deletion of those items untill they are updated
            //         successfuly
            //     - savingOutlineItem=true
            //         remove that item id from updating item list to
            //         available for deletion now
            if (itemId) {
                if (savingOutlineItem) {
                    state.updatingItems = [...state.updatingItems, itemId];
                } else {
                    const index = state.updatingItems.indexOf(itemId);
                    if (index > -1) state.updatingItems.splice(index, 1);
                }
            }
            return state;
        },
        updateMode: (state, action: PayloadAction<{ mode: OutlineModes }>) => {
            state.mode = action.payload.mode;
            return state;
        },
        setOutline: (state, action: PayloadAction<{ outline: Outline; projectId: string }>) => {
            state.outline = action.payload.outline;
            state.projectId = action.payload.projectId;
            return state;
        },
        updatePageTitle: (state, action: PayloadAction<{ title: string; pageId: string }>) => {
            if (state.outline?.pages)
                state.outline.pages = state.outline.pages.map((page) =>
                    page.id === action.payload.pageId ? { ...page, title: action.payload.title } : page
                );

            return state;
        },

        deleteOutlinePage: (
            state,
            action: PayloadAction<{
                pageId: string;
            }>
        ) => {
            if (state.outline?.pages)
                state.outline.pages = state.outline.pages
                    .filter((page) => page.id !== action.payload.pageId)
                    .map((pageItem, number) => ({ ...pageItem, number }));
            return state;
        },
        updateItems: (state, action: PayloadAction<{ pageId: string; items: OutlineItem[] }>) => {
            if (state.outline?.pages)
                state.outline.pages = state.outline.pages.map((page) =>
                    page.id === action.payload.pageId ? { ...page, items: action.payload.items } : page
                );

            return state;
        },

        updateOutlineItem: (
            state,
            action: PayloadAction<{
                pageId: string;
                itemId: string;
                properties: OutlineItemProperties;
            }>
        ) => {
            const { pageId, itemId, properties } = action.payload;

            if (state.outline?.pages)
                state.outline.pages = state.outline.pages.map((page) =>
                    page.id === pageId
                        ? {
                              ...page,
                              items: page.items.map((outlineItem) =>
                                  outlineItem.id === itemId ? { ...outlineItem, properties } : outlineItem
                              ),
                          }
                        : page
                );

            return state;
        },

        enableHighlightingText: (state, action: PayloadAction<{ enableHighlight: boolean }>) => {
            const { enableHighlight } = action.payload;
            state.enableHighlight = enableHighlight;

            return state;
        },
    },
});

export const {
    toggleSavingOutlineItem,
    updateMode,
    setOutline,
    updatePageTitle,
    updateItems,
    updateOutlineItem,
    deleteOutlinePage,
    enableHighlightingText,
} = userSlice.actions;
export default userSlice.reducer;
