import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import moment from 'moment';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
    addPaper,
    addPaperFromDoi,
    removePaperFileFromLibrary as removePaperFileFromLibraryApi,
} from '../../../../backend/paper';
import { removePaperFileProject } from '../../../../backend/paperFileProject';
import { deleteProject, updateProjectTitle } from '../../../../backend/project';
import mergedConfigs from '../../../../config';
import { alertTypes, gtm, paperMetaDataStatus, projectTitleLength } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import {
    removePaperFileFromLibrary,
    removePaperFileFromProject,
    setPaperFile,
    showAlert,
    togglePaperSwitcher,
    toggleSavingOutlineItem,
    updatePaperFileOfProject,
} from '../../../../redux/reducers';
import {
    addMultiplePaperfilesToProject,
    deleteProjectById,
    updateTitleInProjectList,
} from '../../../../redux/reducers/projects';
import { GTMService } from '../../../../services/gtmService';
import {
    PaperFile,
    PaperFileWithPDF,
    PaperStatusList,
    PaperWithNoErrStatusList,
    ProjectInterface,
    InformationModal as InformationModalType,
} from '../../../../types';
import { createSHA256Hash } from '../../../../utils';
import { InformationModal, MuiCMButton, PaperFilesRefresher, Spinner } from '../../../common';
import { routes } from '../../../pages/routes';
import { AddPaper, PapersList, WithoutPaper } from './components';
import DeleteProjectDialog from './components/deleteProjectModal';
import { UploadPdfModel } from './components/uploadPdfModel';
import { style } from './style';

interface ProjectProps {
    selectedProject: ProjectInterface;
    loadPaperFiles: Boolean;
    updatePaperFiles: any;
}

export const Projects = ({ selectedProject, loadPaperFiles, updatePaperFiles }: ProjectProps) => {
    const classes = style();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const [openUploadPdfModal, setOpenUPloadPdfModal] = useState(false);
    const [openDeleteProjectModal, setOpenDeleteModal] = useState(false);
    const [temp, setTemp] = useState(Date.now());
    const [openDOIModal, setOpenDoiModal] = useState(false);
    const SLIDE_PREVIEW_PAGE = `/slide-preview?projectId=${selectedProject.id}`;
    const [projectTitle, setProjectTitle] = useState('');
    const [isDeleting, setDeleting] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [showInformationModal, setShowInformationModal] = useState<InformationModalType>({ open: false });

    const { title } = selectedProject;
    const projectId = selectedProject?.id;

    const readablePaperFiles =
        selectedProject?.paperFiles &&
        selectedProject?.paperFiles?.filter((paperFile: PaperFile) => Boolean(paperFile.key) === true);

    useEffect(() => {
        setProjectTitle(title || '');
    }, [title, setProjectTitle]);

    const onFileDrop = useCallback(
        async (acceptedFiles: File[]) => {
            // why creating paperFilesWithPDF instead of acceptedFiles.map(...)?
            // because with mapping the dispatch action will be called multiple times at the same time. this will cause not having multiple loading/uploading papers issue in the UI. you can test this with [1,2,3].map(.. dispatch(action...))
            // also, we are depending on the demo uuid() to updated the paperFiles after successfully updated.
            // so, we are dispatching redux state first and uploading the file to the server then update the redux state again after success response.
            if (acceptedFiles.length) {
                const paperFilesWithPDF: PaperFileWithPDF[] = acceptedFiles.map((item) => ({
                    pdf: item,
                    paperFile: {
                        name: item.name,
                        loading: true,
                        status: 'Uploading Paper...',
                        id: uuidv4(),
                    },
                }));
                dispatch(addMultiplePaperfilesToProject({ projectId, paperFilesWithPDF }));
                paperFilesWithPDF.map(async (paperFileWithPDF) => {
                    toggleUploadPdfModal(false);
                    const pdfFile = paperFileWithPDF.pdf;

                    try {
                        if (!projectId) throw new Error('Project is not selected');
                        const { paperFile } = await addPaper(speakerId as string, projectId, pdfFile);
                        console.time('test');
                        if (mergedConfigs.featureFlags.hashFiles) {
                            const uploadedFilehash = createSHA256Hash(pdfFile);
                            console.timeEnd('test');
                            console.log('*****Hash of uploaded file*********', uploadedFilehash);
                        }
                        dispatch(
                            updatePaperFileOfProject({
                                projectId,
                                paperFile: { ...paperFile, id: paperFile?.id as string, loading: false },
                                paperFileId: paperFileWithPDF.paperFile.id,
                            })
                        );
                        const gtmService = new GTMService();
                        await gtmService.handleEvent({
                            event: gtm.events.UPLOAD_PDF,
                            userId: speakerId,
                        });
                        setTemp(Date.now());
                    } catch (error) {
                        dispatch(
                            updatePaperFileOfProject({
                                projectId,
                                paperFile: {
                                    name: pdfFile.name,
                                    loading: false,
                                    status: '',
                                    id: paperFileWithPDF.paperFile.id,
                                },
                                paperFileId: paperFileWithPDF.paperFile.id,
                            })
                        );
                        if (error instanceof Error)
                            setShowInformationModal({
                                type: alertTypes.error,
                                open: true,
                                title: error.message,
                                closeBtnLabel: 'Close',
                                cancelBtnLabel: '',
                                successBtnLabel: '',
                                subTitle: '',
                                footer: '',
                                helpInfo: '',
                                cancelBtnLoading: false,
                                handleConfirm: enterDoiAgain,
                                handleClose: closeInformationModal,
                                dataTestId: '',
                            });

                        setTemp(Date.now());
                    }
                });
            }
        },
        [selectedProject]
    );

    // later when we will have design of displaying rejedcted papers we will modify this
    const onFileDropRejected = (fileRejections: FileRejection[]) => {
        let filesToLarge: string[] = [];
        let toManyFiles: string[] = [];

        fileRejections.map((items) => {
            items?.errors[0]?.code === 'file-too-large'
                ? filesToLarge.push(items.file.name)
                : toManyFiles.push(items.file.name);
        });

        filesToLarge.length &&
            setShowInformationModal({
                type: alertTypes.error,
                open: true,
                title: `File Size is Over 5MB of`,
                closeBtnLabel: 'Close',
                cancelBtnLabel: '',
                successBtnLabel: '',
                subTitle: (
                    <ul>
                        {filesToLarge.map((item) => (
                            <li style={{ textAlign: 'left' }}>{item}</li>
                        ))}
                    </ul>
                ),
                footer: '',
                helpInfo: '',
                cancelBtnLoading: false,
                handleConfirm: () => enterDoiAgain(),
                handleClose: () => closeInformationModal(),
                dataTestId: '',
            });

        toManyFiles.length &&
            setShowInformationModal({
                type: alertTypes.error,
                open: true,
                title: `You can upload a maximum of 10 files at a time`,
                closeBtnLabel: 'Close',
                cancelBtnLabel: '',
                successBtnLabel: '',
                footer: '',
                helpInfo: '',
                cancelBtnLoading: false,
                handleConfirm: () => enterDoiAgain(),
                handleClose: () => closeInformationModal(),
                dataTestId: '',
            });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf',
        maxFiles: 10,
        multiple: true,
        onDrop: onFileDrop,
        maxSize: 5000000, // 5MB
        onDropRejected: onFileDropRejected,
    });

    const openReaderView = () => {
        if (readablePaperFiles?.length)
            dispatch(
                setPaperFile({
                    ...readablePaperFiles[0],
                    isCreateProjectWithPaper: false,
                })
            );
        if (readablePaperFiles && readablePaperFiles?.length > 1)
            dispatch(togglePaperSwitcher({ paperSwitcherDrawer: true }));
        else dispatch(togglePaperSwitcher({ paperSwitcherDrawer: false }));
        // using routes.reader as pathname in push(`...`) caused to history pushState error. so had to use in push options
        history.push({
            pathname: routes.reader,
            search: `?projectId=${selectedProject?.id}&key=${readablePaperFiles && readablePaperFiles[0]!.key}`,
        });
    };

    const handleManualDoi = async (paperFile: PaperFile, value: string) => {
        dispatch(
            updatePaperFileOfProject({
                projectId,
                paperFile: {
                    ...paperFile,
                    loading: true,
                    status: paperMetaDataStatus.metadata,
                },
                paperFileId: paperFile.id,
            })
        );
        setTemp(Date.now());

        try {
            const paperFileStatus = Object.values(PaperWithNoErrStatusList).includes(paperFile!.status!)
                ? paperFile.status
                : PaperStatusList.empty;
            const updatedPaperFile: PaperFile = await addPaperFromDoi(
                value,
                paperFile.id as string,
                paperFileStatus as PaperStatusList
            );

            dispatch(
                updatePaperFileOfProject({
                    projectId,
                    paperFile: { ...updatedPaperFile, loading: false },
                    paperFileId: paperFile.id,
                })
            );
            setTemp(Date.now());
            if (Object.values(PaperWithNoErrStatusList).includes(updatedPaperFile!.status!)) {
                setShowInformationModal({
                    type: alertTypes.success,
                    open: true,
                    title: 'DOI was accepted',
                    closeBtnLabel: 'Close',
                    cancelBtnLabel: '',
                    successBtnLabel: '',
                    subTitle: '',
                    footer: '',
                    helpInfo: '',
                    cancelBtnLoading: false,
                    handleConfirm: () => enterDoiAgain(),
                    handleClose: () => closeInformationModal(),
                    dataTestId: '',
                });
            } else {
                setShowInformationModal({
                    type: alertTypes.error,
                    open: true,
                    title: 'DOI was not entered properly',
                    cancelBtnLabel: 'Cancel',
                    successBtnLabel: 'Enter DOI again',
                    closeBtnLabel: '',
                    subTitle: '',
                    footer: '',
                    helpInfo: '',
                    cancelBtnLoading: false,
                    handleConfirm: enterDoiAgain,
                    handleClose: closeInformationModal,
                    dataTestId: '',
                });
            }
        } catch (error) {
            dispatch(
                updatePaperFileOfProject({
                    projectId,
                    paperFile: {
                        ...paperFile,
                        loading: false,
                        status: paperFile.status,
                    },
                    paperFileId: paperFile.id,
                })
            );
            setTemp(Date.now());
            setShowInformationModal({
                type: alertTypes.error,
                open: true,
                title: 'DOI was not entered properly',

                cancelBtnLabel: 'Cancel',
                successBtnLabel: 'Enter DOI again',
                closeBtnLabel: '',
                subTitle: (error as any | Error)?.message?.trim(),
                footer: '',
                helpInfo: '',
                cancelBtnLoading: false,
                handleConfirm: () => enterDoiAgain(),
                handleClose: () => closeInformationModal(),
                dataTestId: '',
            });
        }
    };

    const toggleUploadPdfModal = (state: boolean) => {
        setOpenUPloadPdfModal(state);
    };

    const deletePaperFromProject = async (paperFile: PaperFile) => {
        const originalStatus = paperFile.status ? paperFile.status : '';
        updateStatusOfPaper('Deleting paper from Project', paperFile, true);
        try {
            await removePaperFileProject(projectId, paperFile.id as string);

            removePaperFile(paperFile.id as string, false);
            updateStatusOfPaper('', paperFile, false);
        } catch (error) {
            if (error instanceof Error) {
                dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
            }
            updateStatusOfPaper(originalStatus, paperFile, false);
        }
    };

    const deletePaperFromLibrary = async (paperFile: PaperFile) => {
        const originalStatus = paperFile.status ? paperFile.status : '';
        try {
            updateStatusOfPaper('Deleting paper from Library', paperFile, true);
            const response = await removePaperFileFromLibraryApi(
                paperFile.id as string,
                speakerId as string,
                paperFile?.paper?.id as string,
                paperFile.key as string
            );

            removePaperFile(paperFile.id as string, true);

            let selectedProjectPaperfiles = selectedProject?.paperFiles;
            const updatedPaperFiles: any = selectedProjectPaperfiles
                ?.map((pf) => (pf.id === response.updatedPaperFile?.id ? { ...pf, ...response.updatedPaperFile } : pf))
                .filter((item) => item.id != response.deletedPaperFileId);
            updatePaperFiles(projectId, updatedPaperFiles);
        } catch (error) {
            if (error instanceof Error) {
                dispatch(showAlert({ message: error.message, isVisible: true, type: alertTypes.error }));
            }
            updateStatusOfPaper(originalStatus, paperFile, false);
        }
    };

    const removePaperFile = (paperFileId: string, isLibrary: boolean) => {
        if (isLibrary) {
            return dispatch(
                removePaperFileFromLibrary({
                    paperFileId,
                })
            );
        }
        dispatch(
            removePaperFileFromProject({
                projectId,
                paperFileId,
            })
        );
    };

    // update the status of specific paper in redux
    const updateStatusOfPaper = (status: string, paperFile: PaperFile, loading: boolean) => {
        dispatch(
            updatePaperFileOfProject({
                projectId,
                paperFile: { ...paperFile, loading: loading, status: status ? status : undefined },
                paperFileId: paperFile.id,
            })
        );
    };

    const handleTitleChange = (e: any) => e.target.value.length < projectTitleLength && setProjectTitle(e.target.value);

    const onDeleteProject = async (projectId: string) => {
        setDeleting(true);
        await deleteProject(projectId, speakerId as string)
            .then(({ projectId, deletedPaperFileKeys, projects }) => {
                setDeleting(false);
                // update store
                dispatch(deleteProjectById({ projectId, deletedPaperFileKeys, projects }));
                if (projects.length) {
                    const nextProject = projects[0];
                    if (projectId === nextProject.id) {
                        if (projects[1]) {
                            history.push({
                                pathname: routes.library,
                                search: '?' + new URLSearchParams({ projectId: projects[1].id }),
                            });
                        }
                    } else {
                        history.push({
                            pathname: routes.library,
                            search: '?' + new URLSearchParams({ projectId: projects[0].id }),
                        });
                    }
                } else {
                    history.push({
                        pathname: routes.library,
                    });
                }
                setOpenDeleteModal(false);
            })
            .catch((error) => {
                setDeleting(false);
                setOpenDeleteModal(false);
                dispatch(showAlert({ message: error.message, type: alertTypes.error, isVisible: true }));
            });
    };

    const saveUpdatedTitle = () => {
        if (projectTitle.trim() !== '') {
            if (projectTitle !== title) {
                dispatch(toggleSavingOutlineItem({ savingOutlineItem: true }));
                updateProjectTitle(projectId, projectTitle)
                    .then((project: ProjectInterface) => {
                        //update redux store
                        dispatch(updateTitleInProjectList({ projectId: project.id, title: project.title }));
                        dispatch(toggleSavingOutlineItem({ savingOutlineItem: false }));
                    })
                    .catch((error) => {
                        setProjectTitle(title);
                        dispatch(toggleSavingOutlineItem({ savingOutlineItem: false }));
                        dispatch(showAlert({ message: error.message, type: alertTypes.error, isVisible: true }));
                    });
            }
        } else {
            if (inputRef && inputRef.current) inputRef.current.focus();
        }
    };

    const handleDeleteProject = () => setOpenDeleteModal(!openDeleteProjectModal);

    const handleCloseDeleteProjectModal = () => {
        setOpenDeleteModal(false);
    };

    const handleKeyPress = (e: any) => {
        if (e.key == 'Enter' && inputRef && inputRef.current) {
            inputRef.current.blur();
        }
    };

    const closeInformationModal = () => {
        setShowInformationModal({ ...showInformationModal, open: false });
    };

    const enterDoiAgain = () => {
        closeInformationModal();
        setOpenDoiModal(true);
    };

    const RenderProjectTitleSection = () => (
        <div className={classes.projectTitleContainer}>
            <input
                ref={inputRef}
                onKeyPress={handleKeyPress}
                onChange={handleTitleChange}
                value={projectTitle}
                placeholder={'Project Title'}
                name={'projectTitle'}
                id={'projectTitle'}
                data-testid="projectTitle"
                className={classes.inputText}
                onBlur={saveUpdatedTitle}
                style={{
                    width: `${projectTitle.length + 3}ch`,
                }}
            />
            <div className={`${classes.iconContainer} ${classes.centeredContainer}`}>
                <IconButton data-testid="delete-project" size="small" onClick={handleDeleteProject}>
                    <DeleteOutlined className={classes.iconStyles} />
                </IconButton>
            </div>
            <div className={`${classes.timeContainer} ${classes.centeredContainer}`}>
                Created &nbsp;
                {moment(selectedProject?.createdAt ? selectedProject?.createdAt : '').format('MMMM DD, YYYY')}
            </div>
            <div className={classes.refreshButtonContainer}>
                <PaperFilesRefresher projectId={selectedProject.id} />
            </div>
        </div>
    );

    return (
        <div className={classes.root} data-testid="project-content">
            {RenderProjectTitleSection()}

            <div className={classes.info}>
                <span className={classes.paperCount}>
                    {selectedProject?.paperFiles && selectedProject?.paperFiles?.length} Papers &nbsp;
                </span>
                <div className={classes.buttonsContainer}>
                    <MuiCMButton
                        role="view-slides"
                        variant="outlined"
                        size="small"
                        className={classes.viewSlidesBtn}
                        onClick={() => history.push(SLIDE_PREVIEW_PAGE)}
                    >
                        View Slides
                    </MuiCMButton>
                    <MuiCMButton
                        disabled={readablePaperFiles?.length ? false : true}
                        size="small"
                        role="read-papers"
                        className={classes.readPapersBtn}
                        onClick={readablePaperFiles?.length ? openReaderView : undefined}
                    >
                        Read Papers
                    </MuiCMButton>
                </div>
            </div>

            {loadPaperFiles ? (
                <div className={classes.spinner}>
                    <Spinner />
                </div>
            ) : selectedProject?.paperFiles && selectedProject?.paperFiles?.length ? (
                <PapersList
                    paperFiles={selectedProject?.paperFiles}
                    temp={temp}
                    handleManualDoi={handleManualDoi}
                    isArticleSelected={false}
                    deletePaperFromLibrary={deletePaperFromLibrary}
                    deletePaperFromProject={deletePaperFromProject}
                    projectTitle={selectedProject.title}
                    projectId={projectId}
                    openDOIModal={openDOIModal}
                    setOpenDoiModal={setOpenDoiModal}
                />
            ) : (
                <WithoutPaper />
            )}
            <AddPaper toggleUploadPdfModal={toggleUploadPdfModal} />

            <UploadPdfModel
                open={openUploadPdfModal}
                toggleUploadPdfModal={toggleUploadPdfModal}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
            />
            <DeleteProjectDialog
                open={openDeleteProjectModal}
                handleClose={handleCloseDeleteProjectModal}
                projectTitle={title}
                projectId={projectId}
                onDeleteProject={onDeleteProject}
                isDeleting={isDeleting}
            />
            <InformationModal
                type={showInformationModal.type}
                open={showInformationModal.open}
                title={showInformationModal.title}
                closeBtnLabel={showInformationModal.closeBtnLabel}
                successBtnLabel={showInformationModal.successBtnLabel}
                cancelBtnLabel={showInformationModal.cancelBtnLabel}
                subTitle={showInformationModal.subTitle}
                footer={showInformationModal.footer}
                helpInfo={showInformationModal.helpInfo}
                cancelBtnLoading={showInformationModal.cancelBtnLoading}
                handleConfirm={showInformationModal.handleConfirm}
                handleClose={showInformationModal.handleClose}
                dataTestId={showInformationModal.dataTestId}
            />
        </div>
    );
};
