import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        loaderDiv: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    })
);
