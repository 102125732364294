import { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useOutlineStyles } from './styles';
import { deleteOutlinePage } from '../../../../backend/outline';
import { OutlinePage } from '../outlinePage';
import { OutlineModes } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import {
    deleteOutlinePage as deletePageFromRedux,
    showAlert,
    toggleSavingOutlineItem,
    updateOutlineProject,
} from '../../../../redux/reducers';
import { alertTypes } from '../../../../constants';
import { OutlineLoader } from './components/outlineLoader';

export interface OutlineProps {
    projectId: string;
}

export const Outline = ({ projectId }: OutlineProps) => {
    const state = useSelector((state: RootState) => state);
    const {
        ProjectOutline: { mode, outline },
        Speaker: { id: speakerId },
    } = state;
    const dispatch = useDispatch();
    const styles = useOutlineStyles();
    const [renderOutlinePages, setRenderOutlinePages] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setRenderOutlinePages(true);
        }, 1000);
    }, [setRenderOutlinePages]);

    const deletePage = async (pageId: string) => {
        try {
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: true, pageId, deletePage:true }));

            const deleteOutline = await deleteOutlinePage(pageId, speakerId as string,);
            dispatch(deletePageFromRedux({ pageId }));
            dispatch(updateOutlineProject({ outline: deleteOutline, projectId }));
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId }));
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        message: error.message,
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );
            dispatch(toggleSavingOutlineItem({ savingOutlineItem: false, pageId }));
        }
    };

    return (
        <Box className={mode === OutlineModes.READER ? styles.rootInReader : styles.root}>
            {outline === undefined || projectId==='-1' ? (
                <Box>No outline to show</Box>
            ) : renderOutlinePages ? (
                outline?.pages.map((page) => <OutlinePage key={page.id} page={page} onDelete={deletePage} />)
            ) : (
                <OutlineLoader />
            )}
        </Box>
    );
};
