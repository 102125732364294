import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 800,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: 48,
            borderRadius: '8px',
        },
        title: {
            color: colors.smoke,
        },
        warningIcon: {
            color: '#FF8B00',
            marginRight: '8px',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        subtitle: {
            color: colors.smoke,
            align: 'center',
        },
        actions: {
            paddingTop: 20,
            display: 'flex',
            gap: 25,
            alignItems: 'center',
            justifyContent: 'center',
        },
        cancelBtn: {
            width: '111px',
            fontWeight: 'normal',
        },
        deleteBtn: {
            minWidth: '235px',
            maxWidth: '254px',
        },
    })
);
