import React, { useEffect, useState, Dispatch, SetStateAction, useRef } from 'react';
import { Box, TextField } from '@material-ui/core';
import { useOutlineCitationItemStyles } from './styles';
import { ImportCitation } from './importCitation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/combineReducers';
import { OutlineModes } from '../../../../../../types';
import { colors } from '../../../../../../assets/colors';

export interface CitationCompProps {
    setItemUpdated?: Dispatch<SetStateAction<boolean>>;
    citation?: string;
    setCitation?: Dispatch<SetStateAction<string>>;
}

export const CitationComp = ({ setItemUpdated, citation, setCitation }: CitationCompProps) => {
    const classes = useOutlineCitationItemStyles();
    const [showInput, setShowInput] = useState<boolean>(false);
    const [showCitationOptions, setShowCitationOptions] = useState<boolean>(false);
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const [isImportModalVisble, setIsImportModalVisible] = useState<boolean>(false);
    const { citation: paperCitation } = useSelector((state: RootState) => state.PaperFile);
    const { mode } = useSelector((state: RootState) => state.ProjectOutline);
    const citationOptionsRef: any = useRef(null);
    const inputRef: any = useRef(null);
    const citationTextRef: any = useRef(null);

    useEffect(() => {
        if (!citation) setShowInput(false);
    }, [citation]);

    const handleImportCitation = (value: string) => {
        if (setCitation) setCitation(value);
        if (setItemUpdated) setItemUpdated(true);
        setIsImportModalVisible(false);
    };

    const citationChanged = (event: any) => {
        if (setCitation) setCitation(event.target.value);
        if (setItemUpdated) setItemUpdated(true);
    };

    const citePaper = () => {
        if (paperCitation) {
            if (setCitation) setCitation(paperCitation);
            if (setItemUpdated) setItemUpdated(true);
        }
    };

    const openImportModal = () => {
        setIsImportModalVisible(true);
        setShowCitationOptions(false);
    };
    const closeImportModal = () => setIsImportModalVisible(false);

    const onFocus = () => {
        setShowCitationOptions(true);
        setInputFocused(true);
    };

    const onBlur = () => {
        setInputFocused(false);
    };

    useEffect(() => {
        if (!showCitationOptions && !inputFocused) setShowInput(false);
    }, [showCitationOptions, inputFocused, setShowInput]);

    useEffect(() => {
        // if click is outside the citation text, citation input or
        // citation options it will be disappreared
        // if click on citation (if applicable) box open input field
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
        function handleClick(e: any) {
            if (
                citationOptionsRef.current &&
                !citationOptionsRef.current?.contains(e.target) &&
                inputRef.current &&
                !inputRef.current?.contains(e.target)
            ) {
                setShowCitationOptions(false);
            }

            if (citationTextRef.current && citationTextRef.current?.contains(e.target)) {
                setShowInput(!showInput);
            }
        }
    }, []);

    // check if citation input is not focused
    // and mouse leave from citation box
    // disapear citation options
    const onMouseLeave = () => {
        if (!inputFocused) setShowCitationOptions(false);
    };

    return (
        <Box className={classes.textContainer} onMouseLeave={onMouseLeave}>
            {!showInput ? (
                <div
                    ref={citationTextRef}
                    className={classes.initialCitationText}
                    style={citation ? { color: colors.darkTeal } : { color: colors.elephant }}
                >
                    {citation ? citation : 'Citation (if applicable)'}
                </div>
            ) : (
                <>
                    <TextField
                        ref={inputRef}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={citation}
                        onChange={citationChanged}
                        inputProps={{
                            className: classes.text,
                        }}
                        multiline
                        maxRows={4}
                        InputProps={{
                            disableUnderline: true,
                            className: classes.textWrapper,
                        }}
                        autoFocus
                    />
                    {showCitationOptions && (
                        <p className={classes.importCitationContainer} ref={citationOptionsRef}>
                            {mode === OutlineModes.READER && paperCitation && (
                                <>
                                    <span className={classes.importCitation} role="presentation" onClick={citePaper}>
                                        Cite this paper
                                    </span>
                                    &nbsp;or &nbsp;
                                </>
                            )}
                            <span className={classes.importCitation} role="presentation" onClick={openImportModal}>
                                Import citation
                            </span>
                        </p>
                    )}
                </>
            )}
            <ImportCitation
                addCitation={handleImportCitation}
                close={closeImportModal}
                isImportModalVisble={isImportModalVisble}
            />
        </Box>
    );
};
