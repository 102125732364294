import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useTextEditorStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& p': {
                marginBottom: 0,
                paddingBottom: 0,
            },
        },
        toolbar: {
            '& > *': {
                marginRight: theme.spacing(1),
            },
        },
    })
);
