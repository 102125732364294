import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { colors } from '../../../assets/colors';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface TooltipProps {
    title: string | JSX.Element;
    place?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}



export const HelpInfoTooltip = ({ title, place = 'left-start' }: TooltipProps) => (
    <HelpInfoTooltipStyle title={title} arrow placement={place}>
        <IconButton
            style={{ color: colors.darkTeal, backgroundColor: 'transparent' }}
            disableRipple={true}
            disableFocusRipple
            aria-describedby="none"
            size="small"
        >
            <InfoOutlinedIcon style={{ width: '0.7em', height: '0.7em' }} />
        </IconButton>
    </HelpInfoTooltipStyle>
);

const HelpInfoTooltipStyle = withStyles(() => ({
    tooltip: {
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '12px',
        fontWeight: 'normal',
        borderRadius: 0,
        border: `1px solid ${colors.darkTeal}`,
        padding: '3px 0px ',
        paddingRight: "14px",
        boxShadow: '0px 11px 12px -12px rgba(0, 0, 0, 0.76)',
        left: "-10px"
    },
    arrow: {
        '&:before': {
            border: `1px solid ${colors.darkTeal}`,
        },
        color: "white",
        boxShadow: '0px 11px 12px -12px rgba(0, 0, 0, 0.76)',
    },

}))(Tooltip);
