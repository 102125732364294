import {
    SpeakerPreferences,
    UpdatePreferencesRes,
    UpdateProxyUrlRes,
    UpdateShowFullTextModalRes,
    UpdateSpeakerShowOnboardingRes,
} from '../types';
import { executeGraphqlCall } from './api';

export const updatePreferences = async (
    speakerId: string,
    citationStyle: string,
    proxyUrl: string,
    showFullTextModal: boolean
): Promise<UpdatePreferencesRes> => {
    const variables = { data: { speakerId, citationStyle, proxyUrl, showFullTextModal } };
    const body = `
  mutation UpdateSpeakerPreferences($data: UpdateSpeakerPreferences!) {
      updateSpeakerPreferences(data: $data) {
        id
       citationStyle
       proxyUrl
       showFullTextModal
      }
  }`;
    return executeGraphqlCall<UpdatePreferencesRes>('updateSpeakerPreferences', body, undefined, variables);
};

export const updateProxyUrl = async (speakerId: string, proxyUrl: string): Promise<UpdateProxyUrlRes> => {
    const variables = { data: { speakerId, proxyUrl } };
    const body = `
  mutation UpdateProxyUrl($data: UpdateProxyUrl!) {
    updateProxyUrl(data: $data) {
        id
        proxyUrl
      }
  }`;
    return executeGraphqlCall<UpdateProxyUrlRes>('updateProxyUrl', body, undefined, variables);
};

export const updateShowFullTextModal = async (
    speakerId: string,
    showFullTextModal: boolean
): Promise<UpdateShowFullTextModalRes> => {
    const variables = { data: { speakerId, showFullTextModal } };
    const body = `
      mutation UpdateShowFullTextModal($data: UpdateShowFullTextModal!) {
        updateShowFullTextModal(data: $data) {
            id
            showFullTextModal
          }
      }`;
    return executeGraphqlCall<UpdateShowFullTextModalRes>('updateShowFullTextModal', body, undefined, variables);
};

export const updateSpeakerOnboardingPreference = async (
    speakerId: string,
    showOnboarding: boolean
): Promise<UpdateSpeakerShowOnboardingRes> => {
    const variables = { data: { speakerId, showOnboarding } };
    const body = `
    mutation UpdateSpeakerPreferences($data: UpdateSpeakerPreferences!) {
        updateSpeakerPreferences(data: $data) {
          id
          showOnboarding
        }
    }`;

    return executeGraphqlCall<UpdateSpeakerShowOnboardingRes>('updateSpeakerPreferences', body, undefined, variables);
};

export const getSpeakerPreferences = async (speakerId: string): Promise<SpeakerPreferences> => {
    const variables = { speakerId };
    const body = `query GetSpeakerPreferences($speakerId: String!) {
        getSpeakerPreferences(speakerId: $speakerId) {
          id
          citationStyle
          showOnboarding
        }
      }`;
    return executeGraphqlCall<SpeakerPreferences>('getSpeakerPreferences', body, undefined, variables);
};
