import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { InputActionMeta } from 'react-select';

import { getMatchedInstitution } from '../../../backend/institutions';
import { alertTypes } from '../../../constants';
import { showAlert } from '../../../redux/reducers';
import { Dropdown } from '..';
import { style } from './style';

interface Ivalue {
    value: string;
    label: string | JSX.Element;
}

interface InstitutionSelectProps {
    value: Ivalue;
    onChange: (val: any) => void;
    error: string;
    placeholder?: string;
    disable?: boolean;
    country: string;
}

type option = {
    label: string | JSX.Element;
    value: number | string;
};

export const InstitutionSelect = ({
    onChange,
    value,
    error,
    placeholder,
    disable,
    country,
}: InstitutionSelectProps) => {
    const [offset, setOffset] = useState(0);
    const [institutions, setInstitutions] = useState<Array<option>>([]);
    const [institutionName, setInstitutionName] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();
    const classes = style();

    const { ref, inView } = useInView({
        threshold: 0,
    });

    const showMore = {
        label: (
            <div ref={ref} className={classes.loaderDiv}>
                <CircularProgress size={20} style={{ color: '#000' }} />
            </div>
        ),
        value: '*',
    };

    // Component did mount
    useEffect(() => {
        !disable && getInstitionsFunc('', 0);
    }, [disable, country]);

    // when show loader in institutions list
    useEffect(() => {
        !isLoader && inView && setOffset(offset + 10);
    }, [inView]);

    // When getting more data of institutons
    useEffect(() => {
        offset && getInstitionsFunc(institutionName, offset);
    }, [offset]);

    const getInstitionsFunc = async (name: string, offset: number) => {
        const institutionsTemp = offset === 0 ? [] : institutions;
        try {
            setIsLoader(true);
            const allInstitutions = await getMatchedInstitution(name, offset, country);

            /* when we have last set of institutions that are less than 10 
            now here we will not push circular progress ref so that no more fetching happens 
            */
            if (allInstitutions.length < 10) {
                institutionsTemp.splice(institutionsTemp.length - 1, 1);
                let newInstitutionsList = [...institutionsTemp, ...allInstitutions];
                setInstitutions([...newInstitutionsList]);
                setIsLoader(false);
                return;
            }

            // initial fetching of institutions
            if (!institutionsTemp.length) {
                allInstitutions.push(showMore);
                setInstitutions(allInstitutions);
                setIsLoader(false);
            }

            // fetching after initial fetching to add more
            else {
                institutionsTemp.splice(institutionsTemp.length - 1, 1);
                let newInstitutionsList = [...institutionsTemp, ...allInstitutions];
                newInstitutionsList.push(showMore);
                setInstitutions([...newInstitutionsList]);
                setIsLoader(false);
            }
        } catch (error) {
            if (error instanceof Error) {
                setIsLoader(false);
                dispatch(
                    showAlert({
                        message: error.message,
                        isVisible: true,
                        type: alertTypes.error,
                    })
                );
            }
        }
    };

    const onInputChange = (name: string, actionMeta: InputActionMeta) => {
        setOffset(0);
        setInstitutionName(name);
        if (actionMeta.action === 'menu-close') {
            getInstitionsFunc('', 0);
        }
        if (actionMeta.action === 'input-change') {
            getInstitionsFunc(name, 0);
        }
    };

    return (
        <Dropdown
            placeholder={placeholder ? placeholder : 'Institution'}
            name="institutionId"
            key="institutionId"
            value={value}
            label="Institution"
            id="institutionId"
            onChange={onChange}
            error={error}
            options={institutions}
            onInputChange={(name, actionMeta) => onInputChange(name, actionMeta)}
            isFilterOptions
            disable={disable}
        />
    );
};
