import { SvgIcon } from '@material-ui/core';

export const SlidePreview = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.875 0H10.0192V2.49638H7.875V0Z" fill="#BABABA" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.9921 2.94863C17.9921 2.57288 17.6333 2.26801 17.1911 2.26801H0.878637C0.435387 2.26801 0.0776367 2.57288 0.0776367 2.94863V4.42913H1.15989V13.4843H6.14814L3.56626 16.5645C3.38514 16.7468 3.45264 17.1101 3.71926 17.3768L4.20301 17.8594L7.94589 13.6181V17.964H10.0283V13.5023L13.8746 17.8583L14.3573 17.3756C14.6239 17.1101 14.6925 16.7456 14.5103 16.5634L11.8496 13.4831H16.8064V4.42801H17.9944V2.94863H17.9921ZM15.4616 12.2307H2.53857V8.53844V4.42913H15.4616V8.53844V12.2307Z"
                    fill="#BABABA"
                />
            </svg>
        </SvgIcon>
    );
};
