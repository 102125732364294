import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            position: 'relative',
        },

        title: {
            fontSize: 20,
            color: 'black',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 0px 5px #E5E9F2',
            height: '60px',
        },
        subHeading: {
            color: colors.inactiveStates,
            fontSize: 16,
        },
        projectContainer: {
            boxShadow: '0px 0px 5px #E5E9F2',
            padding: '16px 12px',
            background: '#FFFFFF',
            marginBottom: 10,
        },
        projectTitle: {
            fontSize: 16,
            color: colors.text2,
            lineHeight: '19px',
            letterSpacing: '0.15px',
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        slideTitle: {
            fontSize: 14,
            color: colors.text2,
            lineHeight: '16px',
            letterSpacing: '0.25px',
        },
        moreContainer: {
            fontSize: 14,
            color: colors.disabledMenu,
            lineHeight: '16px',
            letterSpacing: '0.25px',
        },
        dateContainer: {
            fontSize: 14,
            lineHeight: '16px',
            letterSpacing: '0.25px',
            color: colors.inactiveStates,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        emptyProjectText: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 30,
        },
        addProjectContainer: {
            position: 'absolute',
            bottom: '55px',
            left: 0,
            right: 0,
            zIndex: 100000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        addProjectIcon: {
            cursor: 'pointer',
            width: '76px',
            height: '48px',
            borderRadius: '58px',
            color: 'white',
            backgroundColor: colors.primaryButton,
            '&:hover': {
                backgroundColor: colors.primaryButton,
            },
            '&:focus': {
                border: `1px solid ${colors.darkTeal}`,
                boxShadow: '0 0 0 2px #fff ,0 0 0 5px #00B1B8',
            },
            '&:active': {
                backgroundColor: colors.primaryButtonOnPress,
            },
        },
    })
);
