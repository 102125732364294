import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() => ({
    root: {
        display: 'flex',
        height: window.innerHeight - 50, //total height - navbar height,
        marginLeft: 20,
    },

    drawerHeaderContainer: {
        display: 'flex',
        padding: '20px 0px ',
        alignItems: 'center',
        height: 116,
    },
    heading: {
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: colors.text2,
        flex: 1,
        fontWeight: 'bold',
        padding: '0px 20px',
        display: '-webkit-box',
        '-webkitLineClamp': '4',
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
    },
    drawerBodyContainer: {
        height: 'calc(100% - 130px)',
    },
    backButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0px 15px',
        alignItems: 'center',
        cursor: 'pointer',
    },

    position: {
        position: 'absolute',
    },
    referenceClose: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '140%',
        letterSpacing: '0.2px',
        display: 'flex',
        color: colors.darkTeal,
        justifyContent: 'right',
        alignItems: 'center',
        marginRight: 8,
    },
    forwardIcon: {
        marginLeft: '5px',
    },
    referenceContainer: {
        borderBottom: `1px solid ${colors.dividerBorderDisabledState}`,
        padding: '10px 0px',
    },
    referenceTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: colors.text2,
        display: '-webkit-box',
        '-webkitLineClamp': '2',
        '-webkitBoxOrient': 'vertical',
        paddingRight: 20,
        overflow: 'hidden',
        marginBottom: 8,
    },
    info: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.25px',
        color: colors.infoGrayText,
    },
    reference: {
        padding: '0px 22px',
    },
    linkDiv: {
        textAlign: 'right',
        padding: '0px 10px',
    },
    link: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '12px',
        letterSpacing: '0.15px',
        textDecorationLine: 'underline',
        color: colors.darkTeal,
        margin: '0px 5px',
        cursor: 'pointer',
    },
    spinnerDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    noReference: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.25px',
        color: colors.text2,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowIcon: {
        color: colors.darkTeal,
        height: 16,
    },
    loaderDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    circularProgress: {
        color: colors.text2,
        padding: 2,
    },
}));
