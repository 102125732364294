import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

const leftMargin = 35;
const smallSpace = 8;

export const style = makeStyles(() =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: 8,
            width: `calc(100% - ${leftMargin + smallSpace}px)`,
        },
        imagePreviewContainer: {
            position: 'relative',
            width: '100%',
        },
        updateTextContainer: {
            top: 0,
            bottom: 0,
            position: 'absolute',
            display: 'flex',
            width: '100%',
            maxWidth: '14vw',
            justifyContent: 'center',
            alignItems: 'center',
        },
        updateTextStyle: {
            color: colors.darkTeal,
            cursor: 'pointer',
            fontSize: 16,
            fontWeight: 500,
            fontStyle: 'Roboto',
        },
        orText: {
            color: 'gray',
            padding: '0px 5px',
            fontWeight: 500,
        },
        inputContainer: {
            cursor: 'pointer !important',
            position: 'relative',
            flex: 1,
        },
        inputLabel: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 12,
            letterSpacing: 0.15,
            color: colors.darkTeal,
            margin: 0,
            cursor: 'pointer',
        },
        holdingContent: {
            color: colors.grayText,
            fontSize: 14,
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 10,
        },

        inputImage: {
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            fontSize: 0,
            cursor: 'pointer',
        },
        updatedImageInput: {
            visibility: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            fontSize: 0,
            cursor: 'pointer',
        },
        imageName: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 12,
            letterSpacing: 0.15,
            color: colors.darkTeal,
            margin: 0,
        },
        previewImage: {
            maxWidth: '14vw',
        },
        citation: {
            fontSize: '12px',
            padding: '8px 0 0 0',
            width: '100%',
            color: colors.darkTeal,
            fontWeight: 400,
        },
        loader: {
            alignSelf: 'center',
        },
        labelContainer: {
            display: 'flex',
        },
        snipImage: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 12,
            letterSpacing: 0.15,
            color: colors.darkTeal,
            margin: 0,
            cursor: 'pointer',
        },
        snipContainer: {
            marginTop: 3,
            color: colors.grayText,
            fontSize: 12,
            fontWeight: 600,
        },
        circularProgressContainer: {
            padding: 7,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        circularProgress: {
            color: colors.grayText,
        },
    })
);
