import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const ImageIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="34"
                    height="34"
                    rx="2.5"
                    fill="white"
                    stroke="#C4C4C4"
                />
                <path
                    d="M11.9176 25.9678H24.2116C25.1815 25.9678 25.9678 25.1815 25.9678 24.2116V11.9176C25.9678 10.9477 25.1815 10.1614 24.2116 10.1614H11.9176C10.9477 10.1614 10.1614 10.9477 10.1614 11.9176V24.2116C10.1614 25.1815 10.9477 25.9678 11.9176 25.9678ZM11.9176 25.9678L21.5771 16.3083L25.9678 20.699M16.3083 14.9911C16.3083 15.7186 15.7186 16.3083 14.9911 16.3083C14.2637 16.3083 13.6739 15.7186 13.6739 14.9911C13.6739 14.2637 14.2637 13.6739 14.9911 13.6739C15.7186 13.6739 16.3083 14.2637 16.3083 14.9911Z"
                    stroke="#C4C4C4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};
