import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        button: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0.2,
            outline: 'none',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: `1px solid ${colors.darkTeal}`,
            height: 35,
            borderRadius: '50%',
            width: 35,
        },

        plusIcon: {
            width: 30,
            height: 30,
            color: colors.darkTeal,
        },
    })
);
