import { executeGraphqlCall } from './api';
import { PaperDataType, ProjectInterface, ProjectPFCitation } from '../types';

export const createProject = async (speakerId: string, title: string): Promise<ProjectInterface> => {
    const variables = {
        data: {
            speakerId,
            title,
        },
    };
    const body = `mutation createProject($data:CreateProjectInput!) {
        createProject(data:$data){
            id
            createdAt
            title
            updatedAt
        }
    }`;
    return executeGraphqlCall<ProjectInterface>('createProject', body, undefined, variables);
};

export const getProjectCitations = async (papers: PaperDataType[], style: string): Promise<ProjectPFCitation[]> => {
    const variables = { papers, style: style.toLowerCase() };
    const body = `
    query($style: String!, $papers: [PaperDataType!]!){
        getProjectCitations(style: $style, papers: $papers) {
          doi
          citation
        }
      }
    `;
    return executeGraphqlCall<ProjectPFCitation[]>('getProjectCitations', body, undefined, variables);
};

export const getProjectPaperFiles = async (projectId: string): Promise<ProjectInterface> => {
    const variables = { projectId };
    const body = `query($projectId: String!){
        getProjectPaperFiles(id: $projectId) {
          id
          title
          createdAt
          updatedAt
          paperFiles {
            id
            key
            status
            name
            createdAt
            updatedAt
            paper {
              id
              doi
              title
              isOpenAccess
              journalName
              publishedDate
              createdAt
              updatedAt
              primaryAuthor{
                id 
                firstInitial 
                lastName 
                createdAt 
                updatedAt
              }
              secondaryAuthors{
                id 
                firstInitial 
                lastName 
                createdAt 
                updatedAt
              }
            }
          }
        }
      }`;
    return executeGraphqlCall<ProjectInterface>('getProjectPaperFiles', body, undefined, variables);
};

export const createProjectWithPaperFileProject = (speakerId: string, title: string, paperFileId: string) => {
    const variables = { data: { speakerId, title, paperFileId } };
    const body = `
      mutation($data: CreateProjectWithPaperFileProject!){
        createProjectWithPaperFileProject(data: $data) {
          id
          createdAt
          title
          updatedAt
        }
      }`;
    return executeGraphqlCall<ProjectInterface>('createProjectWithPaperFileProject', body, undefined, variables);
};
export const getAllProjects = (speakerId: string): Promise<ProjectInterface[]> => {
    const variables = { speakerId };
    const body = `query($speakerId: String!){
    getAllProjects(speakerId: $speakerId) {
      title
      id
      createdAt
      noOfPaperFiles,
      outline {
        pages {
          title
        }
      }
    }
  }`;

    return executeGraphqlCall<ProjectInterface[]>('getAllProjects', body, undefined, variables);
};

export const updateProjectTitle = (projectId: string, title: string): Promise<ProjectInterface> => {
    const variables = { data: { projectId, title } };
    const body = `
    mutation($data: UpdateProjectTitle!){
      updateProjectTitle(data: $data) {
        id
        createdAt
        title
        updatedAt
      }
    }`;
    return executeGraphqlCall<ProjectInterface>('updateProjectTitle', body, undefined, variables);
};

export const deleteProject = async (projectId: string, speakerId: string): Promise<any> => {
    const variables = { data: { projectId, speakerId } };
    const body = `
  mutation($data: DeleteProjectInput!){
      deleteProject(data: $data){
        projectId
        deletedPaperFileKeys
        projects {
          id
          noOfPaperFiles
        }
      }
    }
  `;
    return executeGraphqlCall<ProjectInterface>('deleteProject', body, undefined, variables);
};
