import { IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add, Done, Remove } from '@material-ui/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorIcon } from '../../../../assets/icons';
import { addPaperFromDoi, getPaperCitation } from '../../../../backend/paper';
import { alertTypes } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import {
    setCitation,
    setPaperFile,
    showAlert,
    showSavePaper,
    updatePaperFileOfProject,
} from '../../../../redux/reducers';
import { InformationModal as InformationModalType, PaperDataType, PaperStatusList } from '../../../../types';
import { generateMetaData } from '../../../../utils';
import { InformationModal, InputModal, ScreenCapture } from '../../../common';
import { DoiModalHelpContent } from '../../../common/modals/inputModal/doiModalInfo';
import { HighlightedTextOptions } from '../highlightedTextOptions';
import { style } from './style';

const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

interface ViewerWrapperProps {
    paperUrl: string;
    isSaved: boolean;
    selectedProject?: any;
    setLoadingPaper: React.Dispatch<React.SetStateAction<boolean>>;
    outlineDrawerOpen: boolean;
    setOnStartCaptureRef: any;
}

export const ViewerWrapper = ({
    paperUrl,
    isSaved,
    setLoadingPaper,
    selectedProject,
    outlineDrawerOpen,
    setOnStartCaptureRef,
}: ViewerWrapperProps) => {
    const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
    // the file property of react-pdf lib depends on tripple equality. more on lib docs.
    const memoizedPdfBlob = useMemo(() => pdfBlob, [pdfBlob]);
    const [pages, setPages] = useState(0);
    const [openDoiModal, setOpenDoiModal] = useState(false);
    const [manualDoi, setManualDoi] = useState('');
    const [scale, setScale] = useState(1);
    const [scaleInputValue, setScaleInputValue] = useState(100);
    const [updateDoi, setUpdateDoi] = useState(false);
    const paperFile = useSelector((state: RootState) => state.PaperFile);
    const SpeakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const inputRef = useRef<HTMLInputElement | null>(null);
    let [showInformationModal, setShowInformationModal] = useState<InformationModalType>({
        type: '',
        open: false,
        title: '',
        closeBtnLabel: '',
        successBtnLabel: '',
        cancelBtnLabel: '',
        subTitle: <></>,
        footer: <></>,
        helpInfo: <></>,
        cancelBtnLoading: false,
        handleConfirm: () => {},
        handleClose: () => {},
        dataTestId: '',
    });

    const doi = paperFile.paper?.doi;
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = style();

    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));

    const getScreenWidth = () => {
        if (screenExtraLarge) {
            return 1200;
        } else if (screenLarge) {
            return 800;
        } else if (screenMedium) {
            return 600;
        } else if (screenSmall) {
            return 450;
        } else {
            return 600;
        }
    };

    useEffect(() => {
        if (paperFile.key) {
            // when paper url changes, means new paper will be loaded. so, reset any state related with the pdf blob to ignore side effects.
            setPdfBlob(null);
            setPages(0);
            if ('caches' in window) {
                caches.open('paper-cache').then((cache) => {
                    cache.match(paperFile.key as string).then((response) => {
                        if (response) {
                            response.blob().then((blob) => {
                                setPdfBlob(blob);
                            });
                        } else {
                            setLoadingPaper(true);
                            fetch(paperUrl)
                                .then(async (res) => {
                                    // From MDN: return response.clone(). Clone is needed because put() consumes the response body.
                                    await cache.put(paperFile.key as string, res.clone());
                                    return res.blob();
                                })
                                .then((blob) => {
                                    setPdfBlob(blob);
                                    setLoadingPaper(false);
                                })
                                .catch(() => {
                                    dispatch(
                                        showAlert({
                                            isVisible: true,
                                            message: 'Failed to download paper file',
                                            type: alertTypes.error,
                                        })
                                    );
                                    setLoadingPaper(false);
                                });
                        }
                    });
                });
            }
        }
    }, [paperFile.key]);

    useEffect(() => {
        setScaleInputValue(Math.round(scale * 100));
    }, [scale, setScaleInputValue]);

    const handleSavePaperModal = () => dispatch(showSavePaper({ savePaperModal: true }));

    const handleInputDoi = (ev: any) => setManualDoi(ev.target.value);

    const toggleDoiModal = () => setOpenDoiModal(!openDoiModal);

    const closeAlertModal = () => {
        setShowInformationModal({ ...showInformationModal, open: false });
    };

    const handleManualDoi = async () => {
        try {
            setUpdateDoi(true);
            const paperMetaData = await addPaperFromDoi(manualDoi, paperFile.id as string, PaperStatusList.empty);
            dispatch(setPaperFile({ ...paperMetaData, isCreateProjectWithPaper: false }));
            setUpdateDoi(false);
            toggleDoiModal();

            const paper = paperFile.paper;
            const style = SpeakerPreferences.citationStyle;
            const metaData = generateMetaData(paper);

            const { citation } = await getPaperCitation(metaData as PaperDataType, style, speakerId!);
            dispatch(setCitation({ citation: citation as string }));
            dispatch(
                updatePaperFileOfProject({
                    projectId: selectedProject?.id,
                    paperFileId: paperFile.id,
                    paperFile: paperMetaData,
                })
            );

            setShowInformationModal({
                type: alertTypes.success,
                open: true,
                title: 'DOI was accepted',
                closeBtnLabel: 'Close',
                cancelBtnLabel: '',
                successBtnLabel: '',
                cancelBtnLoading: false,
                handleConfirm: () => enterDoiAgain(),
                handleClose: () => closeAlertModal(),
                dataTestId: '',
            });
        } catch (error) {
            setUpdateDoi(false);
            toggleDoiModal();
            setShowInformationModal({
                type: alertTypes.error,
                open: true,
                title: (error as any | Error)?.message?.trim() || 'DOI was not entered properly',
                cancelBtnLabel: 'Cancel',
                successBtnLabel: 'Enter DOI again',
                closeBtnLabel: '',
                helpInfo: '',
                cancelBtnLoading: false,
                handleConfirm: () => enterDoiAgain(),
                handleClose: () => closeAlertModal(),
                dataTestId: '',
            });
        }
    };

    const onPdfLoadSuccess = (pdf: any) => {
        setPages(pdf.numPages);
    };

    const increaseScale = () => {
        if (scale < 4.9) setScale(scale + 0.1);
    };

    const decreaseScale = () => {
        if (scale > 0.15) setScale(scale - 0.1);
    };

    const handleScreenCapture = (itemId: string, screenCapture: string | null, width: number, height: number) => {
        emitCustomEvent(`snip-image-result-${itemId}`, { image: screenCapture, width, height });
        return null;
    };

    const startCapturing = () => {
        emitCustomEvent('start-capturing');
        return null;
    };

    const enterDoiAgain = () => {
        setShowInformationModal({ ...showInformationModal, open: false });
        setOpenDoiModal(true);
    };

    const handleScaleChange = (e: any) => {
        const value = e.target.value.replace('%', '');
        if (isNaN(value) || value === '' || parseInt(value) > 500) return;
        setScaleInputValue(parseInt(value));
    };

    const handleScaleInputBlur = () => setScale(scaleInputValue / 100);

    const handleKeyPress = (e: any) => {
        if (e.key == 'Enter' && inputRef && inputRef.current) {
            inputRef.current.blur();
            handleScaleInputBlur();
        }
    };

    return (
        <div id="pdf-wrapper" className={classes.body}>
            {!doi && memoizedPdfBlob && (
                <div className={classes.doiMsgContainer}>
                    <div className={classes.doiMsgLeft}>
                        <ErrorIcon className={classes.errorIcon} />
                        <div className={classes.doiErrorMsg}>
                            This PDF is missing a DOI. Add it so nimdone can auto-generate citations on the outline.
                        </div>
                    </div>
                    <div className={classes.manualDoiText} onClick={toggleDoiModal}>
                        ENTER DOI MANUALLY
                    </div>
                </div>
            )}
            <div className={classes.addProjectContainer}>
                {/* PDF Zoom in/out component */}
                <div className={classes.zoomButtonsContainer}>
                    <IconButton onClick={decreaseScale} size="small">
                        <Remove className={classes.white} />
                    </IconButton>
                    <input
                        ref={inputRef}
                        onKeyPress={handleKeyPress}
                        onBlur={handleScaleInputBlur}
                        className={classes.zoomScale}
                        onChange={handleScaleChange}
                        value={`${scaleInputValue}%`}
                        name={'scale'}
                        id={'scale'}
                        data-testid="scale"
                    />
                    <IconButton onClick={increaseScale} size="small">
                        <Add className={classes.white} />
                    </IconButton>
                </div>

                <div className={classes.savePaperButtonContainer}>
                    <IconButton
                        onClick={handleSavePaperModal}
                        aria-label="Add Project"
                        className={classes.iconButtonConatiner}
                        data-testid="save-pdf-btn"
                    >
                        <div className={classes.iconContainer}>
                            <div className={classes.iconButton}>
                                {isSaved ? <Done className={classes.icon} /> : <Add className={classes.icon} />}
                            </div>

                            <p className={classes.iconLabel}>{isSaved ? 'Saved' : 'Save to...'}</p>
                        </div>
                    </IconButton>
                </div>
            </div>
            <div className={classes.documentContainer} id="document-container">
                <div className={outlineDrawerOpen ? classes.wrapperDivSmall : classes.wrapperDivFull}>
                    {memoizedPdfBlob && (
                        <ScreenCapture onEndCapture={handleScreenCapture} startCapturing={startCapturing}>
                            {({ onStartCapture }: { onStartCapture: () => null }) => {
                                setOnStartCaptureRef(() => onStartCapture);
                                return (
                                    <HighlightedTextOptions scale={scale}>
                                        <Document
                                            file={memoizedPdfBlob}
                                            onLoadSuccess={onPdfLoadSuccess}
                                            options={options}
                                        >
                                            {Array.apply(null, Array(pages))
                                                .map((x, i) => i + 1)
                                                .map((page) => (
                                                    <Page
                                                        scale={scale}
                                                        renderTextLayer={true}
                                                        renderAnnotationLayer={false}
                                                        pageNumber={page}
                                                        key={page}
                                                        width={getScreenWidth()}
                                                        /**
                                                         * These line solves the problem of words combining while highlighting but it
                                                         * drags down the performance of reader issue too much that hangs alot
                                                         */
                                                        // customTextRenderer={({ str }) => <div>{str}</div>}
                                                    />
                                                ))}
                                        </Document>
                                    </HighlightedTextOptions>
                                );
                            }}
                        </ScreenCapture>
                    )}
                </div>
            </div>
            <InputModal
                title="Manually enter the DOI"
                subtitle={paperFile.paper?.title}
                closeModal={toggleDoiModal}
                handleInputChange={handleInputDoi}
                handleSubmit={handleManualDoi}
                isOpen={openDoiModal}
                helpInfo={<DoiModalHelpContent />}
                value={manualDoi}
                placeholder="Eg: 10.1001/archinternmed.2012.940"
                inputLabel="Enter DOI"
                loading={updateDoi}
                modalPrimaryBtnText="Update"
                modalContentwidth={500}
            />
            <InformationModal
                type={showInformationModal.type}
                open={showInformationModal.open}
                title={showInformationModal.title}
                closeBtnLabel={showInformationModal.closeBtnLabel}
                successBtnLabel={showInformationModal.successBtnLabel}
                cancelBtnLabel={showInformationModal.cancelBtnLabel}
                subTitle={showInformationModal.subTitle}
                footer={showInformationModal.footer}
                helpInfo={showInformationModal.helpInfo}
                cancelBtnLoading={showInformationModal.cancelBtnLoading}
                handleConfirm={showInformationModal.handleConfirm}
                handleClose={showInformationModal.handleClose}
                dataTestId={showInformationModal.dataTestId}
            />
        </div>
    );
};
