import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paperFileProjectsDoi, PaperFileProject } from '../../../types';

const initialState: paperFileProjectsDoi = {};

const paperReferenceSlice = createSlice({
    name: 'PaperFileProjectsDoi',
    initialState,
    reducers: {
        setpaperFileProjectsDoi: (
            state,
            action: PayloadAction<{ doi: string; paperFileProjects: PaperFileProject[] }>
        ) => {
            const { paperFileProjects, doi } = action.payload;
            state[doi] = paperFileProjects;
            return state;
        },
        deletePaperFileProjectDoi: (state, action: PayloadAction<{ doi: string; projectId: string }>) => {
            const { projectId, doi } = action.payload;
            let index = state[doi].findIndex((item) => item.projectId === projectId);
            state[doi].splice(index, 1);
            return state;
        },
        addPaperFileProjectDoi: (
            state,
            action: PayloadAction<{ doi: string; projectId: string; paperFileProjectId: string }>
        ) => {
            const { projectId, doi, paperFileProjectId } = action.payload;
            state[doi].push({ projectId: projectId, id: paperFileProjectId });
            return state;
        },
    },
});

export const {
    setpaperFileProjectsDoi,
    deletePaperFileProjectDoi,
    addPaperFileProjectDoi,
} = paperReferenceSlice.actions;
export default paperReferenceSlice.reducer;
