import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';
import { robotoBodyCopy, robotoSmallCopy } from '../../../assets/fonts';
export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
        },
        content: {
            maxWidth: 550,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        logo: {
            height: 110,
            width: 110,
        },
        congratulations: {
            ...robotoBodyCopy,
            textAlign: 'center',
        },
        btn: {
            maxWidth: 300,
            fontWeight: 'normal',
            letterSpacing: '0.15px',
        },
        extensionLater: {
            ...robotoSmallCopy,
            textAlign: 'center',
        },
        blueText: {
            color: colors.blue,
            cursor: 'pointer',
        },
    })
);
