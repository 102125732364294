import React, { useCallback, useMemo } from 'react';
import { FormatListBulleted, FormatListNumbered } from '@material-ui/icons';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { CustomElement } from './components/customElement';
import { TextEditorToggleButton } from './components/toggleButton';
import { useTextEditorStyles } from './style';
import { TextEditorPropsType } from './types';

export const OutlineTextEditor: React.FC<TextEditorPropsType> = ({ textChange, value }) => {
    const classes = useTextEditorStyles();
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const renderElement = useCallback((props) => <CustomElement {...props} />, []);

    return (
        <div className={classes.root}>
            <Slate editor={editor} value={value} onChange={(newValue) => textChange(newValue)}>
                <div className={classes.toolbar}>
                    <TextEditorToggleButton Icon={FormatListBulleted} blockFormat="bulletedList" />
                    <TextEditorToggleButton Icon={FormatListNumbered} blockFormat="numberedList" />
                </div>
                <Editable placeholder="Start typing here..." renderElement={renderElement} spellCheck autoFocus />
            </Slate>
        </div>
    );
};
