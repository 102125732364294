import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            marginTop: 25,
            marginBottom: 25,
        },
        itemText: {
            flex: 1,
            display: 'flex',
            color: colors.text2,
            fontSize: 13,
            fontWeight: 500,
        },
        row: {
            backgroundColor: colors.white,
            boxShadow: `0px 0px 5px ${colors.boxShadow2}`,
            borderRadius: 8,
            marginTop: 30,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            cursor: 'pointer',
        },
        headings: {
            color: colors.text,
            fontWeight: 500,
        },
        itemContainer: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            columnGap: 10,
        },
        itemIcon: {
            alignSelf: 'flex-start',
        },
        listItem: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: colors.backgroundColor2,
            },
        },
        moreVert: {
            color: colors.darkTeal,
        },
    })
);
