import { Drawer, CssBaseline } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/combineReducers';
import { style } from './style';

interface DrawerTogglerProps {
    children: any;
    open?: boolean;
    testId?: string;
    drawerToggler?: boolean;
    anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
}

export const DrawerWrapper = ({ children, open, testId, drawerToggler, anchor }: DrawerTogglerProps) => {
    const classes = style();
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    return (
        <>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: `${classes.drawerPaper} ${isVisible && classes.alertbar} ${drawerToggler &&
                        classes.drawerToggler}`,
                }}
                open={open ? open : true}
                data-testid={testId}
                anchor={Boolean(anchor) ? anchor : 'left'}
            >
                <div className={classes.drawerContainer}>{children}</div>
            </Drawer>
        </>
    );
};
