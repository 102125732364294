import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/colors';

export const useOutlineSidebarStyles = makeStyles(() => ({
    root: {
        marginLeft: '14px',
        marginRight: '12px',
        display: 'flex',
        flexDirection: 'column',
    },
    readerViewRoot: {
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        padding: '40px 40px 48px 14%',
    },
    paperinReader: {
        padding: '0px',
    },
    title: {
        color: colors.text,
        marginBottom: '16px',
    },
    sidebarHeading: {
        flex: 1,
    },
    savingIndicator: {
        color: colors.disabledMenu,
        fontStyle: 'italic',
    },
    caption: {
        color: colors.text,
        fontSize: '1.1em',
        display: 'flex',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0px',
    },
}));
