import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles((theme: Theme) =>
    createStyles({
        settingContainer: {
            marginTop: 82,
            marginLeft: 52,
        },
        heading: {
            fontWeight: 500,
            fontSize: '20px',
        },
        settingContent: {
            width: '441px',
            marginTop: 18,
            padding: 33,
            background: colors.white,
            borderRadius: '8px',
            boxShadow: `0px 0px 5px ${colors.boxShadow}`,
        },
        avatarDefault: {
            fontSize: '18px',
            padding: '27px',
            margin: 'auto',
            background: colors.avatarUsername,
        },
        accSettingForm: {
            marginTop: 12,
        },
        button: {
            width: '210px',
            fontWeight: 'normal',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '24px',
        },
        delAccPaper: {
            display: 'flex',
            marginTop: '16px',
            boxShadow: `0px 0px 5px ${colors.boxShadow}`,
        },
        delAccButton: {
            margin: '16px auto 14px auto',
            '& .MuiButton-label': {
                textTransform: 'capitalize',
                fontSize: '16px',
                color: '#EA2027',
            },
        },
        exampleCitationText: {
            color: colors.text,
            fontSize: 10,
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            marginBottom: 0,
            letterSpacing: '0.2px',
        },
        proxyUrlText: {
            color: colors.text,
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            marginBottom: 0,
            letterSpacing: '0.2px',
        },

        exampleCitation: {
            backgroundColor: colors.skyGray,
            color: colors.darkTeal,
            borderRadius: 5,
            padding: '10px',
        },
        text: {
            textAlign: 'right',
            marginLeft: '80px',
            display: 'flex',
            flexDirection: 'row',
        },
        input: {
            marginBottom: 10,
            fontSize: '12px',
            fontFamily: 'Roboto',
            '& h2': {
                display: 'none',
            },
        },
        proxyUrlContainer: {
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        proxyUrl: {
            marginTop: 5,
        },

        toggleBody: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'end',
            flexDirection: 'row',
            fontSize: 10,
            fontFamily: 'Roboto',
            fontWeight: 'normal',
            letterSpacing: '0.2px',
            width: '100%',
            margin: 0,
        },
        link: {
            color: colors.darkTeal,
            marginLeft: 5,
        },
    })
);
