import { executeGraphqlCall } from './api';
import { PaperFileProject, ExtendedProjectInterfce, PaperSearchResponse, PaperJobResType } from '../types';

export const addPaperFileProject = async (projectId: string, paperFileId: string): Promise<PaperFileProject> => {
    const variables = {
        data: { projectId, paperFileId },
    };
    const body = `
    mutation addPaperFileProject($data:PaperFileProjectInput!) {
        addPaperFileProject(data:$data){
            createdAt
        }
    }
    `;
    return executeGraphqlCall<PaperFileProject>('addPaperFileProject', body, undefined, variables);
};

export const removePaperFileProject = async (projectId: string, paperFileId: string): Promise<void> => {
    const variables = {
        data: { projectId, paperFileId },
    };
    const body = `mutation removePaperFileProject($data:PaperFileProjectInput!) {
        removePaperFileProject(data:$data)
    }`;
    return executeGraphqlCall<void>('removePaperFileProject', body, undefined, variables);
};

export const getPaperFileProjects = async (paperFileId: string): Promise<ExtendedProjectInterfce[]> => {
    const variables = {
        paperFileId,
    };
    const body = `query getPaperFileProjects($paperFileId:String!) {
        getPaperFileProjects(paperFileId:$paperFileId){
            project{ id }
        }
    }`;
    return executeGraphqlCall<ExtendedProjectInterfce[]>('getPaperFileProjects', body, undefined, variables);
};

export const removePaperFileProjectWithDoi = async (projectId: string, doi: string): Promise<boolean> => {
    const variables = {
        data: { projectId, doi },
    };
    const body = `mutation removePaperFileProjectWithDoi($data: PaperWithDoiInput!){
        removePaperFileProjectWithDoi(data: $data)
      }`;
    return executeGraphqlCall<boolean>('removePaperFileProjectWithDoi', body, undefined, variables);
};
export const getPaidPaperWithProjectID = async (
    paperSearchResponse: PaperSearchResponse,
    speakerId: string,
    projectId: string
): Promise<PaperJobResType> => {
    const variables = { data: { paperSearchResponse, speakerId, projectId } };
    const body = `
    query getPaidPaperWithProjectID($data: getPaperWithProjectIDInput!) {
        getPaidPaperWithProjectID(data: $data) {
          doi
          paperFileProjectId
          paperFile {
            id
            key
            status
            name
            createdAt
            updatedAt
            paper {
              id
              doi
              title
              isOpenAccess
              journalName
              publishedDate
              createdAt
              updatedAt
              primaryAuthor {
                lastName
                firstInitial
              }
              secondaryAuthors {
                firstInitial
                lastName
              }
            }
          }
        }
      }`;
    return executeGraphqlCall<PaperJobResType>('getPaidPaperWithProjectID', body, undefined, variables);
};

export const getPaperFileProjectsByDoi = async (speakerId: string, doi: string): Promise<PaperFileProject[]> => {
    const variables = {
        speakerId,
        doi,
    };
    const body = `query GetPaperFileProjectsByDoi($speakerId: String!, $doi: String!) {
        getPaperFileProjectsByDoi(speakerId: $speakerId, doi: $doi){
          projectId
          id
        }
    }`;
    return executeGraphqlCall<PaperFileProject[]>('getPaperFileProjectsByDoi', body, undefined, variables);
};

export const removePaperFileProjectById = async (paperFileProjectId: string) => {
    const variables = { id: paperFileProjectId };

    const body = `mutation deletePaperFileProject($id: String!) {
        deletePaperFileProject(id:$id)
      }`;
    return executeGraphqlCall<boolean>('deletePaperFileProject', body, undefined, variables);
};

export const getFreePaperByJobId = async (
    paperSearchResponse: PaperSearchResponse,
    jobId: string,
    keyToPaperInStorage: string
): Promise<PaperJobResType> => {
    const variables = {
        data: {
            paperSearchResponse,
            jobId,
            keyToPaperInStorage,
        },
    };
    const body = `
    query getFreePaperByJobId($data:getFreePaperByJobIDInput!) {
        getFreePaperByJobId(data:$data){
            url
            status
            doi
            keyToPaperInStorage
            jobId
            paperFileProjectId
            paperFile {
              id
              key
              status
              name 
              createdAt
              updatedAt
              paper{
                id
                doi
                title
                isOpenAccess
                journalName
                publishedDate
                createdAt
                updatedAt
                primaryAuthor{
                  lastName
                  firstInitial
                }
                secondaryAuthors{
                  firstInitial
                  lastName
                }
              }
            }
        }
    }`;
    return executeGraphqlCall<PaperJobResType>('getFreePaperByJobId', body, undefined, variables);
};

export const getFreePaperWithProjectID = async (
    paperSearchResponse: PaperSearchResponse,
    speakerId: string,
    projectId: string
): Promise<PaperJobResType> => {
    const variables = { data: { paperSearchResponse, speakerId, projectId } };
    const body = `
    query getFreePaperWithProjectID($data:getPaperWithProjectIDInput!) {
        getFreePaperWithProjectID(data:$data){
            url
            status
            doi
            keyToPaperInStorage
            jobId
            paperFileProjectId
            paperFile {
              id
              key
              status
              name 
              createdAt
              updatedAt
              paper{
                id
                doi
                title
                isOpenAccess
                journalName
                publishedDate
                createdAt
                updatedAt
                primaryAuthor{
                  lastName
                  firstInitial
                }
                secondaryAuthors{
                  firstInitial
                  lastName
                }
              }
            }
        }
    }`;
    return executeGraphqlCall<PaperJobResType>('getFreePaperWithProjectID', body, undefined, variables);
};
