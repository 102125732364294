import React from 'react';

import Menu from '@material-ui/core/Menu';
import MUIMenuItem from '@material-ui/core/MenuItem';

import { OutlineItemType } from '../../../../../../types';
import { style } from './styles';
import { Box, Typography } from '@material-ui/core/';
import { TextLinesIcon, ImageIcon, TextListIcon } from '../../../../../../assets/icons';

export interface AddItemMenuProps {
    anchorElement: null | HTMLElement;
    onClose: () => void;
    onItemSelected: (type: OutlineItemType) => void;
}

export const AddItemMenu = (props: AddItemMenuProps) => {
    const classes = style();
    return (
        <Menu anchorEl={props.anchorElement} keepMounted open={Boolean(props.anchorElement)} onClose={props.onClose}>
            <MUIMenuItem onClick={() => props.onItemSelected(OutlineItemType.TEXT)} className={classes.root}>
                <TextLinesIcon className={classes.Icon} viewBox={'0 0 35 35'} />
                <Box className={classes.textContainer} data-testid={"textBoxItem"} >
                    <Typography className={classes.text}>Text box</Typography>
                    <Typography className={classes.caption}>Insert a text box</Typography>
                </Box>
            </MUIMenuItem>
            <MUIMenuItem onClick={() => props.onItemSelected(OutlineItemType.LIST)} className={classes.root}>
                <TextListIcon />
                <Box className={classes.textContainer} data-testid={"listBoxItem"}>
                    <Typography className={classes.text}>List box</Typography>
                    <Typography className={classes.caption}>Insert a list box</Typography>
                </Box>
            </MUIMenuItem>
            <MUIMenuItem onClick={() => props.onItemSelected(OutlineItemType.IMAGE)} className={classes.root}>
                <ImageIcon className={classes.Icon} viewBox={'0 0 35 35'} />
                <Box className={classes.textContainer} data-testid={"imageItem"}>
                    <Typography className={classes.text}>Image</Typography>
                    <Typography className={classes.caption}>Insert an image</Typography>
                </Box>
            </MUIMenuItem>
        </Menu>
    );
};
