import { useMediaQuery } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllProjects, getProjectPaperFiles } from '../../backend/project';
import { getSpeaker } from '../../backend/speaker';
import config from '../../config';
import { RootState } from '../../redux/combineReducers';
import { loginSpeaker, setProjects, updatePaperFileArray, updateSpeakerPreference } from '../../redux/reducers';
import { Settings } from '../settings';
import { AddProject, BrowserSupportErr, FreeTrialEndedModal, InformationModal } from '../common';
import { PaperFile, SpeakerSubscriptionStatuses, SpeakerWithPreferences } from '../../types';
import { getSpeakerSubscriptionStatus, useQuery } from '../../utils';
import { ResetPassword } from '../auth/resetPassword';
import { SignupSuccess } from '../auth/signupSuccess';
import { LibraryViewComp } from '../libraryView';
import { OnboardingTutorial } from '../onboarding';
import Pricing from '../stripe/Pricing';
import { SubscriptionSuccess } from '../stripe/subscriptionSuccess';
import { InternalServerError } from './internalServerError';
import { PageNotFound } from './pageNotFound';
import { PdfReaderPage } from './pdfReader';
import { authRoutes, routes } from './routes';
import { SlidePreviewPage } from './slidePreview';
import { SignUp } from '../auth/signup';
import { setSubscriptionData } from '../../redux/reducers/speaker';
import { tabletScreenSize } from '../../constants';

export const Pages = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const projectId = query.get('projectId');
    const doi = query.get('doi');
    const speaker = useSelector((state: RootState) => state.Speaker);
    const speakerPreferences = useSelector((state: RootState) => state.SpeakerPreferences);
    const [isProjectLoading, setIsProjectLoading] = useState(true);
    const { subscriptionStatus } = speaker;
    const tabletBreakpointMatches = useMediaQuery(`(max-width:${tabletScreenSize}px)`);
    const [mobileViewUnoptimizedErr, setMobileViewUnoptimizedErr] = useState<boolean>(false);

    useEffect(() => {
        const displayMobileViewUnoptimizedErr = localStorage.getItem('display_mobile_view_unoptimized_err');
        setMobileViewUnoptimizedErr(displayMobileViewUnoptimizedErr !== 'false' ? true : false);
    }, []);

    const handleFetchProjects = async (speakerId: string) => {
        try {
            setIsProjectLoading(true);
            const projects = await getAllProjects(speakerId as string);
            dispatch(setProjects(projects));
            setIsProjectLoading(false);

            const selectedProject = projects.find((item) => item.id === projectId);

            if (
                history.location.pathname === routes.library &&
                !doi &&
                !projectId &&
                !selectedProject?.id &&
                projects?.length > 0
            ) {
                return history.push({
                    pathname: routes.library,
                    search: '?' + new URLSearchParams({ projectId: projects[0].id }),
                });
            }

            if (
                history.location.pathname === routes.slidePreview &&
                !projectId &&
                !selectedProject?.id &&
                projects?.length > 0
            ) {
                return history.push({
                    pathname: routes.slidePreview,
                    search: '?' + new URLSearchParams({ projectId: projects[0].id }),
                });
            }

            // if project exists then fetch the project paper files
            // otherwise navigate to navigate to page without params
            if (projectId && projects.findIndex((project) => project.id === projectId) > -1) {
                const project = await getProjectPaperFiles(projectId as string);
                dispatch(
                    updatePaperFileArray({
                        projectId: projectId as string,
                        paperFile: project?.paperFiles as PaperFile[],
                    })
                );
            } else if (location.pathname === routes.slidePreview || location.pathname === routes.library) {
                history.push(location.pathname);
            }
        } catch (error) {
            setIsProjectLoading(false);
            // if we try to put project id (without having any project) in url
            // it will throw error and we are navigating it back to the library
            history.push(location.pathname);
        }
    };

    const handleFetchSpeaker = async (speakerId: string) => {
        try {
            const speaker = await getSpeaker(speakerId);
            if (speaker) {
                dispatch(setSubscriptionData(speaker));
            }
            if (config.featureFlags.pricing) {
                const { status } = getSpeakerSubscriptionStatus(
                    speaker.allowUser,
                    speaker.trialEnds,
                    speaker.isSubscriptionActive
                );
                if (status === SpeakerSubscriptionStatuses.NEW_USER) {
                    history.push(routes.selectPlan);
                } else loginUser(speaker);
            } else loginUser(speaker);
        } catch (error) {
            history.push(routes.internalServerError);
        }
    };

    const handleClose = () => {
        setMobileViewUnoptimizedErr(false);
        localStorage.setItem('display_mobile_view_unoptimized_err', 'false');
    };

    const loginUser = (speaker: SpeakerWithPreferences) => {
        dispatch(
            loginSpeaker({
                identityId: speaker.identityId,
                id: speaker.id,
                country: speaker.country,
                name: speaker.name,
                email: speaker.email,
                professionalCategory: speaker.professionalCategory,
                specialty: speaker.specialty,
                emailVerified: speaker.emailVerified,
                institution: speaker.institution,
                allowUser: speaker.allowUser,
                trialEnds: speaker.trialEnds,
                isSubscriptionActive: speaker.isSubscriptionActive,
            })
        );

        dispatch(
            updateSpeakerPreference({
                ...speaker.speakerPreferences,
            })
        );
    };

    const loadSpeakerSession = async () => {
        await Auth.currentAuthenticatedUser()
            .then(async (res) => {
                await handleFetchSpeaker(res?.username);
                await handleFetchProjects(res?.username);
            })
            .catch(async () => {
                await Auth.signOut();
                history.push(routes.login);
            });
    };

    useEffect(() => {
        loadSpeakerSession();
    }, []);

    // if user cancelled subscription
    // had free trial
    const openFreeTrialEndedModal =
        config.featureFlags.pricing &&
        (subscriptionStatus === SpeakerSubscriptionStatuses.CANCELLED_TRAILING ||
            subscriptionStatus === SpeakerSubscriptionStatuses.CANCELLED_SUBSCRIPTION ||
            subscriptionStatus === SpeakerSubscriptionStatuses.NOT_ALLOWED_USER) &&
        !history.location.pathname.includes('select-plan');

    const isAuthPage = (): boolean => {
        return Boolean(authRoutes.find((route) => route.startsWith(history.location.pathname)));
    };

    return (
        <div>
            <InformationModal
                open={tabletBreakpointMatches && mobileViewUnoptimizedErr}
                title="nimdone is optimized for desktop"
                subTitle="We have not optimized nimdone for mobile. If you would like the full expereince, please use nimdone on a computer. "
                titleInCenter
                handleClose={handleClose}
                closeBtnLabel="Close"
                hideBackdrop={false}
                subTitleInCenter={false}
            />
            <BrowserSupportErr displayModal={!tabletBreakpointMatches} />
            <Switch>
                <Route path={routes.library} exact>
                    <LibraryViewComp isProjectLoading={isProjectLoading} />
                </Route>
                <Route path={routes.reader} exact component={PdfReaderPage} />
                <Route path={routes.slidePreview} exact component={SlidePreviewPage} />
                <Route path={routes.internalServerError} exact component={InternalServerError} />
                <Route path={routes.selectPlan} exact component={Pricing} />
                <Route path={routes.settings} component={Settings} />
                <Route path={routes.subscription} exact component={SubscriptionSuccess} />
                <Route path={routes.confirm} exact component={SignupSuccess} />
                <Route path={routes.resetPassword} exact component={ResetPassword} />
                {config.featureFlags.signupPage && <Route path={routes.signup} exact component={SignUp} />}

                <Redirect from={routes.login} to={routes.library} />
                <Redirect from={routes.root} exact to={routes.library} />

                {config.featureFlags.allPaper && (
                    <Route
                        path={routes.article}
                        exact
                        component={(props: any) => <LibraryViewComp {...props} isProjectLoading={isProjectLoading} />}
                    />
                )}

                <Route path="*" component={PageNotFound} />
            </Switch>
            <AddProject />

            <FreeTrialEndedModal open={openFreeTrialEndedModal} />
            {speakerPreferences?.showOnboarding && config.featureFlags.onboarding && !isAuthPage() && (
                <OnboardingTutorial />
            )}
        </div>
    );
};
