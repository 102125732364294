import CheckIcon from '@material-ui/icons/Check';
import { Box, Button } from '@material-ui/core';
import { style } from './styles';
import clsx from 'clsx';
import { stripe } from '../../../constants';

interface IToggle {
    handlePlanChange: any;
    selected: string;
    monthLabel: string;
    yearLabel: string;
}
export const Toggle = ({ handlePlanChange, selected, monthLabel, yearLabel }: IToggle) => {
    const classes = style();

    return (
        <Box className={classes.toggleContainer}>
            <Button
                variant="text"
                onClick={() => handlePlanChange(stripe.monthlyPlan.id)}
                endIcon={
                    selected == stripe.monthlyPlan.id && <CheckIcon style={{ fontSize: '14px', color: '#006366' }} />
                }
                disableTouchRipple
                disableRipple
                disableFocusRipple
                className={clsx(selected == stripe.monthlyPlan.id ? (classes.btn, classes.selected) : classes.btn)}
            >
                {monthLabel}
            </Button>

            <Button
                variant="text"
                onClick={() => handlePlanChange(stripe.annualPlan.id)}
                className={clsx(selected == stripe.annualPlan.id ? (classes.btn, classes.selected) : classes.btn)}
                endIcon={
                    selected == stripe.annualPlan.id && <CheckIcon style={{ fontSize: '14px', color: '#006366' }} />
                }
                disableTouchRipple
                disableRipple
                disableFocusRipple
            >
                {yearLabel}
            </Button>
        </Box>
    );
};
