import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';
import { robotoBodyCopy } from '../../../../../../assets/fonts';
export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            columnGap: 10,
            boxShadow: '0 0 3px',
            borderRadius: 3,
            padding: '10px 18px',
        },
        fileNameContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 50,
        },
        fileName: {
            ...robotoBodyCopy,
            color: colors.text2,
            margin: 0,
        },
        fileNamePercent: {
            ...robotoBodyCopy,
            fontSize: 14,
            color: colors.elephant,
            margin: 0,
        },
        progressContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            rowGap: 5,
            width: 450,
        },
        pdfIcon: {
            width: 38,
            height: 51,
        },
        statusIcon: {
            width: 20,
            height: 20,
            cursor: 'pointer',
        },
        primaryColor: {
            backgroundColor: colors.skyGray,
        },
        bar: {
            backgroundColor: colors.darkTeal,
            opacity: 0.6,
        },
        root: {
            height: 8,
            borderRadius: 10,
        },
    })
);
