import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    LinearProgress,
    Typography,
    withStyles,
    CircularProgress,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { getAllOnboardingSections, getSpeakerOnboardings } from '../../backend/onboarding';
import { updateSpeakerOnboardingPreference } from '../../backend/speakerPreferences';
import { alertTypes } from '../../constants';
import { RootState } from '../../redux/combineReducers';
import { showAlert } from '../../redux/reducers';
import { updateSpeakerPreference } from '../../redux/reducers';
import { OnboardingSection, SpeakerOnboarding } from '../../types';
import { InformationModal } from '../common/modals/informationModal';
import { TutorialsContent } from './components/tutorials';
import { WelcomeContent } from './components/welcome';
import { compStyles, useStyles } from './style';

interface Props {}

const StyledLinearProgress = withStyles(compStyles.linearProgress)(LinearProgress);
const StyledAccordion = withStyles(compStyles.accordion)(Accordion);
const StyledAccordionSummary = withStyles(compStyles.accordionSummary)(AccordionSummary);
const StyledAccordionDetails = withStyles(compStyles.accordionDetails)(AccordionDetails);
const StyledBackdrop = withStyles(compStyles.backdrop)(Backdrop);

export const OnboardingTutorial: React.FC<Props> = () => {
    const classes = useStyles();
    const [progress, setProgress] = useState<number>(0);
    const tutsRef = useRef<HTMLDivElement | null>(null);
    const localStorageExpandedValue = localStorage.getItem('onboarding_tuts_panel_expanded');
    // condions used to expand the onboarding panel for new users by default
    const defaultExpanded =
        localStorageExpandedValue === 'true' ? true : localStorageExpandedValue === 'false' ? false : true;
    const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [closingOnboarding, setClosingOnboarding] = useState<boolean>(false);
    const speaker = useSelector((state: RootState) => state.Speaker);
    const dispatch = useDispatch();
    const [onboardingSections, setOnboardingSections] = useState<OnboardingSection[] | undefined>();
    const [speakerOnboardings, setSpeakerOnboardings] = useState<SpeakerOnboarding[] | undefined>();

    const fetchOnboardingData = async (speakerId: string) => {
        try {
            const onboardingSections = await getAllOnboardingSections();
            setOnboardingSections(onboardingSections);
            const speakerOnboardings = await getSpeakerOnboardings(speakerId);
            setSpeakerOnboardings(speakerOnboardings);
            setLoading(false);
        } catch (error) {
            if (error instanceof Error)
                dispatch(
                    showAlert({
                        isVisible: true,
                        message: error.message,
                        type: alertTypes.error,
                    })
                );
        }
    };

    useEffect(() => {
        if (speaker.id) {
            fetchOnboardingData(speaker.id);
        }
    }, [speaker.id]);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
        localStorage.setItem('onboarding_tuts_panel_expanded', String(!expanded));
    };

    const closeModal = () => {
        setOpen(false);
    };
    const disableOnboardingTut = async () => {
        setClosingOnboarding(true);
        await updateSpeakerOnboardingPreference(speaker.id!, false)
            .then((res) => {
                closeModal();
                dispatch(updateSpeakerPreference(res));
                setClosingOnboarding(false);
            })
            .catch((error) => {
                if (error instanceof Error)
                    dispatch(
                        showAlert({
                            isVisible: true,
                            message: error.message,
                            type: alertTypes.error,
                        })
                    );
                setClosingOnboarding(false);
            });
    };

    return (
        <div className={classes.root} data-testid="onboarding-panel">
            <div className={classes.body}>
                <StyledAccordion onChange={handleAccordionChange} expanded={expanded}>
                    <StyledAccordionSummary
                        expandIcon={
                            <ExpandLessIcon className={classes.expandMoreIcon} data-testid="expand-less-icon" />
                        }
                        IconButtonProps={{ size: 'small', edge: 'start' }}
                        data-testid="onboarding-header"
                    >
                        <StyledLinearProgress
                            className={classes.linearProgress}
                            variant="determinate"
                            value={progress}
                            data-testid="onboarding-progress"
                        />
                        <Typography className={classes.accordionTitle}>nimdone onboarding {progress}%</Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <div className={classes.contentWrapper}>
                            <div className={classes.content}>
                                <WelcomeContent tutsRef={tutsRef} setCloseModalOpen={setOpen} progress={progress} />
                                <div ref={tutsRef}>
                                    <TutorialsContent
                                        setProgress={setProgress}
                                        onboardingSections={onboardingSections}
                                        speakerOnboardings={speakerOnboardings}
                                        setSpeakerOnboardings={setSpeakerOnboardings}
                                        speakerId={speaker.id!}
                                        setLoading={setLoading}
                                    />
                                </div>
                            </div>
                            <StyledBackdrop open={loading} data-testid="onboarding-loading">
                                <CircularProgress color="inherit" />
                            </StyledBackdrop>
                        </div>
                    </StyledAccordionDetails>
                </StyledAccordion>
                <InformationModal
                    open={open}
                    type={alertTypes.error}
                    handleClose={disableOnboardingTut}
                    handleConfirm={closeModal}
                    title="Close Onboarding Tutorial"
                    subTitle="You can find this tutorial in the help section"
                    successBtnLabel="Keep Open"
                    cancelBtnLabel="Close"
                    cancelBtnLoading={closingOnboarding}
                    dataTestId="onboarding-close-modal"
                    closeBtnLabel=""
                />
            </div>
        </div>
    );
};
