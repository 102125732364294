import { Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { CreateButton } from './createButton';

import { style } from './style';

interface ReaderViewModalWrapperProps {
    children: any;
    close: () => void;
    open: boolean;
    loader: boolean;
    onClose: () => void;
    title: string;
    handleCreate: () => void;
    createBtnLabel: string;
}
export const ReaderViewModalWrapper = ({
    children,
    close,
    open,
    loader,
    onClose,
    title,
    handleCreate,
    createBtnLabel,
}: ReaderViewModalWrapperProps) => {
    const classes = style();
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="save-paper-modal-title"
            aria-describedby="save-paper-modal-description"
            className="centered-container"
            role="presentation"
        >
            <div className={classes.root}>
                <div className={classes.modalHeader} style={{ zIndex: 1 }}>
                    <p className={classes.title}>{title}</p>

                    <div className={classes.flex}>
                        <span className={classes.savingIndicator}>{loader ? '(Saving...)' : ''}</span>
                        <div
                            className={classes.closeContainer}
                            role="presentation"
                            onClick={close}
                            data-testid="reader-view-modal-wrapper-closeIcon"
                        >
                            <Close className={classes.closeIcon} />
                        </div>
                    </div>
                </div>
                {children}
                <CreateButton title={createBtnLabel} handleCreate={handleCreate} />
            </div>
        </Modal>
    );
};
