import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const useOutlineCitationItemStyles = makeStyles(() => ({
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    initialCitationText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        marginTop: 5,
        letterSpacing: 0.15,
        color: colors.disabledMenu,
    },
    text: {
        fontSize: 12,
        paddingBottom: 0,
        padding: 0,
        width: '100%',
        border: 0,
    },
    textWrapper: {
        color: colors.darkTeal,
        padding: 0,
        '&.Mui-focused': {
            border: `solid ${colors.elephant} 1px`,
            color: `${colors.text} !important`,
        },
    },
    importCitationContainer: {
        margin: 0,
        color: colors.grayText,
        fontSize: 12,
        fontWeight: 600,
    },
    importCitation: {
        color: colors.darkTeal,
        cursor: 'pointer',
    },
}));

export const importCitationStyles = makeStyles(() => ({
    root: {
        padding: 30,
        width: 400,
    },
    title: {
        color: colors.smoke,
        fontSize: 24,
        fontWeight: 500,
        margin: 0,
    },
    dropdownLabel: {
        fontSize: 12,
        color: colors.text,
    },
    footer: {
        margin: '20px 40px',
    },
    button: {
        height: '37px',
        fontWeight: 'normal',
        marginBottom: '10px',
    },
    cancelBtn: {
        height: '37px',
        fontWeight: 'normal',
    },
    importModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
    },
}));
