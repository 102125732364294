export const robotoBodyCopy = {
    fontFamily: 'Roboto',
    fontWeigth: '400',
    fontSize: 16,
    letterSpacing: 0.2,
};

export const robotoSmallCopy = {
    fontFamily: 'Roboto',
    fontWeigth: '400',
    fontSize: 12,
};
