import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { style } from './style';

const Loader = () => {
    const classes = style();
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress color="inherit" />
            <p>Loading paper...</p>
        </div>
    );
};

export { Loader };
