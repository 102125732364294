import React, { memo, useCallback, useRef, useState } from 'react';
import { CircularProgress, Grid, IconButton, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { uploadPdfForPaper } from '../../../backend/paper';
import config from '../../../config';
import { alertTypes, gtm } from '../../../constants';
import { RootState } from '../../../redux/combineReducers';
import { setPaperFile, showAlert, togglePaperSwitcher, updatePaperFileOfProject } from '../../../redux/reducers';
import { GTMService } from '../../../services/gtmService';
import { UploadPdfModel } from '../../libraryView/components/project/components/uploadPdfModel';
import { routes } from '../../pages/routes';
import { InformationModal } from '../modals/informationModal';
import { InputModal } from '../modals/inputModal';
import { DoiModalHelpContent } from '../modals/inputModal/doiModalInfo';
import { colors } from './../../../assets/colors';
import { Paper, PaperFile, PaperStatusList, InformationModal as InformationModalType } from './../../../types';
import { ClosedAccessInfo, DoiExtractionFailedTooltip } from './components/';
import { style } from './style';

interface PapersListProps {
    paperFiles: PaperFile[];
    temp: any;
    handleManualDoi: (paperFile: PaperFile, value: string) => void;
    deletePaperFromProject?: (paperFile: PaperFile) => void;
    deletePaperFromLibrary: (paperFile: PaperFile) => void;
    isArticleSelected: boolean;
    projectTitle?: string;
    projectId?: string | undefined;
    setOpenDoiModal?: any;
    openDOIModal?: any;
}

const PapersListComp = ({
    paperFiles,
    temp,
    handleManualDoi,
    isArticleSelected,
    deletePaperFromProject,
    deletePaperFromLibrary,
    projectTitle,
    projectId,
    setOpenDoiModal,
    openDOIModal,
}: PapersListProps) => {
    const [optionsAnchor, setOptionsAnchor] = useState(null);
    const [openUploadPdfModal, setOpenUPloadPdfModal] = useState(false);
    const [updatingDoi, setUpdatingDoi] = useState(false);
    const [showInformationModal, setShowInformationModal] = useState<InformationModalType>({ open: false });
    const selectedItem: any = useRef(null);
    const [newDoi, setNewDoi] = useState<string | undefined>();
    const { id: speakerId } = useSelector((state: RootState) => state.Speaker);
    const dispatch = useDispatch();
    const classes = style();
    const history = useHistory();
    const openOptions = Boolean(optionsAnchor);

    const onFileDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (projectId) {
                toggleUploadPdfModal(false);
                const file = acceptedFiles[0];
                dispatch(
                    updatePaperFileOfProject({
                        projectId,
                        paperFile: {
                            ...paperFiles[selectedItem.current],
                            status: 'Uploading Paper...',
                            loading: true,
                        },
                        paperFileId: paperFiles[selectedItem.current].id,
                    })
                );
                try {
                    const updatedPaperFile = await uploadPdfForPaper(
                        speakerId!,
                        paperFiles[selectedItem.current]?.id,
                        file
                    );
                    dispatch(
                        updatePaperFileOfProject({
                            projectId,
                            paperFile: { ...updatedPaperFile!, loading: false },
                            paperFileId: paperFiles[selectedItem.current].id,
                        })
                    );
                    const gtmService = new GTMService();
                    await gtmService.handleEvent({
                        event: gtm.events.UPLOAD_PDF,
                        userId: speakerId,
                    });
                } catch (error) {
                    dispatch(
                        updatePaperFileOfProject({
                            projectId,
                            paperFile: {
                                ...paperFiles[selectedItem.current],
                                status: PaperStatusList.paperDoesntExist,
                                loading: false,
                            },
                            paperFileId: paperFiles[selectedItem.current].id,
                        })
                    );
                    if (error instanceof Error)
                        dispatch(showAlert({ message: error.message, type: alertTypes.error, isVisible: true }));
                }
            }
        },
        [projectId]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf',
        multiple: false,
        maxFiles: 1, // limited to 1 file because this will be used for closed/paid papers
        onDrop: onFileDrop,
    });

    const toggleUploadPdfModal = useCallback((state: boolean) => {
        setOpenUPloadPdfModal(state);
    }, []);

    const handleOpenUploadModal = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        event.stopPropagation();
        selectedItem.current = index;
        toggleUploadPdfModal(true);
    }, []);

    const handleOpenOptions = useCallback((event: any, index: number) => {
        selectedItem.current = index;
        setOptionsAnchor(event.currentTarget);
    }, []);

    const handleCloseOptions = useCallback(() => setOptionsAnchor(null), []);

    const itemHandler = (name: string) => {
        if (name === 'toggleDoiModal') setOpenDoiModal(!openDOIModal);
        handleCloseOptions();
    };

    const handleCloseInputModal = () => {
        itemHandler('toggleDoiModal');
        setNewDoi(undefined);
    };

    const handleChangeDoi = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewDoi(value);
    }, []);

    const submitManualDoi = useCallback(async () => {
        setUpdatingDoi(true);
        handleManualDoi(paperFiles![selectedItem.current], newDoi!);
        setUpdatingDoi(false);
        setOpenDoiModal(!openDOIModal);
        setNewDoi(undefined);
    }, [newDoi, paperFiles![selectedItem.current]]);

    const handleReaderViewRedirect = useCallback(
        async (item: PaperFile) => {
            if (item?.key) {
                if (item?.loading) return;
                dispatch(
                    setPaperFile({
                        ...item,
                        isCreateProjectWithPaper: false,
                    })
                );
                dispatch(togglePaperSwitcher({ paperSwitcherDrawer: false }));

                history.push({
                    pathname: routes.reader,
                    search: '?' + new URLSearchParams({ key: item.key, projectId: projectId! }),
                });
            }
        },
        [projectId]
    );

    const handleDeletePaperFromProject = useCallback(async () => {
        handleCloseOptions();
        if (deletePaperFromProject) deletePaperFromProject(paperFiles![selectedItem.current]);
    }, []);

    const handleDeletePaperFromLibrary = useCallback(async () => {
        handleCloseOptions();
        deletePaperFromLibrary(paperFiles![selectedItem.current]);
    }, [paperFiles![selectedItem.current]]);

    return (
        <div className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <div className={classes.headings}> Title</div>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.headings}> Authors</div>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.headings}> Journal Name</div>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.headings}> Published</div>
                </Grid>
            </Grid>
            {paperFiles.map((item: PaperFile, index: number) => {
                const paper: Paper | undefined = item?.paper;
                const title: string = paper?.title ? paper?.title : (item?.name as string);
                const titleModified: string =
                    title && title.length > 21 && item?.loading
                        ? `${title.substring(0, 14)}... ${title?.substring(title.length - 3, title.length)}`
                        : title;
                const publishedDate = paper?.publishedDate ? paper?.publishedDate.split('T')[0] : '--';
                return (
                    <Grid
                        container
                        spacing={3}
                        id={item?.id}
                        key={`papers-metadata ${item?.id}`}
                        className={classes.row}
                    >
                        <Grid
                            item
                            xs={5}
                            className={classes.itemContainer}
                            onClick={() => handleReaderViewRedirect(item)}
                        >
                            <div className={classes.itemIcon}>
                                {!item.key ? (
                                    <BookmarkBorderIcon style={{ color: colors.darkTeal }} />
                                ) : (
                                    <InsertDriveFileOutlinedIcon style={{ color: colors.darkTeal }} />
                                )}
                            </div>
                            <div>
                                <div className={classes.itemText}>{titleModified}</div>
                                {!item.key && item?.status === PaperStatusList.paperDoesntExist && (
                                    <ClosedAccessInfo
                                        handleOpenUploadModal={(event) => handleOpenUploadModal(event, index)}
                                        paperFile={item}
                                    />
                                )}
                                {item?.status && item?.status !== PaperStatusList.paperDoesntExist && (
                                    <div
                                        className={classes.itemText}
                                        style={{ color: colors.darkTeal }}
                                        data-testid="status"
                                    >
                                        {item.status}
                                        &nbsp; &nbsp;
                                        {item?.status && item?.status === PaperStatusList.doiExtractionFailed && (
                                            <DoiExtractionFailedTooltip />
                                        )}
                                        {item?.loading && (
                                            <CircularProgress
                                                size={15}
                                                style={{ color: colors.elephant }}
                                                thickness={10}
                                                data-testid="loading-icon"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            className={classes.itemContainer}
                            onClick={() => handleReaderViewRedirect(item)}
                        >
                            <div className={classes.itemText}>
                                {paper?.primaryAuthor?.firstInitial ? paper?.primaryAuthor?.firstInitial : '--'}, &nbsp;
                                {paper?.secondaryAuthors && paper?.secondaryAuthors[0]?.firstInitial
                                    ? paper?.secondaryAuthors[0]?.firstInitial
                                    : '--'}
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={classes.itemContainer}
                            onClick={() => handleReaderViewRedirect(item)}
                        >
                            <div className={classes.itemText} data-testid="journalName">
                                {paper?.journalName ? paper?.journalName : '--'}
                            </div>
                        </Grid>
                        <Grid item xs={2} className={classes.itemContainer}>
                            <div className={classes.itemText} onClick={() => handleReaderViewRedirect(item)}>
                                {publishedDate}
                            </div>

                            <IconButton
                                size="small"
                                onClick={(e: any) => handleOpenOptions(e, index)}
                                data-testid="more-options"
                                disabled={item?.loading}
                            >
                                <MoreVert className={classes.moreVert} />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}

            {/* to upload pdf for closed/paid papers */}
            <UploadPdfModel
                open={openUploadPdfModal}
                toggleUploadPdfModal={toggleUploadPdfModal}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
            />

            <Popover
                open={openOptions}
                anchorEl={optionsAnchor}
                onClose={handleCloseOptions}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <List>
                    <ListItem className={classes.listItem} onClick={() => itemHandler('toggleDoiModal')}>
                        <ListItemText primary="Update the Doi" />
                    </ListItem>
                    {!isArticleSelected && config.featureFlags.allPaper && (
                        <ListItem className={classes.listItem} onClick={handleDeletePaperFromProject}>
                            <ListItemText primary={`Remove PDF from “${projectTitle}”`} />
                        </ListItem>
                    )}
                    <ListItem className={classes.listItem} onClick={handleDeletePaperFromLibrary}>
                        <ListItemText primary="Remove PDF from Library" />
                    </ListItem>
                </List>
            </Popover>
            <InputModal
                title="Manually enter the DOI"
                subtitle={paperFiles[selectedItem.current]?.paper?.title as string}
                closeModal={handleCloseInputModal}
                handleInputChange={handleChangeDoi}
                handleSubmit={submitManualDoi}
                isOpen={openDOIModal}
                helpInfo={<DoiModalHelpContent />}
                value={newDoi}
                defaultValue={paperFiles[selectedItem.current]?.paper?.doi}
                placeholder="Eg: 10.1001/archinternmed.2012.940"
                inputLabel="Enter DOI"
                loading={updatingDoi}
                modalPrimaryBtnText="Update"
                modalContentwidth={600}
                disablePrimaryBtn={paperFiles[selectedItem.current]?.paper?.doi === newDoi}
            />
            <InformationModal
                type={showInformationModal.type}
                open={showInformationModal.open}
                title={showInformationModal.title}
                closeBtnLabel={showInformationModal.closeBtnLabel}
                successBtnLabel={showInformationModal.successBtnLabel}
                cancelBtnLabel={showInformationModal.cancelBtnLabel}
                subTitle={showInformationModal.subTitle}
                footer={showInformationModal.footer}
                helpInfo={showInformationModal.helpInfo}
                cancelBtnLoading={showInformationModal.cancelBtnLoading}
                handleConfirm={showInformationModal.handleConfirm}
                handleClose={showInformationModal.handleClose}
                dataTestId={showInformationModal.dataTestId}
            />
        </div>
    );
};

export const PapersList = memo(PapersListComp);
