import React from 'react';
import { LayoutIconProps } from '../types';

export const Content1TextDefault = ({
    selected,
    onClickHandler,
    id
}: LayoutIconProps) => {
    const handleClick = () => {
        if (onClickHandler) onClickHandler(id as string);
    };
    return (
        <div
            role={'presentation'}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
        >
            <svg
                width="75"
                height="50"
                viewBox="0 0 75 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="1" y="1" width="73" height="48" rx="3" fill="white" />
                <rect
                    x="26.001"
                    y="13"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="26"
                    y="17.6054"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="26"
                    y="22.2106"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="26"
                    y="26.8159"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="26"
                    y="31.4211"
                    width="23.3551"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="26"
                    y="36.0263"
                    width="13.1578"
                    height="1.97367"
                    fill="#C4C4C4"
                />
                <rect
                    x="1"
                    y="1"
                    width="73"
                    height="48"
                    rx="3"
                    stroke={selected ? '#A0A4A8' : '#E8E8E8'}
                    strokeWidth="2"
                />
            </svg>
        </div>
    );
};
