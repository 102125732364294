import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/colors';
export const style = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            background: colors.lighBlueBackground,
        },

        mainHeader: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '140%',
            textAlign: 'center',
            letterSpacing: '-0.02em',
            color: colors.marketingPageText,
        },
        planContainer: {
            maxWidth: 'md',
            display: 'flex',
            flexDirection: 'row',
            padding: 16,
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
        },

        plan: {
            width: '445px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: 5,
            borderRadius: '4px',
            minWidth: '300px',
            minHeight: '300px',
            border: `1px solid ${colors.dividerBorderDisabledState}`,
            boxSizing: 'border-box',
            background: colors.white,
            zIndex: 22,
            filter: 'drop-shadow(0px 20px 40px rgba(82, 87, 92, 0.3))',
        },
        topBar: {
            width: '445px',
            height: '8px',
            background: colors.darkTeal,
            borderRadius: '4px 4px 0px 0px',
            position: 'absolute',
        },

        btn: {
            width: '235px',
            height: '56px',
            marginTop: '30px',
            padding: '16px',
            fontSize: '16px',
            lineHeight: '24px',
        },
        planDescription: {
            width: '100%',
            height: '100%',
            margin: '5px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            '& ul': {
                marginLeft: '-23px',
            },
            '& ul li::marker': {
                color: colors.darkTeal,
                fontSize: '25px',
            },
        },
        blueText: {
            color: colors.blue,
            cursor: 'pointer',
        },
        heading: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '28px',
            display: 'flex',
            alignItems: 'flex-end',
            textAlign: 'center',
            letterSpacing: '0.03em',
            color: colors.darkTeal,
            marginTop: '41px',
        },
        pricingPlan: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '44px',
            lineHeight: '51px',
            textAlign: 'center',
            color: colors.marketingPageText,
            margin: 0,
        },
        priceText: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '10px',
            textAlign: 'center',
            color: colors.text2,
            margin: 0,
        },
        backArrow: {
            marginLeft: '0rem',
        },
        contactUsLink: {
            textDecoration: 'none',
            marginTop: '60px',
        },
        backbuttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: '4rem',
            cursor: 'pointer',
            position: 'absolute',
            left: '100px',
            top: '12px',
            [theme.breakpoints.down('md')]: {
                left: '50px',
                top: '-12px',
            },
        },
        pricingSubtitle: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '120%',
            textAlign: 'center',
            color: colors.marketingPageText,
        },
        pricingPoint: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '120%',
            display: 'list-item',
            alignItems: 'center',
            color: colors.darkTeal,
            marginBottom: '12.5px',
        },
        innerText: {
            color: colors.marketingPageText,
        },
        institutionPlan: {
            width: '434px',
            height: '400px',
            background: colors.grayBackground,
            boxShadow: '0px 10px 20px rgb(82 87 92 / 30%)',
            borderRadius: '0px 4px 4px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '-16px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '4px',
            },
        },
        contactButton: {
            width: '241.11px',
            height: '56.13px',
            background: colors.lightTeal,
            borderRadius: '8px',
            color: colors.primaryButton,
            fontSize: '16px',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: colors.secondaryButtonOnhover,
            },
        },
        institutionDesc: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '120%',
            textAlign: 'center',
            color: colors.marketingPageText,
        },
        planbuttonLabel: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
        },
        institutionDescWrapper: {
            maxWidth: '250px',
        },
        subtext: {
            fontSize: '27px',
        },
        selectPlan: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        planInfo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        planText: {
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
        },
        forwardArrow: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7px',
        },
    })
);
