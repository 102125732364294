import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        errorIcon: {
            height: 16,
            width: 16,
        },
        errorIconLarge: {
            height: 24,
            width: 24,
        },
        tooltip: {
            backgroundColor: colors.error,
            height: 100,
        },
        tooltipContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 10,
        },
        tooltipHeading: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 16,
            letterSpacing: 0.2,
            color: colors.darkNavyBlue,
            marginBottom: 10,
        },
        tooltipDescription: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 16,
            letterSpacing: 0.2,
            color: colors.darkNavyBlue,
        },
    })
);
