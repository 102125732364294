import { Grid, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { MuiCMButton } from '..';
import { MuiCMLink } from '../muiCMLink';
import moment from 'moment';
import { useStyles } from './style';

interface Props {
    displayModal: boolean;
}

export const BrowserSupportErr: React.FC<Props> = ({ displayModal }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const errMsgExpires = localStorage.getItem('browser_support_err_msg_hidden');
        let errMsgHidden: boolean | undefined;
        if (errMsgExpires) {
            const errMsgExpiresDiff = moment(errMsgExpires).diff(moment(), 'days');
            if (errMsgExpiresDiff <= 0) errMsgHidden = false;
            else errMsgHidden = true;
        }
        if (window) {
            // Overall, all chroimum based browsers have the index of "Chrome" in userAgent. because they are basically chrome browser.
            // So, to make sure if the browser is the official chrome, we need to put the other browsers before chrome in the switch statement. for example, edge,opera,brave were listed before chrome.
            const browserName = (function (agent) {
                switch (true) {
                    case agent.indexOf('edge') > -1:
                        return 'MS Edge';
                    case agent.indexOf('edg/') > -1:
                        return 'Edge ( chromium based)';
                    case agent.indexOf('brave') > -1:
                        return 'Brave';
                    case agent.indexOf('opr') > -1:
                        return 'Opera';
                    case agent.indexOf('chrome') > -1:
                        return 'Chrome';
                    case agent.indexOf('trident') > -1:
                        return 'MS IE';
                    case agent.indexOf('firefox') > -1:
                        return 'Mozilla Firefox';
                    case agent.indexOf('safari') > -1:
                        return 'Safari';
                    default:
                        return 'other';
                }
            })(window.navigator.userAgent.toLowerCase());
            if (browserName !== 'Chrome' && !errMsgHidden) {
                setOpen(true);
            }
        }
    }, []);

    const onClose = () => {
        const expires = moment().add('7', 'days');
        localStorage.setItem('browser_support_err_msg_hidden', `${expires}`);
        setOpen(false);
    };
    return (
        <Modal open={open && displayModal}>
            <div className={classes.paper} data-testid="browser-support-err">
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <h2 className={classes.title}>Sorry, we don’t support your web browser yet. </h2>
                    </Grid>
                    <Grid item>
                        <IconButton data-testid="browser-err-close-icon-btn" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <p className={classes.subtitle}>
                    <span>Please re-open this page using</span>&nbsp;
                    <MuiCMLink
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        underline="always"
                        rel="noreferrer"
                    >
                        Google Chrome
                    </MuiCMLink>
                </p>
                <MuiCMButton
                    variant="contained"
                    className={classes.closeBtn}
                    onClick={onClose}
                    data-testid="browser-err-close-btn"
                >
                    Close
                </MuiCMButton>
            </div>
        </Modal>
    );
};
