import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        root: {
            width: 350,
            background: '#FFFFFF',
            boxShadow: ' 0px 20px 28px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            padding: 25,
            maxHeight: 400,
            overflow: 'hidden',
            outline: 'none',
        },
        modalHeader: {
            display: 'flex',
            position: 'sticky',
            top: '0px',
            background: 'white',
            paddingTop: '10px',
        },
        title: {
            fontSize: '16px',
            lineHeight: '19px',
            color: colors.darkGray,
            marginTop: 0,
            flex: 1,
        },
        flex: {
            display: 'flex',
        },
        savingIndicator: {
            color: colors.disabledMenu,
            fontStyle: 'italic',
            marginRight: '5px',
        },
        closeContainer: {
            cursor: 'pointer',
        },
        closeIcon: {
            color: colors.inactiveStates,
            fontSize: 18,
        },
    })
);
