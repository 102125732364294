import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../assets/colors';

export const style = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        content: {
            padding: '36px 20px',
            background: colors.white,
            boxShadow: '0px 20px 28px rgba(0, 0, 0, 0.25)',
            borderRadius: '8px',
            margin: '0 22px',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: 24,
            marginTop: 30,
            '&>button': {
                heing: 47,
            },
        },
        title: {
            width: 'fit-content',
            maxWidth: 500,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.2px',
            fontSize: 24,
            flex: ' none',
            order: 1,
            flexGrow: 0,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.smoke,
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
            },
        },

        subTitle: {
            margin: '8px auto 0 auto',
            width: 'fit-content',
            maxWidth: 500,
            textAlign: 'center',
            color: colors.smoke,
            fontWeight: 400,
            fontSize: 16,
            flex: 'none',
            order: 0,
            flexGrow: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        footer: {
            margin: '8px -80px 0 auto',
            width: 'fit-content',
            maxWidth: 500,
            color: colors.smoke,
            fontWeight: 500,
            fontSize: 16,
            order: 0,
            flexGrow: 0,
            letterSpacing: ' 0.2px',
            alignContent: 'flex-end',
        },

        inputContainer: {
            width: '444px',
            height: '40px',
            border: `1px solid ${colors.black}`,
            alignSelf: 'center',
            boxSizing: 'border-box',
        },
        infoIconContainer: {
            display: 'flex',
        },
        infoIcon: {
            color: colors.darkTeal,
        },
        tooltip: {
            background: colors.white,
            border: `1px solid ${colors.darkTeal}`,
            display: 'flex',
            color: 'black',
            width: 380,
            height: 'auto',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        doiList: {
            paddingLeft: '10px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '140%',
            letterSpacing: '0.2px',
            color: colors.black,
            position: 'relative',
        },
        bottomContainer: {
            paddingLeft: '65px',
            paddingRight: '65px',
            marginTop: '20px',
        },
        enterDoiBtn: {
            minWidth: '181px',
            fontWeight: 'normal',
        },
        closeBtn: {
            fontWeight: 'normal',
        },
        iconContainer: {
            paddingTop: '7px',
        },
        warningIcon: {
            color: colors.warningIcon,
            marginRight: '8px',
        },
        successIcon: {
            color: colors.checkCircleRounded,
            marginRight: '8px',
        },
        link: {
            color: colors.blue1,
            cursor: 'pointer',
        },
    })
);
