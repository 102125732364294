import { ProjectInterface } from '../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { style } from './style';
import { colors } from '../../../../assets/colors';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../pages/routes';

interface ProjectListProps {
    isArticleSelected: boolean;
    projectId: string | null;
}

export const ProjectList = ({ isArticleSelected, projectId }: ProjectListProps) => {
    const ProjectsData: ProjectInterface[] = useSelector((state: RootState) => state.Projects);
    const classes = style();
    const history = useHistory();

    const selectProject = (projectId: string) => {
        history.push({
            pathname: routes.library,
            search: '?' + new URLSearchParams({ projectId }),
        });
    };

    return (
        <div>
            {ProjectsData.map((item: ProjectInterface) => (
                <div
                    role="presentation"
                    onClick={() => selectProject(item.id)}
                    id={item.id}
                    style={
                        !isArticleSelected && projectId === item.id ? { backgroundColor: colors.backgroundColor2 } : {}
                    }
                    className={classes.mainContainer}
                    key={item.id}
                    data-testid={
                        !isArticleSelected && projectId === item.id
                            ? `selected-project-${projectId}`
                            : `project-${projectId}`
                    }
                >
                    <div className={classes.projectTitle}>{item.title}</div>
                    <div className={classes.avatarContainer}>
                        <div className={classes.countContainer}>{item.noOfPaperFiles ? item.noOfPaperFiles : 0}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};
