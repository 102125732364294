/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:17411698-1416-4d51-b9ec-67893d7efd24",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uXIxHgU6s",
    "aws_user_pools_web_client_id": "5e03d3cg7mpjvrgolk7v8cvbge",
    "oauth": {
        "domain": "nimdone-development.auth.us-east-1.amazoncognito.com"
    },
    "aws_user_files_s3_bucket": "nimdone-user-files-development",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
