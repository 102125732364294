import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        modalList: {
            paddingLeft: '10px',
            margin: 0,
        },
    })
);

export const ProxyModalHelpContent = () => {
    const classes = useStyles();
    return (
        <ol className={classes.modalList}>
            <li>
                A proxy URL authenticates a library user as someone permitted to use the library’s licenced electronic
                resouces.
            </li>
            <li> Get your proxy URL from your university librarian</li>
        </ol>
    );
};
