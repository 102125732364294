import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import { useSlideStyles } from './style';
import { LayoutIcon } from '../../../../assets/icons/layoutIcons';
import { LayoutMenu } from './components/layoutMenu';
import { Slide as SlideIntreface } from '../../../../types';
import { Presentation, Slide as PresentationSlide, Text, Shape, Image, Line } from 'react-pptx';
import Preview from 'react-pptx/preview';
import { transform } from 'buble';

const primitives = {
    Presentation,
    Slide: PresentationSlide,
    Image,
    Text,
    Shape,
    Line,
};

interface SlideProps {
    slide: SlideIntreface;
}
const SlideComponent = ({ slide }: SlideProps) => {
    const classes = useSlideStyles();

    const [layoutMenuAnchor, setLayoutMenuAnchor] = useState<null | HTMLElement>(null);

    const closeLayoutMenu = () => setLayoutMenuAnchor(null);
    const openLayoutMenu = (e: any) => setLayoutMenuAnchor(e.currentTarget);
    const [doc, setDoc] = React.useState(null);

    const transpile = (code: any, callback: any, onError: any) => {
        try {
            const result = transform(code, {
                objectAssign: 'Object.assign',
                transforms: {
                    dangerousForOf: true,
                    dangerousTaggedTemplateString: true,
                },
            });

            const res = new Function('React', 'ReactPPTX', ...Object.keys(primitives), result.code);

            res(React, { render: (doc: any) => callback(doc) }, ...Object.values(primitives));
        } catch (error) {
            if (onError) {
                onError(error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            const slideMarkup = `ReactPPTX.render(
                <Presentation> \n ${slide.markup} \n </Presentation>
                );`;

            transpile(
                slideMarkup,
                function(doc: any) {
                    setDoc(doc);
                },
                function(error: any) {
                    console.log(error);
                }
            );
        })();
    }, [slide]);

    return (
        <div className={classes.slideContainer}>
            <div className={classes.slidePreviewContainer}>
                <Paper elevation={0} square={true} className={classes.slideItem}>
                    <div className={classes.slidePreview}>{doc && <Preview>{doc}</Preview>}</div>
                </Paper>
                <div className={classes.layoutIconOuterContainer}>
                    <div
                        className={classes.layoutIconContainer}
                        role="presentation"
                        onClick={openLayoutMenu}
                        data-testid="layout-menu"
                    >
                        <LayoutIcon name={slide?.layout?.name} />
                    </div>
                </div>
            </div>
            {layoutMenuAnchor && (
                <LayoutMenu slide={slide} close={closeLayoutMenu} layoutMenuAnchor={layoutMenuAnchor} />
            )}
        </div>
    );
};

const Slide = React.memo(SlideComponent);

export { Slide };
