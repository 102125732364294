import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Reader = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.875 10.125V9H13.5V14.625H14.625V12.375H16.3125V11.25H14.625V10.125H16.875Z"
                    fill="#BABABA"
                />
                <path
                    d="M10.6875 14.625H8.4375V9H10.6875C11.1349 9.00045 11.5639 9.17838 11.8802 9.49475C12.1966 9.81112 12.3746 10.2401 12.375 10.6875V12.9375C12.3746 13.3849 12.1966 13.8139 11.8802 14.1302C11.5639 14.4466 11.1349 14.6246 10.6875 14.625ZM9.5625 13.5H10.6875C10.8366 13.4999 10.9796 13.4405 11.0851 13.3351C11.1905 13.2296 11.2499 13.0866 11.25 12.9375V10.6875C11.2499 10.5384 11.1905 10.3954 11.0851 10.2899C10.9796 10.1845 10.8366 10.1251 10.6875 10.125H9.5625V13.5Z"
                    fill="#BABABA"
                />
                <path
                    d="M6.1875 9H3.375V14.625H4.5V12.9375H6.1875C6.48573 12.9371 6.77162 12.8184 6.9825 12.6075C7.19338 12.3966 7.31205 12.1107 7.3125 11.8125V10.125C7.3122 9.82672 7.19358 9.54075 6.98267 9.32983C6.77175 9.11892 6.48578 9.0003 6.1875 9ZM4.5 11.8125V10.125H6.1875L6.18806 11.8125H4.5Z"
                    fill="#BABABA"
                />
                <path
                    d="M12.375 7.87501V5.62501C12.377 5.55108 12.3629 5.4776 12.3338 5.40963C12.3047 5.34166 12.2612 5.2808 12.2063 5.23126L8.26875 1.29376C8.21923 1.23883 8.15838 1.19529 8.0904 1.16616C8.02242 1.13703 7.94893 1.12299 7.875 1.12501H2.25C1.95191 1.1259 1.66627 1.24471 1.45549 1.4555C1.2447 1.66628 1.12589 1.95191 1.125 2.25001V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H11.25V15.75H2.25V2.25001H6.75V5.62501C6.75089 5.9231 6.8697 6.20873 7.08049 6.41952C7.29127 6.63031 7.57691 6.74912 7.875 6.75001H11.25V7.87501H12.375ZM7.875 5.62501V2.47501L11.025 5.62501H7.875Z"
                    fill="#BABABA"
                />
            </svg>
        </SvgIcon>
    );
};
