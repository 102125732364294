import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';

import { colors } from '../../assets/colors';
import { DrawerWrapper, Navbar } from '../common';
import { style } from './style';
import { AccountSettings } from './account';
import { alertBarHeight, SETTINGS_ITEMS } from '../../constants';
import { Preferences } from './preferences';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/combineReducers';

export const Settings: React.FC = () => {
    const classes = style();
    const history = useHistory();

    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const navigateToPageNotFound = () => {
        history.replace('/404');
        return null;
    };

    return (
        <div className={classes.root}>
            <div className={classes.navbar}>
                <Navbar />
            </div>

            <div className={classes.settingsPageMainContent} style={{ marginTop: isVisible ? alertBarHeight : 0 }}>
                <DrawerWrapper>
                    {SETTINGS_ITEMS.map(({ path, name }) => (
                        <div
                            key={path}
                            className={classes.accountSettings}
                            onClick={() => history.replace(path)}
                            style={{
                                backgroundColor: location.pathname === path ? colors.backgroundColor2 : colors.white,
                            }}
                            data-testid="accountSettings"
                        >
                            {name}
                        </div>
                    ))}
                </DrawerWrapper>
                <Switch>
                    <Route path={`/settings`} exact component={AccountSettings} />
                    <Route path={`/settings/preferences`} exact component={Preferences} />
                    <Route path={`*`} component={navigateToPageNotFound} />
                </Switch>
            </div>
        </div>
    );
};
