import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Speaker, SpeakerSubscriptionStatuses } from '../../../types';
import { getSpeakerSubscriptionStatus } from '../../../utils';

const initialState: Speaker = {
    id: '',
    name: '',
    identityId: '',
    email: '',
    professionalCategory: '',
    specialty: '',
    country: '',
    institution: {
        name: '',
        id: '',
        country: '',
    },
    emailVerified: false,
    stripeCustomerId: '',
    allowUser: false,
    trialEnds: undefined,
    isSubscriptionActive: undefined,
    createdAt: '',
    updatedAt: '',
    subscriptionStatus: SpeakerSubscriptionStatuses.NEW_USER,
    freeTrialRemainingDays: 0,
};

const getSpeakerStutus = ({ allowUser, trialEnds, isSubscriptionActive }: Speaker) => {
    return getSpeakerSubscriptionStatus(allowUser, trialEnds, isSubscriptionActive);
};

const speakerSlice = createSlice({
    name: 'Speaker',
    initialState,
    reducers: {
        loginSpeaker: (state, action: PayloadAction<Speaker>) => {
            state = action.payload;
            const { status, freeTrialRemainingDays } = getSpeakerStutus(action.payload);
            state = { ...state, subscriptionStatus: status, freeTrialRemainingDays: freeTrialRemainingDays || 0 };
            return state;
        },
        setSubscriptionData: (state, action: PayloadAction<Speaker>) => {
            const { status, freeTrialRemainingDays } = getSpeakerStutus(action.payload);
            state = { ...state, subscriptionStatus: status, freeTrialRemainingDays: freeTrialRemainingDays || 0 };
            return state;
        },
        signupSpeaker: (state, action: PayloadAction<Speaker>) => {
            state = action.payload;
            const { status, freeTrialRemainingDays } = getSpeakerStutus(action.payload);
            state = { ...state, subscriptionStatus: status, freeTrialRemainingDays: freeTrialRemainingDays || 0 };
            return state;
        },
    },
});

export const { loginSpeaker, signupSpeaker, setSubscriptionData } = speakerSlice.actions;
export default speakerSlice.reducer;
