import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export const TextLinesIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="0.5"
                y="0.5"
                width="34"
                height="34"
                rx="2.5"
                fill="white"
                stroke="#BABABA"
            />
            <line x1="10" y1="11.5" x2="25.9999" y2="11.5" stroke="#C4C4C4" />
            <line
                x1="10.0001"
                y1="14.8872"
                x2="26"
                y2="14.8872"
                stroke="#C4C4C4"
            />
            <line
                x1="10.0001"
                y1="18.2742"
                x2="26"
                y2="18.2742"
                stroke="#C4C4C4"
            />
            <line
                x1="10.0001"
                y1="25.0483"
                x2="26"
                y2="25.0483"
                stroke="#C4C4C4"
            />
            <line
                x1="10.0001"
                y1="21.6614"
                x2="26"
                y2="21.6614"
                stroke="#C4C4C4"
            />
        </SvgIcon>
    );
};
