import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        toggleContainer: {
            width: '258px',
            height: '42px',
            background: colors.planSwitchBackground,
            borderRadius: '33px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1px 3px',
        },
        selected: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '7px 26px',
            width: 'auto',
            height: '38px',
            background: colors.white,
            borderRadius: '64px',
            fontSize: '13px',
            '&:hover': {
                backgroundColor: colors.white,
                color: colors.marketingPageText,
            },
        },
        btn: {
            width: '158px',
            fontStyle: 'normal',
            fontSize: '13px',
            lineHeight: '24px',
            textAlign: 'center',
            color: colors.white,
        },
    })
);
