import { Backdrop, Box, CircularProgress, createTheme, Divider, Drawer, MuiThemeProvider } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../../assets/colors';
import { themeThumbnailComponents } from '../../../../assets/thumbnails/themes';
import { updatePresentationTheme } from '../../../../backend/presentation';
import { alertTypes } from '../../../../constants';
import { RootState } from '../../../../redux/combineReducers';
import { setPresentation, showAlert } from '../../../../redux/reducers';
import { Theme } from '../../../../types';
import { DrawerToggler } from '../../../common';
import { useThemeDrawerStyles } from './style';

const theme = createTheme({
    // to put the theme drawer beneath the navbar and DrawerToggler. make sure those components are working after changing these values.
    zIndex: {
        modal: 10,
        drawer: 10,
    },
    palette: {
        primary: {
            main: colors.primaryButton, // for circular progress icon color
        },
    },
});

interface Props {
    themes: Theme[] | null;
}
export const ThemeDrawer = ({ themes }: Props) => {
    const classes = useThemeDrawerStyles();
    const dispatch = useDispatch();
    const [openThemesDrawer, setOpenThemesDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const { presentation } = useSelector((state: RootState) => state.ProjectPresentation);
    const speaker = useSelector((state: RootState) => state.Speaker);
    const { isVisible } = useSelector((state: RootState) => state.AlertHandler);

    const handleThemesDrawer = () => {
        setOpenThemesDrawer(!openThemesDrawer);
    };

    const handleChangeTheme = async (themeId: string) => {
        setLoading(true);
        if (presentation && speaker.id) {
            try {
                const outlinePresentation = await updatePresentationTheme(presentation.id, themeId, speaker.id);
                dispatch(
                    setPresentation({
                        presentation: outlinePresentation,
                    })
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error instanceof Error) {
                    dispatch(showAlert({ isVisible: true, message: error.message, type: alertTypes.error }));
                }
            }
        }
    };
    return (
        <MuiThemeProvider theme={theme}>
            <Drawer
                anchor="right"
                open={openThemesDrawer}
                PaperProps={{ className: classes.themeListDrawer }}
                BackdropProps={{ invisible: true }}
                onClose={handleThemesDrawer}
            >
                <Box className={`${classes.themeListContainer} ${isVisible ? classes.alertBarMargin : ''}`}>
                    <h3>Themes</h3>
                    {themes &&
                        themes.map((theme) => {
                            const ThemeThumbnail = themeThumbnailComponents[theme.name];
                            return (
                                <>
                                    <Box
                                        key={theme.id}
                                        className={`${classes.themeThumbnail} ${presentation?.themeId === theme.id &&
                                            classes.currentTheme}`}
                                        onClick={() => handleChangeTheme(theme.id)}
                                    >
                                        <h4>{theme.name}</h4>
                                        <ThemeThumbnail />
                                    </Box>
                                    <Divider variant="middle" className={classes.divider} />
                                </>
                            );
                        })}
                    <Backdrop className={classes.loaderBackdrop} open={loading}>
                        <CircularProgress className={classes.loader} />
                    </Backdrop>
                </Box>
            </Drawer>
            <Box className={classes.drawerTogglerContainer}>
                <DrawerToggler
                    arrowStyle={{ transform: openThemesDrawer ? 'rotate(0deg)' : 'rotate(-180deg)' }}
                    toggleDrawer={handleThemesDrawer}
                    open={!openThemesDrawer}
                />
            </Box>
        </MuiThemeProvider>
    );
};
