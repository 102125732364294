import { Grid } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { FormikProps, withFormik } from 'formik';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NimdoneBetaLogo } from '../../../assets/icons';
import { addIdentityId, getSpeaker } from '../../../backend/speaker';
import config from '../../../config';
import { alertTypes, gtm } from '../../../constants';
import { loginSpeaker, showAlert } from '../../../redux/reducers';
import { GTMService } from '../../../services/gtmService';
import { Speaker, SpeakerSubscriptionStatuses } from '../../../types';
import { getSpeakerSubscriptionStatus, useQuery } from '../../../utils';
import { loginValidation } from '../../../validation';
import { MuiCMButton, TextInput } from '../../common';
import { routes } from '../../pages/routes';
import { style } from './style';

interface loginFormValues {
    email: string;
    password: string;
}

interface loginFormProps {
    dispatch: Dispatch<any>;
    history: any;
    redirectUrl: URL;
}

const InnerForm = (props: FormikProps<loginFormValues>) => {
    const { handleSubmit, touched, errors, values, handleChange, isSubmitting } = props;
    const classes = style();

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                placeholder="yourname@gmail.com"
                name="email"
                key="email"
                value={values.email}
                label="E-mail address"
                id="email"
                onChange={handleChange}
                containerClassName={classes.input}
                error={errors.email && touched.email ? errors.email : ''}
            />
            <TextInput
                placeholder="********"
                name="password"
                key="password"
                value={values.password}
                label="Password"
                id="password"
                onChange={handleChange}
                containerClassName={classes.input}
                type="password"
                error={errors.password && touched.password ? errors.password : ''}
            />
            <MuiCMButton loading={isSubmitting} className={classes.button} type="submit" fullWidth>
                Login
            </MuiCMButton>
        </form>
    );
};

const MyForm = withFormik<loginFormProps, loginFormValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
    }),
    validationSchema: loginValidation,
    handleSubmit: (values, { setSubmitting, props }) => {
        const { email, password } = values;
        const { dispatch, history, redirectUrl } = props;

        Auth.signIn(email.trim(), password)
            .then((res) => {
                getSpeaker(res?.username)
                    .then(async (res: Speaker) => {
                        if (res) {
                            //fire gtm event
                            const gtmService = new GTMService();
                            await gtmService.handleEvent({
                                event: gtm.events.LOGIN,
                                userId: res.id,
                            });
                            //end gtm event
                            const { identityId, email, allowUser, isSubscriptionActive, trialEnds } = res;
                            if (!identityId) {
                                const { identityId: speakerIdentityId } = await Auth.currentUserCredentials();

                                await addIdentityId(email, speakerIdentityId);
                            }
                            localStorage.setItem('unauth_speaker', JSON.stringify(res));
                            dispatch(loginSpeaker(res));

                            if (config.featureFlags.pricing) {
                                const { status } = getSpeakerSubscriptionStatus(
                                    allowUser,
                                    trialEnds,
                                    isSubscriptionActive
                                );
                                if (status === SpeakerSubscriptionStatuses.NEW_USER) history.push(routes.selectPlan);
                                else
                                    history.push({
                                        pathname: redirectUrl.pathname,
                                        search: redirectUrl.search,
                                    });
                            } else
                                history.push({
                                    pathname: redirectUrl.pathname,
                                    search: redirectUrl.search,
                                });
                        } else
                            dispatch(
                                showAlert({
                                    message: 'Something went wrong',
                                    isVisible: true,
                                    type: alertTypes.error,
                                })
                            );
                        setSubmitting(false);
                    })
                    .catch((error: NodeJS.ErrnoException) => {
                        const message = typeof error === 'string' ? error : error.message;
                        dispatch(
                            showAlert({
                                message,
                                isVisible: true,
                                type: alertTypes.error,
                            })
                        );
                        setSubmitting(false);
                    });
            })
            .catch((error: NodeJS.ErrnoException) => {
                if (error.code === 'UserNotConfirmedException')
                    dispatch(
                        showAlert({
                            message: 'Please click the verification link in your email before login',
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );
                else
                    dispatch(
                        showAlert({
                            message: error.message,
                            isVisible: true,
                            type: alertTypes.error,
                        })
                    );

                setSubmitting(false);
            });
    },
})(InnerForm);

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = style();
    const redirect = useQuery().get('redirect');
    const redirectUrl = new URL(redirect ? redirect : window.location.origin + routes.library);

    return (
        <Grid container className={classes.container}>
            <NimdoneBetaLogo viewBox={'0 0 68 70'} className={classes.logo} data-testid="nimdone-logo" />
            <div className={classes.formContainer}>
                <h1 className={classes.heading}> Log into your account</h1>
                <MyForm dispatch={dispatch} history={history} redirectUrl={redirectUrl} />
                <p className={classes.noAccountText} data-testid="signup-reference">
                    <span className={classes.forgotPassText} onClick={() => history.push(routes.forgotPassword)}>
                        Forgot Password?
                    </span>
                    <br />
                    Don't have an account yet? &nbsp;
                    <span className={classes.signupText} onClick={() => history.push(routes.signup)}>
                        Sign up
                    </span>
                </p>
            </div>
        </Grid>
    );
};
