import { OnboardingSection, SpeakerOnboarding } from '../types';
import { executeGraphqlCall } from './api';

export const getAllOnboardingSections = async (): Promise<OnboardingSection[]> => {
    const body = `query GetAllOnboardingSections {
        getAllOnboardingSections {
          id
          title
          onboards {
            id
            title
            hint
            videoLink
          }
        }
      }`;
    return await executeGraphqlCall<OnboardingSection[]>('getAllOnboardingSections', body);
};

export const getSpeakerOnboardings = async (speakerId: string): Promise<SpeakerOnboarding[]> => {
    const variables = { data: { speakerId } };
    const body = `query GetAllSpeakerOnboardings($data: GetAllSpeakerOnboardingsInput!) {
        getAllSpeakerOnboardings(data: $data) {
          id
          onboardingId
        }
      }`;

    return await executeGraphqlCall<SpeakerOnboarding[]>('getAllSpeakerOnboardings', body, undefined, variables);
};

export const addSpeakerOnboarding = async (speakerId: string, onboardingId: string): Promise<SpeakerOnboarding> => {
    const variables = { data: { speakerId, onboardingId } };
    const body = `mutation AddSpeakerOnboarding($data: UpdateSpeakerOnboardingInput!) {
        addSpeakerOnboarding(data: $data) {
          id
          onboardingId
        }
      }`;

    return await executeGraphqlCall<SpeakerOnboarding>('addSpeakerOnboarding', body, undefined, variables);
};
