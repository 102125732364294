import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../../assets/colors';

export const style = makeStyles(() =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 25,
            backgroundColor: colors.white,
            borderRadius: 5,
            height: 400,
            width: '100%',
            boxShadow: `0px 0px 5px ${colors.boxShadow2}`,
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 30,
            width: 100,
            position: 'relative',
            height: 120,
        },
        tildEmptyRowsContainer: {
            top: -20,
            left: -50,
            right: 50,
            bottom: 20,
            zIndex: 1,
            background: colors.white,
            position: 'absolute',
            transform: 'rotate(-15deg)',
            border: `3px solid ${colors.border}`,
            borderRadius: 10,
            padding: 10,
        },
        straightContainer: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            background: colors.white,
            border: `3px solid ${colors.border}`,
            borderRadius: 10,
            position: 'absolute',
            padding: 10,
        },
        parentContainer: {
            marginTop: 10,
            marginBottom: 10,
            display: 'flex',
        },
        filedSquareContainer: {
            height: 25,
            width: 40,
            backgroundColor: colors.darkTeal,
        },
        emptyRowContainers: {
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        emptyRowOne: {
            height: 3,
            backgroundColor: colors.backgroundColor2,
            width: 20,
            marginBottom: 3,
        },
        emptyRowTwo: {
            height: 3,
            backgroundColor: colors.backgroundColor2,
            width: 30,
            marginBottom: 3,
        },

        textOne: {
            color: colors.smoke,
        },
        textTwo: {
            color: colors.text,
            fontWeight: 600,
        },
    })
);
