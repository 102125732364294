import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        doiList: {
            paddingLeft: '10px',
            margin: 0,
        },
    })
);

export const DoiModalHelpContent = () => {
    const classes = useStyles();
    return (
        <ol className={classes.doiList}>
            <li>
                In most scholarly journal articles, the DOI will be printed with the article itself, usually on the
                first page somewhere: below the title or in the header or footer.
            </li>
            <li>
                If the DOI isn't included in the article, look it up on the website CrossRef.org (use the "Search
                Metadata" option) to check for an assigned DOI.
            </li>
        </ol>
    );
};
